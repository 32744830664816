/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/AddService";
class serviceIntegration {

   
    async getServiceList(partnersId) {
        const response = await http.get(`${controller}/GetServiceList/${partnersId}`);
        const data = response;
        return data;
    }

    
    async addService(serviceData) {
        const response = await http.post(`${controller}/AddService`, serviceData);
        const data = response;
        return data;
    }
    async deleteService(id) {
        const response = await http.delete(`${controller}/DeleteService?id=${id}`);
        const data = response;
        return data;
    }

    async getService(id) {
        const response = await http.get(`${controller}/${id}`);
        const data = response;
        return data;
    }

    async updateService(id) {
        const response = await http.put(`${controller}/${id}`);
        const data = response;
        return data;
    }

    async mapLeadandJobId(partnersId,leadId,job, alertId, locationId) {
        const response = await http.get(`${controller}/addorMappLeadandJobIds?partnersId=${partnersId}&leadId=${leadId}&job=${job}&alertId=${alertId}&locationId=${locationId}`);
        const data = response;
        return data;
    }

}

export default new serviceIntegration();