/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import "../../../CSS/DataModal.css";
import styled from "styled-components";
import { Row, Container, Figure } from 'react-bootstrap';

const StyledContainer = styled.div`
    padding: 5px;
    text-align: left;
    margin-left: 10px;
    margin-top: 5px;
    padding-bottom: 0;
`;

const SideIcon = styled.div`
    padding-top:0;
    margin-bottom: 0;
`;

export class ResetToFactory extends React.Component {
   
    render() {
        return (
            <Container>
                <StyledContainer>
                    <Row>
                        <div className="col-xs-11 col-sm-5 col-md-6">
                            <h5>Reset the Gateway</h5>
                            <div className="row">
                              <div id="color-description">
                                    <small> Use a paperclip to press the Reset button on the Gateway. You can also power cycle the Gateway to reset it. If there are issues with the Gateway, resetting it can be used as the first troubleshooting step as it triggers the Gateway to connect to the OTA server to check the latest firmware update.
                                    </small>
                                </div>
                            </div>
                            <br/>
                        </div> 
                        <div className="col-xs-10 col-sm-6 col-md-5 col-lg-4 col-xl-3 offset-1" id="pic">
                                <SideIcon>
                                    <Figure>
                                        <Figure.Image
                                            width="auto"
                                            src={require('../../../img/Gateway-1.png')}
                                            alt='gatewayImg'
                                        />
                                    </Figure>
                                </SideIcon>
                        </div> 
                    </Row>
                </StyledContainer>
                <br/>
                <StyledContainer>
                    <Row>
                        <div className="col-xs-11 col-sm-5 col-md-6">
                            <h5>Reset the Sensor Hub</h5>
                            <div className="row">
                                <div id="color-description">
                                    <small> Use a paperclip to press the Reset button on the Sensor Hub. If there are issues with the Sensor Hub, resetting it can be used as the first troubleshooting step</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-10 col-sm-6 col-md-5 col-lg-4 col-xl-3 offset-1" id="pic">
                            <SideIcon>
                                <Figure>
                                    <Figure.Image
                                        width="auto"
                                        src={require('../../../img/SensorHubReset.png')}
                                        alt='sensorImg'
                                    />
                                </Figure>
                            </SideIcon>
                        </div> 
                    </Row> 
                </StyledContainer>
            </Container>
        );
    }
}