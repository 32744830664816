/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';

class AddMultipleEmailAddresses extends Component {

    onAddEmailClicked = () => {
        this.props.onAddEmailClicked();
    }

    onDeleteEmailClicked = (position) => {
        this.props.onDeleteEmailClicked(position);
    }

    onEmailChanged = (e, value, position) => {
        this.props.onEmailChanged(e, value, position);
    }

    render() {
        let contents = [];
        let onDeleteEmailClicked = this.onDeleteEmailClicked;
        let onEmailChanged = this.onEmailChanged;

        if (this.props.existingEmailAddresses && this.props.existingEmailAddresses.length > 0) {
            this.props.existingEmailAddresses.forEach(function (value, i) {
                contents.push(<div key={`multipleEmails-${i}`}>
                    <span style={{ fontSize: 14 }}>Email {i + 1}</span>
                    <div style={{ display: "flex" }}>
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            value={value ? value : ""}
                            onChange={e => onEmailChanged(e, e.target.value, i)}
                            style={{ float: "left" }}
                        />
                        <button type="button" className="btn-close" style={{ float: "right", marginLeft: 5 }} onClick={e => onDeleteEmailClicked(i)}>
                            <span>&times;</span>
                        </button>
                    </div>
                </div>)
            })
        } else {
            if (this.props.showMessage)
                contents.push(<div key={`multipleEmailss`}>
                    <span style={{ fontSize: 14 }}>No system email addresses. These are used to notify the designated individuals of updates to our system. Select <b>Add Email Address</b> to configure.</span>
                </div>)
        }

        return (
            <div style={{ marginBottom: 10 }}>
                {contents}
                <button type="button" className="secondary-btn btn-small" style={{ marginTop: 10, marginBottom: 10 }} onClick={this.onAddEmailClicked}>Add Email Address</button>
            </div>
        );
    }
}

export default AddMultipleEmailAddresses;
