/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";

class IdleTriageModal extends Component {
    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };


    render() {
        if (!this.props.show) {
            return null;
        }
        else {
            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Idle Triage Session</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); }}>×</span>
                        </div>

                        <div className="modal-body">
                            <p style={{ textAlign: "left" }}>
                                You have been inactive for over 5 minutes, so your Triage Session has automatically ended. Click "ok" to start a new Triage Session.
                            </p>
                        </div>

                        <div className="modal-footer">
                            <button className="primary-btn btn-small" onClick={e => { this.onClick(e); }}>Ok</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default IdleTriageModal;