/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, {useState} from 'react';
import "../../CSS/DataModal.css";
import { Spinner, Image } from 'react-bootstrap';
import {Event} from "../GoogleAnalytics";
import GetAppIcon from '@material-ui/icons/GetApp';
import MenuIcon from "@material-ui/icons/Menu";
import LongArrowDownIcon from "@material-ui/icons/ArrowDownward";
import LongArrowUpIcon from "@material-ui/icons/ArrowUpward";
import DatePicker from 'react-datepicker';

const csvBaseName = "AgeOfUnitsReport";

const customHeadings = {
    assetType: "ASSET TYPE",
    zeroToFive: '0-5 YEARS',
    sixToTen: '6-10 YEARS',
    elevenToFifteen: '11-15 YEARS',
    sixteenPlus: '16+ YEARS',
    total: 'TOTAL'
};

function convertToCSV(objArray) {
    const headers = Object.keys(objArray[0]);

    const customHeaders = headers.map(key => customHeadings[key]);

    const array = [customHeaders].concat(
        objArray.map(obj => headers.map(header => convertPascalToReadable(obj[header])))
    );

    return array.map(row => row.join(',')).join('\n');
};

function downloadCSV(data) {
    Event("Download Data Clicked", "User clicked download data button on Triage Alerts Monthly Summary", "Download Data Clicked");
    const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(data));
    const link = document.createElement("a");
    link.setAttribute("href", csvContent); // Set csvContent directly
    link.setAttribute("download", csvBaseName || "data.csv");
    document.body.appendChild(link);
    link.click();
};


function convertPascalToReadable(name) {
    if (typeof name === 'string') {
            return name
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/^([A-Z])/g, ' $1')
        .trim();
    }
    return name;
}

function AgeOfUnitsModal(props) {
    const { classes, show, ageData, loading, onClick, loadingMessage } = props;
    const onModalClick = (e) => {
        onClick && onClick(e);
    };

    const onCancelClick = () => {

    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();

        // Pad single-digit month and day with leading zero
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        return `${formattedMonth}/${formattedDay}/${year}`;
    }

    let ageContent = loading ?
        <div>
                <Spinner animation="border" variant="dark"/>
                <p style={{ color: 'black', marginTop: '10px' }}> {loadingMessage} </p>
        </div>
        :
        ageData.length ?
            <table className="table-hover" style={{width: '100%'}}>
                <thead>
                <tr className="alerts-table-row">
                    <th style={{padding: '0px 0px 0px 10px'}}>
                        <span style={{cursor: 'pointer'}}>ASSET TYPE</span>
                    </th>
                    <th>
                        <span style={{cursor: 'pointer'}}>0-5 YEARS</span>
                    </th>
                    <th>
                        <span style={{cursor: 'pointer'}}>6-10 YEARS</span>
                    </th>
                    <th>
                        <span style={{cursor: 'pointer'}}>11-15 YEARS</span>
                    </th>
                    <th>
                        <span style={{cursor: 'pointer'}}>16+ YEARS</span>
                    </th>
                    <th>
                        <span style={{cursor: 'pointer'}}>TOTAL</span>
                    </th>
                    <th className="table-shrink"
                        style={{textAlign: 'center', padding: '10px 10px 10px 5px'}}></th>
                </tr>
                </thead>

                <tbody>
                {ageData.map((entry, index) =>
                    [<tr className="alerts-table-row" key={`${entry.assetType}-tableRow`} data={index} value={index}>
                        <td style={{padding: '16px 0px 16px 10px', borderLeft: '1px solid #ebeef0'}}>
                            {convertPascalToReadable(entry.assetType)}
                        </td>
                        <td>{entry.zeroToFive}</td>
                        <td>{entry.sixToTen}</td>
                        <td>{entry.elevenToFifteen}</td>
                        <td>{entry.sixteenPlus}</td>
                        <td className="alerts-td-right">{entry.total}</td>
                    </tr>
                    ])}

                </tbody>
            </table>
            : 
            <table>
                <tbody>
                    <tr>
                        <td>{"No Triage Metrics"}</td>
                    </tr>
                </tbody>
            </table>;

    return (
        <div className="modal" style={{display: show ? "block" : "none"}}>
            <div className="modal-wrapper"
                 style={{
                     transform: show ? 'translateY(0vh)' : 'translateY(-100vh)',
                     opacity: show ? '1' : '0'
                 }}>

                <div className="modal-header">
                    <h3>Age of Units Table</h3>
                    <button className="primary-btn btn-small" 
                            onClick={() => downloadCSV(ageData)}>
                        <GetAppIcon />
                    </button>

                    <span className="close-modal-btn" onClick={e => {
                        onModalClick(e);
                        onCancelClick();
                    }}>×</span>
                </div>

                <div className="modal-body">
                    {ageContent}
                </div>

                <div className="modal-footer" style={{position: 'sticky', bottom:0}}>
                    <button className="primary-btn btn-small" onClick={e => {
                        onModalClick(e);
                        onCancelClick();
                    }}>Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AgeOfUnitsModal;