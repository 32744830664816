/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { useState, useEffect } from 'react';
import serviceIntegration from '../../services/serviceIntegration.service';

export default function DeleteIntegrationModel(props) {

 const [isDeletEnable, setIsDeletEnable] = useState(false);

    const deleteService = () => {
        serviceIntegration.deleteService(props?.delete?.id)
            .then(response => {
              //  if (response && response?.data) {
                 setIsDeletEnable(false);
                 props.onChage(false);
               // }
            })
            .catch(e => {
                console.log(e);
            });
    };


   const onCancelClick = () => {
        setIsDeletEnable(false);
        props.onChage(false);
    };


    return (<div className="modal" style={{ display: "block"  }}>
        <div className="modal-wrapper"
            style={{
                transform:  'translateY(0vh)' ,
                opacity:  '1' 
            }}>

            <div className="modal-header">
                <h3>Delete Integration</h3>
                <span className="close-modal-btn" onClick={onCancelClick }>×</span>
            </div>

            <div className="modal-body">
	            <p>Are you sure you want to remove below service ?</p>
                <br />
                <p>Service Type : {props.delete.serviceType}</p>
                <p>Last Updated on : {props.delete.updatedTimeStamp}</p>
            </div>

            <div className="modal-footer">
                <button className="secondary-btn btn-small" onClick={ onCancelClick }>Cancel</button>
                <button className="primary-btn btn-small" onClick={deleteService}>Yes</button>
            </div>
        </div>
    </div>)
}

    