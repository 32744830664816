/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import "../../CSS/DataModal.css";
import "react-datepicker/dist/react-datepicker.css";

class SilenceAlertModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            yesClicked: false,
            selectedDate: null
        };

        this.onChangeDate = this.onChangeDate.bind(this);
        this.onYes = this.onYes.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {

    };

    onYes() {
        this.setState({ yesClicked: true });
    }

    onConfirm() {
        this.props.onConfirm(this.state.selectedDateStr);
        this.setState({ yesClicked: false });
    }

    onChangeDate(e) {
        var date = moment(e).toDate();
        var dateStr = moment(e).format();
        this.setState({
            selectedDate: date,
            selectedDateStr: dateStr
        });
    }

    /*handleRemove(e, classId) {
        if (e.target.checked) {
            this.props.removeList = this.props.removeList.concat(classId);
        }
    };

    handleApply(e, classId) {
        if (e.target.checked) {
            this.props.applyList.concat(classId);
        }
    };*/

    renderWarningContent() {
        return (
            <div>
                <div className="modal-body">
                    <br />
                    <p><b>Warning:</b> you are about to remove this {this.props.alertPriority} priority item from the alert list.<br /> Are you sure you would like to proceed?</p>
                    <br />
                </div>

                <div className="modal-footer">
                    <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                    <button className="primary-btn btn-small" onClick={() => this.onYes()}>Yes</button>
                </div>

            </div>
        );
    }

    renderDateContent() {
        return (
            <div>
                <div className="modal-body">
                    <p>When would you like to see this alert again?</p>
                    <DatePicker
                        id="muteDate"
                        name="muteDate"
                        selected={this.state.selectedDate}
                        onChange={date => this.onChangeDate(date)}
                        dateFormat="MM/dd/yyyy hh:mm:ss aa"
                        timeIntervals={15}
                        showTimeSelect
                        inline
                    />
                </div>

                <div className="modal-footer">
                    <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                    <button className="primary-btn btn-small" onClick={() => this.onConfirm()}>Confirm</button>
                </div>

            </div>
        );
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        else {
            let modalBodyFooter = this.state.yesClicked ? this.renderDateContent() : this.renderWarningContent();

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Silence Alert</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        {modalBodyFooter}

                    </div>
                </div>
            );
        }
    }
}

export default SilenceAlertModal;
