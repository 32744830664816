/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import "../../CSS/DataModal.css";
import {Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";

class ConfirmDeleteAccountModal extends React.Component {

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
        this.props.onCancelClick && this.props.onCancelClick();
    };

    render() {

        if (!this.props.show) {
            return null;
        }
        else {
            let content = this.props.loading ?
                <button className="primary-btn btn-small" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </button>
            : this.props.isDeleted ?
                    <button className="secondary-btn btn-small" onClick={e => {
                        this.onClick(e);this.onCancelClick();}}>Ok</button>
                    : this.props.isActiveBillingPlan ?
                        <>
                            <button className="secondary-btn btn-small" onClick={e => {
                                this.onClick(e);
                                this.onCancelClick();
                            }}>Cancel
                            </button>
                            <Link className="primary-btn btn-small"
                                  to="/billingInfo">Go To Billing Info
                            </Link>
                        </>
                        :
                        <>
                            <button className="secondary-btn btn-small" onClick={e => {
                                this.onClick(e);this.onCancelClick();}}>Cancel</button>
                        <button className="primary-btn btn-small" disabled={this.props.isDeleted}
                                onClick={() => this.props.onConfirm(this.props.accountId) }>Yes</button>
                    </>
            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                         style={{
                             transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                             opacity: this.props.show ? '1' : '0'
                         }}>

                        <div className="modal-header">
                            <h3>Delete {this.props.title ? this.props.title : 'Account'}</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body">
                            <br/>
                            <p>{this.props.messageBody}</p>
                        </div>

                        <div className="modal-footer">
                            {content}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default ConfirmDeleteAccountModal;