/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import "../../CSS/DataModal.css";

class FullScreenChartModal extends React.Component {

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {

    };

    render() {

        if (!this.props.show) {
            return null;
        }
        else {

            return (
                <div className="fullscreen-modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="big-modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header" >
                            <h3>{this.props.title}</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ textAlign: "center", justifyContent: "center" }}>
                            {this.props.graph}
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Close</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default FullScreenChartModal;