/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import "../../CSS/DataModal.css";

class AskSupportModal extends React.Component {

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {

    };

    superscriptTrademark(message) {
        const trademark = "TM";

        if (message.includes(trademark)) {
            const parts = message.split(trademark);
            const trademarkPart = <sup>{trademark}</sup>;

            return (
                <p>
                    {parts[0]}
                    {trademarkPart}
                    {parts[1]}
                </p>
            );
        }
        else {
            return (
                <p>
                    {message}
                </p>
            );
        }  
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        else {
            var modalButtons = this.props.isCloseOnly ?
                <div className="modal-footer">
                    <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Close</button>
                </div> :
                <div className="modal-footer">
                    <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>No</button>
                    <button className="primary-btn btn-small" onClick={this.props.onYes}>Yes</button>
                </div>

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>{this.props.title}</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body">
                            <br />
                            {this.superscriptTrademark(this.props.message)}
                        </div>
                        {modalButtons}
                    </div>
                </div>
            );
        }
    }
}

export default AskSupportModal;