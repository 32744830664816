/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
import "../../CSS/DataModal.css";

const animatedComponents = makeAnimated();

//Verfied has a date
//False Positive is a bool
//Unverified is unknown within the headers for the details call


class AlertEditModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
    };

    getOptionLabel = (option) => {
        return (option["priorityName"]);
    }

    getOptionValue = (option) => {
        return (option["priority"]);
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        else {
            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Edit Alert</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body-2" style={{ textAlign: "left" }}>
                            <form>
                                <div className="row">
                                    <div className="col">
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Alert ID</p>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="alertId"
                                                value={this.props.editAlert.alertId}
                                                disabled={true}
                                            />
                                        </div>
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Name</p>
                                            <input 
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                value={this.props.editAlert.name}
                                                disabled={true}
                                            />
                                        </div>
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Description</p>
                                            <input 
                                                type="text"
                                                className="form-control"
                                                id="description"
                                                value={this.props.editAlert.message}
                                                disabled={true}
                                            />
                                        </div>
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Type</p>
                                            <input 
                                                type="text"
                                                className="form-control"
                                                id="type"
                                                value={this.props.editAlert.type}
                                                disabled={true}
                                            />
                                        </div>
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Start Time</p>
                                            <input 
                                                type="text"
                                                className="form-control"
                                                id="startTime"
                                                value={this.props.editAlert.startTime}
                                                disabled={true}
                                            />
                                        </div>
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">End Time</p>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="endTime"
                                                value={this.props.editAlert.endTime}
                                                disabled={true}
                                            />
                                        </div>
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Message</p>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="message"
                                                value={this.props.editAlert.message}
                                                onChange={this.props.onMessageChange}
                                            />
                                        </div>
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Priority</p>
                                            <select className="selectpicker-modal" style={{ width: '220px' }} value={this.props.editAlert.priority} onChange={this.props.onPriorityChange}>
                                                <option key="0" id="0" defaultValue hidden value={0}>--Select Priority--</option>
                                                <option key="1" id="1" value={3}>Low</option>
                                                <option key="2" id="2" value={2}>Medium</option>
                                                <option key="3" id="3" value={1}>High</option>
                                            </select>
                                            <p className="formLbl">Verification</p>
                                            <select className="selectpicker-modal" style={{ width: '220px' }} value={this.props.editAlert.isFalsePositive != null ? this.props.editAlert.isFalsePositive : ''} onChange={this.props.onFalsePositiveChange}>
                                                <option key="0" id="0" defaultValue hidden value={0}>--Select Verification--</option>
                                                <option key="1" id="1" value={false}>Verified</option>
                                                <option key="2" id="2" value={true}>False Positive</option>
                                                <option key="3" id="3" value=''>Unverified</option>
                                            </select>
                                        </div>
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Recommendation</p>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="firstName"
                                                value={this.props.editAlert.comment}
                                                onChange={this.props.onRecommendationChange}
                                            />
                                        </div>
                                        <br/>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={this.props.onSave}>Save</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default AlertEditModal;