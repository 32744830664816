/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';

/*const StyledBreadcrumb = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[100],
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip);

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}*/

class InstallCheckBreadcrumb extends React.Component {

    getPrettyName(name) {
        if (name !== null && name !== undefined) {

            var split = name.split("__");
            name = split[0].replace("?", "'");

            const indexOfSlash = name.indexOf(' / ');
            if (indexOfSlash !== -1) {
                name = name.substring(indexOfSlash + 3);
            }

            return name;
        }
        else {
            return "";
        }
    }

    render() {
        let techInfo = this.props.techStr !== "" && this.props.techStr !== null && this.props.techStr !== undefined ?
            <span style={{ paddingLeft: '20px', color: 'slategrey' }}>Installed By: {this.props.techStr}</span>
            : null;

        return (
            
            <Breadcrumbs aria-label="breadcrumb">
               <select id="home" className="selectpicker" style={{ width: 200 + 'px' }} value={this.props.organizationId} onChange={this.props.onChange}>
                    {this.props.homes.map((obj, index) =>
                        <option key={`${index}-${obj.orgId}`} id={`${index}-${obj.orgId}`} value={index}>{this.getPrettyName(obj.nickname)}</option>
                    )}
                </select>
                <span>
                    <select className="selectpicker" style={{ width: 200 + 'px' }} value={this.props.houseAssetId} onChange={this.props.onHvacChange}>
                        {this.props.hvacList.map((obj, index) =>
                            <option key={`${index}-${obj.assetId}`} id={`${index}-${obj.assetId}`} value={index}>{this.getPrettyName(obj.name)}</option>
                        )}
                    </select>
                    {techInfo}
                </span>
                
            </Breadcrumbs>
        );
    }
}

export default InstallCheckBreadcrumb;
