/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";
import { Spinner } from 'react-bootstrap';

class TestSystemModal extends Component {

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    render() {

        if (!this.props.show) {
            return null;
        }
        else {
            var content = (this.props.systems.length && !this.props.systemTestLoading && this.props.systemTestResult === "") ?
                <select className="selectpicker" style={{ width: 220 + 'px' }} onChange={this.props.onSystemSelect} value={this.props.systems[0].type}>
                    {
                        this.props.systems.map((obj, index) => {
                            if (obj.type !== "Thermostat") {
                                return <option key={`${index}-${obj.assetId}`} id={`${index}-${obj.assetId}`} value={obj.assetId}>{obj.type}</option>
                            }
                            else {
                                return null;
                            }
                        })
                    } </select> :
                <Spinner animation="border" />

            var modalBody = (this.props.systems.length && !this.props.systemTestLoading && this.props.systemTestResult === "") ?
                <div className="modal-body">
                    <br />
                            What system would you like to run?
                            <div className="select-container" style={{ alignText: 'left' }}>
                        {content}
                    </div>
                    <br />
                </div>
                : (!this.props.systemTestLoading && this.props.systemTestResult !== "") ?
                    <div className="modal-body" >
                        <br />
                        Test Finished: <b>{this.props.systemTestResult}!</b>
                        <br />
                    </div>
                    : <div className="modal-body" >
                        <br />
                        <Spinner animation="border" /><br />
                        Testing System, this could take a while...
                        <br/>
                    </div>;

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Test System</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); }}>×</span>
                        </div>

                        {modalBody}

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); }}>Cancel</button>
                            <button type='button' className="primary-btn btn-small" onClick={this.props.systemTestResult === "" ? this.props.onSave : this.props.onClick}>Submit</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}


export default TestSystemModal;