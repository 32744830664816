/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import "../../CSS/DataModal.css";
import { Spinner, Image } from 'react-bootstrap';

class EmailHistoryModal extends React.Component {

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {

    };

    formatDate(unixString) {
        const milliseconds = unixString * 1000;

        // Create a new Date object using the milliseconds
        const dateObject = new Date(milliseconds);

        // Format the date as desired
        const formattedDate = dateObject.toLocaleString();

        return formattedDate;
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        else {
            let historyList = this.props.emailHistory;
            let emailDetails = this.props.emailDetails;
            let emailHeader = 
                <div style={{ height: '24px', display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                    <div style={{ display: "flex", margin: "0 auto", border: "2px solid #ebeef0" }}>
                        <span style={{}}>
                            <div style={{ textAlign: 'left' }}>
                                <div style={{ margin: '10px 10px 0 10px' }}><b>TO:</b></div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ margin: '0 10px 10px 10px' }}>{emailDetails.contactAlt}</div>
                            </div>
                        </span>
                    </div>
                    <div style={{ display: "flex", margin: "0 auto", border: "2px solid #ebeef0" }}>
                        <span style={{}}>
                            <div style={{ textAlign: 'left' }}>
                                <div style={{ margin: '10px 10px 0 10px' }}><b>DATE:</b></div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ margin: '0 10px 10px 10px' }}>{this.props.formatDate(emailDetails.arrivedAt)}</div>
                            </div>
                        </span>
                    </div>
                </div>;
            let historyContent = this.props.loading ?
                <div style={{ display: "block" }}>
                    <div className="loading-wrapper">
                        <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                    </div></div>
                :
                historyList.data.length ?
                <tbody>
                    {historyList.data.map((history, index) =>
                        [<tr className="alerts-table-row" key={`${history.id}-tableRow`} data={index} value={index} onClick={() => this.onEmailClick(index, history.id)}>
                            <td style={{ padding: '16px 0px 16px 10px', borderLeft: '1px solid #ebeef0' }}>
                                {this.props.getStatus(history.eventType)}
                            </td>
                            <td>{ history.state}</td>
                            <td className="alerts-td-right">{this.formatDate(history.eventAt)}</td>
                        </tr>
                        ])}

                </tbody>
                : <tbody>
                    <tr>
                        <td>{"No History Found For This Email"}</td>
                    </tr>
                </tbody>;

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>{this.props.emailDetails.subject}</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body">
                            <br />
                            {emailHeader}
                            <br /><br />
                            <table className="table-hover" style={{ width: '100%' }}>

                                <thead>
                                    <tr className="alerts-table-row">
                                        <th style={{ padding: '0px 0px 0px 10px' }}>
                                            <span style={{ cursor: 'pointer' }}>ACTION</span>
                                        </th>
                                        <th>
                                            <span style={{ cursor: 'pointer' }}>DETAILS</span>
                                        </th>
                                        <th>
                                            <span style={{ cursor: 'pointer' }}>DATE</span>
                                        </th>
                                        <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 10px 10px 5px' }}></th>
                                    </tr>
                                </thead>

                                {historyContent}

                            </table>
                        </div>

                        <div className="modal-footer">
                            <button className="primary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Close</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default EmailHistoryModal;