/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";
import { Spinner } from 'react-bootstrap';

class SelectPartnerDataModal extends Component { 
    
    onClick = e => {
        this.props.onClick && this.props.onClick(e);
      };

    render() {
                
        if(!this.props.show){
            return null;
        }
        else {
            var content = (this.props.partners.length) ?
                <select className="selectpicker" style={{ width: 220 + 'px' }} onChange={this.props.onPartnerSelect} value={this.props.mySelectedPartner}>
                    {
                        this.props.partners.map((obj, index) =>
                            <option key={`${index}-${obj.organizationId}`} id={`${index}-${obj.organizationId}`} value={obj.organizationId}>{obj.company}</option>
                        )
                    } </select> :
                <Spinner animation="border" />

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                            style={{
                                transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                                opacity: this.props.show ? '1' : '0'
                            }}>

                        <div className="modal-header">
                            <h3>Partners</h3>
                            <span className="close-modal-btn" onClick={e => {this.onClick(e);}}>×</span>
                        </div>

                        <div className="modal-body">
                            <br/>
                            Select a Partner
                            <div className="select-container" style={{ alignText: 'left' }}>
                                {content}
                            </div>
                            <br/>
                        </div>

                        <div className="modal-footer">                            
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); }}>Cancel</button>
                            <button type='button' className="primary-btn btn-small" onClick={this.props.onSave}>Save</button>
                        </div>
                    </div>
                </div>                
            );
        }
    }
  } 
  
  
export default SelectPartnerDataModal;