/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import "../../CSS/DataModal.css";
import "react-datepicker/dist/react-datepicker.css";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});


const animatedComponents = makeAnimated();

class UnScheduleJObCloseModel extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;

        if (!this.props.show) {
            return null;
        }
        else {
            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.pauseScreen ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.pauseScreen ? '1' : '0'
                        }}>
                        <div className="modal-header">
                            <h3>Close Job: {this.props.currentJobDetails?.subject}</h3>
                            <span className="close-modal-btn" onClick={e =>  this.props?.onClick(e) }>×</span>
                        </div>

                        <div className="modal-body" style={{ textAlign: "left" }}>
                            <div >
                            <label htmlFor="description" style={{fontWeight :'bold'}} >Would you like to close this job with below inforation ? </label>
                            </div>
                            <div className="col-6 offset-3">
                                <form>
                                    <div style={{ paddingRight: 2 + "px" }}>
                                        <label htmlFor="description">Schedule For  &emsp;:&emsp; </label>
                                        <label htmlFor="description" style={{fontWeight :'bold'}}>{this.props.currentJobDetails?.scheduledForTimeStr}</label>
                                    </div>

                                    <div style={{ paddingRight: 2 + "px" }}>
                                        <label htmlFor="description">Assign To   &emsp;&emsp;&nbsp; :  &emsp;</label>
                                        <label htmlFor="description" style={{fontWeight :'bold'}}>{this.props.currentJobDetails?.assignedToName} </label>
                                    </div>

                                    <div style={{ paddingRight: 2 + "px" }}>
                                        <label htmlFor="description">Verification  &emsp;&emsp;: &emsp; </label>
                                        <label htmlFor="description" style={{fontWeight :'bold'}}>verified</label>                                 
                                    </div>

                                    <br />
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e =>this.props?.onClick(e) }>Cancel</button>
                             < button className="primary-btn btn-small" onClick={this.props?.unScheduleJobClose}>Close</button> 
                        </div>
                    </div>
                </div>
            );
        }
    }

}

export default withStyles(styles)(UnScheduleJObCloseModel);