/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { Loading } from 'react-loading-dot';
import { SketchPicker } from 'react-color';
import { PageView, Event } from "../GoogleAnalytics";
import PartnerService from "../../services/partner.service";
import AlertService from "../../services/alerts.service";
import HighchartGraph from '../Graph/HighchartGraph';

import LongArrowUpIcon from '@material-ui/icons/ArrowUpward';
import LongArrowDownIcon from '@material-ui/icons/ArrowDownward';

import moment from "moment";
import { withStyles } from '@material-ui/core/styles';
import trainingSessionService from "../../services/trainingSession.service";

const COLORS = ['#0033a0', '#3ed3c2', '#ff7555', '#ff9900', '#7d8ee2'];

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

const initState = {
    loading: false,
    alertTableLoading: false,
    selected: -1,
    alertSelected: false,
    currentAlert: [],
    currentAlertType: "",
    //alertCountInfo: {},
    //trainingList: [],
    columnList: [],
    columnAltered: false,
    columnSort: {
        enabled: false,
        type: null,
        reverse: false
    },
    showColorPicker: false,
    selectedColor: -1,
    runTableLoading: false,
    runAssetSelected: false,
    currentAssetId: "",
    assetRunList: [],
    selectedRuns: [],
    graphedRuns: [],
    dataList: [],
    chartDataList: [],
    rawDataList: [],
    last150DataList: [],
    runData: {},
    allSelected: false,
    allGraphed: false
};

class ACAlertTrainingReview extends Component {
    static displayName = ACAlertTrainingReview.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.loadInfo = this.loadInfo.bind(this);
        this.populateInfo = this.populateInfo.bind(this);
        this.onColumnClick = this.onColumnClick.bind(this);
        this.columnSort = this.columnSort.bind(this);
        this.onAlertRowClick = this.onAlertRowClick.bind(this);
        this.onShowAllClick = this.onShowAllClick.bind(this);

        this.onAlertAssetClick = this.onAlertAssetClick.bind(this);
        this.onBackClick = this.onBackClick.bind(this);

        this.onSelectAll = this.onSelectAll.bind(this);
        this.handleRunCheck = this.handleRunCheck.bind(this);
        this.onGraphAll = this.onGraphAll.bind(this);
        this.handleGraphRunCheck = this.handleGraphRunCheck.bind(this);

        this.handleColorClick = this.handleColorClick.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.closeColorPicker = this.closeColorPicker.bind(this);

        this.state = {
            loading: false,

            /* all assets page */
            alertTableLoading: false,
            selected: -1,
            alertSelected: false,
            currentAlert: [],
            currentAlertType: "",

            alertCountInfo: {},
            trainingList: [],

            columnList: [],
            columnAltered: false,
            columnSort: {
                enabled: false,
                type: null,
                reverse: false
            },

            orgId: 1,
            showAllTraining: true,//false,
            showShowAllBtn: false, 

            /* device page */
            showColorPicker: false,
            selectedColor: -1,

            runTableLoading: false,
            runAssetSelected: false,
            currentAssetId: "",
            assetRunList: [],

            selectedRuns: [],
            graphedRuns: [],
            dataList: [],
            chartDataList: [],
            rawDataList: [],
            last150DataList: [],
            longRunList: [],
            runData: {},
            allSelected: false,
            allGraphed: false,

            visibility: {
                rla: false,
                lra: false,
                fla: false
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        if (!this.props.isLoading)
            this.loadInfo();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

/** Alert Count Training Review **/

    loadInfo() {
        if (!this.props.isLoading)
            this.setState({ loading: true }, async () => {
                let orgId = this.props.currentPartnerId;
                let showBtn = true;
                let showAll = false;
                if (this.props.loggedInOrg !== "LifeWhere") {
                    showBtn = false;
                }
                else if (this.props.showAllTraining) {
                    showAll = true;
                    orgId = 1;
                }

                this.setState({
                    orgId: orgId,
                    showAllTraining: showAll,
                    showShowAllBtn: showBtn
                }, async () => {
                    await this.populateInfo();
                })
            });
    }

    async populateInfo() {
        await trainingSessionService.getalertcountinfo(this.state.orgId, true)
            .then(response => {
                if (this._isMounted) {
                    if (response != null && response !== {}) {
                        this.setState({ alertCountInfo: response }, () => {
                            this.setState({
                                trainingList: this.state.alertCountInfo.trainingAlerts !== undefined ? this.state.alertCountInfo.trainingAlerts : [],
                                loading: false 
                            });
                        });
                    }
                    else {
                        this.setState({ loading: false });
                    }
                }

            })
            .catch(e => {
                console.log(e);
            });
    }

    onShowAllClick() {
        Event("Show All Clicked", "User clicked Show All in AC Alert Training Review", "Show All Clicked");
        this.setState({ loading: true, showAllTraining: !this.state.showAllTraining }, () => {
            this.props.setShowAllTraining(this.state.showAllTraining);
            this.setState({ orgId: this.state.showAllTraining ? 1 : this.props.currentPartnerId }, async () => {
                await this.populateInfo();
            });
        });
    }

    onColumnClick(selectedCol) {
        const colSort = this.state.columnSort;
        let newSort = colSort;
        const tableList = this.state.trainingList; 
        let trainingAlerts = tableList;
        let colAlerts = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colAlerts = this.columnSort(trainingAlerts, "RUNCOUNT", false); /* default sort by is RUNCOUNT */
                    colAltered = false;
                }
                else {
                    newSort.reverse = true;
                    colAlerts = this.columnSort(trainingAlerts, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colAlerts = this.columnSort(trainingAlerts, selectedCol, false);
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colAlerts = this.columnSort(trainingAlerts, selectedCol, false);
            colAltered = true;
        }

        this.setState({ columnSort: newSort, columnList: colAlerts }, () => {
            this.setState({ columnAltered: colAltered });
        });
    }

    columnSort(list, col, isReverse) {
        let colAlerts = [];
        let trainingAlerts = list;
        
        if (col === "ALERTTYPE") {
            colAlerts = trainingAlerts.sort((a, b) => {
                const nameA = a.alertType.toLocaleUpperCase();
                const nameB = a.alertType.toLocaleUpperCase();

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "ASSETCOUNT" || col === "RUNCOUNT") {
            colAlerts = trainingAlerts.sort((a, b) => {
                const valA = col === "ASSETCOUNT" ? a.assetCount : a.runCount;
                const valB = col === "ASSETCOUNT" ? b.assetCount : b.runCount;

                return valB - valA;
            })
        }
        else if (col === "ASSETPERCENTAGE") {
            colAlerts = trainingAlerts.sort((a, b) => {
                const valA = (a.assetCount / this.state.alertCountInfo.assetCount) * 100;
                const valB = (b.assetCount / this.state.alertCountInfo.assetCount) * 100;

                return valB - valA;
            });
        }
        else {
            colAlerts = trainingAlerts.sort((a, b) => {
                const valA = (a.runCount / this.state.alertCountInfo.runCount) * 100;
                const valB = (b.runCount / this.state.alertCountInfo.runCount) * 100;

                return valB - valA;
            });
        }

        if (isReverse) {
            colAlerts = colAlerts.reverse();
        }

        return colAlerts;
    }

    onAlertRowClick(index) {
        let x = index;
        let alert = this.state.trainingList[x];
        let alertType = this.state.trainingList[x].alertType;

        this.setState({
            currentAlert: alert,
            currentAlertType: alertType,
            selected: x,
            alertSelected: true,
            runAssetSelected: false,
            alertTableLoading: true
        }, () => {
            this.setState({
                alertTableLoading: false
            });
        });
    }

  /** Alert Type Training Review **/

    onBackClick() {
        Event("Back Clicked", "User clicked back button in AC Alert Training Review", "Back Clicked");
        this.setState(initState);
    }

    onAlertAssetClick(id) {
        this.setState({
            runTableLoading: true,
            runAssetSelected: true,
            currentAssetId: id
        }, () => {
                this.setState({
                    assetRunList: this.state.currentAlert.runsByAssetDict[this.state.currentAssetId]
                }, async () => {
                    await AlertService.gettrainingrunlistdata(this.state.currentAssetId, true, true, this.state.assetRunList)
                        .then(response => {
                            this.setState({
                                runData: response.data,
                                runTableLoading: false
                            });
                        })
                        .catch(e => {
                            console.log(e);
                            this.setState({ runTableLoading: false });
                        });
                });
        });
    }

    onSelectAll() {
        Event("Select All clicked", "User selected all in AC Alert Training Review", "Select All Clicked");
        let selectedRuns = [];

        this.setState({ allSelected: !this.state.allSelected }, () => {
            if (this.state.allSelected)
                selectedRuns = this.state.assetRunList;

            this.setState({ selectedRuns: selectedRuns });
        });
    }

    handleRunCheck(e) {
        const index = e.target.value;
        const run = this.state.assetRunList[index];
        let selectedRuns = this.state.selectedRuns;

        if (e.target.checked) {
            selectedRuns = selectedRuns.concat(run);
            this.setState({ selectedRuns: selectedRuns });
        }
        else {
            selectedRuns = selectedRuns.filter(s => s.runId !== run.runId);
            this.setState({ selectedRuns: selectedRuns });
        }
    }

    onGraphAll() {
        Event("Graph All Clicked", "User clicked graph all in AC Alert Training Review", "Graph All Clicked");
        let graphedRuns = [];
        let selectedRuns = [];
        let dataList = [];
        let chartDataList = [];
        let rawDataList = [];
        let last150DataList = [];
        let dataMax = 0;
        let rawMax = 0;

        this.setState({ allGraphed: !this.state.allGraphed }, () => {
            if (this.state.allGraphed) {
                selectedRuns = this.state.assetRunList;
                this.setState({ loading: true }, async () => {
                    await AlertService.gettrainingrunlistdata(this.state.currentAssetId, false, true, this.state.assetRunList)
                        .then(response => {
                            if (this._isMounted)
                                this.setState({
                                    runData: response.data
                                }, () => {
                                        for (let i = 0; i < this.state.assetRunList.length; i++) {
                                            const run = this.state.assetRunList[i];
                                            let series = this.state.runData[run.runId];

                                            let seriesRuns = series.series; 
                                            //series.series = seriesRuns;

                                            if (series !== undefined) { /* has already been loaded */
                                                graphedRuns = graphedRuns.concat(run);
                                                dataList = dataList.concat(series);
                                                if (series !== undefined) {
                                                    for (let i = 0; i < seriesRuns.length; i++) {
                                                        if (seriesRuns[i].length > dataMax)
                                                            dataMax = seriesRuns[i].length;
                                                    }
                                                    if (series.dataMax > rawMax)
                                                        rawMax = series.dataMax;

                                                    for (let j = 0; j < seriesRuns.length; j++) {
                                                        let runNum = j + 1;
                                                        let dataArr = seriesRuns[j].map((item) => (item.value));
                                                        let lastArr = [...dataArr].slice(dataArr.length >= 151 ? dataArr.length - 151 : 0);

                                                        const chartData = {
                                                            name: `${run.runId}`,
                                                            data: dataArr,
                                                            color: series.color,
                                                            dashStyle: 'solid',
                                                            type: 'line',
                                                            marker: {
                                                                enabledThreshold: 0
                                                            }
                                                        };
                                                        chartDataList = chartDataList.concat(chartData);

                                                        const lastData = {
                                                            name: `${run.runId}`,
                                                            data: lastArr,
                                                            color: series.color,
                                                            dashStyle: 'solid',
                                                            type: 'line',
                                                            marker: {
                                                                enabledThreshold: 0
                                                            }
                                                        };

                                                        last150DataList = last150DataList.concat(lastData);
                                                    }
                                                }
                                            }
                                        }

                                    this.setState({
                                        loading: false,
                                        graphedRuns: graphedRuns,
                                        selectedRuns: selectedRuns,
                                        dataList: dataList,
                                        chartDataList: chartDataList,
                                        rawDataList: rawDataList,
                                        last150DataList: last150DataList,
                                        dataMax: dataMax,
                                        rawMax: rawMax
                                    });
                                });
                        })
                        .catch(e => {
                            console.log(e);
                            this.setState({ loading: false, message: "Failed to fetch graph data for all runs" });

                            setTimeout(() => {
                                this.setState({
                                    message: ""
                                });
                            }, 3000);
                        });
                });
            }
            else {
                selectedRuns = this.state.selectedRuns;

                this.setState({
                    graphedRuns: graphedRuns,
                    selectedRuns: selectedRuns,
                    dataList: dataList,
                    dataMax: dataMax,
                    rawMax: rawMax,
                    chartDataList: chartDataList,
                    rawDataList: rawDataList,
                    last150DataList: last150DataList
                });
            }
        });
    }

    async handleGraphRunCheck(e) {
        const index = e.target.value;
        const run = this.state.assetRunList[index]; 
        const graphRunId = run.runId;

        let graphedRuns = this.state.graphedRuns;
        let selectedRuns = this.state.selectedRuns;
        let runData = this.state.runData;
        let dataList = this.state.dataList;
        let longRunList = this.state.longRunList;
        let chartDataList = this.state.chartDataList;
        let rawDataList = this.state.rawDataList;
        let last150DataList = this.state.last150DataList;

        let list = [];
        list = list.concat(run);

        if (e.target.checked) {
            this.setState({ loading: true }, async () => {
                await AlertService.gettrainingrunlistdata(this.state.currentAssetId, false, true, list)
                    .then(response => {
                        if (this._isMounted) {
                            let responseData = response.data;
                            responseData[graphRunId].color = runData[graphRunId].color;
                            runData[graphRunId] = responseData[graphRunId];

                            let series = responseData[graphRunId];
                            longRunList = longRunList.concat(run);
                            graphedRuns = graphedRuns.concat(run);
                            selectedRuns = selectedRuns.concat(run);
                            dataList = dataList.concat(series);

                            let seriesRuns = series.series; 
                            series.series = seriesRuns;

                            let seriesMax = 0;
                            if (series !== undefined) {
                                for (var i = 0; i < seriesRuns.length; i++) { 
                                    let thisMax = seriesRuns[i].length;
                                    if (thisMax > seriesMax)
                                        seriesMax = thisMax;
                                }

                                for (let j = 0; j < seriesRuns.length; j++) {
                                    let runNum = j + 1;
                                    let dataArr = seriesRuns[j].map((item) => (item.value));
                                    let lastArr = [...dataArr].slice(dataArr.length >= 151 ? dataArr.length - 151 : 0);

                                    const chartData = {
                                        name: `${run.runId}`,
                                        data: dataArr,
                                        color: series.color,
                                        dashStyle: 'solid',
                                        type: 'line',
                                        marker: {
                                            enabledThreshold: 0
                                        }
                                    };
                                    chartDataList = chartDataList.concat(chartData);

                                    const lastData = {
                                        name: `${run.runId}`,
                                        data: lastArr,
                                        color: series.color,
                                        dashStyle: 'solid',
                                        type: 'line',
                                        marker: {
                                            enabledThreshold: 0
                                        }
                                    };

                                    last150DataList = last150DataList.concat(lastData);
                                }

                            }

                            let dataMax = this.state.dataMax;
                            let rawMax = this.state.rawMax;
                            if (seriesMax > dataMax)
                                dataMax = seriesMax;
                            if (series.dataMax > rawMax)
                                rawMax = series.dataMax;

                            this.setState({
                                loading: false,
                                longRunList: longRunList,
                                graphedRuns: graphedRuns,
                                selectedRuns: selectedRuns,
                                dataList: dataList,
                                dataMax: dataMax,
                                rawMax: rawMax,
                                chartDataList: chartDataList,
                                rawDataList: rawDataList,
                                last150DataList: last150DataList
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ loading: false, message: "Failed to load graph data for selected run!" });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 3000);
                    });
            })
        }
        else {
            longRunList = longRunList.filter(a => a.runId !== graphRunId);
            graphedRuns = graphedRuns.filter(a => a.runId !== graphRunId);
            dataList = dataList.filter(d => d.name !== graphRunId);
            chartDataList = chartDataList.filter(d => !d.name.includes(graphRunId));
            rawDataList = rawDataList.filter(d => !d.name.includes(graphRunId));
            last150DataList = last150DataList.filter(d => !d.name.includes(graphRunId));

            this.setState({ longRunList: longRunList, graphedRuns: graphedRuns, dataList: dataList, chartDataList: chartDataList, rawDataList: rawDataList, last150DataList: last150DataList });
        }
    }

    handleColorClick() {

    }

    handleColorChange() {

    }

    closeColorPicker() {

    }

/** UI helpers **/

    getAssetPercentage(count) {
        let total = this.state.alertCountInfo.assetCount;
        let percentage = (count / total) * 100;
        percentage = percentage.toFixed(2);

        return (percentage + "%");
    }

    getRunPercentage(count) {
        let total = this.state.alertCountInfo.runCount;
        let percentage = (count / total) * 100;
        percentage = percentage.toFixed(2);

        return (percentage + "%");
    }

    getStartDate(date) {
        var dateStr = moment(date).format();
        return (dateStr);
    }

    renderGraph() {

        return (
            <div className="row no-gutters">
                <div className="col-sm-12 col-md-10">

                    <HighchartGraph graphType={"ALERT"} chartData={this.state.chartDataList} key={`${this.state.currentAssetId}-${this.state.chartDataList.length}-${this.state.dataList.length}-${this.state.visibility}`} isAirConditioner={true} isTraining={true}
                        dataMax={this.state.dataMax} rawMax={this.state.rawMax} rawData={this.state.rawDataList} last150Data={this.state.last150DataList} showRLA={this.state.visibility.rla} rlaValue={this.state.dataList.length ? this.state.dataList[0].rla : null}
                        showLRA={this.state.visibility.lra} lraValue={this.state.dataList.length ? this.state.dataList[0].lra : null} showFLA={this.state.visibility.fla} flaValue={this.state.dataList.length ? this.state.dataList[0].fla : null} alertList={this.state.graphedRuns} assetId={this.state.currentAssetId} setLoading={this.setLoading} />

                </div>
                {/*<div className="col-sm-4 col-md-2">
                    <div style={{ marginLeft: '20px' }}>
                        {buttons}
                    </div>
                    {acOptions}
                </div>*/}
            </div>
        );
    }

    renderAlertContent() {
        const { classes } = this.props;

        let alertLoader = this.state.alertTableLoading ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : null;

        let tableContent = this.state.runAssetSelected && this.state.assetRunList !== undefined && this.state.assetRunList.length ?
            <tbody>
                {this.state.assetRunList.map((run, index) =>
                    <tr key={`${index}-${run.runId}`} data={index}>
                        <td>{run.runId}</td>
                        <td>{this.getStartDate(run.startTime)}</td>
                        <td>{this.getStartDate(run.endTime)}</td>
                        <td>{run.alertType}</td>
                        <td>
                            <input type="checkbox" value={index/*run.runId*/} id={`${run.runId}-select`} checked={this.state.selectedRuns.some(a => a.runId == run.runId)} onChange={this.handleRunCheck} />
                        </td>
                        <td>
                            <input type="checkbox" value={index/*run.runId*/} id={`${run.runId}-graph`} checked={this.state.graphedRuns.some(a => a.runId == run.runId)} onChange={this.handleGraphRunCheck} />
                        </td>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <div style={{ height: '13px', width: '13px', border: '1px solid grey', backgroundColor: this.state.runData[run.runId] !== undefined ? this.state.runData[run.runId].color : 'white' }} onClick={() => this.handleColorClick(run.runId)} />
                                {this.state.showColorPicker && this.state.selectedColor == run.runId ?
                                    <div style={{ position: 'absolute', zIndex: '2', right: '5px' }}>
                                        <div onClick={this.closeColorPicker} style={{ position: 'fixed', top: '0px', bottom: '0px', left: '0px', right: '0px' }} />
                                        <SketchPicker color={this.state.runData[run.runId] !== undefined ? this.state.runData[run.runId].color : 'white'} onChange={this.handleColorChange} />
                                    </div>
                                    : null
                                }
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
            : <tbody>
                <tr><td>{this.state.runTableLoading || this.state.loading ? "Loading Runs for Selected Device..." : "No Runs for this Device"}</td></tr>
            </tbody>;

        let runTable = this.state.runAssetSelected ?
            <div style={{ maxHeight: '330px', marginBottom: '15px', overflow: "auto" }}>
                <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Run Id</th>
                            <th>Start Time</th>
                            <th>End Time </th>
                            <th>Alert Type</th>
                            <th>Select <input type="checkbox" value={this.state.allSelected} checked={this.state.allSelected} id='selectAll' onChange={this.onSelectAll} style={{ marginLeft: '5px' }} /></th>
                            <th>Graph <input type="checkbox" value={this.state.allGraphed} checked={this.state.allGraphed} id='graphAll' onChange={this.onGraphAll} style={{ marginLeft: '5px' }} /></th>
                            <th style={{ width: '25px' }}></th>
                        </tr>
                    </thead>
                    {tableContent}
                </table>
            </div>
            : <div>{null}</div>;

        return (
            <div style={{ paddingLeft: '10px', paddingTop: '5px' }}>
                <div className="row no-gutters" style={{ display: 'flex' }}>
                    <div className="col-sm-12 col-md-4">

                        <div className="grey-container" style={{ minHeight: 'calc(100% - 10px)' }}>
                            <h5 className="sectionTitle">
                                <button className="tertiary-btn" onClick={this.onBackClick} style={{ margin: '0px' }}>{"<"}</button>
                                Alert Type: {this.state.currentAlertType}
                                {/*<IconTooltip title="Force Reload Asset Alerts" enterDelay={200} leaveDelay={200} style={{ marginTop: '-5px', paddingTop: '-5px', backgroundColor: '#f7f9fa' }}>
                                    <IconButton onClick={() => this.openForceReloadConfirmModal()} style={{ padding: '0px', marginLeft: '8px', marginTop: '-5px' }}>
                                        <RefreshIcon style={{ color: "#0033a0", width: '20px', height: '20px' }} />
                                    </IconButton>
                                </IconTooltip>*/}
                            </h5>
                            {/*<div className="detailTitle" style={{ marginLeft: '15px' }}>
                                Mac: {this.state.currentDeviceId}
                            </div>*/}
                            <div className="table-container">

                                <div style={{ marginBottom: '0px', overflow: "auto" }}>
                                    <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                                        <thead>
                                            <tr>
                                                <th>Device Id</th>
                                                <th>Failed Run Count</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.state.currentAlert.alertAssets.length ?
                                                this.state.currentAlert.alertAssets.map((asset, index) =>
                                                    <tr key={`${asset.deviceId}-${index}`} data={index} className={this.state.currentAssetId === asset.assetId ? "tableSelected" : ""} onClick={() => { this.onAlertAssetClick(asset.assetId) }}>
                                                        <td>{asset.deviceId}</td>
                                                        <td>{asset.failedRunCount}</td>
                                                    </tr>
                                                )
                                                : <tr><td>No Assets for this Alert Type!</td></tr>
                                            }
                                        </tbody>

                                    </table>
                                </div>
                            </div>

                            {/*<div className="lastItem">
                                {customerInfoContent}
                                {assetInfoContent}
                                {customerInfoBtn}
                                {assetInfoBtn}
                            </div>*/}
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-8">

                        <div className="white-container" style={{ minHeight: '100%' }}>
                            <h5 className="sectionTitle">Device Runs{/*this.state.currentAssetId == "" ? "Selected Device Runs" : this.state.currentAssetId + " Runs"*/}</h5>
                            <div className="table-container" style={{ maxHeight: '330px' }}>
                                {runTable}
                            </div>
                            {alertLoader}
                        </div>
                    </div>
                </div>

                <div>
                    {this.state.runAssetSelected ?
                        <div>
                            <br />
                            {this.renderGraph()}
                        </div>
                        : <div className="centered">
                            <br />
                            Select a Device to generate graph
                            </div>
                    }
                    <p>{this.state.message}</p>
                </div>
            </div>
        );
    }

    renderTrainingContent() {
        let tableList = this.state.columnAltered ? this.state.columnList : this.state.trainingList;

        let tableContent = tableList.length ?
            <tbody>
                {this.state.trainingList.map((alert, index) =>
                    <tr className="alerts-table-row" key={`${alert.alertType}-tableRow`} data={index} value={index} onClick={() => this.onAlertRowClick(index)}>
                        <td style={{ borderLeft: '1px solid #ebeef0', paddingLeft: '30px' }}>{alert.alertType}</td>
                        <td>{alert.assetCount}</td>
                        <td>{this.getAssetPercentage(alert.assetCount)}</td>
                        <td>{alert.runCount}</td>
                        <td style={{ borderRight: '1px solid #ebeef0' }}>{this.getRunPercentage(alert.runCount)}</td>
                    </tr>
                )}
            </tbody>
            :
            <tbody>
                <tr>
                    <td>No Alerts to Show!</td>
                </tr>
            </tbody>;


        let arrowIcon = this.state.columnSort.enabled ?
            (this.state.columnSort.reverse ?
                <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
                : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />)
            : null;

        let alertTypeIcon = this.state.columnSort.enabled && this.state.columnSort.type === "ALERTTYPE" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;

        let assetCountIcon = this.state.columnSort.enabled && this.state.columnSort.type === "ASSETCOUNT" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;

        let assetPercentageIcon = this.state.columnSort.enabled && this.state.columnSort.type === "ASSETPERCENTAGE" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;

        let runCountIcon = this.state.columnSort.enabled && this.state.columnSort.type === "RUNCOUNT" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;

        let runPercentageIcon = this.state.columnSort.enabled && this.state.columnSort.type === "RUNPERCENTAGE" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;

        return (
            <div>
                <div className="row no-gutters alerts-tab-wrapper" style={{ margin: '20px 30px', border: '1px solid #ebeef0', borderRadius: '.1rem', boxShadow: 'inset 0 -1px 0 rgba(100,121,143,0.122)' }}>
                    <div className="col"></div>
                    <div className="col-4" style={{ paddingTop: '15px' }}>
                        <p>Assets in Training Session: &nbsp;<b>{this.state.alertCountInfo.assetCount}</b></p>
                        <p>Runs Observed: &nbsp;<b>{this.state.alertCountInfo.runCount}</b></p>
                    </div>
                    <div className="col-4" style={{ paddingTop: '15px' }}>
                        <p>Training Session Date: &nbsp;<b>{this.state.alertCountInfo.trainingSessionDate}</b></p>
                        <p>Training Session Id: &nbsp;<b>{this.state.alertCountInfo.trainingSessionId}</b></p>
                    </div>
                    <div className="col"></div>
                </div>

                <div style={{ margin: '20px 30px' }}>

                    <div>
                        <table className="table-hover" style={{ width: '100%' }}>

                            <thead>
                                <tr className="alerts-table-row">
                                    <th style={{ paddingLeft: '30px' }}>
                                        <div onClick={() => this.onColumnClick("ALERTTYPE")}>
                                            <span style={{ cursor: 'pointer' }}>ALERT TYPE</span>
                                            {alertTypeIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => this.onColumnClick("ASSETCOUNT")}>
                                            <span style={{ cursor: 'pointer' }}># ASSETS WITH ALERT</span>
                                            {assetCountIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => this.onColumnClick("ASSETPERCENTAGE")}>
                                            <span style={{ cursor: 'pointer' }}>PERCENTAGE OF ALERT</span>
                                            {assetPercentageIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => this.onColumnClick("RUNCOUNT")}>
                                            <span style={{ cursor: 'pointer' }}>NUMBER OF RUNS WITH FAILURE</span>
                                            {runCountIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => this.onColumnClick("RUNPERCENTAGE")}>
                                            <span style={{ cursor: 'pointer' }}>PERCENTAGE OF RUNS WITH FAILURE</span>
                                            {runPercentageIcon}
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            {tableContent}

                        </table>
                    </div>
                </div>

            </div>
        );
    }

    render() {
        const { classes } = this.props;

        let loader = this.state.loading ?
            <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : null;

        let title = this.state.alertSelected ? this.state.currentAlertType + " Training Review" : "Air Conditioner Training Alert Count Review";
        let contents = this.state.alertSelected ? this.renderAlertContent() : this.renderTrainingContent();

        let showAllBtn = !this.state.alertSelected && this.state.showShowAllBtn ?
            <div className="btn-right" style={{ padding: '25px 0px 0px 30px' }}>
                <button className={this.state.showAllTraining ? "primary-btn btn-large" : "secondary-btn btn-large"} style={{ width: 150 + 'px' }} onClick={this.onShowAllClick}>Show All</button>
            </div>
            : null;

        return (
            <div className={classes.root}>
                <div className='infoPage'>
                    <div className="row no-gutters">
                        <div className="col">
                            <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                                {title}
                            </h2>
                        </div>
                        <div className="col">
                            {showAllBtn}
                        </div>
                    </div>

                    {contents}
                    {loader}

                </div>
            </div>
        );
    }

}

export default withStyles(styles)(ACAlertTrainingReview);