/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/Experiments";

class ExperimentsService {

    async getForceResetPasswordsExperiment() {
        return await http.get(`${controller}/forceresetpasswords`);
    }

}

export default new ExperimentsService();