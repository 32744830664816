/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router'
import { Form } from 'react-bootstrap';

import "../CSS/DataModal.css";

class LogOffModal extends Component {

    constructor(props) {
        super(props);        

        this.state = {
            showMessage: false,
            message: "",            
        }
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {

    };

    onUsernameChange(e) {
        const username = e.target.value;

        this.setState(prevState => ({
            newLogon: {
                ...prevState.newLogon,
                username: username
            }
        }));
    }

    onPasswordChange(e) {
        const password = e.target.value;

        this.setState(prevState => ({
            newLogon: {
                ...prevState.newLogon,
                password: password
            }
        }));
    }

    render() {
      return (

          <div className="modal" style={{ display: this.props.show ? "block" : "none" }}> 
              <div className="small-modal-wrapper"
                  style={{
                      transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                      opacity: this.props.show ? '1' : '0'
                  }}>

                    <div className="modal-header">
                        <h3>Log out</h3>
                        <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                    </div>

                    <div className="modal-body">
                        <br/>
                        <h6>
                          Are you sure you would like to log out?
                        </h6>

                        <p><b>{this.state.message}</b></p>
                    </div>

                    <div className="modal-footer">
                        <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                        <button className="primary-btn btn-small" onClick={this.props.onLogOff}>Log out</button>
                    </div>                  
              </div>
          </div>
      );
  }
}
export default withRouter(LogOffModal);