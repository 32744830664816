/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import "../../CSS/DataModal.css";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

class AlertDetailsModal extends Component {
    constructor(props) {
        super(props);

        this.renderDetails = this.renderDetails.bind(this);
        this.getFormatDate = this.getFormatDate.bind(this);

        this.state = {
            alertName: "",
            detailsLoading: true, 
        }
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
        this.props.onCancelClick();
        this.setState({ alertName: "" });
    };

    getFormatDate(timeStr) {

        var time = timeStr.substring(
            timeStr.indexOf("(") + 1,
            timeStr.lastIndexOf(")")
        )

        var d = new Date(parseInt(time, 10));
        //var hr = d.getHours() < 10 ? "0" : "";
        //var min = d.getMinutes() < 10 ? "0" : "";
        //var sec = d.getSeconds() < 10 ? "0" : "";
        var month = d.getMonth() + 1;

        var date = month + "/" + d.getDate() + "/" + d.getFullYear() + " " + d.toLocaleTimeString('en-US');

        if (time === "" || time == null)
            date = "";

        return (date);
    }

    renderDetails() {
        let details = this.props.details.alert[0];
        let status = details.closedOn ? "Closed" : "Open";
        let priority = details.priority === 3 ? "High" : details.priority === 2 ? "Medium" : "Low";
        let startTime = details.startTime ?
                this.getFormatDate(details.startTime)
                :
            "";

        let endTime = details.endTime ?
            this.getFormatDate(details.endTime) :
            "Active";

        let alertMsg = {};
        var alertMsgObj = this.props.messaging.find(a => a.typeName === details.type);
        if (alertMsgObj !== null && alertMsgObj !== undefined) {
            alertMsg = alertMsgObj;
        }

        let currentAsset = this.props.asset;

        return (
            <div className="modal-body-2" style={{ textAlign: "left" }}>
                <div className="row no-gutters">
                        <p className="bold">
                           {details.type}
                        </p>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="row no-gutters">
                            <div className="col-6">
                                <p className="semibold"> <strong>Make</strong> <br />
                                    {currentAsset.make}
                                </p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-6">
                                <p className="semibold"> <strong>Model</strong> <br />
                                    {currentAsset.model}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row no-gutters">
                            <div className="col-10">
                                <p className="semibold"> <strong>Problem</strong> <br />
                                    {details.message}
                                </p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-10">
                                <p className="semibold"> <strong>Status</strong> <br />
                                    {status}
                                </p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-10">
                                <p className="semibold"> <strong>Priority</strong> <br />
                                    {priority}
                                </p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-10">
                                <p className="semibold"> <strong>Start</strong> <br />
                                    {startTime}
                                </p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-10">
                                <p className="semibold"> <strong>End</strong> <br />
                                    {endTime}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row no-gutters">
                            <div className="col-10">
                                <p className="semibold"> <strong>Possible Causes</strong> <br />
                                    {alertMsg.possibleCauses}
                                </p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-10">
                                <p className="semibold"> <strong>Suggested Technician Action</strong> <br />
                                    {alertMsg.techAction}
                                </p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-10">
                                <p className="semibold"> <strong>Suggested Homeowner Action</strong> <br />
                                    {alertMsg.homeownerAction}
                                </p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-10">
                                <p className="semibold"> <strong>Risk</strong> <br />
                                    {alertMsg.risk}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        else {

            //let detailInfo = (this.props.details != null && this.props.details.alert !== undefined && this.props.details.alert.length > 0) ? this.props.details.alert[0] : null;

            let content = this.props.detailsLoading ?
                <div className="modal-body">
                    <Spinner animation="border" />
                </div>
                :
                this.renderDetails();

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Alert Details</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        {content}

                        <div className="modal-footer">
                            <button className="primary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                        </div>
                    </div>
                </div>
            );
        }
    }

}

export default withStyles(styles) (AlertDetailsModal);