/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Form, FormGroup, FormControl, FormLabel, Spinner } from "react-bootstrap";
import { SetPageView, Event } from "../GoogleAnalytics";
import forgotpasswordService from '../../services/forgotpassword.service';

class ForgotPasswordForm extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onContinueClick = this.onContinueClick.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validateForm = this.validateForm.bind(this);

        console.log("Customer email: " + this.props.customerEmail);

        this.state = {
            message: "",
            email: this.props.emailToReset ? this.props.emailToReset : "",
            emailSent: false,
            btnClicked: false,
            loading: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        SetPageView('/forgotPassword');
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChangeEmail(e) {
        let email = e.target.value;

        if (email != null)
            email = email.trim();

        this.setState({ email: email });
    }

    onContinueClick() {
        Event("Continue Clicked", "User clicked continue button in Forgot Password", "Continue Clicked");
        this.setState({ loading: true }, () => {
            if (this.validateEmail(this.state.email)) {
                forgotpasswordService.post(this.state.email, this.props.isMobile).then(async response => {
                    if (this._isMounted) {
                        this.setState({ emailSent: true, loading: false });
                    }
                }).catch(e => {
                    // BadRequest (400) will be caught here.
                    if (this._isMounted) {
                        console.log(e);

                        this.setState({ loading: false, message: "Something went wrong, please try again." });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 5000);
                    }
                });
            }
            else {
                if (this._isMounted) {
                    this.setState({ loading: false, message: "Please enter a valid email address." });

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 5000);
                }
            }
        });
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    onResendClick() {
        Event("Resend Email Clicked", "User clicked Resend Email button in Forgot Password", "Resend Email Clicked");
        this.setState({ emailSent: false });
    }

    validateForm() {
        return this.state.email.length > 0;
    }

    render() {
        let loader = this.state.loading ?
            <div className="loading" style={{ display: "block", marginLeft: '0px', paddingRight: '0px', width: '100%' }}>
                <div className="loading-wrapper loading-wrapper-login" style={{ display: "block", justifyContent: "center", alignItems: "center" }}>
                    <div className="loading-body"><Spinner animation="border" variant="light" /></div>
                </div>
            </div>
            : null;

        let loginBtn = this.props.isMobile || this.props.isModal ?
            null
            : <div>
                <button className="tertiary-btn" onClick={this.props.endForgotPassword}>Back to Login</button>
            </div>;

        let body = !this.state.emailSent ?
            <div>
                <h5>Forgot Your Password?</h5>
                <div className="passwordBody">Enter your email address and we will send you instructions to reset your password.</div>
                <form>
                    <div className="row">
                        <div className="col">
                            <div style={{ padding: "15px 0px" }}>
                                <p className="formLbl" style={{ textAlign: 'left' }}>Email</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    value={this.state.email}
                                    onChange={this.onChangeEmail}
                                    placeholder="user@email.com"
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <br />
                <div className="centered" style={{ marginBottom: '0' }}>
                    <input type="button" disabled={!this.validateForm()} onClick={e => { this.onContinueClick(e); }} value="Continue" className="btn-wide primary-btn btn-medium" />
                </div>
                {loginBtn}
            </div>
            : <div>
                <h5>Check Your Email</h5>
                <div className="passwordBody">
                    Please check the email address <span style={{ fontWeight: '500' }}>{this.state.email}</span> for instructions to reset your password.
                </div>
                <br />
                <div className="centered" style={{ paddingBottom: '10px' }}>
                    <input type="button" onClick={e => { this.onResendClick(e); }} value="Resend Email" className="btn-wide secondary-btn btn-medium" />
                </div>
            </div>;


        return (
            <div className='passwordPage'>
                <div className="row centered">
                    <div className="password-container">
                        {body}
                    </div>
                </div>
                <div className="row centered">
                    {this.state.message}
                </div>
                {loader}
            </div>
        );
    }
}

export default ForgotPasswordForm;
