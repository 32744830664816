import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import moment from "moment/moment";
import numeral from "numeral";
import React, {useEffect, useState} from "react";
import PartnerService from "../../services/partner.service";

function JobValues({ currentPartnerId, partnerInfoObj, onRefresh }) {

    const [isJobValuesLoading, setIsJobValuesLoading] = useState(false);
    
    const [jobValues, setJobValues] = useState({
        furnaceYtd: 0,
        airHandlerYtd: 0,
        airConditionerYtd: 0,
        heatPumpYtd: 0,
        furnaceLifetime: 0,
        airHandlerLifetime: 0,
        airConditionerLifetime: 0,
        heatPumpLifetime: 0
    });

    useEffect(()=> {
        getPartnerProps().then();
    }, []);

    useEffect(()=> {
        getPartnerProps().then();
    }, [onRefresh]);
    
    const getPartnerProps = async () => {
        setIsJobValuesLoading(true);
        
        await PartnerService.getpartnerproperties(currentPartnerId)
            .then(response => {
                calculateJobValues(response);
                setIsJobValuesLoading(false);
            })
            .catch(e => {
                console.log(e);
                setIsJobValuesLoading(false);
            });
    }

    const calculateJobValues = (propsList) => {
        let avgFurnaceJobProp = propsList.find(obj => obj.name === "avgFurnaceJob").valueRaw;
        let avgAirHandlerJobProp = propsList.find(obj => obj.name === "avgAirHandlerJob").valueRaw;
        let avgAirConditionerJobProp = propsList.find(obj => obj.name === "avgAirConditionerJob").valueRaw;
        let avgHeatPumpJobProp = propsList.find(obj => obj.name === "avgHeatPumpJob").valueRaw;

        let avgFurnaceJob = avgFurnaceJobProp === null ? 350.00 : parseFloat(avgFurnaceJobProp).toFixed(2);
        let avgAirHandlerJob = avgAirHandlerJobProp === null ? 350.00 : parseFloat(avgAirHandlerJobProp).toFixed(2);
        let avgAirConditionerJob = avgAirConditionerJobProp === null ? 400.00 : parseFloat(avgAirConditionerJobProp).toFixed(2);
        let avgHeatPumpJob = avgHeatPumpJobProp === null ? 400.00 : parseFloat(avgHeatPumpJobProp).toFixed(2);

        let furnaceYtd = partnerInfoObj.jobCounts.furnaceCountYtd * avgFurnaceJob;
        let airHandlerYtd = partnerInfoObj.jobCounts.airHandlerCountYtd * avgAirHandlerJob;
        let airConditionerYtd = partnerInfoObj.jobCounts.airConditionerCountYtd * avgAirConditionerJob;
        let heatPumpYtd = partnerInfoObj.jobCounts.heatPumpCountYtd * avgHeatPumpJob;

        let furnaceLifetime = partnerInfoObj.jobCounts.furnaceCountLifetime * avgFurnaceJob;
        let airHandlerLifetime = partnerInfoObj.jobCounts.airHandlerCountLifetime * avgAirHandlerJob;
        let airConditionerLifetime = partnerInfoObj.jobCounts.airConditionerCountLifetime * avgAirConditionerJob;
        let heatPumpLifetime = partnerInfoObj.jobCounts.heatPumpCountLifetime * avgHeatPumpJob;

        setJobValues({
            furnaceYtd: furnaceYtd,
            airHandlerYtd: airHandlerYtd,
            airConditionerYtd: airConditionerYtd,
            heatPumpYtd: heatPumpYtd,
            furnaceLifetime: furnaceLifetime,
            airHandlerLifetime: airHandlerLifetime,
            airConditionerLifetime: airConditionerLifetime,
            heatPumpLifetime: heatPumpLifetime
        });
    }
    
    return (
        isJobValuesLoading ?
            <div className="row no-gutters" style={{marginBottom: '20px'}}>
                <div className="col">
                    <Card elevation={4} style={{height: '180px'}}>
                        <CardContent style={{padding: '10px 0px'}}>
                            <h6 style={{paddingLeft: '15px'}}>Estimated Job Values</h6>
                            <div className='row no-gutters card-row centered' style={{minHeight: '52px'}}>
                                <div className="dot-flashing"></div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
            :
            <div className="row no-gutters" style={{marginBottom: '20px'}}>
                <div className="col">
                    <Card elevation={4} style={{height: '180px'}}>
                        <CardContent style={{padding: '10px 0px'}}>
                            <div>
                                <h6 style={{paddingLeft: '15px', marginBottom: '0px'}}>Estimated Job Values</h6>
                                <div className="row no-gutters card-row" style={{textAlign: 'center', margin: '10px 0px'}}>
                                    <div className="col-6" style={{color: 'dimgray', paddingLeft: '15px', borderRight: '1px solid lightGray'}}>
                                        <h6 style={{color: "black"}}>YTD ({moment().year()})</h6>
                                        <small>
                                            <div className="row no-gutters" style={{marginBottom: '3px'}}>
                                                <div className="col-7" style={{textAlign: 'left'}}>
                                                    Furnace
                                                </div>
                                                <div className="col-4" style={{textAlign: 'right'}} title={jobValues !== null && jobValues !== undefined ? new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                }).format(jobValues.furnaceYtd) : 0}>
                                                    {jobValues !== null && jobValues !== undefined ? numeral(jobValues.furnaceYtd).format('$0.0a') : 0}
                                                </div>
                                            </div>

                                            <div className="row no-gutters" style={{marginBottom: '3px'}}>
                                                <div className="col-7" style={{textAlign: 'left'}}>
                                                    Air Handler
                                                </div>
                                                <div className="col-4" style={{textAlign: 'right'}} title={jobValues !== null && jobValues !== undefined ? new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                }).format(jobValues.airHandlerYtd) : 0}>
                                                    {jobValues !== null && jobValues !== undefined ? numeral(jobValues.airHandlerYtd).format('$0.0a') : 0}
                                                </div>
                                            </div>

                                            <div className="row no-gutters" style={{marginBottom: '3px'}}>
                                                <div className="col-7" style={{textAlign: 'left'}}>
                                                    Air Conditioner
                                                </div>
                                                <div className="col-4" style={{textAlign: 'right'}} title={jobValues !== null && jobValues !== undefined ? new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                }).format(jobValues.airConditionerYtd) : 0}>
                                                    {jobValues !== null && jobValues !== undefined ? numeral(jobValues.airConditionerYtd).format('$0.0a') : 0}
                                                </div>
                                            </div>

                                            <div className="row no-gutters" style={{marginBottom: '3px'}}>
                                                <div className="col-7" style={{textAlign: 'left'}}>
                                                    Heat Pump
                                                </div>
                                                <div className="col-4" style={{textAlign: 'right'}} title={jobValues !== null && jobValues !== undefined ? new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                }).format(jobValues.heatPumpYtd) : 0}>
                                                    {jobValues !== null && jobValues !== undefined ? numeral(jobValues.heatPumpYtd).format('$0.0a') : 0}
                                                </div>
                                            </div>
                                        </small>
                                    </div>

                                    <div className="col-6" style={{color: 'dimgray', paddingLeft: '15px'}}>
                                        <h6 style={{color: "black"}}>Lifetime</h6>
                                        <small>
                                            <div className="row no-gutters" style={{marginBottom: '3px'}}>
                                                <div className="col-7" style={{textAlign: 'left'}}>
                                                    Furnace
                                                </div>
                                                <div className="col-4" style={{textAlign: 'right'}} title={jobValues !== null && jobValues !== undefined ? new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                }).format(jobValues.furnaceLifetime) : 0}>
                                                    {jobValues !== null && jobValues !== undefined ? numeral(jobValues.furnaceLifetime).format('$0.0a') : 0}
                                                </div>
                                            </div>

                                            <div className="row no-gutters" style={{marginBottom: '3px'}}>
                                                <div className="col-7" style={{textAlign: 'left'}}>
                                                    Air Handler
                                                </div>
                                                <div className="col-4" style={{textAlign: 'right'}} title={jobValues !== null && jobValues !== undefined ? new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                }).format(jobValues.airHandlerLifetime) : 0}>
                                                    {jobValues !== null && jobValues !== undefined ? numeral(jobValues.airHandlerLifetime).format('$0.0a') : 0}
                                                </div>
                                            </div>

                                            <div className="row no-gutters" style={{marginBottom: '3px'}}>
                                                <div className="col-7" style={{textAlign: 'left'}}>
                                                    Air Conditioner
                                                </div>
                                                <div className="col-4" style={{textAlign: 'right'}} title={jobValues !== null && jobValues !== undefined ? new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                }).format(jobValues.airConditionerLifetime) : 0}>
                                                    {jobValues !== null && jobValues !== undefined ? numeral(jobValues.airConditionerLifetime).format('$0.0a') : 0}
                                                </div>
                                            </div>

                                            <div className="row no-gutters" style={{marginBottom: '3px'}}>
                                                <div className="col-7" style={{textAlign: 'left'}}>
                                                    Heat Pump
                                                </div>
                                                <div className="col-4" style={{textAlign: 'right'}} title={jobValues !== null && jobValues !== undefined ? new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                }).format(jobValues.heatPumpLifetime) : 0}>
                                                    {jobValues !== null && jobValues !== undefined ? numeral(jobValues.heatPumpLifetime).format('$0.0a') : 0}
                                                </div>
                                            </div>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
    );
}

export default JobValues;
