/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

let options = [
    {
        role: "Technician",
        roleId: "13"
    },
    {
        role: "Scheduler",
        roleId: "14"
    },
    {
        role: "Partner Admin",
        roleId: "15"
    },
    {
        role: "Operations",
        roleId: "26"
    }
];

const ota = {
    role: "OTA",
    roleId: "11"
}

const api = {
    role: "API",
    roleId: "3"
}

  
const animatedComponents = makeAnimated();


class InviteUserForm extends Component {
    constructor(props) {
        super(props);

        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeRoles = this.onChangeRoles.bind(this);
        if (this.props?.isGlobalAdmin === 1) {
            options = options.filter(option => option.role !== "Operations");
        }
        this.state = {
            user: {
                firstName: "",
                lastName: "",
                email: "",
                roleList: "",
                roleListId: [],
                roleListIdStr: ""
            },
            roleOptions: options,
        }
    }

    componentDidMount() {

        if (this.props.isCustomerAccount) {

            let options = api["roleId"];
            let roleList = api["role"];
            let roleIdStr = api["roleId"];

            this.setState(prevState => ({
                user: {
                    ...prevState.user,
                    roleListId: options,
                    roleList: roleList,
                    roleListIdStr: roleIdStr
                }
            }), () => {
                this.props.handleChangeUser(this.state.user, this.props.index);
            });
        }
    }

    onChangeFirstName(e) {
        const name = e.target.value;

        this.setState(prevState => ({
            user: {
                ...prevState.user,
                firstName: name
            }
        }), () => {
            this.props.handleChangeUser(this.state.user, this.props.index);
        });
    }

    onChangeLastName(e) {
        const name = e.target.value;

        this.setState(prevState => ({
            user: {
                ...prevState.user,
                lastName: name
            }
        }), () => {
            this.props.handleChangeUser(this.state.user, this.props.index);
        });
    }

    onChangeEmail(e) {
        const email = e.target.value;

        this.setState(prevState => ({
            user: {
                ...prevState.user,
                email: email
            }
        }), () => {
                this.props.handleChangeUser(this.state.user, this.props.index);
        });
    }

    onChangeRoles = (selectedOptions) => {
        var options = [];
        var roleList = "";
        var roleIdStr = "";
        var i = 0;
        selectedOptions.forEach(option => {
            options = options.concat(option["roleId"]);
            roleList += (i > 0 ? ", " : "") + option["role"];
            roleIdStr += (i > 0 ? ", " : "") + option["roleId"];
            i = 1;
        });

        if (roleList.length > 0 && !roleList.includes("OTA"))
        {
            options = options.concat(ota["roleId"]);
            roleList += ", " + ota["role"];
            roleIdStr += ", " + ota["roleId"];
            
        }
        if (roleList.length > 0 && !roleList.includes("API") ) {
            options = options.concat(api["roleId"]);
            roleList += ", " + api["role"];
            roleIdStr += ", " + api["roleId"];
        }

        this.setState(prevState => ({
            user: {
                ...prevState.user,
                roleListId: options,
                roleList: roleList,
                roleListIdStr: roleIdStr
            }
        }), () => {
                this.props.handleChangeUser(this.state.user, this.props.index);
        });
    }

    getOptionLabel = (option) => {
        return ( option["role"] );
    }

    getOptionValue = (option) => {
        return ( option["roleId"] );
    }

    render() {
        let types = this.props.isCustomerAccount ? null :
            <div style={{ paddingRight: 2 + "px" }}>
                <label htmlFor="description">Types</label>
                <Select
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    isMulti
                    options={this.state.roleOptions}
                    getOptionLabel={this.getOptionLabel}
                    getOptionValue={this.getOptionValue}
                    onChange={this.onChangeRoles}
                />
            </div>;

        return (
            <div>
                <form>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div style={{ paddingRight: 2 + "px" }}>
                                <label htmlFor="description">First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    onChange={this.onChangeFirstName}
                                />
                            </div>
                            <div style={{ paddingRight: 2 + "px" }}>
                                <label htmlFor="description">Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lastName"
                                    onChange={this.onChangeLastName}
                                />
                            </div>
                            <br/>
                        </div>
                        <div className="col-sm-12 col-md-6">
                                {types}
                            <div style={{ paddingRight: 2 + "px" }}>
                                <label htmlFor="description">Email</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    onChange={this.onChangeEmail}
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <hr/>
            </div>
        );
    }
}

export default InviteUserForm;