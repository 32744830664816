/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/
import React from "react";
import {useState} from "react";
import AccountService from "../../../services/account.service";

export default function ConfirmUserPasswordForm(props){
    const [password, setPassword] = useState("");
    const [firstTime, setFirstTime] = useState(true);
    const [loading, setLoading] = useState(false);

    function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        if(password !== null && password !== undefined && password !== "")
            AccountService.isValid(password).then(response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null) {
                    setPassword("");
                    setFirstTime(false);
                    setLoading(false);
                    props.onSubmit(response.data);
                }
            }).catch(e => {
                console.log(e);
                setLoading(false);
            });
    }

    return (
        <>
            <p>Signup with the current user password.</p>
            <form className="row g-2" onSubmit={handleSubmit}>
                <div className="input-group col-md-4 offset-4">
                    <input
                        id="password"
                        type="password"
                        name="password"
                        placeholder="Password"
                        className={firstTime ? "form-control" : props.isValid ? "form-control is-valid" : "form-control is-invalid"}
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {loading ?
                    <button className="btn btn-outline-secondary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button> :
                        <button className="btn btn-outline-secondary" type="submit">Confirm identity</button>}
                    <div className="invalid-feedback"> Wrong password.</div>
                </div>
            </form>
        </>
    );
}