/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";
import authService from "./authService";

const controller = "/login";
const loginService = {
    login: async (user) => {
        const response = await http.post(`${controller}`, user);
        authService.setToken(`Bearer ${response.data}`);
        return response.data;
    }/*,
    logout: async () => {
        const response = await http.post(`${controller}/logout`, null);
        authService.logout();
        return response.data;
    }*/
};
export default loginService;