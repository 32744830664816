/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import eulaFile from '../../txt/EULA.txt';
import DOMPurify from 'dompurify';

class Eula extends Component {

    constructor() {
        super();

        this.showEula = this.showEula.bind(this);

        this.state = {
            eulaText: ""
        };
    }

    componentDidMount() {
        this.showEula();
    }

    showEula() {
        fetch(eulaFile)
            .then(response => response.text())
            .then(textContent => {                
                this.setState({
                    eulaText: DOMPurify.sanitize(textContent)
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    renderEULA() {
        return (
            <div style={{ margin: '30px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <div className="col-sm-12 col-md-8">                                       
                    <p style={{ height: '100%', overflow: 'scroll', paddingLeft: '15px', paddingRight: '15px' }} dangerouslySetInnerHTML={{ __html: this.state.eulaText }} />
                </div>                
            </div>
        );
    }

    render() {        
        let contents = this.renderEULA();

        return (
            <div>
                
                {contents}                

            </div>
        );
    }
}

export default Eula;
