/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/registeruser";
class RegisterUserService {
    
    async get(id) {
        const response = await http.get(`${controller}/activate?email=${encodeURIComponent(id)}`);
        const data = response;
        return data.data;
    }

    async gethomeowneraccount(id, email) {
        const response = await http.get(`${controller}/gethomeowneraccount?organizationId=${encodeURIComponent(id)}&email=${encodeURIComponent(email)}`);
        const data = response;
        return data;
    }

    async createhomeowneraccount(obj) {
        const response = await http.post(`${controller}/createhomeowneraccount`, obj);
        const data = response;
        return data;
    }

    async registerhomeowner(obj) {
        const response = await http.post(`${controller}/registerhomeowner`, obj);
        const data = response;
        return data;
    }

    async emailhomeowner(user) {
        const response = await http.post(`${controller}/emailhomeowner`, user);
        const data = response;
        return data;
    }

    async create(user) {
        const response = await http.post(`${controller}/createaccount/`, user);
        const data = response;
        return data.data;
    }
}

export default new RegisterUserService();