/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";
import "../../CSS/Gateway.css";
import { Row } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { GatewaySidebar } from "../Provisioning//Gateway/GatewaySidebar";
import { Workflow } from "../Provisioning/Gateway/Workflow";
import { MoreColors } from "../Provisioning/Gateway/MoreColors";
import { WiFiProblem } from "../Provisioning/Gateway/WiFiProblem";
import { ResetToFactory } from "../Provisioning/Gateway/ResetToFactory";

class GatewayWorkflowModal extends Component {

    constructor(props) {
        super(props);
        this.state = { currentSelect: 0 };
        this.onCustomerSelect = this.onCustomerSelect.bind(this);
    }

    onCustomerSelect(e) {
        this.setState({ currentSelect: e.target.value })
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    
    render() {

        if (!this.props.show) {
            return null;
        }
        else {
            return (
                
                    <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                        <div className="modal-wrapper-gateway" backdrop="static"
                            style={{
                                transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                                opacity: this.props.show ? '1' : '0',
                                bottom: 0,
                            }}>
                        <div className="modal-header">
                            <h3>Gateway Workflow</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); }}>×</span>
                        </div>

                        <div className="modal-body">
                                <div>
                                    <Row>
                                        <div className="col-3 px-1 position-fixed" id="sticky-sidebar">
                                            <GatewaySidebar />
                                        </div>
                                        <div id="main" style={{ marginLeft: '175px', position: 'fixed', overflowY: 'scroll', maxHeight: 'calc(100% - 60px)' }}>

                                            <Tabs>
                                                <TabList>
                                                    <Tab>Workflow</Tab>
                                                    <Tab>More Colors</Tab>
                                                    <Tab>Wi-Fi Problems</Tab>
                                                    <Tab>Reset Devices</Tab> 
                                                </TabList>

                                                <TabPanel>
                                                    <Workflow />
                                                </TabPanel>
                                                <TabPanel>
                                                    <MoreColors />
                                                </TabPanel>
                                                <TabPanel>
                                                    <WiFiProblem />
                                                </TabPanel>
                                                <TabPanel>
                                                    <ResetToFactory />
                                                </TabPanel>
                                            </Tabs>

                                        </div>
                                    </Row>
                                </div>
                        </div>
                    </div>
                    </div>
            );
        }
    }
}

export default GatewayWorkflowModal;