/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/thermostat";
class ThermostatService {

     async getlocations(apiKey) {
        const response = await http.get(`${controller}/locations?apiKey=${apiKey}`);
        const data = response;
        return data;
     }

    async getdeviceinfo(apiKey, deviceId, locationId) {
        const response = await http.get(`${controller}/getdeviceinfo?apiKey=${apiKey}&deviceId=${deviceId}&locationId=${locationId}`);
        const data = response;
        return data;
    }

    async updateSetpoint(apiKey, deviceId, locationId, deviceSP) {
        const response = await http.post(`${controller}/updateSetpoint?apiKey=${apiKey}&deviceId=${deviceId}&locationId=${locationId}`, deviceSP);
        const data = response;
        return data;
    }
}

export default new ThermostatService();