/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import "../../CSS/DataModal.css";

class TriageMenuModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clearClicked: false,
            clearConfirmed: false
        };

        this.onClearClick = this.onClearClick.bind(this);
        this.onClearConfirm = this.onClearConfirm.bind(this);
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
        this.setState({ clearClicked: false, clearConfirmed: false }, () => {
            //this.props.onClick();
        });
    };

    onClearClick() {
        this.setState({ clearClicked: true });
    }

    onClearConfirm() {
        this.setState({ clearConfirmed: true }, () => {
            this.props.onClear();
        });
    }

    getDateStr(dateTime) {
        var dt = new Date(dateTime);
        var dateStr = dt.toLocaleString('en-US')

        return dateStr;
    }

    render() {

        if (!this.props.show) {
            return null;
        }
        else {
            let clearBtn = !this.props.clearedAssets && !this.state.clearClicked && this.props.modalList.length ?
                <button className="primary-btn btn-small" onClick={this.onClearClick/*this.props.onClear*/} disabled={this.props.modalList.length ? false : true}>{this.props.isSkips ? "Clear Skips" : "Clear Checkouts"}</button>
                : null;

            let confirmBtn = this.state.clearClicked && !this.state.clearConfirmed ?
                <button className="primary-btn btn-small" onClick={this.onClearConfirm/*this.props.onClear*/}>Confirm</button>
                : null;

            let tableContent = this.props.modalList != null && this.props.modalList !== undefined && this.props.modalList.length ?
                (this.props.isSkips ?
                    <tbody>
                        {this.props.modalList.map((asset, index) =>
                            <tr className="alerts-table-row" key={`${asset.assetId}-${index}`} data={index} style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>
                                <td>{asset.assetId}</td>
                                <td>{this.getDateStr(asset.skipDateTime)}</td>
                            </tr>
                        )}
                    </tbody>
                    : <tbody>
                        {this.props.modalList.map((asset, index) =>
                            <tr className="alerts-table-row" key={`${asset.assetId}-${index}`} data={index} style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>
                                <td>{asset.assetId}</td>
                                <td>{asset.userId}</td>
                                <td>{this.getDateStr(asset.checkoutDateTime)}</td>
                            </tr>
                        )}
                    </tbody>)
                : <tbody>
                    <tr className="alerts-table-row" style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>
                        <td>{this.props.loading ? "Fetching assets..." : (this.props.isSkips ? "No Skipped Assets" : "No Current Asset Checkouts")}</td>
                        <td></td>
                    </tr>
                </tbody>

            let table = this.props.isSkips ?
                <table className="table-hover" style={{ width: '100%' }}>
                    <thead>
                        <tr className="alerts-table-row">
                            <th>ASSET ID</th>
                            <th>SKIP DATE</th>
                        </tr>
                    </thead>
                    {tableContent}
                </table>
                : <table className="table-hover" style={{ width: '100%' }}>

                    <thead>
                        <tr className="alerts-table-row">
                            <th>ASSET ID</th>
                            <th>USER ID</th>
                            <th>CHECKOUT DATE</th>
                        </tr>
                    </thead>
                    {tableContent}
                </table>;

            let content = this.state.clearClicked && !this.state.clearConfirmed ?
                <div>
                    <br />
                    <h5>{this.props.isSkips ? "Click confirm below to clear out all of your currently skipped assets" : "Click confirm below to clear out all the currently checked out assets"}</h5>
                    <br />
                </div>
                : (this.props.clearedAssets ?
                    <div>
                        <br />
                        <h5>{this.props.clearedAssetsSuccess ? "Successfully cleared assets" : (this.props.loading ? "Clearing assets..." : "Failed to clear assets")}</h5>
                        <br />
                    </div>
                    : table);

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>{this.props.title}</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ padding: '20px 60px' }}>
                            {content}
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Done</button>
                            {clearBtn}
                            {confirmBtn}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default TriageMenuModal;
