/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/
import React, {useEffect, useState} from "react";

const SelectHVACSystem = ({hvacList, onChange, children}) => {

    useEffect(() => {
        let isMounted = true;
        if (isMounted && hvacList !== undefined && hvacList.length) {
            let value = hvacList[0];
            onChange(value);
        }
        return () => {
            isMounted = false;
        }
    }, [hvacList]);

    function onChangeItem(e){
        let value = hvacList[e.target.value];
        onChange(value);
    }

    function getPrettyName(name){

        if (name !== null && name !== undefined) {

            var split = name.split("__");
            return split[0];
        }
        else {
            return "";
        }
    }

    return hvacList != null && hvacList.length ?
        <div className="select-container-2">
                    <span className="select-message">Select which HVAC System<br/>
                            <select className="selectpicker" style={{width: 300 + 'px'}} onChange={onChangeItem}>
                                {hvacList.map((obj, index) =>
                                    <option key={`${index}-${obj.assetId}`} id={`${index}-${obj.assetId}`}
                                            value={index}>{getPrettyName(obj.name)}</option>
                                )}
                            </select>
                        {children}
                    </span>
        </div>
        :
        <div className="select-container-2">
                <span className="select-message">Select which HVAC System<br />
                    <select className="selectpicker" style={{ width: 300 + 'px' }} >
                        
                    </select>
                </span>
        </div>
}
export default SelectHVACSystem;