import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React, {useState} from "react";
import "../../custom.css";

function AgeOfFleet({ partnerInfoObj }) {

    const [isAgeOfFleetLoading, setIsAgeOfFleetLoading] = useState(false);

    return (
        isAgeOfFleetLoading ?
            <div className="row no-gutters" style={{ marginBottom: '20px' }}>
                <div className="col">
                    <Card elevation={4} style={{height: '140px'}}>
                        <CardContent style={{padding: '10px 0px'}}>
                            <h6 style={{paddingLeft: '15px'}}>Age Of Fleet</h6>
                            <div className='row no-gutters card-row centered' style={{minHeight: '52px'}}>
                                <div className="dot-flashing"></div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
            :
            <div className="row no-gutters" style={{ marginBottom: '20px' }}>
                <div className="col">
                    <Card elevation={4} style={{height: '140px'}}>
                            <CardContent style={{padding: '10px 0px'}}>
                                <h6 style={{paddingLeft: '15px', marginBottom: '0px'}}>Age Of Fleet</h6>
                                <div style={{textAlign: 'center', fontSize: '80%', marginTop: '20px', marginBottom: '15px' }}>
                                    <div className="row no-gutters card-row"
                                         style={{textAlign: 'center', margin: '10px 0px'}}>
                                        <div className="col">
                                            <div>
                                                <div className="dashboard-number" style={{marginBottom: '4px'}}>
                                                    {partnerInfoObj.ageOfFleet !== undefined && partnerInfoObj.ageOfFleet !== null ? partnerInfoObj.ageOfFleet.total : 0}
                                                </div>
                                                <span style={{color: "dimGray"}}>Total Units</span>
                                            </div>
                                        </div>
                                        <div className="col" style={{borderLeft: '1px solid lightGray', borderRight: '1px solid lightGray'}}>
                                            <div>
                                                <div className="dashboard-number" style={{marginBottom: '4px'}}>
                                                    {partnerInfoObj.ageOfFleet !== undefined && partnerInfoObj.ageOfFleet !== null ? partnerInfoObj.ageOfFleet.new : 0}
                                                </div>
                                                <span style={{color: "dimGray"}}>New< br />0-5 Years</span>
                                            </div>
                                        </div>
                                        <div className="col" style={{borderRight: '1px solid lightGray'}}>
                                            <div>
                                                <div className="dashboard-number" style={{marginBottom: '4px'}}>
                                                    {partnerInfoObj.ageOfFleet !== undefined && partnerInfoObj.ageOfFleet !== null ? partnerInfoObj.ageOfFleet.aging : 0}
                                                </div>
                                                <span style={{color: "dimGray"}}>Aging< br />5-10 Years</span>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div>
                                                <div className="dashboard-number" style={{marginBottom: '4px'}}>
                                                    {partnerInfoObj.ageOfFleet !== undefined && partnerInfoObj.ageOfFleet !== null ? partnerInfoObj.ageOfFleet.target : 0}
                                                </div>
                                                <span style={{color: "dimGray"}}>Targets< br />10+ Years</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                    </Card>
                </div>
            </div>
    );
}

export default AgeOfFleet;
