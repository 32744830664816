/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { Loading } from 'react-loading-dot';
import { SketchPicker } from 'react-color';
import { PageView, ModalView, Event } from "../GoogleAnalytics";
import PartnerService from "../../services/partner.service";
import AlertService from "../../services/alerts.service";
import AssetService from "../../services/asset.service";

import IconButton from '@material-ui/core/IconButton';
import IconTooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import LongArrowUpIcon from '@material-ui/icons/ArrowUpward';
import LongArrowDownIcon from '@material-ui/icons/ArrowDownward';

import moment from "moment";
import { withStyles } from '@material-ui/core/styles';
import tinycolor from "tinycolor2";

import HighchartGraph from '../Graph/HighchartGraph';
import PublishModal from './PublishModal';
import PublishResultModal from './PublishResultModal';
import FurnacePropertiesDrawer from './FurnacePropertiesDrawer';

import { jsAsset, alertCustomer, jsAlert, jsGraph, jsGraphData } from "../../componentObjects";
import getLast150, { breakAlertData } from '../../processAlertDataArray';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import trainingSessionService from "../../services/trainingSession.service";

const COLORS = ['#0033a0', '#3ed3c2', '#ff7555', '#ff9900', '#7d8ee2'];

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

const contentTypes = {
    GOOD: "GOOD",
    BAD: "BAD",
    FANONLY: "FANONLY"
};

const initState = {
    loading: false,
    modalTitle: "",
    modalMessage: "",
    deviceTableLoading: false,
    selected: -1,
    deviceSelected: false,
    currentDevice: [],
    currentDeviceId: null,
    //currentRadio: "Failed",
    //trainingInfo: {},
    //trainingPage: {},
    //trainingList: [],

    columnList: [],
    columnAltered: false,
    columnSort: {
        enabled: false,
        type: null,
        reverse: false
    },
    showCustomerInfo: false,
    customerLoading: false,
    currentCustomer: alertCustomer,
    currentPartnerName: "",
    showAssetInfo: false,
    assetLoading: false,
    currentAssetInfo: jsAsset,
    showColorPicker: false,
    selectedColor: -1,
    runTableLoading: false,
    runTypeSelected: false,
    currentType: "",
    typeRunList: [],
    selectedRuns: [],
    graphedRuns: [],
    dataList: [],
    chartDataList: [],
    rawDataList: [],
    last150DataList: [],
    runData: {},
    allSelected: false,
    allGraphed: false,
    showAnalytics: false,
    analytics: [],
    analyticsRun: {},
    analyticsSeries: {},
    analyticsLoading: false,
    showPublishModal: false,
    showPublishResultModal: false,
    assetProps: [],
    originalInfo: {},
    publishInfo: {},
    publishLoading: false,
    isPublishSuccess: null,
    ttAssetUrl: '',
    gotProperties: false,
    showProperties: false,
    propertiesLoading: false,
    propsChanged: false,
    editProps: {},
    editFurnaceFinal: {},
    changedProps: [],
    showResetConfirm: false,
    showRetrainConfirm: false,
    assetRetrained: false,
    retrainDevice: []
}

class FurnaceAssetTrainingReview extends Component {
    static displayName = FurnaceAssetTrainingReview.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.loadInfo = this.loadInfo.bind(this);
        this.populateInfo = this.populateInfo.bind(this);
        this.changeTableRadio = this.changeTableRadio.bind(this);
        this.onColumnClick = this.onColumnClick.bind(this);
        this.columnSort = this.columnSort.bind(this);
        this.onDeviceRowClick = this.onDeviceRowClick.bind(this);
        this.onShowAllClick = this.onShowAllClick.bind(this);

        this.onRunTypeClick = this.onRunTypeClick.bind(this);
        //this.onCustomerInfoClick = this.onCustomerInfoClick.bind(this);
        //this.onAssetInfoClick = this.onAssetInfoClick.bind(this);
        this.onBackClick = this.onBackClick.bind(this);

        this.onSelectAll = this.onSelectAll.bind(this);
        this.handleRunCheck = this.handleRunCheck.bind(this);
        this.onGraphAll = this.onGraphAll.bind(this);
        this.handleGraphRunCheck = this.handleGraphRunCheck.bind(this);

        this.handleColorClick = this.handleColorClick.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.closeColorPicker = this.closeColorPicker.bind(this);

        this.openPublishModal = this.openPublishModal.bind(this);
        this.showPublishModal = this.showPublishModal.bind(this);
        this.onContinuePublish = this.onContinuePublish.bind(this);
        this.openPublishResultModal = this.openPublishResultModal.bind(this);
        this.showPublishResultModal = this.showPublishResultModal.bind(this);
        this.onViewInTT = this.onViewInTT.bind(this);

        this.onApplyAnalyticsClick = this.onApplyAnalyticsClick.bind(this);

        this.openPropertiesDrawer = this.openPropertiesDrawer.bind(this);
        this.showPropertiesDrawer = this.showPropertiesDrawer.bind(this);
        this.openResetConfirmModal = this.openResetConfirmModal.bind(this);
        this.showResetConfirmModal = this.showResetConfirmModal.bind(this);
        this.openRetrainConfirmModal = this.openRetrainConfirmModal.bind(this);
        this.showRetrainConfirmModal = this.showRetrainConfirmModal.bind(this);

        this.changeProperties = this.changeProperties.bind(this);
        this.onResetProperties = this.onResetProperties.bind(this);
        this.onApplyProperties = this.onApplyProperties.bind(this);
        this.onRetrain = this.onRetrain.bind(this);

        this.analyzeIgnition = this.analyzeIgnition.bind(this);
        this.analyzeShutdown = this.analyzeShutdown.bind(this);
        this.handleIgnitioNCheckbox = this.handleIgnitioNCheckbox.bind(this);
        this.handleShutdowCheckbox = this.handleShutdowCheckbox.bind(this);
        this.gettrainingpublishinfo = this.gettrainingpublishinfo.bind(this);

        this.state = {
            loading: false,
            modalTitle: "",
            modalMessage: "",

            /* all assets page */
            deviceTableLoading: false,
            selected: -1,
            deviceSelected: false,
            currentDevice: [],
            currentDeviceId: null,
            currentAssetId: "",
            currentRadio: "Failed",

            trainingInfo: {},
            trainingPage: {},
            trainingList: [],

            columnList: [],
            columnAltered: false,
            columnSort: {
                enabled: false,
                type: null,
                reverse: false
            },

            orgId: 1,
            showAllTraining: true,//false,
            showShowAllBtn: false,

            /*showCustomerInfo: false,
            customerLoading: false,
            currentCustomer: alertCustomer,
            currentPartnerName: "",
            showAssetInfo: false,
            assetLoading: false,
            currentAssetInfo: jsAsset,*/

            /* device page */
            showColorPicker: false,
            selectedColor: -1,

            runTableLoading: false,
            runTypeSelected: false,
            currentType: "",
            typeRunList: [],

            selectedRuns: [],
            graphedRuns: [],
            dataList: [],
            chartDataList: [],
            rawDataList: [],
            last150DataList: [],
            longRunList: [],
            runData: {},
            allSelected: false,
            allGraphed: false,

            visibility: {
                rla: false,
                lra: false,
                fla: false
            },

            showAnalytics: false,
            analytics: [],
            analyticsRun: {},
            analyticsSeries: {},
            analyticsLoading: false,

            showPublishModal: false,
            showPublishResultModal: false,
            assetProps: [],
            originalInfo: {},
            publishInfo: {},
            editInfo: {},
            editFinal: {},
            editProperty: {},
            publishLoading: false,
            isPublishSuccess: false,
            ttAssetUrl: '',

            gotProperties: false,
            showProperties: false,
            propertiesLoading: false,
            propsChanged: false,
            editProps: {},
            editFurnaceFinal: {},
            changedProps: [],
            showResetConfirm: false,
            showRetrainButton: true,
            showRetrainConfirm: false,
            assetRetrained: false,
            retrainDevice: [],
            analyzeIgnitionData: {
                delayHigh: false,
                delayLow: false,
                ignitionHigh: false,
                ignitionLow: false,
                igniterOnAmps: false,
            },
            analyzeShutdownData: {
                gasValveOffLow: false,
                gasValveOffHigh: false,
                igniterOnAmps: false,
                shutdownLow: false,
                shutdownHigh: false,
            },
            showAnalyzeIgnitionData: false,
            showanAlyzeShutdownData: false,
            AnalyzeData: []
        }
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        if (!this.props.isLoading)
            this.loadInfo();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /** Asset Training Review **/

    loadInfo() {
        if (!this.props.isLoading)
            this.setState({ loading: true }, async () => {
                let orgId = this.props.currentPartnerId;
                let showBtn = true;
                let showAll = false;
                if (this.props.loggedInOrg !== "LifeWhere") {
                    showBtn = false;
                }
                else if (this.props.showAllTraining) {
                    showAll = true;
                    orgId = 1;
                }

                this.setState({
                    orgId: orgId,
                    showAllTraining: showAll,
                    showShowAllBtn: showBtn
                }, async () => {
                    await this.populateInfo();
                })

            });
    }

    async populateInfo() {
        await PartnerService.getassettraininginfo(this.state.orgId, false)
            .then(response => {
                if (this._isMounted) {
                    if (response != null && response !== {}) {
                        this.setState({ trainingInfo: response }, () => {
                            if (this.state.trainingInfo.trainingPageDict !== {} && this.state.trainingInfo.trainingPageDict !== undefined && this.state.trainingInfo.trainingPageDict["Failed"] !== undefined) {
                                let radio = "Failed";
                                let page = this.state.trainingInfo.trainingPageDict["Failed"];
                                let list = this.state.trainingInfo.trainingPageDict["Failed"].trainingAssets;
                                if (!list.length) {
                                    if (this.state.trainingInfo.trainingPageDict["LowData"] !== undefined && this.state.trainingInfo.trainingPageDict["LowData"].trainingAssets.length) {
                                        radio = "LowData";
                                        page = this.state.trainingInfo.trainingPageDict["LowData"];
                                        list = this.state.trainingInfo.trainingPageDict["LowData"].trainingAssets;
                                    }
                                    else if (this.state.trainingInfo.trainingPageDict["Trained"] !== undefined && this.state.trainingInfo.trainingPageDict["Trained"].trainingAssets.length) {
                                        radio = "Trained";
                                        page = this.state.trainingInfo.trainingPageDict["Trained"];
                                        list = this.state.trainingInfo.trainingPageDict["Trained"].trainingAssets;
                                    }
                                }
                                this.setState({
                                    currentRadio: radio,
                                    trainingPage: page,
                                    trainingList: list,
                                    loading: false
                                });
                            }
                            else {
                                this.setState({
                                    currentRadio: "Failed",
                                    trainingPage: {},
                                    trainingList: [],
                                    loading: false
                                });
                            }
                        });
                    }
                    else {
                        this.setState({ loading: false });
                    }
                }

            })
            .catch(e => {
                console.log(e);
            });
    }

    onShowAllClick() {
        Event("Show All Clicked", "User clicked show all in Furnace Asset Training Review", "Show All Clicked");
        this.setState({ loading: true, showAllTraining: !this.state.showAllTraining }, () => {
            this.props.setShowAllTraining(this.state.showAllTraining);
            this.setState({ orgId: this.state.showAllTraining ? 1 : this.props.currentPartnerId }, async () => {
                await this.populateInfo();
            });
        });
    }

    changeTableRadio(e) {
        Event("Radio Clicked", "User clicked radio in Furnace Asset Training Review", "Radio Clicked");
        let radio = e.target.value;

        this.setState({
            currentRadio: radio,
            trainingPage: this.state.trainingInfo.trainingPageDict[radio],
            trainingList: this.state.trainingInfo.trainingPageDict[radio].trainingAssets
        });
    }

    onColumnClick(selectedCol) {
        const colSort = this.state.columnSort;
        let newSort = colSort;
        const tableList = this.state.trainingList;
        let trainingAssets = tableList;
        let colAssets = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colAssets = this.columnSort(trainingAssets, "DEVICEID", false); /* default sort by is DEVICEID */
                    colAltered = false;
                }
                else {
                    newSort.reverse = true;
                    colAssets = this.columnSort(trainingAssets, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colAssets = this.columnSort(trainingAssets, selectedCol, false);
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colAssets = this.columnSort(trainingAssets, selectedCol, false);
            colAltered = true;
        }

        this.setState({ columnSort: newSort, columnList: colAssets }, () => {
            this.setState({ columnAltered: colAltered });
        });
    }

    columnSort(list, col, isReverse) {
        let colAssets = [];
        let trainingAssets = list;

        if (col === "ORGANIZATION") {
            colAssets = trainingAssets.sort((a, b) => {
                const nameA = a.organization.toLocaleUpperCase();
                const nameB = b.organization.toLocaleUpperCase();

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "DEVICEID") {
            colAssets = trainingAssets.sort((a, b) => {
                const valA = a.deviceId.toLocaleUpperCase();
                const valB = b.deviceId.toLocaleUpperCase();

                return valA.localeCompare(valB, undefined, { numeric: true });
            });
        }
        else {
            colAssets = trainingAssets.sort((a, b) => {
                const valA = col === "ASSETID" ? a.assetId : (col === "TOTALRUNS" ? a.runCount : (col === "GOODRUNS" ? a.goodRunCount : a.badRunCount));
                const valB = col === "ASSETID" ? b.assetId : (col === "TOTALRUNS" ? b.runCount : (col === "GOODRUNS" ? b.goodRunCount : b.badRunCount));

                return valB - valA;
            });
        }

        if (isReverse) {
            colAssets = colAssets.reverse();
        }

        return colAssets;
    }

    async onDeviceRowClick(index) {
        let x = index;
        let device = this.state.trainingList[x];
        let deviceId = this.state.trainingList[x].deviceId;

        this.setState({
            currentDevice: device,
            currentAssetId: device.assetId,
            currentDeviceId: deviceId,
            selected: x,
            deviceSelected: true,
            runTypeSelected: false,
            deviceTableLoading: true
        }, () => {
            this.setState({
                deviceTableLoading: false
            });
        });
    }

    /** Device Training Review **/

    onBackClick() {
        Event("Back Clicked", "User clicked back button in Furnace Asset Training Review", "Back Clicked");
        this.setState(initState);
    }

    onRunTypeClick(runType) {
        this.setState({
            runTableLoading: true,
            runTypeSelected: true,
            currentType: runType,
            longRunList: [],
            graphedRuns: [],
            selectedRuns: [],
            dataList: [],
            dataMax: 0,
            rawMax: 0,
            chartDataList: [],
            rawDataList: [],
            last150DataList: [],
            analyticsSeries: {},
            showAnalytics: false,
            analytics: []
        }, () => {
            this.setState({
                typeRunList: this.state.currentType == contentTypes.GOOD ? this.state.currentDevice.goodRuns :
                    this.state.currentType == contentTypes.BAD ? this.state.currentDevice.badRuns : this.state.currentDevice.fanOnlyRuns
            }, async () => {
                await AlertService.gettrainingrunlistdata(this.state.currentAssetId, true, false, this.state.typeRunList)
                    .then(response => {
                        this.setState({
                            runTableLoading: false,
                            runData: response.data
                        });
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ runTableLoading: false });
                    });
            });
        });
    }

    onSelectAll() {
        Event("Select All Clicked", "User clicked select all in Furnace Asset Training Review", "Select All Clicked");
        let selectedRuns = [];

        this.setState({ allSelected: !this.state.allSelected }, () => {
            if (this.state.allSelected)
                selectedRuns = this.state.typeRunList;

            this.setState({ selectedRuns: selectedRuns });
        });
    }

    handleRunCheck(e) {
        const index = e.target.value;
        const run = this.state.typeRunList[index];
        let selectedRuns = this.state.selectedRuns;

        if (e.target.checked) {
            selectedRuns = selectedRuns.concat(run);
            this.setState({ selectedRuns: selectedRuns });
        }
        else {
            selectedRuns = selectedRuns.filter(s => s.runId !== run.runId);
            this.setState({ selectedRuns: selectedRuns });
        }
    }

    onGraphAll() {
        Event("Graph All Clicked", "User clicked graph all in Furnace Asset Training Review", "Graph All Clicked");
        let graphedRuns = [];
        let selectedRuns = [];
        let dataList = [];
        let chartDataList = [];
        let rawDataList = [];
        let last150DataList = [];
        let dataMax = 0;
        let rawMax = 0;

        this.setState({ allGraphed: !this.state.allGraphed }, () => {
            if (this.state.allGraphed) {
                selectedRuns = this.state.typeRunList;
                this.setState({ loading: true, showAnalytics: this.state.typeRunList.length > 1 ? false : this.state.showAnalytics, analytics: this.state.typeRunList.length > 1 ? [] : this.state.analytics }, async () => {
                    await AlertService.gettrainingrunlistdata(this.state.currentAssetId, false, false, this.state.typeRunList)
                        .then(response => {
                            if (this._isMounted)
                                this.setState({
                                    runData: response.data
                                }, () => {
                                    for (let i = 0; i < this.state.typeRunList.length; i++) {
                                        const run = this.state.typeRunList[i];
                                        let series = this.state.runData[run.runId];

                                        let seriesRuns = series.series;
                                        //series.series = seriesRuns;

                                        if (series !== undefined) { /* has already been loaded */
                                            graphedRuns = graphedRuns.concat(run);
                                            dataList = dataList.concat(series);
                                            if (series !== undefined) {
                                                for (let i = 0; i < seriesRuns.length; i++) {
                                                    if (seriesRuns[i].length > dataMax)
                                                        dataMax = seriesRuns[i].length;
                                                }
                                                if (series.dataMax > rawMax)
                                                    rawMax = series.dataMax;

                                                for (let j = 0; j < seriesRuns.length; j++) {
                                                    let runNum = j + 1;
                                                    let dataArr = seriesRuns[j].map((item) => (item.value));
                                                    let lastArr = [...dataArr].slice(dataArr.length >= 151 ? dataArr.length - 151 : 0);

                                                    const chartData = {
                                                        name: `${run.runId}`,
                                                        data: dataArr,
                                                        color: series.color,
                                                        dashStyle: 'solid',
                                                        type: 'line',
                                                        marker: {
                                                            enabledThreshold: 0
                                                        }
                                                    };
                                                    chartDataList = chartDataList.concat(chartData);

                                                    const lastData = {
                                                        name: `${run.runId}`,
                                                        data: lastArr,
                                                        color: series.color,
                                                        dashStyle: 'solid',
                                                        type: 'line',
                                                        marker: {
                                                            enabledThreshold: 0
                                                        }
                                                    };
                                                    last150DataList = last150DataList.concat(lastData);
                                                }

                                            }
                                        }
                                    }

                                    this.setState({
                                        loading: false,
                                        graphedRuns: graphedRuns,
                                        selectedRuns: selectedRuns,
                                        dataList: dataList,
                                        chartDataList: chartDataList,
                                        rawDataList: rawDataList,
                                        last150DataList: last150DataList,
                                        dataMax: dataMax,
                                        rawMax: rawMax
                                    });
                                });
                        })
                        .catch(e => {
                            console.log(e);
                            this.setState({ loading: false, message: "Failed to fetch graph data for all runs" });

                            setTimeout(() => {
                                this.setState({
                                    message: ""
                                });
                            }, 3000);
                        });
                });
            }
            else {
                selectedRuns = this.state.selectedRuns;

                this.setState({
                    graphedRuns: graphedRuns,
                    selectedRuns: selectedRuns,
                    dataList: dataList,
                    dataMax: dataMax,
                    rawMax: rawMax,
                    chartDataList: chartDataList,
                    rawDataList: rawDataList,
                    last150DataList: last150DataList
                });
            }
        });
    }

    async handleGraphRunCheck(e) {
        const index = e.target.value;
        const run = this.state.typeRunList[index];
        const graphRunId = run.runId;

        let graphedRuns = this.state.graphedRuns;
        let selectedRuns = this.state.selectedRuns;
        let runData = this.state.runData;
        let dataList = this.state.dataList;
        let longRunList = this.state.longRunList;
        let chartDataList = this.state.chartDataList;
        let rawDataList = this.state.rawDataList;
        let last150DataList = this.state.last150DataList;

        let list = [];
        list = list.concat(run);

        if (e.target.checked) {
            var showAnalytics = graphedRuns.length > 0 ? false : this.state.showAnalytics;
            this.setState({ loading: true, analyticsRun: run, showAnalytics: showAnalytics, analytics: showAnalytics ? this.state.analytics : [] }, async () => {
                await AlertService.gettrainingrunlistdata(this.state.currentAssetId, false, false, list)
                    .then(response => {
                        if (this._isMounted) {
                            let responseData = response.data;
                            responseData[graphRunId].color = runData[graphRunId].color;
                            runData[graphRunId] = responseData[graphRunId];

                            let series = responseData[graphRunId];
                            longRunList = longRunList.concat(run);
                            graphedRuns = graphedRuns.concat(run);
                            selectedRuns = selectedRuns.concat(run);
                            dataList = dataList.concat(series);

                            let seriesRuns = series.series;
                            series.series = seriesRuns;

                            let seriesMax = 0;
                            if (series !== undefined) {
                                for (var i = 0; i < seriesRuns.length; i++) {
                                    let thisMax = seriesRuns[i].length;
                                    if (thisMax > seriesMax)
                                        seriesMax = thisMax;
                                }

                                for (let j = 0; j < seriesRuns.length; j++) {
                                    let runNum = j + 1;
                                    let dataArr = seriesRuns[j].map((item) => (item.value));
                                    let lastArr = [...dataArr].slice(dataArr.length >= 151 ? dataArr.length - 151 : 0);

                                    const chartData = {
                                        name: `${run.runId}`,
                                        data: dataArr,
                                        color: series.color,
                                        dashStyle: 'solid',
                                        type: 'line',
                                        marker: {
                                            enabledThreshold: 0
                                        }
                                    };
                                    chartDataList = chartDataList.concat(chartData);

                                    const lastData = {
                                        name: `${run.runId}`,
                                        data: lastArr,
                                        color: series.color,
                                        dashStyle: 'solid',
                                        type: 'line',
                                        marker: {
                                            enabledThreshold: 0
                                        }
                                    };

                                    last150DataList = last150DataList.concat(lastData);
                                }

                            }

                            let dataMax = this.state.dataMax;
                            let rawMax = this.state.rawMax;
                            if (seriesMax > dataMax)
                                dataMax = seriesMax;
                            if (series.dataMax > rawMax)
                                rawMax = series.dataMax;

                            this.setState({
                                loading: false,
                                longRunList: longRunList,
                                graphedRuns: graphedRuns,
                                selectedRuns: selectedRuns,
                                dataList: dataList,
                                dataMax: dataMax,
                                rawMax: rawMax,
                                chartDataList: chartDataList,
                                rawDataList: rawDataList,
                                last150DataList: last150DataList,
                                analyticsSeries: series
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ loading: false, message: "Failed to load graph data for selected run!" });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 3000);
                    });
            })
        }
        else {
            longRunList = longRunList.filter(a => a.runId !== graphRunId);
            graphedRuns = graphedRuns.filter(a => a.runId !== graphRunId);
            dataList = dataList.filter(d => d.name !== graphRunId);
            chartDataList = chartDataList.filter(d => !d.name.includes(graphRunId));
            rawDataList = rawDataList.filter(d => !d.name.includes(graphRunId));
            last150DataList = last150DataList.filter(d => !d.name.includes(graphRunId));

            this.setState({ longRunList: longRunList, graphedRuns: graphedRuns, dataList: dataList, chartDataList: chartDataList, rawDataList: rawDataList, last150DataList: last150DataList });
        }
    }

    handleColorClick() {

    }

    handleColorChange() {

    }

    closeColorPicker() {

    }

    openPublishModal() {
        this.setState({ showPublishModal: true, publishLoading: true }, async () => {
            ModalView("publishFurnace");
            if (this.state.publishInfo == {} || !this.state.gotProperties) {
                await trainingSessionService.gettrainingpublishinfo(this.state.currentAssetId, false)
                    .then(response => {
                        this.setState({
                            originalInfo: response,
                            publishInfo: response,
                            publishLoading: false,
                            gotProperties: true
                        });
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ publishLoading: false });
                    })
            }
            else {
                this.setState({ publishLoading: false });
            }
        })
    }

    showPublishModal() {
        this.setState({ showPublishModal: !this.state.showPublishModal }, () => {
            if (this.state.showPublishModal)
                ModalView("publishFurnace");
        })
    }

    onContinuePublish() {
        Event("Publish Furnace Training", "User published furnace training", "Publish Furnace Training");
        this.setState({
            publishLoading: true,
            showPublishResultModal: true,
            showPublishModal: false,
            ttAssetUrl: "https://app.lifewhere.com/twins/detail/" + this.state.currentAssetId,
            editFurnaceFinal: this.state.publishInfo.furnaceFinalResult
        }, async () => {
                /* publish here*/
                var publishedBy = this.props.currentAccount.firstName + " " + this.props.currentAccount.lastName;
                if (!this.state.propsChanged) {
                    await AssetService.publishfurnacetraining(this.state.currentAssetId, publishedBy, this.state.publishInfo.furnaceFinalResult)
                        .then(response => {
                            if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false)
                                this.setState({
                                    isPublishSuccess: true,
                                    publishLoading: false
                                });
                            else
                                this.setState({
                                    isPublishSuccess: false,
                                    publishLoading: false 
                                });
                        })
                        .catch(e => {
                            console.log(e);
                            this.setState({
                                isPublishSuccess: false,
                                publishLoading: false
                            });
                        });
                }
                else {
                    let editProperty = this.getPropertyObjFromList(this.state.editProps);
                    this.setState(prevState => ({
                        editFurnaceFinal: {
                            ...prevState.editFurnaceFinal,
                            furnaceProperty: editProperty
                        }
                    }), async () => {
                            await AssetService.publishfurnaceedittraining(this.state.currentAssetId, publishedBy, this.state.editFurnaceFinal)
                                .then(response => {
                                    if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false)
                                        this.setState({
                                            isPublishSuccess: true,
                                            publishLoading: false
                                        });
                                    else
                                        this.setState({
                                            isPublishSuccess: false,
                                            publishLoading: false
                                        });
                                })
                                .catch(e => {
                                    console.log(e);
                                    this.setState({
                                        isPublishSuccess: false,
                                        publishLoading: false
                                    });
                                });
                    });
                }
        });
    }

    openPublishResultModal() {
        this.showPublishResultModal();
    }

    showPublishResultModal() {
        this.setState({ showPublishResultModal: !this.state.showPublishResultModal }, () => {
            if (this.state.showPublishResultModal)
                ModalView("publishResult");
        });
    }

    onViewInTT() {
        Event("View in TwinThread clicked", "User clicked to view Furnace in TwinThread", "View in TwinThread clicked");
        window.open(this.state.ttAssetUrl, "_blank");
        this.setState({ showPublishResultModal: false });
    }

    analyzeIgnition(e) {
        this.gettrainingpublishinfo();
        this.setState({
            showAnalyzeIgnitionData: !this.state.showAnalyzeIgnitionData,
            analyzeIgnitionData: {
                delayLow: !this.state.showAnalyzeIgnitionData,
                ignitionLow: !this.state.showAnalyzeIgnitionData,
                ignitionHigh: !this.state.showAnalyzeIgnitionData,
                delayHigh: !this.state.showAnalyzeIgnitionData,
                igniterOnAmps: !this.state.showAnalyzeIgnitionData
            }
        }
        );

    }


    analyzeShutdown(e) {
        this.gettrainingpublishinfo();
        this.setState({
            showanAlyzeShutdownData: !this.state.showanAlyzeShutdownData,
            analyzeShutdownData: {
                gasValveOffLow: !this.state.showanAlyzeShutdownData,
                gasValveOffHigh: !this.state.showanAlyzeShutdownData,
                igniterOnAmps: !this.state.showanAlyzeShutdownData,
                shutdownLow: !this.state.showanAlyzeShutdownData,
                shutdownHigh: !this.state.showanAlyzeShutdownData
            }

        }
        );
    }

    handleIgnitioNCheckbox(e) {
        const checkedVal = e.target.value;

        if (e.target.checked) {
            this.setState(prevState => ({
                analyzeIgnitionData: {
                    ...prevState.analyzeIgnitionData,
                    [checkedVal]: true
                }
            }));
        }
        else {
            this.setState(prevState => ({
                analyzeIgnitionData: {
                    ...prevState.analyzeIgnitionData,
                    [checkedVal]: false
                }
            }));
        }
    }

    handleShutdowCheckbox(e) {
        const checkedVal = e.target.value;

        if (e.target.checked) {
            this.setState(prevState => ({
                analyzeShutdownData: {
                    ...prevState.analyzeShutdownData,
                    [checkedVal]: true
                }
            }));
        }
        else {
            this.setState(prevState => ({
                analyzeShutdownData: {
                    ...prevState.analyzeShutdownData,
                    [checkedVal]: false
                }
            }));
        }
    }

    onApplyAnalyticsClick() {
        Event("Apply Analytics Clicked", "Apply analytics clicked in Furnace Asset Training Review", "Apply Analytics Clicked");
        this.setState({
            showAnalytics: !this.state.showAnalytics,
            loading: true,
            analyticsLoading: true,
            editInfo: this.state.publishInfo,
            editFurnaceFinal: this.state.publishInfo.furnaceFinalResult
        }, async () => {
                if (this.state.showAnalytics) {
                    if (!this.state.propsChanged) { /* no property edits made */
                        if (this.state.publishInfo == {} || !this.state.gotProperties) {
                            await trainingSessionService.gettrainingpublishinfo(this.state.currentAssetId, false)
                                .then(response => {
                                    this.setState({
                                        originalInfo: response,
                                        publishInfo: response,
                                        propertiesLoading: false,
                                        gotProperties: true
                                    }, async () => {
                                            let annotationsRequest = {
                                                assetId: this.state.currentAssetId.toString(),
                                                start: this.state.analyticsRun.startTime,
                                                end: this.state.analyticsRun.endTime,
                                                dataSeries: this.state.analyticsSeries,
                                                furnaceFinal: this.state.publishInfo.furnaceFinalResult
                                            };

                                            await AssetService.getfurnaceedittrainingannotations(annotationsRequest)
                                                .then(response => {
                                                    this.setState({
                                                        analytics: response.data,
                                                        loading: false,
                                                        analyticsLoading: false
                                                    })
                                                })
                                                .catch(e => {
                                                    console.log(e);
                                                    this.setState({ analyticsLoading: false, loading: false });
                                                })
                                    });
                                })
                                .catch(e => {
                                    console.log(e);
                                    this.setState({ propertiesLoading: false });
                                })
                        }
                        else {
                            let annotationsRequest = {
                                assetId: this.state.currentAssetId.toString(),
                                start: this.state.analyticsRun.startTime,
                                end: this.state.analyticsRun.endTime,
                                dataSeries: this.state.analyticsSeries,
                                furnaceFinal: this.state.publishInfo.furnaceFinalResult
                            };

                            await AssetService.getfurnaceedittrainingannotations(annotationsRequest)
                                .then(response => {
                                    this.setState({
                                        analytics: response.data,
                                        loading: false,
                                        analyticsLoading: false
                                    })
                                })
                                .catch(e => {
                                    console.log(e);
                                    this.setState({ analyticsLoading: false, loading: false });
                                })
                        }
                        
                    }
                    else {
                        let editProperty = this.getPropertyObjFromList(this.state.editProps);
                        this.setState(prevState => ({
                            editFurnaceFinal: {
                                ...prevState.editFurnaceFinal,
                                furnaceProperty: editProperty
                            }
                        }), async () => {
                            let annotationsRequest = {
                                assetId: this.state.currentAssetId.toString(),
                                start: this.state.analyticsRun.startTime,
                                end: this.state.analyticsRun.endTime,
                                dataSeries: this.state.analyticsSeries,
                                furnaceFinal: this.state.editFurnaceFinal
                            };

                            await AssetService.getfurnaceedittrainingannotations(annotationsRequest)
                                .then(response => {
                                    this.setState({
                                        analytics: response.data,
                                        loading: false,
                                        analyticsLoading: false
                                    })
                                })
                                .catch(e => {
                                    console.log(e);
                                    this.setState({ analyticsLoading: false, loading: false });
                                });
                        });
                    }
                }
                else {
                    this.setState({ analyticsLoading: false, loading: false });
                }
        });
    }

  gettrainingpublishinfo() {
       this.setState({ propertiesLoading: true }, async () => {
           if (this.state.publishInfo == {} || !this.state.gotProperties) {
               await trainingSessionService.gettrainingpublishinfo(this.state.currentAssetId, false)
                   .then(response => {
                       this.setState({
                           originalInfo: response,
                           publishInfo: response,
                           gotProperties: true
                       });
                   })
                   .catch(e => {
                       console.log(e);
                   })
           }
       })
}
/* PROPERTIES */

    openPropertiesDrawer() {
        this.setState({ showProperties: true, propertiesLoading: true }, async () => {
            if (this.state.publishInfo == {} || !this.state.gotProperties) {
                await trainingSessionService.gettrainingpublishinfo(this.state.currentAssetId, false)
                    .then(response => {
                        this.setState({
                            originalInfo: response,
                            publishInfo: response,
                            propertiesLoading: false,
                            gotProperties: true
                        });
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ propertiesLoading: false });
                    })
            }
        })
    }

    showPropertiesDrawer() {
        this.setState({ showProperties: !this.state.showProperties }, () => {
            if (this.state.showProperties)
                ModalView("propertiesDrawer");
        });
    }

    openResetConfirmModal() {
        this.setState({
            showProperties: false,
            modalTitle: "Reset Properties",
            modalMessage: "You are about to reset the properties back to their starting point, doing so will erase any changes made in your training session. Would you still like to proceed?"
        }, () => {
                this.showResetConfirmModal();
        })
    }

    showResetConfirmModal() {
        this.setState({ showResetConfirm: !this.state.showResetConfirm });
    }

    changeProperties(propsChanged, changedProps, editProps) {
        this.setState({
            propsChanged: propsChanged,
            changedProps: changedProps,
            editProps: editProps,
            showAnalytics: false,
            analytics: []
        });
    }

    onResetProperties() {
        Event("Reset Furnace Properties", "User clicked reset properties in Furnace Asset Training Review", "Reset Furnace Properties");
        this.setState({
            showProperties: false,
            showResetConfirm: false,
            showAnalytics: false,
            analytics: [],
            propsChanged: false,
            editProps: {},
            changedProps: []
        });
    }

    onApplyProperties() {
        Event("Apply Furnace Properties", "User clicked apply properties in Furnace Asset Training Review", "Apply Furnace Properties");
        this.setState({
            showAnalytics: !this.state.showAnalytics,
            showProperties: false,
            loading: true,
            analyticsLoading: true,
            editFurnaceFinal: this.state.publishInfo.furnaceFinalResult
        }, async () => {
            if (this.state.showAnalytics) {
                let editProperty = this.getPropertyObjFromList(this.state.editProps);
                this.setState(prevState => ({
                    editFurnaceFinal: {
                        ...prevState.editFurnaceFinal,
                        furnaceProperty: editProperty
                    }
                }), async () => {
                    let annotationsRequest = {
                        assetId: this.state.currentAssetId.toString(),
                        start: this.state.analyticsRun.startTime,
                        end: this.state.analyticsRun.endTime,
                        dataSeries: this.state.analyticsSeries,
                        furnaceFinal: this.state.editFurnaceFinal
                    };

                    await AssetService.getfurnaceedittrainingannotations(annotationsRequest)
                        .then(response => {
                            this.setState({
                                analytics: response.data,
                                loading: false,
                                analyticsLoading: false
                            })
                        })
                        .catch(e => {
                            console.log(e);
                            this.setState({ analyticsLoading: false, loading: false });
                        });
                });
            }
            else {
                this.setState({ analyticsLoading: false, loading: false });
            }
        });
    }


/* END PROPERTIES */
/* RETRAIN */

    openRetrainConfirmModal() {
        var numRuns = parseInt(this.state.currentDevice.goodRunCount) + parseInt(this.state.currentDevice.badRunCount);
        this.setState({
            modalTitle: "Retrain",
            modalMessage: "You are about to apply the analytics to all of the runs in the training set (" + numRuns + " Runs). This could take a long time, are you sure you want to apply this action?"
        }, () => {
            this.showRetrainConfirmModal();
        })
    }

    showRetrainConfirmModal() {
        this.setState({ showRetrainConfirm: !this.state.showRetrainConfirm });
    }

    onRetrain() {
        Event("Retrain clicked", "User clicked retrain in Furnace Asset Training Review", "Retrain Clicked");
        this.setState({ loading: true, showRetrainConfirm: false }, async () => {
            let editFurnFinal = this.state.editFurnaceFinal;
            if (editFurnFinal == {}) {
                const furnFinal = this.state.publishInfo.furnaceFinalResult;

                let furnProperty = furnFinal.furnaceProperty;
                for (let i = 0; i < this.state.changedProps.length; i++) {
                    let propName = this.state.changedProps[i];
                    let propIndex = this.state.editProps.findIndex(p => p.name == propName);
                    let newValue = this.state.editProps[propIndex].value;
                    furnProperty[propName] = newValue;
                }

                editFurnFinal = furnFinal;
                editFurnFinal.furnaceProperty = furnProperty;
            }

            let applyRequest = {
                assetId: this.state.currentAssetId.toString(),
                furnaceFinal: editFurnFinal,
                trainingAsset: this.state.currentDevice
            };

            await AssetService.getfurnaceapplypropsresult(applyRequest)
                .then(response => {
                    this.setState({
                        currentDevice: response.data,
                        retrainDevice: response.data,
                        runTypeSelected: false,
                        typeRunList: [],
                        assetRetrained: true,
                        loading: false,
                        showAnalytics: false,
                        analytics: [],
                        analyticsSeries: {}
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ loading: false, assetRetrained: false });
                })
        });
    }

/* END RETRAIN */

    getGraphAnnotations(annotations) {
        let graphAnns = [];

        for (let i = 0; i < annotations.length; i++) {
            let ann = annotations[i];
            let graphAnn = {
                color: ann.color + "45",
                from: ann.x1,
                to: ann.x2,
                label: {
                    text: ann["state"],
                    rotation: -90,
                    textAlign: 'left',
                    verticalAlign: 'top',
                    y: 90
                },
                zIndex: 1
            };
            graphAnns = graphAnns.concat(graphAnn);
        }
        
        return graphAnns;
    }

    getPropertyObjFromList(editProps) {
        let editProperty = {};
        editProps.forEach((prop) => {
            editProperty[prop.name] = prop.name !== "fanOnlyMode" ? prop.value : (prop.value == 0 ? false : true);
        });
        
        return editProperty;
    }

    getPropList(pubInfo) {
        let props = [];

        if (pubInfo !== undefined && pubInfo !== null && pubInfo.furnaceFinalResult !== undefined && pubInfo.furnaceFinalResult !== null && pubInfo.furnaceFinalResult.furnaceProperty !== undefined && pubInfo.furnaceFinalResult.furnaceProperty !== null) {
            let prop = pubInfo.furnaceFinalResult.furnaceProperty;
            
            let keys = Object.keys(prop);
            let vals = Object.values(prop);
            
            for (let i = 0; i < keys.length; i++) {
                if (vals[i] !== null) {
                    let currProp = {
                        name: keys[i],
                        value: vals[i]
                    };
                    if (currProp.value == null || currProp.value == undefined || currProp.value == true || currProp.value == false) {
                        if (vals[i] == true || vals[i] == false)
                            currProp.value = vals[i] == true ? 1 : 0;
                    }
                    props = props.concat(currProp);
                }
            }
        }
        
        return props;
    }

    renderGraph() {
        let resetButton = this.state.propsChanged ?
            <div><button className="secondary-btn btn-small" onClick={this.openResetConfirmModal} style={{ width: '140px', marginBottom: '2px' }}>Reset Properties</button></div>
            : null;

        let analyzeIgnitionOptions = this.state.showAnalyzeIgnitionData ?
            <div className="grey-container" style={{ marginLeft: '15px', marginTop: '20px', width: '200px' }}>
                <h6 className="sectionTitle">Analyze Ignition Options</h6>
                <div className="radio-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left' }}>
                    <div>
                        <input type="checkbox" value="delayHigh" checked={this.state.analyzeIgnitionData.delayHigh} id="gdo" onChange={this.handleIgnitioNCheckbox} />
                        &nbsp; Delay High &nbsp;&nbsp;
                    </div>
                    <div>
                        <input type="checkbox" value="delayLow" checked={this.state.analyzeIgnitionData.delayLow} id="gdo" onChange={this.handleIgnitioNCheckbox} />
                        &nbsp; Delay Low &nbsp;&nbsp;
                    </div>
                    <div>
                        <input type="checkbox" value="ignitionLow" checked={this.state.analyzeIgnitionData.ignitionLow} id="gdo" onChange={this.handleIgnitioNCheckbox} />
                        &nbsp; Ignition Low &nbsp;&nbsp;
                    </div>
                    <div>
                        <input type="checkbox" value="ignitionHigh" checked={this.state.analyzeIgnitionData.ignitionHigh} id="gdo" onChange={this.handleIgnitioNCheckbox} />
                        &nbsp; Ignition High &nbsp;&nbsp;
                    </div>

                    <div>
                        <input type="checkbox" value="igniterOnAmps" checked={this.state.analyzeIgnitionData.igniterOnAmps} id="gdo" onChange={this.handleIgnitioNCheckbox} />
                        &nbsp; Induction Off Amps Max &nbsp;&nbsp;
                    </div>
                </div>
            </div> : null;

        let analyzeShutdownOptions = this.state.showanAlyzeShutdownData ?
            <div className="grey-container" style={{ marginLeft: '15px', marginTop: '20px', width: '200px' }}>
                <h6 className="sectionTitle">Analyze Shutdown Options</h6>
                <div className="radio-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left' }}>

                    <div>
                        <input type="checkbox" value="gasValveOffLow" checked={this.state.analyzeShutdownData.gasValveOffLow} id="gdo" onChange={this.handleShutdowCheckbox} />
                        &nbsp; GasValve Off Low &nbsp;&nbsp;
                    </div>
                    <div>
                        <input type="checkbox" value="gasValveOffHigh" checked={this.state.analyzeShutdownData.gasValveOffHigh} id="gdo" onChange={this.handleShutdowCheckbox} />
                        &nbsp; GasValve Off High &nbsp;&nbsp;
                    </div>
                    <div>
                        <input type="checkbox" value="igniterOnAmps" checked={this.state.analyzeShutdownData.igniterOnAmps} id="gdo" onChange={this.handleShutdowCheckbox} />
                        &nbsp; Igniter On Amps &nbsp;&nbsp;
                    </div>
                    <div>
                        <input type="checkbox" value="shutdownLow" checked={this.state.analyzeShutdownData.shutdownLow} id="gdo" onChange={this.handleShutdowCheckbox} />
                        &nbsp; Shutdown Low &nbsp;&nbsp;
                    </div>
                    <div>
                        <input type="checkbox" value="shutdownHigh" checked={this.state.analyzeShutdownData.shutdownHigh} id="gdo" onChange={this.handleShutdowCheckbox} />
                        &nbsp; Shutdown High &nbsp;&nbsp;
                    </div>

                </div>
            </div> : null;

        let buttons =
            <div style={{ marginTop: '25px' }}>
                <div><button className="secondary-btn btn-small" onClick={this.openPublishModal} style={{ width: '140px', marginBottom: '2px' }}>Publish</button></div>
                <div><button className={this.state.showAnalytics ? "primary-btn btn-small" : "secondary-btn btn-small"} onClick={this.onApplyAnalyticsClick} style={{ width: '140px', marginBottom: '2px' }} disabled={this.state.graphedRuns.length == 1 ? false : true}>Apply Analytics</button></div>
                <div><button className="secondary-btn btn-small" onClick={this.openPropertiesDrawer} style={{ width: '140px', marginBottom: '2px' }}>Edit Properties</button></div>
                {resetButton}
                <div><button className="secondary-btn btn-small" onClick={this.analyzeIgnition} style={{ width: '140px', marginBottom: '2px' }}>Analyze Ignition</button></div>
                <div><button className="secondary-btn btn-small" onClick={this.analyzeShutdown} style={{ width: '140px', marginBottom: '2px' }}>Analyze Shutdown</button></div>
            </div>;

        return (
            <div className="row no-gutters">
                <div className="col-sm-12 col-md-10">

                    <HighchartGraph graphType={"ALERT"} chartData={this.state.chartDataList} key={`${this.state.currentAssetId}-${this.state.chartDataList.length}-${this.state.dataList.length}-${this.state.visibility}`} isAirConditioner={true} isTraining={true}
                        dataMax={this.state.dataMax} rawMax={this.state.rawMax} rawData={this.state.rawDataList} last150Data={this.state.last150DataList}
                        showAnnotations={this.state.showAnalytics} graphAnnotations={this.getGraphAnnotations(this.state.analytics)} showRLA={this.state.visibility.rla} rlaValue={this.state.dataList.length ? this.state.dataList[0].rla : null}
                        showLRA={this.state.visibility.lra} lraValue={this.state.dataList.length ? this.state.dataList[0].lra : null} showFLA={this.state.visibility.fla} flaValue={this.state.dataList.length ? this.state.dataList[0].fla : null} alertList={this.state.graphedRuns} assetId={this.state.currentAssetId} setLoading={this.setLoading}
                        AnalyzeData={this.state.originalInfo} AnalyzeIgnitionData={this.state.analyzeIgnitionData} AnalyzeShutdownData={this.state.analyzeShutdownData} ShowAnalyzeIgnitionData={this.state.showAnalyzeIgnitionData} ShowanAlyzeShutdownData={this.state.showanAlyzeShutdownData} />

                </div>
                <div className="col-sm-4 col-md-2">
                    <div style={{ marginLeft: '20px' }}>
                        {buttons}
                       
                    </div>
                    {analyzeIgnitionOptions}
                    {analyzeShutdownOptions}
                </div>
            </div>
        );
    }

    getStartDate(date) {
        var dateStr = moment(date).format();
        return (dateStr);
    }

    renderDeviceContent() {
        const { classes } = this.props;

        let deviceLoader = this.state.deviceTableLoading ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : null;

        let customerInfoBtn = this.state.showCustomerInfo ?
            <button className="tertiary-btn" onClick={this.onCustomerInfoClick}>- Hide Customer Info</button>
            : <button className="tertiary-btn" onClick={this.onCustomerInfoClick}>Show Customer Info</button>;

        let customerInfoContent = this.state.showCustomerInfo ?
            this.state.customerLoading ?
                <div className='centered'><Spinner animation="border" variant="dark" /></div>
                : <div style={{ marginLeft: '15px', marginRight: '15px' }}>
                    <small>
                        <div className="detailTitle">Customer Information</div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Customer:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentCustomer.name}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Partner:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentPartnerName}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Phone:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentCustomer.phone}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Email:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentCustomer.email}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Address:</p>
                            </div>
                            <div className="col-8">
                                {this.getAddress()}
                            </div>
                        </div>
                    </small>
                </div>
            : null;

        let assetInfoBtn = this.state.showAssetInfo ?
            <button className="tertiary-btn" onClick={this.onAssetInfoClick}>- Hide Asset Info</button>
            : <button className="tertiary-btn" onClick={this.onAssetInfoClick}>Show Asset Info</button>;

        let assetInfoContent = this.state.showAssetInfo ?
            this.state.assetLoading ?
                <div className='centered'><Spinner animation="border" variant="dark" /></div>
                : <div style={{ marginLeft: '15px', marginRight: '15px' }}>
                    <small>
                        <div className="detailTitle">Asset Information</div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Make:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentAssetInfo.make}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Model:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentAssetInfo.model}</p>
                            </div>
                        </div>
                    </small>
                </div>
            : null;

        let retrainButton = this.state.showRetrainButton ?
            <button className={"secondary-btn btn-small"} onClick={this.openRetrainConfirmModal} style={{ marginLeft: '10px' }} disabled={this.state.changedProps.length >= 1 ? false : true}>Retrain</button>
            : null;

        let tableContent = this.state.typeRunList.length && this.state.runTypeSelected ?
            <tbody>
                {this.state.typeRunList.map((run, index) =>
                    <tr key={`${index}-${run.runId}`} data={index}>
                        <td>{run.runId}</td>
                        <td>{this.getStartDate(run.startTime)}</td>
                        <td>{this.getStartDate(run.endTime)}</td>
                        <td>{run.alertType}</td>
                        <td>
                            <input type="checkbox" value={index} id={run.runId} checked={this.state.selectedRuns.some(a => a.runId == run.runId)} onChange={this.handleRunCheck} />
                        </td>
                        <td>
                            <input type="checkbox" value={index} id={`${run.runId}-graph`} checked={this.state.graphedRuns.some(a => a.runId == run.runId)} onChange={this.handleGraphRunCheck} />
                        </td>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <div style={{ height: '13px', width: '13px', border: '1px solid grey', backgroundColor: this.state.runData[run.runId] !== undefined ? this.state.runData[run.runId].color : 'white' }} onClick={() => this.handleColorClick(run.runId)} />
                                {this.state.showColorPicker && this.state.selectedColor == run.runId ?
                                    <div style={{ position: 'absolute', zIndex: '2', right: '5px' }}>
                                        <div onClick={this.closeColorPicker} style={{ position: 'fixed', top: '0px', bottom: '0px', left: '0px', right: '0px' }} />
                                        <SketchPicker color={this.state.runData[run.runId] !== undefined ? this.state.runData[run.runId].color : 'white'} onChange={this.handleColorChange} />
                                    </div>
                                    : null
                                }
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
            : <tbody>
                <tr><td>{this.state.runTableLoading || this.state.loading ? "Loading Runs of Selected Type..." : "No Runs of this Type"}</td></tr>
            </tbody>;

        let runTable = this.state.runTypeSelected ?
            <div style={{ maxHeight: '330px', marginBottom: '15px', overflow: "auto" }}>
                <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Run Id</th>
                            <th>Start Time</th>
                            <th>End Time </th>
                            <th>Alert Type</th>
                            <th>Select <input type="checkbox" value={this.state.allSelected} checked={this.state.allSelected} id='selectAll' onChange={this.onSelectAll} style={{ marginLeft: '5px' }} /></th>
                            <th>Graph <input type="checkbox" value={this.state.allGraphed} checked={this.state.allGraphed} id='graphAll' onChange={this.onGraphAll} style={{ marginLeft: '5px' }} /></th>
                            <th style={{ width: '25px' }}></th>
                        </tr>
                    </thead>
                    {tableContent}
                </table>
            </div>
            : <div>{null}</div>;

        return (
            <div style={{ paddingLeft: '10px', paddingTop: '5px' }}>
                <div className="row no-gutters" style={{ display: 'flex' }}>
                    <div className="col-sm-12 col-md-4">

                        <div className="grey-container" style={{ minHeight: 'calc(100% - 10px)' }}>
                            <h5 className="sectionTitle">
                                <button className="tertiary-btn" onClick={this.onBackClick} style={{ margin: '0px' }}>{"<"}</button>
                                Asset Id: {this.state.currentDevice.assetId}
                                {/*<IconTooltip title="Force Reload Asset Alerts" enterDelay={200} leaveDelay={200} style={{ marginTop: '-5px', paddingTop: '-5px', backgroundColor: '#f7f9fa' }}>
                                    <IconButton onClick={() => this.openForceReloadConfirmModal()} style={{ padding: '0px', marginLeft: '8px', marginTop: '-5px' }}>
                                        <RefreshIcon style={{ color: "#0033a0", width: '20px', height: '20px' }} />
                                    </IconButton>
                                </IconTooltip>*/}
                            </h5>
                            <div className="detailTitle" style={{ marginLeft: '15px' }}>
                                Mac: {this.state.currentDeviceId}  {this.state.assetRetrained ? "(User Updated Values)" : ""}
                            </div>
                            <div className="table-container">

                                <div style={{ marginBottom: '0px', overflow: "auto" }}>
                                    <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                                        <thead>
                                            <tr>
                                                <th>Run Type</th>
                                                <th>Run Count</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr className={this.state.runTypeSelected && this.state.currentType === contentTypes.GOOD ? "tableSelected" : ""} onClick={() => { this.onRunTypeClick(contentTypes.GOOD) }}>
                                                <td>Good Runs {this.state.assetRetrained ? "(Manual)" : ""}</td>
                                                <td>{this.state.currentDevice.goodRunCount} {this.state.assetRetrained ? "(Manual)" : ""}</td>
                                            </tr>

                                            <tr className={this.state.runTypeSelected && this.state.currentType === contentTypes.BAD ? "tableSelected" : ""} onClick={() => { this.onRunTypeClick(contentTypes.BAD) }}>
                                                <td>Bad Runs {this.state.assetRetrained ? "(Manual)" : ""}</td>
                                                <td>{this.state.currentDevice.badRunCount} {this.state.assetRetrained ? "(Manual)" : ""}</td>
                                            </tr>

                                            <tr className={this.state.runTypeSelected && this.state.currentType === contentTypes.FANONLY ? "tableSelected" : ""} onClick={() => { this.onRunTypeClick(contentTypes.FANONLY) }}>
                                                <td>Fan Only Runs {this.state.assetRetrained ? "(Manual)" : ""}</td>
                                                <td>{this.state.currentDevice.fanOnlyRunCount} {this.state.assetRetrained ? "(Manual)" : ""}</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>

                            <div className="lastItem">
                                {retrainButton}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-8">

                        <div className="white-container" style={{ minHeight: '100%' }}>
                            <h5 className="sectionTitle">
                                {this.state.currentType == "" ? "Selected Run Type" : this.state.currentType == contentTypes.GOOD ? "Good Runs" : this.state.currentType == contentTypes.BAD ? "Bad Runs" : "Fan Only Runs"}
                            </h5>
                            <div className="table-container" style={{ maxHeight: '330px' }}>
                                {runTable}
                            </div>
                            {deviceLoader}
                        </div>
                    </div>
                </div>

                <div>
                    {this.state.runTypeSelected ?
                        <div>
                            <br />
                            {this.renderGraph()}
                        </div>
                        : <div className="centered">
                            <br />
                            Select a Run Type to generate graph
                            </div>
                    }
                    <p>{this.state.message}</p>
                </div>
                <PublishModal show={this.state.showPublishModal} loading={this.state.publishLoading} onClick={this.showPublishModal} onContinuePublish={this.onContinuePublish} publishInfo={this.state.publishInfo}
                    originalProps={this.getPropList(this.state.originalInfo)} assetProps={this.getPropList(this.state.publishInfo)} editProps={this.state.editProps} changedProps={this.state.changedProps} propsChanged={this.state.propsChanged} />
                <PublishResultModal show={this.state.showPublishResultModal} loading={this.state.publishLoading} isPublishSuccess={this.state.isPublishSuccess} onClick={this.showPublishResultModal} onContinue={this.showPublishResultModal} onViewInTT={this.onViewInTT} />
                <FurnacePropertiesDrawer show={this.state.showProperties} loading={this.state.propertiesLoading} onClick={this.showPropertiesDrawer} onResetClick={this.openResetConfirmModal} onApplyClick={this.onApplyProperties} changeProperties={this.changeProperties}
                    assetProps={this.getPropList(this.state.publishInfo)} editProps={this.state.editProps} propsChanged={this.state.propsChanged} changedProps={this.state.changedProps} showAnalytics={this.state.showAnalytics} analyticsDisabled={this.state.graphedRuns.length == 1 ? false : true} />
                <ConfirmationModal show={this.state.showResetConfirm} onYes={this.onResetProperties} onClick={this.showResetConfirmModal} message={this.state.modalMessage} title={this.state.modalTitle}> </ConfirmationModal>
                <ConfirmationModal show={this.state.showRetrainConfirm} onYes={this.onRetrain} onClick={this.showRetrainConfirmModal} message={this.state.modalMessage} title={this.state.modalTitle}> </ConfirmationModal>
            </div>
        );
    }

    renderTrainingContent() {
        let tableList = this.state.columnAltered ? this.state.columnList : this.state.trainingList;

        let tableContent = tableList.length ?
            <tbody>
                {this.state.trainingList.map((asset, index) =>
                    <tr className="alerts-table-row" key={`${asset.deviceId}-tableRow`} data={index} value={index} onClick={() => this.onDeviceRowClick(index)}>
                        <td style={{ borderLeft: '1px solid #ebeef0', paddingLeft: '30px' }}>{asset.deviceId}</td>
                        <td>{asset.assetId}</td>
                        <td>{asset.organization}</td>
                        <td>{asset.runCount}</td>
                        <td>{asset.goodRunCount}</td>
                        <td>{asset.badRunCount}</td>
                        <td style={{ borderRight: '1px solid #ebeef0' }}>{asset.fanOnlyRunCount}</td>
                    </tr>
                )}
            </tbody>
            :
            <tbody>
                <tr>
                    <td>No Trainings to Show!</td>
                </tr>
            </tbody>;

        let arrowIcon = this.state.columnSort.enabled ?
            (this.state.columnSort.reverse ?
                <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
                : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />)
            : null;

        let devIdIcon = this.state.columnSort.enabled && this.state.columnSort.type === "DEVICEID" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let assetIdIcon = this.state.columnSort.enabled && this.state.columnSort.type === "ASSETID" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let orgIcon = this.state.columnSort.enabled && this.state.columnSort.type === "ORGANIZATION" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let totalIcon = this.state.columnSort.enabled && this.state.columnSort.type === "TOTALRUNS" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let goodIcon = this.state.columnSort.enabled && this.state.columnSort.type === "GOODRUNS" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let badIcon = this.state.columnSort.enabled && this.state.columnSort.type === "BADRUNS" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let fanOnlyIcon = this.state.columnSort.enabled && this.state.columnSort.type === "FANONLYRUNS" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;

        return (
            <div>
                <div className="row no-gutters alerts-tab-wrapper" style={{ margin: '20px 30px', border: '1px solid #ebeef0', borderRadius: '.1rem', boxShadow: 'inset 0 -1px 0 rgba(100,121,143,0.122)' }}>
                    <div className="col"></div>
                    <div className="col-4" style={{ paddingTop: '15px' }}>
                        <p>Assets in Training Session: &nbsp;<b>{this.state.trainingInfo.assetCount}</b></p>
                        <p>Runs Observed: &nbsp;<b>{this.state.trainingInfo.runCount}</b></p>
                    </div>
                    <div className="col-4" style={{ paddingTop: '15px' }}>
                        <p>Training Session Date: &nbsp;<b>{this.state.trainingInfo.trainingSessionDate}</b></p>
                        <p>Training Session Id: &nbsp;<b>{this.state.trainingInfo.trainingSessionId}</b></p>
                    </div>
                    <div className="col"></div>
                </div>

                <div className="alerts-tab-wrapper" style={{ margin: '0 30px', backgroundColor: "#f7f9fa", padding: '10px 0' }}>
                    <div  style={{ paddingLeft: '40px', margin: '5px' }}>
                        <div style={{ height: '24px', display: 'flex', alignItems: 'center' }}>
                            <input type="radio" value="Failed" name="trainingType" onChange={e => this.changeTableRadio(e)} checked={this.state.currentRadio === "Failed"} />
                                    &nbsp; Failed Training &nbsp;&nbsp;
                            <input type="radio" value="LowData" name="trainingType" onChange={e => this.changeTableRadio(e)} checked={this.state.currentRadio === "LowData"} />
                                &nbsp; Low Data Training &nbsp;&nbsp;
                            <input type="radio" value="Trained" name="trainingType" onChange={e => this.changeTableRadio(e)} checked={this.state.currentRadio === "Trained"} />
                                &nbsp; Successful Training &nbsp;&nbsp;
                        </div>
                    </div >
                </div>

                <div style={{ margin: '20px 30px' }}>

                    <div>
                        <table className="table-striped table-bordered table-sm table-hover" style={{ width: '100%' }}>

                            <thead>
                                <tr className="alerts-table-row">
                                    <th style={{ paddingLeft: '30px' }}>
                                        <div onClick={() => this.onColumnClick("DEVICEID")}>
                                            <span style={{ cursor: 'pointer' }}>DEVICE ID</span>
                                            {devIdIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => this.onColumnClick("ASSETID")}>
                                            <span style={{ cursor: 'pointer' }}>ASSET ID</span>
                                            {assetIdIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => this.onColumnClick("ORGANIZATION")}>
                                            <span style={{ cursor: 'pointer' }}>ORGANIZATION</span>
                                            {orgIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => this.onColumnClick("TOTALRUNS")}>
                                            <span style={{ cursor: 'pointer' }}>TOTAL RUNS</span>
                                            {totalIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => this.onColumnClick("GOODRUNS")}>
                                            <span style={{ cursor: 'pointer' }}>GOOD RUNS</span>
                                            {goodIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => this.onColumnClick("BADRUNS")}>
                                            <span style={{ cursor: 'pointer' }}>BAD RUNS</span>
                                            {badIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => this.onColumnClick("FANONLYRUNS")}>
                                            <span style={{ cursor: 'pointer' }}>FAN ONLY RUNS</span>
                                            {fanOnlyIcon}
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            {tableContent}

                        </table>
                    </div>
                </div>

            </div>
        );
    }

    render() {
        const { classes } = this.props;

        let loader = this.state.loading ?
            <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : null;//this.renderContent();

        let title = this.state.deviceSelected ? this.state.currentDeviceId + " Training Review" : "Furnace Asset Training Review";
        let contents = this.state.deviceSelected ? this.renderDeviceContent() : this.renderTrainingContent();

        let showAllBtn = !this.state.deviceSelected && this.state.showShowAllBtn ?
            <div className="btn-right" style={{ padding: '25px 0px 0px 30px' }}>
                <button className={this.state.showAllTraining ? "primary-btn btn-large" : "secondary-btn btn-large"} style={{ width: 150 + 'px' }} onClick={this.onShowAllClick}>Show All</button>
            </div>
            : null;

        return (
            <div className={classes.root}>
                <div className='infoPage'>
                    <div className="row no-gutters">
                        <div className="col">
                            <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                                {title}
                            </h2>
                        </div>
                        <div className="col">
                            {showAllBtn}
                        </div>
                    </div>

                    {contents}
                    {loader}

                </div>
            </div>
        );
    }

}

export default withStyles(styles)(FurnaceAssetTrainingReview);