/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/settings";
const settingService = {
    organizationUpdateAttributesCustomer: async (customer) => {
        const response = await http.post(`${controller}/organizationUpdateAttributes`, customer);
        return response.data;
    },
    addLocationIdtoHome: async (locationId, assetId) => {
        const response = await http.post(`${controller}/addLocationIdtoHome?locationId=${locationId}&assetId=${assetId}`);
        return response.data;
    }
};
export default settingService;