/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/hvaccomponent";
const hvaccomponentService = {
    gethvaccomponents: async (type) => {
        const response = await http.get(`${controller}/gethvaccomponents?assetType=${type}`);
        return response.data;
    }
};
export default hvaccomponentService;