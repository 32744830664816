/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import PartnerService from '../services/partner.service';
import "../CSS/DataModal.css";
import "../CSS/react-draft-wysiwyg.css";
import "../custom.css";
import { withStyles } from '@material-ui/core/styles';
//import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

class AboutModal extends Component {

    constructor(props) {
        super(props);

        this.onAddVersionClick = this.onAddVersionClick.bind(this);
        this.onChangeVersion = this.onChangeVersion.bind(this);
        this.onChangeNotes = this.onChangeNotes.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);

        this.state = {
            showAddVersion: false,
            showErrorMsg: false,
            newVersion: '',
            newNotes: '',
            editorState: EditorState.createEmpty()
        }
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
        this.setState({ showAddVersion: false, newVersion: '', newNotes: '', editorState: EditorState.createEmpty() })
    }

    onAddVersionClick() {
        this.setState({ showAddVersion: true, newVersion: '', newNotes: '', editorState: EditorState.createEmpty() });
    }

    onChangeVersion(e) {
        let newVal = e.target.value;

        this.setState({ newVersion: newVal });
    }

    onChangeNotes = e => {
        this.setState({
            editorState: e,
            newNotes: draftToHtml(convertToRaw(e.getCurrentContent()))
        });
    }

    onSubmitClick() {
        this.setState({ showAddVersion: false }, () => {
            this.props.onSubmitNotes(this.state.newVersion, this.state.newNotes);
        });
    }

    renderAddVersion() {
        return (<div className="modal-body" style={{ textAlign: 'left', margin: '0px 20px' }}>
            <form>
                <div>
                    <label>Version</label>
                    <input
                        type="text"
                        className="form-control"
                        id="version"
                        value={this.state.newVersion}
                        onChange={this.onChangeVersion}
                    />
                </div>
                <div>
                    <label>Release Notes</label>
                    <Editor
                        editorState={this.state.editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onChangeNotes}
                    />
                </div>
            </form>
        </div>
        );
    }

    renderNotes() {
        let releaseNotes = this.props.releaseNotes != null && this.props.releaseNotes !== undefined && this.props.releaseNotes.length ? this.props.releaseNotes.sort((a, b) => a.version.localeCompare(b.version, undefined, { numeric: true })) : [];

        let notesContent = releaseNotes.length ?
            <div className="modal-body" style={{ textAlign: 'left', margin: '15px 40px' }}>
                <span className="select-message" style={{ paddingLeft: '0px' }}>Select Version:
                    <select className="selectpicker" style={{ width: 200 + 'px', height: 30 + 'px', paddingLeft: 13 + 'px', paddingRight: 18 + 'px' }} onChange={this.props.onVersionSelect} value={this.props.selectedVersionIndex}>
                        {
                            releaseNotes.map((obj, index) =>
                                <option key={`${index}-${obj.version}`} id={`${index}-${obj.version}`} value={index}>{obj.version}</option>
                            )
                        }
                    </select>
                </span>
                <br />
                <br />
                <small>
                    <div dangerouslySetInnerHTML={{ __html: this.props.selectedVersion.notes }} style={{ height: '250px', overflow: 'scroll', borderRadius: '.3em', border: '1px solid #ebeef0', padding: '5px' }} />
                </small>
            </div>
            : <div className="modal-body" style={{ textAlign: 'left', margin: '15px 40px' }}>
                <p>No Versions to show</p>
            </div>;

        return (notesContent);
    }

    render() {
        const { classes } = this.props;

        if (!this.props.show) {
            return null;
        }
        else {
            let loader = this.props.loading ?
                <div className={["loading"]} style={{ display: "block", marginLeft: '0', width: '100%' }}>
                    <div className="loading-wrapper">
                        <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                    </div></div>
                : null;

            let modalContent = this.state.showAddVersion && this.props.isPortalAdmin ? this.renderAddVersion() : this.renderNotes();

            let addButton = this.props.isPortalAdmin ?
                <button className="secondary-btn btn-small" onClick={e => { this.onAddVersionClick(); }}>Add Version</button>
                : null;

            let modalFooter = this.state.showAddVersion && this.props.isPortalAdmin ?
                <div className="modal-footer">
                    <button className="secondary-btn btn-small" onClick={e => { this.onCancelClick(); }}>Cancel</button>
                    <button className="primary-btn btn-small" onClick={e => { this.onSubmitClick(); }}>Submit Notes</button>
                </div>
                : <div className="modal-footer">
                    {addButton}
                    <button className="primary-btn btn-small" onClick={e => { this.onClick(e); }}>Done</button>
                </div>;

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>{this.state.showAddVersion && this.props.isPortalAdmin ? "Add Version" : "Release Notes" }</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); }}>×</span>
                        </div>

                        {modalContent}

                        {loader}

                        {modalFooter}

                    </div>
                </div>
            );
        }
    }
}

export default withStyles(styles)(AboutModal);
