/*

Resideo/LifeWhere
Copyright (C) 2018-2023 Resideo/LifeWhere

mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";
import { Spinner } from 'react-bootstrap';

class HomeownerConfirmModal extends Component {
    constructor(props) {
        super(props);

        this.onInvite = this.onInvite.bind(this);

        this.state = {
            
        }
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
        this.setState({  });
    };

    onInvite() {
        this.props.onInvite();
        this.setState({  });
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        else {
            let inviteLoading = this.props.loading ?
                <div className="loading" style={{ display: "block", marginLeft: '0', width: '100%', backgroundColor: 'rgba(0,0,0,0.65)' }}>
                    <div className="loading-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', top: '35%' }}>
                        <div className="loading-body" style={{ display: 'block' }}>
                            <Spinner animation="border" variant="light" />
                        </div>
                    </div></div>
                : <div>{null}</div>

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Confirm New Homeowner Info</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ textAlign: "left" }}>
                            <br />
                            <div className='row no-gutters'>
                                <div className="col-4 offset-2">
                                    <h5>Customer Info</h5>
                                    <p>
                                        {this.props.homeowner.lastName}, {this.props.homeowner.firstName}<br />
                                        {this.props.homeowner.email}<br />
                                        {this.props.homeowner.phone}
                                    </p>
                                </div>
                                <div className="col-4 offset-1">
                                    <h5>Address Info</h5>
                                    <p>
                                        {this.props.homeowner.homeNickname}<br />
                                        {this.props.homeowner.address}<br />
                                        {this.props.homeowner.city}, {this.props.homeowner.state}, {this.props.homeowner.zip}
                                    </p>
                                </div>
                            </div>
                            <br/>
                        </div>


                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={this.onInvite}>Invite Homeowner</button>
                        </div>
                        {inviteLoading}
                    </div>
                </div>
            );
        }
    }

}

export default HomeownerConfirmModal;