/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";

class AddUnparentedModal extends Component { 
    
    onClick = e => {
        this.props.onClick && this.props.onClick(e);
      };

    render() {
                
        if(!this.props.show){
            return null;
        }
        else{
            return (

                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Unparented Assets</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); }}>×</span>
                        </div>

                        <div className="modal-body">                            
                            <br/>
                            <div className="select-container">
                                <span className="select-message">Select an Asset<br />
                                    <select className="selectpicker" value={this.props.organizationId} onChange={this.props.onChange}>
                                        {this.props.unparentedAssets.map((obj, index) =>
                                            <option key={`${index}-${obj.assetId}`} id={`${index}-${obj.orgId}`} value={index}>{obj.name}</option>
                                        )}
                                    </select>
                                </span>
                            </div>
                            <br/>
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={this.props.onSave}>Save</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}   
  
export default AddUnparentedModal;