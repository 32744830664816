/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/
import React, {useEffect, useState} from "react";
import useTechnicianInfo from "./UseTechnicianInfo";
import moment from "moment/moment";

const TechnicianView = ({macId, setTechnicianInfo}) => {
    const technicianInfo = useTechnicianInfo(macId);
    const [data, setData] = useState({});

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setData(technicianInfo);
            if (setTechnicianInfo != null && setTechnicianInfo !== undefined)
                setTechnicianInfo(technicianInfo);
        }
        return () => {
            isMounted = false;
        }
    }, [technicianInfo]);

    return technicianInfo === null ? <div></div> :
        <span style={{ paddingLeft: '20px', color: 'slategrey' }}>Installed By: {technicianInfo.technicianName !== null && technicianInfo.technicianName !== undefined ? technicianInfo.technicianName + " (" + technicianInfo.technicianEmail + ") " + moment(technicianInfo.startDateTime).format("MM/DD/YYYY hh:mm a") : ""}</span>;
}
export default TechnicianView;