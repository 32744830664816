/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();


function TitanIntegration(props) {
    const [titanServiceData, setTitanServiceData] = useState(props?.data);
    const [clientSecretError, setClientSecretError] = useState(false);
    const [clientIdError, setClientIdError] = useState(false);
    const [aPIKeyError, setAPIKeyError] = useState(false);
    const [tenantIdError, setTenantIdError] = useState(false);
    const [isSTEdit, setIsEdit] = useState(props?.isSTEdit);
    const [editClientId, setEditClientId] = useState(props?.data?.ClientId?.length > 5 ? props?.data?.ClientId?.substring(0, 5).padEnd(props?.data?.ClientId.length, "*") :props?.data?.ClientId);
    const [editAPIKey, setEditAPIKey] = useState(props?.data?.APIKey?.length > 5 ? props?.data?.APIKey?.substring(0, 5).padEnd(props?.data?.APIKey.length, "*") : props?.data?.APIKey);
    const [editClientSecret, setEditClientSecret] = useState(props?.data?.ClientSecret?.length > 5 ? props?.data?.ClientSecret?.substring(0, 5).padEnd(props?.data?.ClientSecret.length, "*") : props?.data?.ClientSecret);
    const [is1stEditClientId, setIs1stEditClientId] = useState(true);
    const [is1stEditAPIKey, setIs1stEditAPIKey] = useState(true);
    const [is1stEditClientSecret, setIs1stEditClientSecret] = useState(true);

    const validation = (e) => {
        props?.data?.TenantId?.length > 0 ? setTenantIdError(false) : setTenantIdError(true);
        props?.data?.APIKey?.length > 0 ? setAPIKeyError(false) : setAPIKeyError(true);
        props?.data?.ClientId?.length > 0 ? setClientIdError(false) : setClientIdError(true);
        props?.data?.ClientSecret?.length > 0 ? setClientSecretError(false) : setClientSecretError(true);
        if (!clientSecretError && !clientIdError && !aPIKeyError && !setTenantIdError) {
            props.onValidationChange(true);
        }
        else {
            props.onValidationChange(false);
        }
    }

    const onChangeTenantId = (e) => {
        const tenantIdData = 
            { ...titanServiceData, TenantId : e.target.value  }
        setTitanServiceData(tenantIdData);
        props.onTitanIntegrationChange(tenantIdData);
    };

    const onChangeAPIKey = (e) => {
        const APIKeyData =
            { ...titanServiceData, APIKey : e.target.value,  }
        setTitanServiceData(APIKeyData);
        props.onTitanIntegrationChange(APIKeyData);
    };

    const onChangeClientId = (e) => {
        const clientIdData =   
            { ...titanServiceData, ClientId: e.target.value  }
        setTitanServiceData(clientIdData);
        props.onTitanIntegrationChange(clientIdData);
    };

    const onChangeClientSecret = (e) => {
        const clientSecretData  =
            { ...titanServiceData, ClientSecret : e.target.value}
        setTitanServiceData(clientSecretData);
        props.onTitanIntegrationChange(clientSecretData);
    };

    const onSelectAPIKey = (e) => {
        console.log(e); 
        if (isSTEdit && is1stEditAPIKey) {
            setEditAPIKey("");  
            setIs1stEditAPIKey(false);
            const clientIdData =
                { ...titanServiceData, APIKey: "" }
            setTitanServiceData(clientIdData);
            props.onTitanIntegrationChange(clientIdData);
        }
     }

    const onSelectClientId = (e) => {
        console.log(e);
        if (isSTEdit && is1stEditClientId) {
            setEditClientId("");
            setIs1stEditClientId(false);
            const clientIdData =
                { ...titanServiceData, ClientId: "" }
            setTitanServiceData(clientIdData);
            props.onTitanIntegrationChange(clientIdData);
        }
    }

    const onSelectClientSecret = (e) => {
        console.log(e);
        if (isSTEdit && is1stEditClientSecret) {
            setEditClientSecret("");
            setIs1stEditClientSecret(false);
            const clientIdData =
                { ...titanServiceData, ClientSecret: "" }
            setTitanServiceData(clientIdData);
            props.onTitanIntegrationChange(clientIdData);
        }
    }

        return (
            <div>
                <form>
                  
                        <div className="col-sm-12 col-md-9">
                            <div style={{ paddingRight: 2 + "px" }}>
                            <label htmlFor="tenantId">TenantId</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="tenantId"
                                    value={props?.data?.TenantId}
                                    onChange={onChangeTenantId}
                                     onBlur={validation}
                                />
                            {tenantIdError && <label style={{ color: 'red' }}>TenantId is required </label> }
                            </div>
                      
                            <div style={{ paddingRight: 2 + "px" }}>
                                <label htmlFor="APIKey">APIKey</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    onChange={onChangeAPIKey}
                                onSelect={onSelectAPIKey}
                                    value={props?.data?.APIKey}
                                value={(isSTEdit && editAPIKey != "") ? editAPIKey : props?.data?.APIKey}
                                onBlur={validation }
                                />
                            {aPIKeyError && <label style={{ color: 'red' }}>API Key is required </label>}
                            </div>
                       
                            <div style={{ paddingRight: 2 + "px" }}>
                            <label htmlFor="ClientId">ClientId</label>
                                <input
                                type="text"
                                className="form-control"
                                id="email"
                                onChange={onChangeClientId}
                                onSelect={onSelectClientId}
                                value={(isSTEdit && editClientId != "") ? editClientId: props?.data?.ClientId}
                               // value={props?.data?.ClientId?.length > 5 ? props?.data?.ClientId?.substring(0, 5).padEnd(props?.data?.ClientId.length, "*") : props?.data?.ClientId}
                                onBlur={validation }
                                />
                            {clientIdError && <label style={{ color: 'red' }}>ClientId is required </label>}
                            </div>
                       
                            <div style={{ paddingRight: 2 + "px" }}>
                            <label htmlFor="ClientSecret">ClientSecret</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                onChange={onChangeClientSecret}
                                onSelect={onSelectClientSecret}
                               // value={props?.data?.ClientSecret}
                                value={(isSTEdit && editClientSecret != "") ? editClientSecret : props?.data?.ClientSecret}
                                onBlur={validation }
                                />
                            {clientSecretError && <label style={{ color: 'red' }}>ClientSecret is required </label>}
                            </div>
                        <br/>
                        </div>
                   
                </form>
               
            </div>
        );
    
}

export default TitanIntegration;
