/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/emaillinks";
class EmailLinksService {
    async get(orgId) {
        const response = await http.get(`${controller}/${orgId}`);
        const data = response;
        return data.data;
    }
}

export default new EmailLinksService();