/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/
import http from "../http-common";

const controller = "/partnerDashboardInfo";
const partnerDashboardInfoService = {
    getdashboardinfo: async (orgId) => {
        const response = await http.get(`${controller}/${orgId}`);
        return response.data;
    }
};
export default partnerDashboardInfoService;