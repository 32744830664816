/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/
import React, {useEffect, useState} from "react";
import SelectHome from "../../Home/SelectHome";
import SelectHVACSystem from "../../System/SelectHVACSystem";
import UseUserAssets from "./UseUserAssets";
import {assetHierarchy, assetHierarchy as asset} from "../../../componentObjects";

const AssetHierarchyView = ({accountId, onHVACSystemSelect, fromAssetId, children}) => {
    const data= UseUserAssets(accountId, fromAssetId);
    const [homeList, setHomeList] = useState([]);
    const [systemList, setSystemList] = useState([]);
    useEffect(() => {
        let isMounted = true;
        function getChild(item, arr){
            arr.push(item);
            if(item.children.length){
                for(let i of item.children) {
                    getChild(i, arr);
                }
            }
        }
        if (isMounted) {
            let accumulator = [];
            let homes = [];
            for (let asset of data) {
                homes.push({orgId:asset.id, nickname:asset.name, children: asset.children});
                getChild(asset, accumulator);
            }
            if (data.length){
                setHomeList(homes);
            }else{
                onHVACSystemSelect();
            }

        }
        return () => {
            isMounted = false;
        }
    }, [data]);

    function onChangeHome(e){
        if (e.children.length){
            setSystemList(e.children);
        }else{
            let defaultSystem = [];
            defaultSystem.push(assetHierarchy);
            setSystemList(defaultSystem);
            onHVACSystemSelect();
        }
    }

    return (
        <>
            <div className="white-container">
                <div className="select-container-3">
                    <SelectHome style={{width: 300 + 'px'}} homes={homeList} onChange={onChangeHome}/>
                </div>
            </div>
            <div className="white-container">
                <div className="select-container-3">
                    <SelectHVACSystem style={{width: 300 + 'px'}} hvacList={systemList} onChange={onHVACSystemSelect}>
                        {children}
                    </SelectHVACSystem>
                </div>
            </div>
        </>
    );
}
export default AssetHierarchyView;