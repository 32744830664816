/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/thread";
const threadService = {
    runthread: async (threadId, blockId = 1) => {
        const response = await http.post(`${controller}/runthread?threadId=${threadId}&blockId=${blockId}`);
        return response.data;
    }
};
export default threadService;