/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import "../../../CSS/DataModal.css";
import styled from "styled-components";
import { Row, Container } from 'react-bootstrap';

const StyledContainer = styled.div`
    padding: 5px;
    text-align: left;
    margin-left: 10px;
    margin-top: 5px;
`;

export class Workflow extends React.Component {
    
    render() {
        return (
            <Container>
                <h4>Gateway</h4>
                <h5>Startup Window</h5>
            <StyledContainer>
                <Row>
                        <div className="col-md-6 col-lg-3 px-1" id="color-name">
                            <p> 1. <span style={{ color: '#ffc107' }}> Blinking Yellow </span></p>
                    </div>
                        <div className="col-md-12 col-lg-9" id="color-description">
                        <small> Wi-Fi is initializing either in AP or cloud mode. This is device level startup and has nothing to do with the home router. </small>
                    </div>
                </Row>
                <br />
                <Row>
                        <div className="col-md-6 col-lg-3 px-1" id="color-name" text-align="left">
                            <p> 2. <span style={{ color: 'cyan' }}> Cyan </span></p>
                    </div>
                        <div className="col-md-12 col-lg-9" id="color-description" text-align="left">
                            <small> Wi-Fi is waiting for NTP time update. The Gateway needs to synchronize its clock so that its time stamp is accurate. This allows us to get data from indoor and outdoor devices and ensures that the timestamps are the same for both. NTP is an internet protocol called Network Time Protocol and is supported by all Wi-Fi Routers </small>
                    </div>
                </Row>
                <br/>
                <Row>
                        <div className="col-md-6 col-lg-3 px-1" id="color-name" text-align="left">
                            <p> 3. <span style={{ color: 'blue' }}> Blinking Blue </span>/ <span style={{ color: 'green' }}> Green </span></p>
                    </div>
                        <div className="col-md-12 col-lg-9" id="color-description" text-align="left">
                            <small> Wi-Fi communication to cloud is in progress. The Gateway talks to LifeWhere Portal to register your asset and to send data on the electrical draw of your asset. It also talks to the LifeWhere Update Server to check for firmware updates. </small>
                    </div>
                </Row>
                <br/>
                <Row>
                        <div className="col-md-6 col-lg-3 px-1" id="color-name" text-align="left">
                            <p> 4. <span style={{ color: 'blue' }}> Blue </span></p>
                    </div>
                        <div className="col-md-12 col-lg-9" id="color-description" text-align="left">
                            <small> Gateway is configured and ready for cloud communications. The Gateway is online! </small>
                    </div>
                    </Row>
                    <br/><br/><br/><br/>
                </StyledContainer>
            </Container>
            );
    }
}