/*

Resideo/LifeWhere
Copyright (C) 2018-2023 Resideo/LifeWhere

mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";

class ReinviteHomeownerModal extends Component {

    onClick = e => {
        this.props.onClick();
    };

    renderResultContent() {
        let resultMessage = this.props.reinviteLoading ? "Sending Invite..." : (this.props.reinviteResult ? `Success! ${this.props.email} Invited!` : `Failed to invite ${this.props.email}, would you like to try again?`);

        let retryButton = this.props.reinviteResult ?
            null 
            : <button className="primary-btn btn-small" onClick={this.props.onTryAgain}>Try Again</button>

        return (
            <div className="modal-wrapper"
                style={{
                    transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                    opacity: this.props.show ? '1' : '0'
                }}>

                <div className="modal-header">
                    <h3>Send User Invite</h3>
                    <span className="close-modal-btn" onClick={e => { this.onClick(e); }}>×</span>
                </div>

                <div className="modal-body">
                    <br />
                    <h6 style={{ padding: '0px 70px' }}>
                        {resultMessage}
                    </h6>
                    <br />
                </div>

                <div className="modal-footer">
                    <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); }}>{this.props.reinviteResult ? "Continue" : "Cancel"}</button>
                    {retryButton}
                </div>

            </div>
        );
    }

    renderEmailBody() {
        return (
            <div className="modal-wrapper"
                style={{
                    transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                    opacity: this.props.show ? '1' : '0'
                }}>

                <div className="modal-header">
                    <h3>Send User Invite</h3>
                    <span className="close-modal-btn" onClick={e => { this.onClick(e); }}>×</span>
                </div>

                <div className="modal-body">
                    <br />
                    <p style={{ padding: '0px 70px' }}>
                        Please enter the Email of the person you are trying to invite below:
                    </p>
                    <br />
                    <div className="alerts-tab-wrapper" style={{ margin: '0 15px 0 30px' }}>
                        <div style={{ marginLeft: '15px', marginRight: '15px' }}>
                            <div className="row no-gutters">
                                <div className="col-3" style={{ textAlign: 'right', paddingRight: '15px' }}>
                                    <p className="semibold">User Email:</p>
                                </div>
                                <div className="col-7">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        value={this.props.email}
                                        onChange={this.props.onEmailChange}
                                        style={{ margin: '0 0 0.75em', height: '32px' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); }}>Cancel</button>
                    <button className="primary-btn btn-small" onClick={this.props.onConfirm}>Send Invite</button>
                </div>

            </div>
        );
    }

    render() {
        if (!this.props.show) {
            return null;
        } else {
            let modalContent = this.props.reinviteSent ? this.renderResultContent() : this.renderEmailBody();

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    {modalContent}
                </div> 
            );
        }
    }
}

export default ReinviteHomeownerModal;