/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/provisioning";
class ProvisioningService {   

    async get(id, customerId, assetId, provision) {

        //await http.post(`/asset/allAssets`, homeList);

        const response = await http.post(`${controller}/getdeviceinfo?Mac=` + id + '&customerId=' + customerId + '&assetId=' + assetId, provision);
        const data = response;
        return data;
    }

    async getconfig(mac) {
        const response = await http.get(`${controller}/getdeviceconfig?mac=` + mac);
        const data = response;
        return data;
    }

    async getunassigned() {
        const response = await http.get(`${controller}/unassigned`);
        const data = response;
        return data;
    }
}

export default new ProvisioningService();