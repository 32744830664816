import React, { useState, useEffect, Component } from 'react';
import { PageView, ModalView, Event } from "../GoogleAnalytics";
import { withStyles } from '@material-ui/core/styles';
import AlertService from '../../services/alerts.service';
import LongArrowUpIcon from '@material-ui/icons/ArrowUpward';
import LongArrowDownIcon from '@material-ui/icons/ArrowDownward';
import { Spinner, Image } from 'react-bootstrap';
import triageService from "../../services/triage.service";

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

function AlertTriageDetails(props) {
    let isMounted = false;
    let isDataFetched = false;
    const { classes, isLoading } = props;
    const [currentRadio, setRadio] = useState("Day");
    const [columnAltered, setColumnAltered] = useState(false);
    const [columnList, setColumnList] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [contentLoading, setContentLoading] = useState(true);
    const [metricsList, setMetricsList] = useState([]);
    const [summarizedList, setSummarizedList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [selectedUserIndex, setSelectedUserIndex] = useState(0)
    //const [isDataFetched, setIsDataFetched] = useState(false);
    const [columnSort, setColumnSort] = useState({
        enabled: true,
        type: "DATE",
        reverse: false
    });

    const maintainMetricsSorting = () => {
        var colMetrics = sortColumn(summarizedList, columnSort.type, columnSort.reverse);
        var colAltered = true;

        setSummarizedList(colMetrics, () => {
            setColumnAltered(colAltered);
        });
    }

    const summarizeMetrics = (data) => {
        // Initialize an empty object to store summarized data
        const summary = {};

        // Iterate over each data entry
        data.forEach(entry => {
            // Extract necessary information
            const { dailyId, sessionDate, sessionDurationMinutes, funaceAlertCount, airConditionerAlertCount,
                heatPumpAlertCount, airHandlerAlertCount, escalatedFunaceAlertCount, escalatedAirConditionerAlertCount,
                escalatedHeatPumpAlertCount, escalatedAirHandlerAlertCount, falsePositiveFunaceAlertCount,
                falsePositiveAirConditionerAlertCount, falsePositiveHeatPumpAlertCount, falsePositiveAirHandlerAlertCount } = entry;

            let date = new Date(sessionDate);
            const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;

            let key;
            if (currentRadio === "Day") {

                key = date.toDateString();

            } else if (currentRadio === "Week") {

                const firstDayOfWeek = new Date(date);
                firstDayOfWeek.setDate(date.getDate() - date.getDay());
                key = `Week ${firstDayOfWeek.toLocaleDateString()}`;

            } else if (currentRadio === "Month") {

                key = `${date.getMonth() + 1} / ${date.getFullYear()}`;

            }

            if (!summary[key]) {
                summary[key] = {
                    date: key,
                    sessionDurationMinutes: 0,
                    alertCount: 0,
                    escalatedAlertCount: 0,
                    falsePositiveAlertCount: 0
                };
            }

            summary[key].sessionDurationMinutes += entry.sessionDurationMinutes;
            summary[key].alertCount += entry.furnaceAlertCount + entry.airConditionerAlertCount + entry.heatPumpAlertCount + entry.airHandlerAlertCount;
            summary[key].escalatedAlertCount += entry.escalatedFurnaceAlertCount + entry.escalatedAirConditionerAlertCount + entry.escalatedHeatPumpAlertCount + entry.escalatedAirHandlerAlertCount;
            summary[key].falsePositiveAlertCount += entry.falsePositiveFurnaceAlertCount + entry.falsePositiveAirConditionerAlertCount + entry.falsePositiveHeatPumpAlertCount + entry.falsePositiveAirHandlerAlertCount;
        });

        var summarizedDataList = Object.entries(summary).map(([key, value]) => ({
            ...value
        }));

        return summarizedDataList;
    }

    const populateInfo = (userList, index = 0) => {
        PageView();
        let userId = userList.length > 0 ? userList[index].id : -1;
        triageService.getusertriagemetrics(userId)
            .then(response => {
                setMetricsList(response);
                setContentLoading(false);
                //setSummarizedList(summarizeMetrics(response.data));
            })
            .catch(e => {
                console.log(e);
            });
    }

    const getUsers = () => {
        triageService.getalltriageusers()
            .then(response => {
                setUserList(() => {
                    populateInfo(response);
                    return response;
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    const onUserChange = (e) => {
        Event("Alert Triage Details User Changed", "New user selected in Alert Triage Details", "Alert Triage Details User Changed");
        let index = parseInt(e.target.value, 10);
        setSelectedUserIndex(() => {
            populateInfo(userList, index);
            return index;
        });
    }

    const formatMinutes = (totalMinutes) => {
        if (totalMinutes == 0) return '0 minutes';
        const hours = Math.floor(totalMinutes / 60); 
        const minutes = totalMinutes % 60; 

        let formattedTime = '';
        if (hours > 0) {
            formattedTime += `${hours} hour${hours > 1 ? 's' : ''}`; // Pluralize "hour" if necessary
        }
        if (minutes > 0) {
            formattedTime += ` ${minutes} minute${minutes > 1 ? 's' : ''}`; // Pluralize "minute" if necessary
        }

        return formattedTime.trim(); // Trim any leading/trailing whitespace
    }

    const onColumnClick = (selectedCol) => {
        const colSort = columnSort;
        let newSort = colSort;
        const tableList = summarizedList;
        let metrics = tableList;
        let colAssets = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colAssets = sortColumn(metrics, "DATE", false); /* default sort by is DEVICEID */
                    colAltered = false;
                }
                else {
                    newSort = {
                        enabled: true,
                        type: selectedCol,
                        reverse: true
                    };
                    colAssets = sortColumn(metrics, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colAssets = sortColumn(metrics, selectedCol, false);
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colAssets = sortColumn(metrics, selectedCol, false);
            colAltered = true;
        }

        setColumnSort(newSort, () => {
            setColumnList(colAssets, () => {
                setColumnAltered(colAltered);
            });
        });
    }

    function sortColumn(dataList, col, isReverse) {
        let colData = [];
        let data = dataList;


        if (col === "DATE") {
            colData = data.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateB - dateA;
            });
        }
        else if (col === "TRIAGETIME") {
            colData = data.sort((a, b) => {
                const numA = a.sessionDurationMinutes;
                const numB = b.sessionDurationMinutes;

                return numB - numA;
            });
        }
        else if (col === "ALERTSTRIAGED") {
            colData = data.sort((a, b) => {
                const numA = a.alertCount;
                const numB = b.alertCount;

                return numB - numA;
            });
        }
        else if (col === "ESCALATIONS") {
            colData = data.sort((a, b) => {
                const numA = a.escalatedAlertCount;
                const numB = b.escalatedAlertCount;

                return numB - numA;
            });
        }
        else if (col === "FALSEPOSITIVES") {
            colData = data.sort((a, b) => {
                const numA = a.falsePositiveAlertCount;
                const numB = b.falsePositiveAlertCount;

                return numB - numA;
            });
        }
        else {
            colData = data;
        }

        if (isReverse) {
            colData = colData.reverse();
        }

        return colData;
    }

    const changeTableRadio = (e) => {
        Event("Alert Triage Details radio clicked", "User clicked a radio in Alert Triage Details", "Alert Triage Details radio clicked");
        let radio = e.target.value;
        setRadio(radio);
    }

    useEffect(() => {
        isMounted = true;

        if (!isLoading) {
            getUsers();
        }

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (metricsList.length > 0) {
            let sumMetrics = summarizeMetrics(metricsList);
            var colMetrics = sortColumn(sumMetrics, columnSort.type, columnSort.reverse);
            var colAltered = true;

            setSummarizedList(colMetrics, () => {
                setColumnAltered(colAltered);
            });

            setTableLoading(false);
        }
    }, [currentRadio, metricsList]);

    const renderMetricsContent = () => {
        let tableList = columnAltered ? columnList : summarizedList;

        let tableContent =
            tableLoading ?
                <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                    <div className="loading-wrapper">
                        <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                    </div>
                </div>
            :
            tableList.length ?
                <tbody>
                    {tableList.map((metric, index) =>
                        <tr className="alerts-table-row" key={`${metric.date}-tableRow`} data={index} value={index} onClick={() => this.onDeviceRowClick(index)}>
                            <td style={{ borderLeft: '1px solid #ebeef0', paddingLeft: '30px' }}>{metric.date}</td>
                            <td>{formatMinutes(metric.sessionDurationMinutes)}</td>
                            <td>{metric.alertCount}</td>
                            <td>{metric.escalatedAlertCount}</td>
                            <td style={{ borderRight: '1px solid #ebeef0' }}>{metric.falsePositiveAlertCount}</td>
                        </tr>
                    )}
                </tbody>
            :
                <tbody>
                    <tr>
                        <td>No Metrics to Show!</td>
                    </tr>
                </tbody>;

        let arrowIcon = columnSort.enabled ?
            (columnSort.reverse ?
                <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
                : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />)
            : null;

        let dateIcon = columnSort.enabled && columnSort.type === "DATE" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let triageTimeIcon = columnSort.enabled && columnSort.type === "TRIAGETIME" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let alertsTriagedIcon = columnSort.enabled && columnSort.type === "ALERTSTRIAGED" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let escalationsIcon = columnSort.enabled && columnSort.type === "ESCALATIONS" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let falsePositivesIcon = columnSort.enabled && columnSort.type === "FALSEPOSITIVES" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;

        let headerContent =
            <div>
                <div className="alerts-tab-wrapper" style={{ margin: '0 30px', backgroundColor: "#f7f9fa", padding: '10px 0' }}>
                    <div style={{ paddingLeft: '40px', margin: '5px' }}>
                        <div style={{ height: '24px', display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                            <div style={{ display: "flex", margin: "0 auto" }}>
                                <UserSelector users={userList} selectedIndex={selectedUserIndex} onChange={onUserChange} />
                            </div>
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                Summarize By: &nbsp;&nbsp;
                                <input type="radio" value="Day" name="summaryType" onChange={e => changeTableRadio(e)} checked={currentRadio === "Day"} />
                                        &nbsp; Day &nbsp;&nbsp;
                                <input type="radio" value="Week" name="summaryType" onChange={e => changeTableRadio(e)} checked={currentRadio === "Week"} />
                                    &nbsp; Week &nbsp;&nbsp;
                                <input type="radio" value="Month" name="summaryType" onChange={e => changeTableRadio(e)} checked={currentRadio === "Month"} />
                                    &nbsp; Month &nbsp;&nbsp;
                            </div>
                        </div>
                    </div >
                </div>
            </div>;

        return (
            <div>
                {headerContent}
                <div style={{ margin: '20px 30px' }}>

                    <div>
                        <table className="table-striped table-bordered table-sm table-hover" style={{ width: '100%' }}>

                            <thead>
                                <tr className="alerts-table-row">
                                    <th style={{ paddingLeft: '30px' }}>
                                        <div onClick={() => onColumnClick("DATE")}>
                                            <span style={{ cursor: 'pointer' }}>DATE</span>
                                            {dateIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => onColumnClick("TRIAGETIME")}>
                                            <span style={{ cursor: 'pointer' }}>TRIAGE TIME</span>
                                            {triageTimeIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => onColumnClick("ALERTSTRIAGED")}>
                                            <span style={{ cursor: 'pointer' }}>ALERTS TRIAGED</span>
                                            {alertsTriagedIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => onColumnClick("ESCALATIONS")}>
                                            <span style={{ cursor: 'pointer' }}>ESCALATIONS</span>
                                            {escalationsIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => onColumnClick("FALSEPOSITIVES")}>
                                            <span style={{ cursor: 'pointer' }}>FALSE POSITIVES</span>
                                            {falsePositivesIcon}
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            {tableContent}

                        </table>
                    </div>
                </div>

            </div>
        );
    }

    let content = contentLoading ?
        <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
            <div className="loading-wrapper">
                <div className="modal-body"><Spinner animation="border" variant="light" /></div>
            </div></div>
        : renderMetricsContent();

    return (

        <div className={classes.root}>
            <div className='infoPage'>
                <div className="row no-gutters">
                    <div className="col">
                        <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                            Alert Triage Details
                        </h2>
                    </div>
                </div>
                {content}
                
            </div>
        </div>
        )
}

function UserSelector(props) {
    const { classes, users, selectedIndex, onChange } = props;

    if (users != null) {
        return (
            <div className="select-container-2">
                <span className="select-message">User: <br />
                    <select className="selectpicker" style={{ width: 300 + 'px' }} value={selectedIndex} onChange={onChange}>
                        {users.map((obj, index) =>
                            <option key={`${index}-${obj.userId}`} id={`${index}-${obj.userId}`} value={index}>{obj.name}</option>
                        )}
                    </select>
                </span>
            </div>
        );
    }
    else {
        return (<div className="select-container-2">
            <span className="select-message">User:<br />
                <select className="selectpicker" style={{ width: 300 + 'px' }} >

                </select>
            </span>
        </div>);
    }
}

export default withStyles(styles)(AlertTriageDetails);







