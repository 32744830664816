/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";
import { Spinner } from 'react-bootstrap';
import SelectHome from '../Home/SelectHome';

class SelectHomeDataModal extends Component { 
    
    onClick = e => {
        this.props.onClick && this.props.onClick(e);
      };

    render() {
                
        if(!this.props.show){
            return null;
        }
        else {
            var content = (this.props.homes.length) ?
            <div>
                <SelectHome style={{ width: 300 + 'px' }} homes={this.props.homes} onChange={this.props.onHomeSelect} selectedIndex={this.props.mySelectedHome} />
            </div> :
                <Spinner animation="border" />

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                            style={{
                                transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                                opacity: this.props.show ? '1' : '0'
                            }}>

                        <div className="modal-header">
                            <h3>Restore System</h3>
                            <span className="close-modal-btn" onClick={e => {this.onClick(e);}}>×</span>
                        </div>

                        <div className="modal-body">
                            <br/>
                            <div className="select-container" style={{ alignText: 'left' }}>
                                {content}
                            </div>
                            <br/>
                        </div>

                        <div className="modal-footer">                            
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); }}>Cancel</button>
                            <button type='button' className="primary-btn btn-small" onClick={this.props.onRestore}>Restore</button>
                        </div>
                    </div>
                </div>                
            );
        }
    }
  } 
  
  
export default SelectHomeDataModal;