/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/trainingSession";
const trainingSessionService = {
    getalertcountinfo: async (orgId, isAC = true) => {
        const response = await http.get(`${controller}/getalertcountinfo?orgId=${orgId}&isAC=${isAC}`);
        return response.data;
    },
    gettrainingpublishinfo: async (assetId, isAC = true) => {
        const response = await http.get(`${controller}/gettrainingpublishinfo?assetId=${assetId}&isAC=${isAC}`);
        return response.data;
    }
};
export default trainingSessionService;