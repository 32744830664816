/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/
import React , { useState, useEffect } from "react";
import UseUserDevices from "./UseUserDevices";

const UserDeviceList = ({data}) => {
    const userDevices = data;
    // const [loading, setLoading] = useState(true);

    let list = [];
    // setLoading(true);
    list.push(<strong key={`organization-strong`}>The following assets will be permantly removed with the system:</strong>)
    for (var i = 0; i < userDevices.length; i++) {
        list.push(<br key={`${userDevices[i].assetId}-br`}/>);
        list.push(<span key={userDevices[i].assetId}>{userDevices[i].name} </span>)
    }
    // setLoading(false);

    return list;
}
export default UserDeviceList;