/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner, Image } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { PageView, ModalView, Event } from "../GoogleAnalytics";
import DatePicker from 'react-datepicker';
import MailjetService from '../../services/mailjet.service';
import memoize from 'memoize-one';
import moment from 'moment';
import LongArrowUpIcon from '@material-ui/icons/ArrowUpward';
import LongArrowDownIcon from '@material-ui/icons/ArrowDownward';
import Pagination from '@material-ui/lab/Pagination';
import { withStyles } from '@material-ui/core/styles';
import WarningModal from './WarningModal'
import EmailHistoryModal from './EmailHistoryModal'
import EmailStatusTable from './EmailStatusTable'

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important'
        },
    }
});

const itemsPerPage = 100;
const maxMessageCount = 1000;
const noAlerts = "No Alerts!";
const noEmailFound = "No Contact Found for Searched Email Address!"

const getCellBackgroundColor = (value) => {        
    if (value === 'sent') {
        return <div className="db-sky-blue-bg2 mailjet-email-status">Delivered</div>;
    } else if (value === 'opened') {
        return <div className="db-green-bg2 mailjet-email-status">Opened</div>;
    } else if (value === 'softbounced') {
        return <div className="db-pink-bg2 mailjet-email-status">Soft-bounced</div>;
    } else if (value === 'hardbounced') {
        return <div className="db-purple-bg2 mailjet-email-status">Hard-bounced</div>;
    } else if (value === 'queued') {
        return <div className="db-gray-bg2 mailjet-email-status">Queued</div>;
    } else if (value === 'blocked') {
        return <div className="db-tan-bg2 mailjet-email-status">Blocked</div>;
    } else if (value === 'spam') {
        return <div className="db-salmon-bg2 mailjet-email-status">Spam</div>;
    } else if (value === 'unsub') {
        return <div className="db-maroon-bg2 mailjet-email-status">Unsubscribed</div>;
    } else if (value === 'deferred') {
        return <div className="db-violet-bg2 mailjet-email-status">Retrying</div>;
    } else {
        return <div className="db-red-bg2 mailjet-email-status">{value}</div>;
    }
};

class EmailStatus extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.getMessages = this.getMessages.bind(this);
        this.onChangeStart = this.onChangeStart.bind(this);
        this.onChangeEnd = this.onChangeEnd.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.showWarningModal = this.showWarningModal.bind(this);
        this.onEmailSearchChange = this.onEmailSearchChange.bind(this);

        this.state = {
            loading: true,
            tableLoading: true,
            messages: [],
            messagesObj: {},
            senders: [],
            columnSort: {
                enabled: true,
                type: "TO",
                reverse: false
            },
            showMessage: false,
            showWarningModal: false,
            historyLoading: false,
            historyTitle: "EMAIL HISTORY",
            warningMessage: "Results from Date Range have exceeded the 1000 email maximum. Results have been truncated, shorten the date range to view complete results.",
            warningTitle: "WARNING: MAX RESULTS REACHED",
            emailStartTime: moment().startOf('day'),
            emailEndTime: moment(),
            messageCount: 0,
            messageLoadedCount: 0,
            pageCount: 0,
            currentPage: 0,
            emailToSearch: "",
            contactId: 0,
            isEmailSearch: false,
            noContactFound: false,
            emptyTableMessage: ""
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        PageView();

        if (!this.props.isLoading) {
            this.setState({ loading: true, tableLoading: true }, async () => {

                this.setState({
                    partnerIdFilter: this.props.currentPartnerId,
                });
                await this.getSenders();
                await this.getMessages();
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    memAlerts = memoize(this.getMessages);

    async getMessages() {
        var startTime = this.state.emailStartTime;
        var endTime = this.state.emailEndTime;
        var startStr = startTime.toISOString()//.format();
        var endStr = endTime.toISOString()//.format();
        var emailSearch = this.state.isEmailSearch ? this.state.contactId : "";

        var startTimeNow = performance.now();
        var now = moment();
        //var before = moment(now).add(-1, 'days'); //two weeks ago; set only for demo set 04/01/21
        var nowStr = now.format();
        //var beforeStr = before.format();
        return new Promise((resolve) => {
            if (this._isMounted)
                this.setState(prevState => ({
                    alertArgs: {
                        ...prevState.alertArgs,
                        startTime: startStr,
                        endTime: endStr
                    }
                }), () => {
                    MailjetService.getmessagecount(startStr, endStr, emailSearch)
                        .then(response1 => {
                            var countObject = response1;
                            var messageCount = countObject.count > maxMessageCount ? maxMessageCount : countObject.count;
                            if (countObject.count > maxMessageCount) {
                                this.showWarningModal();
                            }
                            var pageCount = Math.ceil(messageCount / itemsPerPage);
                            if (this._isMounted)
                                this.setState({ messageCount: messageCount, pageCount: pageCount, currentPage: 1 }, () => {
                                    MailjetService.getmessages(startStr, endStr, itemsPerPage, 0, emailSearch)
                                        .then(response2 => {
                                            if (this._isMounted) {
                                                var loadedCount = messageCount < itemsPerPage ? messageCount : itemsPerPage;
                                                this.setState({ messages: response2.data, messageLoadedCount: loadedCount, loading: false, tableLoading: false, emptyTableMessage: noAlerts });
                                                resolve();
                                            }
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            resolve();
                                        });
                                });
                        })
                        .catch(e => {
                            console.log(e);
                            resolve();
                        });
                });
        });
    }

    async getSenders() {
        return new Promise((resolve) => {
            MailjetService.getsenders()
                .then(response3 => {
                    if (this._isMounted)
                        this.setState({ senders: response3.data });
                    resolve();
                })
                .catch(e => {
                    console.log(e);
                    resolve();
                });
        });
    }

    getDate(dateTime) {
        var d = new Date(dateTime);
        var hr = d.getHours() < 10 ? "0" : "";
        var min = d.getMinutes() < 10 ? "0" : "";
        var sec = d.getSeconds() < 10 ? "0" : "";
        var month = d.getMonth() + 1;

        var date = month + "/" + d.getDate() + "/" + d.getFullYear() + " " + d.toLocaleTimeString('en-US');

        if (dateTime == "" || dateTime == null)
            date = "";

        return (date);
    }

    onChangeStart(e) {
        var start = moment(e)
        this.setState({ emailStartTime: moment(e).startOf('day'), searchChanged: true });
    }

    onChangeEnd(e) {
        var end = moment(e)
        this.setState({ emailEndTime: moment(e).endOf('day'), searchChanged: true });
    }

    updateSearch() {
        Event("Update Email Status Search Dates", "User updated the search dates in Email Status page", "Update Email Status Search Dates");
        let isEmailSearch = !(this.state.emailToSearch === "");
        this.setState({ tableLoading: true, isEmailSearch: isEmailSearch }, async () => {
            if (isEmailSearch) {
                await MailjetService.getcontact(this.state.emailToSearch)
                    .then(response1 => {
                        if (this._isMounted) {
                            if (response1.count > 0 && response1.data !== null && response1.data !== undefined && response1.data.length) {
                                var contactId = response1.data[0].id;
                                this.setState({ contactId: contactId }, async () => {
                                    await this.getMessages();
                                    this.maintainMessageSorting();
                                });
                            }
                            else {
                                this.setState({ emptyTableMessage: noEmailFound, tableLoading: false, messages: [], messageLoadedCount: 0, messageCount: 0 });
                                console.log("no email found");
                            }
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
            else {
                await this.getMessages();
            }
        });
    }

    getSenderEmail(id) {
        var senderObj = this.state.senders.find(a => a.id == id);
        if (senderObj !== null && senderObj !== undefined) {
            return senderObj.email;
        }
        else {
            return "";
        }
    }

    onEmailSearchChange(e) {
        const value = e.target.value;
        this.setState({ emailToSearch: value });
    }

    handlePageChange(value) {
        var startTime = this.state.emailStartTime;
        var endTime = this.state.emailEndTime;
        var startStr = startTime.toISOString()//.format();
        var endStr = endTime.toISOString()//.format();
        var offset = this.state.messageLoadedCount;
        var limit = value === -1 ? (this.state.messageCount - this.state.messageLoadedCount) : itemsPerPage;
        let contact = this.state.emailToSearch === "" ? 0 : this.state.contactId;

        this.setState({ currentPage: value, tableLoading: true }, async () => {
            await MailjetService.getmessages(startStr, endStr, limit, offset, contact)
                .then(response1 => {
                    if (this._isMounted) {
                        let messages = [...this.state.messages, ...response1.data];
                        this.setState({ messages: messages, tableLoading: false, messageLoadedCount: (offset + response1.count) });
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    nextPage() {
        this.setState({ currentPage: this.state.currentPage + 1 });
    }

    prevPage() {
        this.setState({ currentPage: this.state.currentPage - 1 });
    }

    showWarningModal() {
        this.setState({ showWarningModal: !this.state.showWarningModal }, () => {
            if (this.state.showWarningModal)
                ModalView('emailStatusWarning');
        });
        window.scrollTo(0, 0);
    }

    renderContent() {
        const { classes } = this.props;
        let tableOptions = null;
        let tableEmails = this.state.messages;
        //let pages = this.state.pageCount;
        let endDatePicker = <span style={{}}>
            <div style={{ textAlign: 'center' }}>
                <div style={{ marginLeft: '10px' }}>End:</div>
                <DatePicker
                    customInput={<input className="txt-detail" disabled={this.state.disableGraphOptions} style={{ minWidth: '200px', textAlign: "center" }} />}
                    id="endDate"
                    name="endDate"
                    //selected={this.state.endTime.toDate()}
                    //onChange={date => { this.onChangeEnd(date); }}
                    dateFormat="MM/dd/yyyy h:mm:ss aa"
                    timeIntervals={15}
                    showTimeSelect
                    popperPlacement="top-end"
                    popperProps={{
                        positionFixed: true
                    }}
                />
            </div>
        </span>;

        //let pagePicker = <div style={{ display: "flex" }}>
        //    <button className="secondary-btn btn-small" onClick={() =>this.handlePageChange(null, this.state.currentPage - 1)} disabled={this.state.pageCount == 1 ? true : false} style={{ minWidth: '50px', height: '38px', borderRadius: '.25rem', padding: '0px 10px', marginLeft: '12px' }}>Prev Page</button>
        //    <p style={{ padding: '0px 10px', marginTop: "7px", marginBottom: "7px" }}>
        //        Page {this.state.currentPage} of {pages}
        //    </p>
        //    <button className="secondary-btn btn-small" onClick={() => this.handlePageChange(null, this.state.currentPage + 1)} disabled={this.state.pageCount == pages ? true : false} style={{ minWidth: '50px', height: '38px', borderRadius: '.25rem', padding: '0px 10px', marginLeft: '12px' }}>Next Page</button>
        //</div>;

        //let pagination = <div style={{ justifyContent: 'center', display: "flex" }}>
        //        <p style={{ padding: '0px 10px', marginTop: "7px", marginBottom: "7px" }}>
        //            Emails {((this.state.currentPage - 1) * itemsPerPage) + 1}-{endRange} of {this.state.messageCount}
        //        </p>
        //        <Pagination count={this.state.pageCount} page={this.state.currentPage} color="primary" onChange={this.handlePageChange} />
        //    </div>;
        let emailSearch =
                <div style={{ display: "flex", alignItems: 'center' }}>
                    <div style={{ paddingRight: 2 + "px" }}>
                        <input
                            type="text"
                            className="form-control"
                            id="email-search"
                            value={this.state.emailToSearch}
                            placeholder="Email Address Search..."
                            style={{ backgroundColor: (this.state.emailToSearch === "") ? "#D3D3D3" : "" }}
                            onChange={e => this.onEmailSearchChange(e)}
                        />
                    </div>
                    <div style={{ paddingRight: 2 + "px"}}>
                        <button style={{ backgroundColor: "white", height: 36 + 'px', border: 0 + 'px', verticalAlign: "top", float: "right" }} onClick={this.updateSearch}  >
                            <Image src={require('../../img/search-icon.png')} style={{ width: 30 + 'px', height: 30 + 'px', display: "inline-block" }} />
                        </button>
                    </div>
                </div>;

        tableOptions =
            <div className="alerts-tab-wrapper" style={{ margin: '0 30px', backgroundColor: "#f7f9fa", padding: '10px 0' }}>
                <div className="alerts-tab-label" style={{ paddingLeft: '45px' }}>Table Options</div>
            <div style={{ paddingLeft: '40px', margin: '5px' }}>
                <div style={{ height: '24px', display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <div style={{ display: "flex", margin: "0 auto" }}>
                            <span style={{}}>
                                <div style={{ textAlign: 'center' }}>
                                    <div style={{ marginLeft: '10px' }}>Start Day:</div>
                                    <DatePicker
                                        customInput={<input className="txt-detail" disabled={this.state.disableGraphOptions} style={{ minWidth: '200px', textAlign: "center" }} />}
                                        id="startDate"
                                        name="startDate"
                                        selected={this.state.emailStartTime.toDate()}
                                        onChange={date => { this.onChangeStart(date); }}
                                        dateFormat="MM/dd/yyyy"                                      
                                        popperPlacement="top-end"
                                        popperProps={{
                                            positionFixed: true
                                        }}
                                    />
                                </div>
                            </span>
                            <span style={{}}>
                                <div style={{ textAlign: 'center' }}>
                                    <div style={{ marginLeft: '10px' }}>End Day:</div>
                                    <DatePicker
                                        customInput={<input className="txt-detail" disabled={this.state.disableGraphOptions} style={{ minWidth: '200px', textAlign: "center" }} />}
                                        id="endDate"
                                        name="endDate"
                                        selected={this.state.emailEndTime.toDate()}
                                        onChange={date => { this.onChangeEnd(date); }}
                                        dateFormat="MM/dd/yyyy"
                                        popperPlacement="top-end"
                                        popperProps={{
                                            positionFixed: true
                                        }}
                                    />
                                </div>
                            </span>
                           
                            <button className="secondary-btn btn-small" onClick={this.updateSearch} disabled={!this.state.searchChanged} style={{ minWidth: '50px', height: '38px', borderRadius: '.25rem', padding: '0px 10px', marginLeft: '12px' }}>Update Search</button>
                        </div>
                    {emailSearch}
                    </div>
                </div>
            </div >

        let endRange = this.state.messageCount > (this.state.currentPage * itemsPerPage) ?
            (this.state.currentPage * itemsPerPage) : this.state.messageCount;

        let loadMoreContent = this.state.tableLoading ? null :
            <div>
                <div style={{ justifyContent: 'center', display: "flex" }}>
                    <button className="secondary-btn btn-small" onClick={() => this.handlePageChange(itemsPerPage)} disabled={this.state.messageLoadedCount == this.state.messageCount ? true : false} style={{ minWidth: '50px', height: '38px', borderRadius: '.25rem', padding: '0px 10px', marginLeft: '12px' }}>Load More</button>
                    <button className="secondary-btn btn-small" onClick={() => this.handlePageChange(-1)} disabled={this.state.messageLoadedCount == this.state.messageCount ? true : false} style={{ minWidth: '50px', height: '38px', borderRadius: '.25rem', padding: '0px 10px', marginLeft: '12px' }}>Load All</button>
                </div>
                <div style={{ justifyContent: 'center', display: "flex" }}>
                    <p style={{ padding: '0px 10px', marginTop: "7px", marginBottom: "7px" }}>
                        Displaying {this.state.messageLoadedCount} of {this.state.messageCount} Emails
                    </p>
                </div>
            </div>;

        return (
            <div>
                {tableOptions}
                <div>
                    <EmailStatusTable messages={this.state.messages} senders={this.state.senders} isLoading={this.state.tableLoading} isMounted={this._isMounted} />
                </div>
                {loadMoreContent}
            </div>
        );
    }

    render() {
        const { classes } = this.props;

        let contents = this.state.loading ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : this.renderContent();

        return (
            <div className={classes.root}>
                <div className="infoPage">

                    <div className="row no-gutters">
                        <div className="col">
                            <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                                Email Status
                            </h2>
                        </div>
                    </div>

                    <div>
                        {contents}
                    </div>
                </div>
                <WarningModal show={this.state.showWarningModal} onClick={this.showWarningModal} message={this.state.warningMessage} title={this.state.warningTitle}> </WarningModal>
                <EmailHistoryModal show={this.state.showHistoryModal} onClick={this.showHistoryModal} title={this.state.historyTitle} loading={this.state.historyLoading} emailHistory={this.state.historyObj} getStatus={getCellBackgroundColor} emailDetails={this.state.singleMessageObj} formatDate={this.getDate}> </EmailHistoryModal>
            </div>
        );
    }
}

export default withStyles(styles)(EmailStatus);