/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";

class InviteFamilyMemberModal extends Component { 
    
    onClick = e => {
        this.props.onClick && this.props.onClick(e);
      };

    render() {
                
        if(!this.props.show){
            return null;
        }
        else{
            return (  

                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                            style={{
                                transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                                opacity: this.props.show ? '1' : '0'
                            }}>

                        <div className="modal-header">
                            <h3>Family Member</h3>
                            <span className="close-modal-btn" onClick={e => {this.onClick(e);}}>×</span>
                        </div>

                        <div className="modal-body">
                            <br/>
                            <p style={{textAlign:"left"}}>Invite a family member to see alerts for this house.</p>
                            <input 
                                type="text"
                                className="form-control"
                                id="new-email"
                                onChange={e => { this.props.onInviteEmailChange(e); }}
                                placeholder="email@gmail.com"
                            />                            
                            <br/>
                        </div>

                        <div className="modal-footer">                            
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={this.props.onSave}>Ok</button>
                        </div>
                    </div>
                </div>                
            );
        }
    }
}   
  
export default InviteFamilyMemberModal;