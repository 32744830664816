/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import axios from "axios";
import updateHeaderInterceptor from "./updateHeaderInterceptor";

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,    
    headers: {
        "Content-type": "application/json",
        // "X-CSRF-TOKEN": getCookie('CSRF-TOKEN')
    }
});

updateHeaderInterceptor(httpClient);

export default httpClient;
//"https://support-lifewhere.azurewebsites.net/",//"https://qa-support-lifewhere.azurewebsites.net/",//"https://dev-supportweb.azurewebsites.net/",//https://localhost:5003