/*

Resideo/LifeWhere
Copyright (C) 2018-2023 Resideo/LifeWhere

mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Snackbar from '@material-ui/core/Snackbar';
import Select from 'react-select';
import RegisterUserService from "../../services/registeruser.service";
import AccountService from '../../services/account.service';
import makeAnimated from "react-select/animated";
import { PageView, Event, Timing, ModalView } from "../GoogleAnalytics";
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';


const animatedComponents = makeAnimated();

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

const lifewhereDetails = {
    orgId: "1",
    orgName: "LifeWhere",
    companyName: "LifeWhere_Master"
}

const lifewhereOrgId = 1;

const validEmailEnds = ["com", "net", "gov", "org", "edu"];

class InviteAdmins extends Component {
    static displayName = InviteAdmins.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.resetForm = this.resetForm.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeConfirmEmail = this.onChangeConfirmEmail.bind(this);

        this.handleInviteAdmins = this.handleInviteAdmins.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validateConfirmEmail = this.validateConfirmEmail.bind(this);

        this.openConfirmModal = this.openConfirmModal.bind(this);
        this.showConfirmModal = this.showConfirmModal.bind(this);
        this.onInvite = this.onInvite.bind(this);

        this.onContinueWithoutSaving = this.onContinueWithoutSaving.bind(this);
        this.onSaveThenContinue = this.onSaveThenContinue.bind(this);

        this.state = {
            loading: false,
            showMessage: false,
            message: "",
            emailMessage: "",
            validated: false,
            validEmail: true,
            confirmEmailsMatch: true,
            nicknameChanged: false,
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,
            confirmEmail: "",
            roleOptions: [],
            selectedRoles: [
                { roleId: "4", name: "Admin" },
                { roleId: "9", name: "GetAlerts" },
                { roleId: "11", name: "OTA" },
                { roleId: "12", name: "Support" },
            ],
            user: {
                firstName: "",
                lastName: "",
                email: "",
                roleList: "Admin, GetAlerts, OTA, Support",
                roleListId: ["4", "9", "11", "12"],
                roleListIdStr: "4, 9, 11, 12"
            },
            unsavedChanges: false,
            showUnsavedModal: null
        };
    }

    getStorageValue(key, defaultValue, isJson = false) {
        const saved = localStorage.getItem(key);
        var value = saved !== null ? saved : defaultValue;
        if (isJson && value !== defaultValue && value !== "") {
            try {
                value = JSON.parse(value);
            }
            catch(e) {
                console.log(e);
                console.log(`error parsing value: ${value}`);
            }
        }
        return value;
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();
        
        let unsavedChanges = this.getStorageValue("unsavedChanges", false);
        if (!this.props.isLoading) {
            this.getRoles();

            if (unsavedChanges === 'true' || unsavedChanges === true) {
                let nicknameChanged = this.getStorageValue("nicknameChanged", false);
                let selectedRoles = this.getStorageValue("selectedRoles", '[]', true);
                let user = this.getStorageValue("adminUser", '{}', true);
                let confirmEmail = this.getStorageValue("confirmEmail", "");
                this.setState({ nicknameChanged: nicknameChanged, selectedRoles: selectedRoles, user: user, confirmEmail });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getRoles() {
        AccountService.getRolesList(lifewhereOrgId)
            .then(response => {
                if (response.data) {
                    if (this._isMounted)
                        this.setState({
                            roleOptions: response.data
                        });
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    resetForm() {
        this.setState({
            loading: false,
            emailMessage: "",
            validated: false,
            validEmail: true,
            confirmEmailsMatch: true,
            confirmEmail: "",
            selectedRoles: [
                { roleId: "4", name: "Admin" },
                { roleId: "9", name: "GetAlerts" },
                { roleId: "11", name: "OTA" },
                { roleId: "12", name: "Support" },
            ],
            user: {
                firstName: "",
                lastName: "",
                email: "",
                roleList: "Admin, GetAlerts, OTA, Support",
                roleListId: ["4", "9", "11", "12"],
                roleListIdStr: "4, 9, 11, 12"
            },
        }, () => {
            localStorage.setItem("unsavedChanges", false);
            localStorage.setItem("nicknameChanged", false);
            localStorage.setItem("confirmEmail", "");
            localStorage.setItem("selectedRoles", JSON.stringify(this.state.selectedRoles));
            localStorage.setItem("adminUser", JSON.stringify(this.state.user));
        })
    }

    /* input handlers */

    onChangeFirstName(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                user: {
                    ...prevState.user,
                    firstName: newValue.trim()
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("adminUser", JSON.stringify(this.state.user));
        });
    }

    onChangeLastName(e) {
        const newValue = e.target.value;
        this.setState(function (prevState) {
            return {
                user: {
                    ...prevState.user,
                    lastName: newValue.trim()
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("adminUser", JSON.stringify(this.state.user));
        });
    }

    onChangeEmail(e) {
        const newValue = e.target.value;
        let isValid = this.validateEmail(newValue);

        this.setState(function (prevState) {
            return {
                user: {
                    ...prevState.user,
                    email: newValue.trim()
                },
                validEmail: isValid
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("adminUser", JSON.stringify(this.state.user));
        });
    }

    onChangeConfirmEmail(e) {
        const newValue = e.target.value;

        this.setState({ confirmEmail: newValue.trim() }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("confirmEmail", this.state.confirmEmail);
        });
    }

    /* Submit Handlers */

    handleInviteAdmins(e) {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;

        if (this.validateForm() && form.checkValidity() === true) {

            this.setState(function (prevState) {
                return {
                    validated: true, user: {
                        ...prevState.user,
                    }
                }
            });

            this.onInvite();
        }
        else {
            console.log("form.checkValidity(): " + form.checkValidity());
            this.setState({
                validated: false,
                message: "Missing or Invalid inputs",
                showMessage: true
            });

            setTimeout(() => {
                this.setState({
                    message: "",
                    showMessage: false
                });
            }, 5000);
        }
    }

    openConfirmModal() {

    }

    showConfirmModal() {
        this.setState({ showConfirmModal: !this.state.showConfirmModal }, () => {
            if (this.state.showConfirmModal)
                ModalView('confirmInviteAdmin');
        });
    }

    onInvite(goNextPage = false) {
        Event("Admins Invites", "User invited admins", "Admins Invited");
        this.setState({ loading: true }, async () => {
            AccountService.inviteusersloop(lifewhereDetails.orgName, lifewhereDetails.orgId, [this.state.user])
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            message: "The new user invites were sent successfully!", showMessage: true
                        }, () => goNextPage ? this.onContinueWithoutSaving() : this.resetForm() );
                        Event("Partner User Invite", "User clicked invite button", "Invite User Clicked");
                    }
                    else {
                        this.setState({
                            message: "Failed to send invites to the new users!", showMessage: true
                        });
                    }

                    setTimeout(() => {
                        this.setState({
                            message: "",
                            showMessage: false
                        });
                    }, 3000);
                })
                .catch(e => {
                    console.log(e);
                    if (this._isMounted)
                        this.setState({
                            loading: false,
                            message: "Failed to invite this user",
                            showMessage: true
                        });

                    setTimeout(() => {
                        this.setState({
                            message: "",
                            showMessage: false
                        });
                    }, 5000);
                })
        });
    }

    onSaveThenContinue() {
        this.setState({ loading: true, showUnsavedModal: false }, () => {
            localStorage.setItem("showUnsavedModal", false);
            let nicknameChanged = this.getStorageValue("nicknameChanged", false) === 'true';
            let user = this.getStorageValue("adminUser", '{}', true);
            let selectedRoles = this.getStorageValue("selectedRoles", '[]', true);
            let confirmEmail = this.getStorageValue("confirmEmail", "");
            this.setState({ nicknameChanged: nicknameChanged, user: user, selectedRoles: selectedRoles, confirmEmail: confirmEmail }, () => {
                if (this.validateForm()) { //&& form.checkValidity() === true

                    this.setState(function (prevState) {
                        return {
                            validated: true, user: {
                                ...prevState.user,
                            }
                        }
                    });
        
                    this.onInvite(true);
                }
                else {
                    //console.log("form.checkValidity(): " + form.checkValidity());
                    this.setState({
                        loading: false,
                        validated: false,
                        message: "Missing or Invalid inputs",
                        showMessage: true
                    });
        
                    setTimeout(() => {
                        this.setState({
                            message: "",
                            showMessage: false
                        });
                    }, 5000);
                }
            })
        })
    }

    onContinueWithoutSaving() {
        localStorage.setItem("unsavedChanges", false);
        localStorage.setItem("showUnsavedModal", false);
        localStorage.setItem("nicknameChanged", false);
        localStorage.setItem("adminUser", '{}');
        localStorage.setItem("selectedRoles", '[]');
        localStorage.setItem("confirmEmail", "");

        let nextPath = this.getStorageValue("nextPath", null);
        this.props.history.push(nextPath);
        this.props.setActivePath(nextPath);
    }

    validateForm() {
        var isValid = true;

        if (!this.validateEmail(this.state.user.email))
            isValid = false;

        if (!this.validateConfirmEmail(this.state.user.email, this.state.confirmEmail))
            isValid = false;

        return isValid;
    }

    validateConfirmEmail(email, confirmEmail) {
        var emailMatch = false;

        if (email == null)
            email = "";
        if (confirmEmail == null)
            confirmEmail = "";

        if (email === confirmEmail)
            emailMatch = true;

        if (emailMatch) {
            this.setState({ confirmEmailsMatch: true });
            return true;
        }
        else {
            this.setState({ confirmEmailsMatch: false });
            return false;
        }
    }

    validateEmail(email) {
        let errorMsg = "";

        if (email == null)
            email = "";
        //todo console warning, there are two unnecessary escape characters in this string... - "Unnecessary escape character: \[  no-useless-escape"
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let validConfig = regex.test(email);

        if (!validConfig)
            errorMsg = "Valid Email is required";
        else {
            let split = email.split('.');
            let end = split.pop();

            if (end === "" || end == null || end === undefined || !validEmailEnds.includes(end))
                errorMsg = "Email must end in .com, .net, .gov, .org, or .edu";
        }

        if (errorMsg !== "") {
            this.setState({ validEmail: false, emailMessage: errorMsg });
            return false;
        }
        else {
            this.setState({ validEmail: true, emailMessage: "" });
            return true;
        }
    }

    onChangeRoles = (selectedOptions) => {
        var options = [];
        var roleList = "";
        var roleIdStr = "";
        var i = 0;
        selectedOptions.forEach(option => {
            options = options.concat(option["roleId"]);
            roleList += (i > 0 ? ", " : "") + option["name"];
            roleIdStr += (i > 0 ? ", " : "") + option["roleId"];
            i = 1;
        });

        this.setState(prevState => ({
            user: {
                ...prevState.user,
                roleListId: options,
                roleList: roleList,
                roleListIdStr: roleIdStr
            },
            selectedRoles: selectedOptions
        }), () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("adminUser", JSON.stringify(this.state.user));
            localStorage.setItem("selectedRoles", JSON.stringify(this.state.selectedRoles));
        });
    }
    getOptionLabel = (option) => {
        return (option["name"]);
    }

    getOptionValue = (option) => {
        return (option["roleId"]);
    }

    renderContent() {

        return (
            <div style={{ margin: '15px 30px', padding: '15px 25px 20px', border: '2px solid #ebeef0', borderRadius: '.3rem' }}>
                <Form noValidate validated={this.state.validated && this.state.validEmail && this.state.confirmEmailsMatch} onSubmit={this.handleInviteAdmins}>
                    <h5>User Info</h5>
                    <Row>
                        <Form.Group as={Col} md="6" controlId="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control required type="text" placeholder="" value={this.state.user.firstName} onChange={this.onChangeFirstName} />
                            <Form.Control.Feedback type="invalid">First Name is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control required type="text" placeholder="" value={this.state.user.lastName} onChange={this.onChangeLastName} />
                            <Form.Control.Feedback type="invalid">Last Name is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} md="6" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control required type="email" placeholder="" value={this.state.user.email} onChange={this.onChangeEmail} isValid={this.state.validated && this.state.validEmail} isInvalid={!this.state.validEmail} />
                            <Form.Control.Feedback type="invalid">{this.state.emailMessage/*Email is required*/}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="confirmEmail">
                            <Form.Label>Confirm Email</Form.Label>
                            <Form.Control required type="email" placeholder="" value={this.state.confirmEmail} onChange={this.onChangeConfirmEmail} isInvalid={!this.state.confirmEmailsMatch} />
                            <Form.Control.Feedback type="invalid">Confirm Email is required and must match Email</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} md="12" controlId="roles">
                            <label htmlFor="description">Roles</label>
                            <Select
                                style={{ overflow: 'visible', height:'auto' }}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                isMulti
                                value={this.state.selectedRoles}
                                options={this.state.roleOptions}
                                getOptionLabel={this.getOptionLabel}
                                getOptionValue={this.getOptionValue}
                                onChange={this.onChangeRoles}
                            />
                        </Form.Group>
                    </Row>

                    {(!this.state.isCustomerSupport) && (
                        <Button type="submit" style={{ backgroundColor: '#0033a0', borderColor: '#0033a0' }}>Invite User</Button>)}
                </Form>
            </div>
        );
    }

    render() {
        const { classes } = this.props;

        let loader = this.state.loading
            ? <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : null;

        let contents = this.renderContent();

        return (
            <div className={classes.root}>
                <div className='infoPage'>
                    <h3 className="pageTitle" id="tabelLabel" >Invite Admin</h3>
                    {contents}
                    {loader}

                    <Snackbar open={this.state.showMessage} message={this.state.message} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} />
                    <ConfirmationModal 
                        show={this.state.showUnsavedModal == null ? this.getStorageValue("showUnsavedModal", false) === 'true' : this.state.showUnsavedModal} 
                        onYes={this.onSaveThenContinue} 
                        onClick={this.onContinueWithoutSaving} 
                        message={"You have unsaved changes, would like to save before leaving?"} 
                        title={"Unsaved Changes"}></ConfirmationModal>
                    <br /><br /><br /><br /><br /><br />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(InviteAdmins);