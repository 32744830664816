/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/dailyStatistics";
class DailyStatisticsService {
    
    partnerEnergySummary(partnerId) {
        const response = http.get(`${controller}/partnerEnergySummary?partnerId=${partnerId}`);
        return response;
    }

    monthlypartnerdata(month, year, partnerId) {
        const response = http.get(`${controller}/monthlypartnerdata?month=${month}&year=${year}&partnerId=${partnerId}`);
        return response;
    }

    monthlyAssetData(month, year, assetIds) {
        let query = assetIds.join('&assetIds=');
        const response = http.get(`${controller}/monthlyassetdata?month=${month}&year=${year}&assetIds=${query}`);
        return response;
    }
}

export default new DailyStatisticsService();