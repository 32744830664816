/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { PageView, ModalView, Event } from "../GoogleAnalytics";
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AssetService from "../../services/asset.service";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

import { withStyles } from '@material-ui/core/styles';
import { jsAssetDeletionLog } from '../../componentObjects';

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

class ManageAssets extends Component {
    static displayName = ManageAssets.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.onAssetChange = this.onAssetChange.bind(this);
        this.onAssetDelete = this.onAssetDelete.bind(this);
        this.onAssetKeyDown = this.onAssetKeyDown.bind(this);
        this.onAssetKeyUp = this.onAssetKeyUp.bind(this);
        this.onRemoveAssetsClick = this.onRemoveAssetsClick.bind(this);
        this.performRemoveAssets = this.performRemoveAssets.bind(this);
        this.openAssetModal = this.openAssetModal.bind(this);
        this.showAssetModal = this.showAssetModal.bind(this);

        this.onDeviceChange = this.onDeviceChange.bind(this);
        this.onDeviceDelete = this.onDeviceDelete.bind(this);
        this.onDeviceKeyDown = this.onDeviceKeyDown.bind(this);
        this.onDeviceKeyUp = this.onDeviceKeyUp.bind(this);
        this.openDeviceModal = this.openDeviceModal.bind(this);
        this.showDeviceModal = this.showDeviceModal.bind(this);

        this.handleAssetCheck = this.handleAssetCheck.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.searchMacs = this.searchMacs.bind(this);
        this.onRemoveMacAssetsClick = this.onRemoveMacAssetsClick.bind(this);

        this.state = {
            message: "",
            modalMessage: "",
            modalTitle: "",
            loading: false,
            assetMsg: "",
            assetInput: "",
            assetIdList: [],
            assetKeyReleased: true,
            showAssetConfirmation: false,
            deviceMsg: "",
            deviceInput: "",
            deviceIdList: [],
            deviceKeyReleased: true,
            showDeviceConfirmation: false,

            searchLoading: false,
            searchedMac: false,
            searchList: [],
            selectList: []
        }
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /* ASSETS */

    openAssetModal() {
        const input = this.state.assetInput.trim();
        let assetList = this.state.assetIdList;

        if (!input.length) {
            if (!assetList.length)
                this.setState({ assetMsg: "Must provide at least one Asset Id in order to delete." });
            else
                this.setState({ modalMessage: "You are about to delete all the Assets with the entered Asset IDs, along with the associated Device IDs. Are you sure you wish to delete these Assets?", modalTitle: "Delete Assets" }, () => {
                    this.showAssetModal();
                }); 
        }
        else {
            if (this.validAssetFormat(input)) {
                assetList = assetList.concat(input);
                this.setState({ assetIdList: assetList, assetInput: "" }, async () => {
                    this.setState({ modalMessage: "You are about to delete all the Assets with the entered Asset IDs, along with the associated Device IDs. Are you sure you wish to delete these Assets?", modalTitle: "Delete Assets" }, () => {
                        this.showAssetModal();
                    });
                });
            }
            else
                this.setState({ assetMsg: "Invalid Asset ID" });
        }

        setTimeout(() => {
            this.setState({
                assetMsg: ""
            });
        }, 3000);

        
    }

    showAssetModal() {
        this.setState({ showAssetConfirmation: !this.state.showAssetConfirmation }, () => {
            if (this.state.showAssetConfirmation)
                ModalView('showAssetConfirm');
        });
    }

    validAssetFormat(input) {
        var validFormat = true;

        if (input.length < 4)
            validFormat = false;

        return validFormat;
    }

    onAssetChange(e) {
        const val = e.target.value;
        this.setState({ assetInput: val });
    }

    onAssetKeyDown = (e) => {
        const { key } = e;
        const input = this.state.assetInput.trim();
        let assetList = this.state.assetIdList;

        if (/[a-zA-Z]/.test(key)) {
            e.preventDefault();
            this.setState({ assetMsg: "Asset ID cannot contain letters" });
        }

        if (key === ',' && input.length && !assetList.includes(input)) {
            e.preventDefault();
            if (this.validAssetFormat(input)) {
                assetList = assetList.concat(input);
                this.setState({ assetIdList: assetList, assetInput: "" });
            }
            else {
                this.setState({ assetMsg: "Invalid Asset ID" });
            }
        }

        if (key === 'Backspace' && !input.length && assetList.length && this.state.assetKeyReleased) {
            const lastInput = assetList.pop();
            this.setState({ assetIdList: assetList, assetInput: lastInput });
        }

        setTimeout(() => {
            this.setState({
                assetMsg: ""
            });
        }, 3000);

        this.setState({ assetKeyReleased: false });
    }

    onAssetKeyUp = (e) => {
        this.setState({ assetKeyReleased: true });
    }

    onAssetDelete = (e, index) => {
        let assetList = this.state.assetIdList;
        const assetId = assetList[index];

        assetList = assetList.filter((id) => id !== assetId);
        this.setState({ assetIdList: assetList });
    }

    onRemoveAssetsClick() {
        const input = this.state.assetInput.trim();
        let assetList = this.state.assetIdList;

        if (!input.length) {
            if (!assetList.length)
                this.setState({ assetMsg: "Must provide at least one Asset Id to delete." });
            else
                this.performRemoveAssets();
        }
        else {
            if (this.validAssetFormat(input)) {
                assetList = assetList.concat(input);
                this.setState({ assetIdList: assetList, assetInput: "" }, async () => {
                    this.performRemoveAssets();
                });
            }
            else
                this.setState({ assetMsg: "Invalid Asset ID" });
        }

        setTimeout(() => {
            this.setState({
                assetMsg: ""
            });
        }, 3000);
    }

    performRemoveAssets() {
        Event("Remove Test Assets", "User removed test assets", "Remove Test Assets");
        this.setState({ showAssetConfirmation: false, loading: true }, async () => {
            await AssetService.removetestassetsordevices("Test Centers, LLC", true, this.state.assetIdList)  
                .then(response => {
                    if (this._isMounted) {
                        let removeResponse = response.data;
                        let message = "";
                        if (removeResponse !== null && removeResponse !== undefined) {
                            if (removeResponse["notInOrg"] !== undefined && removeResponse["notInOrg"].length)
                                message += "These assets are outside your organization and could not be deleted: " + removeResponse["notInOrg"].join(", ") + "\n";

                            if (removeResponse["failed"] !== undefined && removeResponse["failed"].length)
                                message += "These assets failed to be deleted: " + removeResponse["failed"].join(", ") + "\n";

                            if (removeResponse["success"] !== undefined && removeResponse["success"].length)
                                message += "These assets were successfully deleted: " + removeResponse["success"].join(", ") + "\n";
                        }

                        this.setState({
                            loading: false,
                            message: message,
                            assetInput: "",
                            assetIdList: []
                        });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 5000);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    /* DEVICES */

    openDeviceModal() {
        this.setState({ modalMessage: "You are about to delete all the selected Assets/Devices. This cannot be undone. Are you sure you wish to delete these Assets/Devices?", modalTitle: "Delete Assets and Devices" }, () => {
            this.showDeviceModal();
        });
    }

    showDeviceModal() {
        this.setState({ showDeviceConfirmation: !this.state.showDeviceConfirmation }, () => {
            if (this.state.showDeviceConfirmation)
                ModalView('showDeleteConfirm');
        });
    }

    validDeviceFormat(input) {
        var validFormat = true;

        if (input.length !== 12) 
            validFormat = false;

        return validFormat;
    }

    onDeviceChange(e) {
        const val = e.target.value;
        this.setState({ deviceInput: val });
    }

    onDeviceKeyDown = (e) => {
        const { key } = e;
        const input = this.state.deviceInput.trim();
        let deviceList = this.state.deviceIdList;

        if (key === ',' && input.length && !deviceList.includes(input)) {
            e.preventDefault();
            if (this.validDeviceFormat(input)) {
                deviceList = deviceList.concat(input);
                this.setState({ deviceIdList: deviceList, deviceInput: "" });
            }
            else {
                this.setState({ deviceMsg: "MAC ID is not in correct format" });
            }
        }

        if (key === 'Backspace' && !input.length && deviceList.length && this.state.deviceKeyReleased) {
            const lastInput = deviceList.pop();
            this.setState({ deviceIdList: deviceList, deviceInput: lastInput });
        }

        if (!/[a-zA-Z0-9]/.test(key) && key !== ',' && key !== 'Backspace') {
            e.preventDefault();
            this.setState({ deviceMsg: "MAC ID can only contain letters and numbers." }); // and hypens." });
        }

        setTimeout(() => {
            this.setState({
                deviceMsg: ""
            });
        }, 3000);

        this.setState({ deviceKeyReleased: false });
    }

    onDeviceKeyUp = (e) => {
        this.setState({ deviceKeyReleased: true });
    }

    onDeviceDelete = (e, index) => {
        let deviceList = this.state.deviceIdList;
        const deviceId = deviceList[index];

        deviceList = deviceList.filter((id) => id !== deviceId);
        this.setState({ deviceIdList: deviceList });
    }

    async handleAssetCheck(e) {
        const checkedVal = parseInt(e.target.value, 10); 
        let selected = this.state.selectList;

        if (e.target.checked)
            selected = selected.concat(checkedVal);
        else
            selected = selected.filter((a) => a !== checkedVal);

        this.setState({ selectList: selected });
    }

    onSearchClick() {
        Event("Search Clicked", "User clicked search button in Manage Assets", "Search Clicked");
        const input = this.state.deviceInput.trim();
        let deviceList = this.state.deviceIdList;

        if (!input.length) {
            if (!deviceList.length)
                this.setState({ deviceMsg: "Must provide at least one MAC ID to perform a search." });
            else
                this.searchMacs();
        }
        else {
            if (this.validDeviceFormat(input)) {
                deviceList = deviceList.concat(input);
                this.setState({ deviceIdList: deviceList, deviceInput: "" }, async () => {
                    this.searchMacs();
                });
            }
            else {
                this.setState({ deviceMsg: "MAC ID is not in correct format" });
            }
        }

        setTimeout(() => {
            this.setState({
                deviceMsg: ""
            });
        }, 4000);
    }

    searchMacs() {
        this.setState({ searchedMac: true, searchLoading: true }, async () => {
            await AssetService.searchfrommac("Test Centers, LLC", this.state.deviceIdList)
                .then(response => {
                    if (this._isMounted)
                        this.setState({
                            searchList: response.data,
                            searchLoading: false
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    onRemoveMacAssetsClick() {
        Event("Delete Selected Assets Clicked", "User clicked delete selected assets button in Manage Assets", "Delete Selected Assets Clicked");
        this.setState({ showDeviceConfirmation: false, loading: true }, async () => {
            if (this.state.selectList.length) {    
                var assetDetails = jsAssetDeletionLog;
                var assetList = [];

                // todo this loop is causing console warning - "Function declared in a loop contains unsafe references to variable(s) 'assetId"
                for (let x in this.state.selectList) {
                    var assetId = this.state.selectList[x];
                    var assetMatch = this.state.searchList.find(a => a.assetId === assetId);
                    var assetObject = {
                        assetId: assetId,
                        assetName: assetMatch ? assetMatch.name : ""
                    }
                    assetList.push(assetObject);
                }

                assetDetails = {
                    userEmail: this.props.currentAccount.email,
                    assetList: assetList
                };
                
                await AssetService.removetestassetsordevices("Test Centers, LLC", true, assetDetails) /* todo change orgname to be user specific? For now just test centers for QA */
                    .then(response => {
                        if (this._isMounted) {
                            let removeResponse = response.data;
                            let message = "";
                            if (removeResponse !== null && removeResponse !== undefined) {
                                if (removeResponse["notInOrg"] !== undefined && removeResponse["notInOrg"].length)
                                    message += "These assets/devices are outside your organization and could not be deleted: " + removeResponse["notInOrg"].join(", ") + ". \n\n";

                                if (removeResponse["failed"] !== undefined && removeResponse["failed"].length)
                                    message += "These assets/devices failed to be deleted: " + removeResponse["failed"].join(", ") + ". \n\n";

                                if (removeResponse["success"] !== undefined && removeResponse["success"].length) 
                                    message += "These assets/devices were successfully deleted: " + removeResponse["success"].join(", ") + ". \n\n";
                                    
                            }

                            this.setState({
                                loading: false,
                                message: message,
                                deviceInput: "",
                                deviceIdList: [],
                                searchList: [],
                                selectList: [],
                                searchedMac: false
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
            else {
                this.setState({ message: "Must select at least one asset to delete." });
            }

            setTimeout(() => {
                this.setState({
                    message: ""
                });
            }, 5000);
        });
    }

    renderContent() {

        /*let assetIdTags = this.state.assetIdList.length ?
            <div style={{ display: 'flex' }}>
                {this.state.assetIdList.map((id, index) => 
                    <div className="tag">
                        {id}
                        <IconButton key={`${index}`} onClick={(e) => this.onAssetDelete(e, index)} style={{ padding: '0px' }}>
                            <HighlightOffIcon style={{ width: '20px', height: '20px', padding: '2px', margin: '0px 2px 3px 2px' }} />
                        </IconButton>
                    </div>
                )}
            </div>
            : null;*/

        let deviceIdTags = this.state.deviceIdList.length ?
            <div style={{ display: 'flex' }}>
                {this.state.deviceIdList.map((id, index) =>
                    <div key={`${index}-${id}`} className="tag">
                        {id}
                        <IconButton key={`${index}-${id}`} onClick={(e) => this.onDeviceDelete(e, index)} style={{ padding: '0px' }}>
                            <HighlightOffIcon style={{ width: '20px', height: '20px', padding: '2px', margin: '0px 2px 3px 2px' }} />
                        </IconButton>
                    </div>
                )}
            </div>
            : null;

        let macSearchContent = this.state.searchedMac ?
            this.state.searchLoading ?
                <div className='centered'><Spinner animation="border" variant="dark" /></div>
            : <div>
                <div className="table-container">
                    <div style={{ marginBottom: '0px', overflow: "auto" }}>
                        <small>
                        <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                            <thead>
                                <tr>
                                    <th>Asset ID</th>
                                    <th>Name</th>
                                    <th>Organization</th>
                                    <th>Parent ID</th>
                                    <th>Select</th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.state.searchList.length ?
                                    this.state.searchList.map((asset, index) =>
                                        <tr key={`${asset.assetId}-${index}`} data={index}>
                                            <td>{asset.assetId}</td>
                                            <td>{asset.name}</td>
                                            <td>{asset.orgName}</td>
                                            <td>{asset.parentId}</td>
                                            <td>
                                                <input type="checkbox" value={asset.assetId} id={asset.assetId} checked={this.state.selectList.some((a) => a === asset.assetId)} onChange={this.handleAssetCheck} />
                                            </td>
                                        </tr>
                                    )
                                    : <tr><td>No Assets found for this MAC!</td></tr>
                                }
                            </tbody>

                        </table>
                        </small>
                    </div>
                    </div>
                    <button className="btn-small secondary-btn" onClick={this.openDeviceModal/*onRemoveMacAssetsClick*/} style={{ marginTop: '10px', marginLeft: '15px', marginBottom: '20px' }}> Delete Selected Assets </button>
            </div>
            : null;

        return (
            <div>
                {/*<div className="row no-gutters">
                    <div className="white-container col-sm-12 col-md-10 col-lg-8">
                        <div className="input-container" style={{ marginTop: '15px' }}>
                            <label>Enter the Asset ID (ex: 12509) for each Asset you would like to delete, followed by a comma.</label>
                            <div className="form-control" style={{ display: 'flex' }}>
                                {assetIdTags}
                                <input style={{ border: 'none' }}
                                    value={this.state.assetInput}
                                    placeholder="Enter Asset ID"
                                    pattern="[0-9]*"
                                    onKeyDown={this.onAssetKeyDown}
                                    onKeyUp={this.onAssetKeyUp}
                                    onChange={this.onAssetChange}
                                />
                            </div>
                            <small style={{ color: 'red' }}>{this.state.assetMsg}</small>
                        </div>
                        <button className="btn-small secondary-btn" onClick={this.openAssetModal} style={{ marginTop: '10px', marginLeft: '15px', marginBottom: '20px' }}> Delete Assets </button>
                    </div>
                </div>*/}
                <div className="row no-gutters">
                    <div className="white-container col-sm-12 col-md-10 col-lg-8">
                        <div className="input-container" style={{ marginTop: '15px' }}>
                            <label>Enter the MAC ID (ex: 123456789abc) for the Asset(s)/ Device(s) you would like to delete. To delete >1 Asset/ Device, enter each MAC ID followed by a comma. Then click "Search" to view and select which associated Asset(s)/ Device(s) to delete. We recommend you delete all Assets/ Devices in between tests.</label>
                            <div className="form-control" style={{ display: 'flex' }}>
                                {deviceIdTags}
                                <input style={{ border: 'none' }}
                                    value={this.state.deviceInput}
                                    placeholder="Enter MAC ID"
                                    onKeyDown={this.onDeviceKeyDown}
                                    onKeyUp={this.onDeviceKeyUp}
                                    onChange={this.onDeviceChange}
                                />
                            </div>
                            <small style={{ color: 'red' }}>{this.state.deviceMsg}</small>
                        </div>
                        <button className="btn-small secondary-btn" onClick={this.onSearchClick} style={{ marginTop: '10px', marginLeft: '15px', marginBottom: '20px' }}> Search </button>
                        {macSearchContent}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <p className="status-msg">{this.state.message}</p>
                        <br />
                        <br />
                        <br />
                    </div>
                </div>

                {/*<ConfirmationModal show={this.state.showAssetConfirmation} onYes={this.onRemoveAssetsClick} onClick={this.showAssetModal} message={this.state.modalMessage} title={this.state.modalTitle}> </ConfirmationModal>*/}
                <ConfirmationModal show={this.state.showDeviceConfirmation} onYes={this.onRemoveMacAssetsClick} onClick={this.showDeviceModal} message={this.state.modalMessage} title={this.state.modalTitle}> </ConfirmationModal>

            </div>
        )
    }

    render() {
        const { classes } = this.props;

        let loader = this.state.loading ?
            <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div>
            </div>
            : null;

        return (
            <div className={classes.root}>
                <div className='infoPage'>
                    <h3 className="pageTitle" id="tabelLabel" >Manage Assets</h3>
                    {this.renderContent()}
                    {loader}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ManageAssets);