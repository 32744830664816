/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/userfavorites";
const userfavoriteService = {
    getuserfavorites: async (userName) => {
        const response = await http.post(`${controller}/getuserfavorites`, {"userName":userName});
        return response.data;
    },
    adduserfavorite: async (user) => {
        const response = await http.post(`${controller}/adduserfavorite`, user);
        return response.data;
    },
    deleteuserfavorite: async (user) => {
        const response = await http.post(`${controller}/deleteuserfavorite`, user);
        return response.data;
    },
    deleteuserfavorites: async (users) => {
        const response = await http.post(`${controller}/deleteuserfavorites`, users);
        return response.data;
    },
    updateuserfavorites: async (user, favorites) => {
        const response = await http.post(`${controller}/updateuserfavorites?userName=${user}`, favorites);
        return response.data;
    }
};
export default userfavoriteService;