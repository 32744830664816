import {useEffect} from "react";
import * as signalR from "@microsoft/signalr";

const useSignalR = ({hubUrl, eventName, handleNewMessage, authToken, enabled = true}) => {
    useEffect(() => {
        if (!enabled) return;
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(hubUrl, { 
                accessTokenFactory: ()=> authToken.replace("Bearer ", ""),
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build()
        
        connection
            .start()
            .then(()=>{
            console.log("Connection started");
            
            connection.on(eventName, (message) => {
                handleNewMessage(message);
            });
        })
            .catch((err) => console.log('SignalR connection failed: ',err));
        return () => {
            connection.stop();
        }
        
    },[hubUrl, eventName, handleNewMessage, authToken]);
};

export default useSignalR;