/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import "../../CSS/DataModal.css";


class AddHouseModal extends Component { 
    
    onClick = e => {
        this.props.onClick && this.props.onClick(e);
      };

    onCancelClick() {
        this.props.newHome.orgId= "";
        this.props.newHome.houseAssetId= ""; 
        this.props.newHome.nickname= ""; 
        this.props.newHome.address= "";
        this.props.newHome.city= "";
        this.props.newHome.state= "";
        this.props.newHome.zip= "";
        this.props.newHome.latitude= "";
        this.props.newHome.longitude= "";
        this.props.newHome.location= "";
        this.props.newHome.locationId = "";
    };

    render() {
        let LocationRecardId = (this.props.hasServiceTitan && this.props.hasBilling) ?
            <div>
                <label>Location Id</label>
                <div className="row">
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            id="add-locationId"
                            value={this.props.newHome.locationId}
                            onChange={this.props.onLocationRecordIdAddHomeChange}
                            placeholder="LocationId"
                        />
                    </div>
                </div>
            </div> : null
        if(!this.props.show){
            return null;
        }
        else{
            return (  
               
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                            style={{
                                transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                                opacity: this.props.show ? '1' : '0'
                            }}>

                        <div className="modal-header">
                            <h3>Add Home</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ textAlign: "left" }}>
                            <div className="row no-gutters">
                                <div className="col-sm-12 col-md-8">
                                    <form>
                                        <div>
                                            <label>Nickname</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="add-nick-name"
                                                value={this.props.newHome.nickname}
                                                onChange={this.props.onNickNameChange}
                                            />
                                        </div>
                                        <div>
                                            <label>Address</label>
                                            <input 
                                                type="text"
                                                className="form-control"
                                                id="add-address"
                                                value={this.props.newHome.address }
                                                onChange={this.props.onAddressChange}
                                            />
                                        </div>
                                        <div>
                                            <label>City</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="add-city"
                                                value={this.props.newHome.city}
                                                onChange={this.props.onCityChange}
                                            />
                                        </div>
                                        <div>
                                            <label>State</label>
                                            <input 
                                                type="text"
                                                className="form-control"
                                                id="add-state"
                                                value={this.props.newHome.state}
                                                onChange={this.props.onStateChange}
                                            />
                                        </div>
                                        <div>
                                            <label>Zip</label>
                                            <input 
                                                type="text"
                                                className="form-control"
                                                id="add-zip"
                                                value={this.props.newHome.zip}
                                                onChange={this.props.onZipChange}
                                            />
                                        </div>
                                        <div>
                                            <label>Location</label>
                                            <div className="row">
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="add-latitude"
                                                        value={this.props.newHome.latitude}
                                                        onChange={this.props.onLatitudeChange}
                                                        placeholder="Latitude"
                                                    />
                                                </div>
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="add-longitude"
                                                        value={this.props.newHome.longitude}
                                                        onChange={this.props.onLongitudeChange}
                                                        placeholder="Longitude"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {LocationRecardId}
                                        
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={this.props.onSave}>Save</button>
                        </div>
                    </div>
                </div>                
            );
        }
    }
}   
  
export default AddHouseModal;