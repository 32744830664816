/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { PageView, ModalView, Event, Timing } from "../GoogleAnalytics";
import { Spinner } from 'react-bootstrap';
import AccountService from '../../services/account.service';
import AlertService from '../../services/alerts.service';
import PartnerService from '../../services/partner.service';
import ServiceTitanService from '../../services/servicetitan.service';
import CommentsService from '../../services/comments.service';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import BaseAlertPage from '../LWTriageAlerts/BaseAlertPage';
import FullScreenChartModal from '../LWTriageAlerts/FullScreenChartModal';
import AlertEditModal from '../LWTriageAlerts/AlertEditModal';
import CreateJobModal from '../LWTriageAlerts/CreateJobModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import memoize from 'memoize-one';
import moment from 'moment';
import UpgradeSubscriptionModal from "../Dashboard/UpgradeSubscriptionModal";

//import { jsAsset, alertCustomer, jsAlert, jsGraph, jsOpportunity, jsUpdateOpportunity, jsGraphData } from "../../componentObjects";

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TriageAlertsTable from './TriageAlertsTable';
import { AlertTypeIdsEnum, TagIdsEnum, alertSearchStartDate } from '../../componentObjects';
import alerttypemessagingService from "../../services/alerttypemessaging.service";

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important'
        },
    }
});

class TriageAlerts extends Component {
    static displayName = TriageAlerts.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.getAlerts = this.getAlerts.bind(this);
        this.changeRadio = this.changeRadio.bind(this);
        this.reloadTable = this.reloadTable.bind(this);
        this.filterAlerts = this.filterAlerts.bind(this);
        this.consolidateAlerts = this.consolidateAlerts.bind(this);
        this.addConversationToAlert = this.addConversationToAlert.bind(this);
        this.updateAlertList = this.updateAlertList.bind(this);

        this.onShowAllClick = this.onShowAllClick.bind(this);

        this.openUpgradeSubscriptionModal = this.openUpgradeSubscriptionModal.bind(this);
        this.showUpgradeSubscriptionModal = this.showUpgradeSubscriptionModal.bind(this);
        this.onUpgradeSubscription = this.onUpgradeSubscription.bind(this);
        this.onSubscriptionSelect = this.onSubscriptionSelect.bind(this);
        this.onlocationChange = this.onlocationChange.bind(this);
        this.onLocationObjChange = this.onLocationObjChange.bind(this);
        this.onLocationSearch = this.onLocationSearch.bind(this);
        this.checkValidCustomer = this.checkValidCustomer.bind(this);
        this.getAddress = this.getAddress.bind(this);
        this.onResetClick = this.onResetClick.bind(this);
        this.setBackrefreshAlertList = this.setBackrefreshAlertList.bind(this);

        this.state = {
            alertArgs: {
                queryId: "custom",
                startTime: null,
                endTime: null,
                isActive: true,
                priority: 3
            },
            alertType: "Active",
            alertList: [],
            loading: true,
            alertTableLoading: false,
            alertMessaging: [],
            assetComments: [],
            showShowAllBtn: false,
            partnerIdFilter: "",
            partnerInfo: {},
            tableObj: {},
	    fromDashboard: false,
            startTabIndex: 0,
            startTableCounts: {
                equipCritical: 0,
                equipWarning: 0,
                devCritical: 0,
                devWarning: 0
            },
            consolidateAlertsDict: {},
            showUpgradeSubscription: false,
            newSubscriptionId: '0',
            jobTypesList: [],
            campaignIdsList: [],
            tagsList: [],
            callReasonsList: [],
            locationList: [],
            customerList: [],
            locationRawList: [],
            customerRawList: [],
            locationID :0,
            customerID: 0,
            loadingCreateJob: false,
            selectedLocation: {
                name: "",
                street: "",
                city: "",
                state : "",
                zip: ""
	         },
          selectedCustomer: {
                name: "",
                street: "",
                city: "",
                state: "",
                zip: ""
          },
            isInValidCustomerId: false,
            isInvalidCustomerandLocation : true,
            requiredToRefreshAlertList : false 
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        this.baseUrl = window.location.href;
        PageView();

        if (!this.props.isLoading) {
            this.setState({ loading: true }, async () => {
                let showBtn = true;
                if (this.props.userOrg !== "LifeWhere") {
                    showBtn = false;
                }

               let fromDB = false;
                let startTabIndex = 0;
                if (this.baseUrl.toLowerCase().indexOf("equipment") >= 0 || this.baseUrl.toLowerCase().indexOf("device") >= 0) {
                    fromDB = true;
                    if (this.baseUrl.toLowerCase().indexOf("device") >= 0)
                        startTabIndex = 1;
                }

                this.setState({
                    partnerIdFilter: this.props.currentPartnerId,
                    showShowAllBtn: showBtn,
                    fromDashboard: fromDB,
                    startTabIndex: startTabIndex
                });

                this.getAlerts();
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    memAlerts = memoize(this.getAlerts);

   async getAlerts(id) {
        var startTime = performance.now();
        var now = moment();
        var before = moment(alertSearchStartDate);//moment(now).add(-2, 'weeks'); //two weeks ago; set only for demo set 04/01/21
        var nowStr = now.format();
        var beforeStr = before.format();
        var startTabIndex = 0;
        var startTableCounts = {};
        var alertList = [];

        var jobTypesList = [];
        var campaignIdsList = [];
        var tagsList = [];
        var callReasonsList = [];
        let locationList = [];
        let customerList = [];
        let locationRawList = [];
        let customerRawList = [];

        this.setState(prevState => ({
            alertArgs: {
                ...prevState.alertArgs,
                startTime: beforeStr,
                endTime: nowStr
            }

        }),  () => {
            if (this.props.hasServiceTitan) {
                ServiceTitanService.getservicetitanoptions(this.props.currentPartnerId)
                    .then(response6 => {
                        if (this._isMounted) {
                            var stOptions = response6.data;

                            if (stOptions !== undefined && stOptions !== null) {
                                if (stOptions.jobTypesList !== undefined && stOptions.jobTypesList !== null && stOptions.jobTypesList.length)
                                    jobTypesList = stOptions.jobTypesList;

                                if (stOptions.campaignIdsList !== undefined && stOptions.campaignIdsList !== null && stOptions.campaignIdsList.length)
                                    campaignIdsList = stOptions.campaignIdsList;

                                if (stOptions.tagsList !== undefined && stOptions.tagsList !== null && stOptions.tagsList.length)
                                    tagsList = stOptions.tagsList;

                                if (stOptions.callReasonsList !== undefined && stOptions.callReasonsList !== null && stOptions.callReasonsList.length)
                                    callReasonsList = stOptions.callReasonsList;

                                if (stOptions.locationList !== undefined && stOptions.locationList !== null && stOptions.locationList.length) {
                                    locationRawList = stOptions.locationList;
                                    locationList = stOptions.locationList.map(a => { return { id: a.id, name: a.id + "-" + a.name + "|" + a.address.street + "|" + a.address.city }; });
                                }
                                if (stOptions.customerList !== undefined && stOptions.customerList !== null && stOptions.customerList.length) {
                                    customerRawList = stOptions.customerList;
                                    customerList = stOptions.customerList.map(a => { return { id: a.id, name: a.id + "-" + a.name + "|" + a.address.street + "|" + a.address.city }; });
                                }
                            }
                            this.setState({
                                jobTypesList: jobTypesList,
                                campaignIdsList: campaignIdsList,
                                tagsList: tagsList,
                                callReasonsList: callReasonsList,
                                //locationList: locationList,
                                //locationRawList: locationRawList,
                                //customerRawList: customerRawList,
                                //customerList: customerList
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })

            }
            alerttypemessagingService.getpartnermessaging(this.props.currentPartnerId)
                .then(response3 => {
                    if (this._isMounted)
                        this.setState({ alertMessaging: response3 }, async () => {
                            await CommentsService.getassetcomments()
                                .then(response6 => {
                                    if (this._isMounted)
                                        this.setState({ assetComments: response6.data }, async () => {
                                            AlertService.gettriagealertslist(beforeStr, nowStr, this.state.alertMessaging, this.state.partnerIdFilter)
                                                .then(response5 => {
                                                    this.setState({ tableObj: response5.data }, () => {

                                                        let filteredEquipAlertList = this.state.tableObj.equipmentAlertsList !== undefined && this.state.tableObj.equipmentAlertsList !== null ? this.state.tableObj.equipmentAlertsList.filter(a => (a.alert.tagIds.includes(TagIdsEnum.JobCreated) == false) && a.alert.isMuted == false) : [];
                                                        let filteredDevAlertList = this.state.tableObj.deviceAlertsList !== null ? this.state.tableObj.deviceAlertsList.filter(a => a.alert.tagIds.includes(TagIdsEnum.JobCreated) == false && a.alert.isMuted == false) : [];

                                                        if (this.props.hasServiceTier) {
                                                            if (this.props.serviceTier < 3) {
                                                                if (this.props.serviceTier == 2) {
                                                                    filteredEquipAlertList = filteredEquipAlertList.filter(f => f.typeId > 101 && f.typeId < 107);
                                                                    filteredDevAlertList = filteredDevAlertList.filter(f => f.typeId > 101 && f.typeId < 107);
                                                                }
                                                                else {
                                                                    filteredEquipAlertList = [];
                                                                    filteredDevAlertList = [];
                                                                }
                                                            }
                                                        }
                                                        // auto tab select from dashboard here
                                                        if (this.state.fromDashboard) {
                                                            alertList = this.state.startTabIndex === 0 ? filteredEquipAlertList : filteredDevAlertList;
                                                            startTabIndex = this.state.startTabIndex;
                                                        }
                                                        else if (filteredEquipAlertList.length > 0) {
                                                            alertList = filteredEquipAlertList;
                                                            startTabIndex = 0;
                                                        }
                                                        else {

                                                            alertList = filteredDevAlertList; //this.state.tableObj.deviceAlertsList !== null ? this.state.tableObj.deviceAlertsList.filter(a => a.alert.tagIds.includes(TagIdsEnum.JobCreated) == false && a.alert.isMuted == false) : [];

                                                            /*if (this.props.hasServiceTier && this.props.serviceTier < 3) {
                                                                alertList = alertList.filter(f => f.typeId > 101 && f.typeId < 107);
                                                            }*/
                                                            startTabIndex = 1;
                                                        }

                                                        let startTableCounts = {
                                                            equipCritical: filteredEquipAlertList.filter(a => a.severity == "Critical").length,
                                                            equipWarning: filteredEquipAlertList.filter(a => a.severity == "Warning").length,
                                                            devCritical: filteredDevAlertList.filter(a => a.severity == "Critical").length,
                                                            devWarning: filteredDevAlertList.filter(a => a.severity == "Warning").length
                                                        };

                                                        this.setState({ loading: false, /*alertTableLoading: false, alertList: alertList,*/ startTabIndex: startTabIndex, startTableCounts: startTableCounts, requiredToRefreshAlertList: true }, () => {
                                                            this.consolidateAlerts(alertList);
                                                        });
                                                    });
                                                })
                                                .catch(e => {
                                                    console.log(e);
                                                    this.setState({ loading: false });
                                                })
                                        })
                                })
                                .catch(e => {
                                    console.log(e);
                                    this.setState({ loading: false });
                                })
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        });

    }

    consolidateAlerts(alerts) {
        let distinctAlerts = [];
        let collapseAlerts = [];
        let consolidateAlertsDict = {};
        let collapseUnitAlertsDict = {}; // {{ assetId: assetId used in consolidateDict }}
        let isDevAlerts = this.state.tabIndex === 1;

        for (let i = 0; i < alerts.length; i++) {
            let currAlert = alerts[i];

            if (!distinctAlerts.some(a => a.typeId == currAlert.typeId && a.alert.assetId == currAlert.alert.assetId)) {
                var assetTypeAlerts = alerts.filter(a => a.typeId == currAlert.typeId && a.alert.assetId == currAlert.alert.assetId);
                var alertConversationId = Math.max(...assetTypeAlerts.map(o => o.alert.conversationId)).toString();
                var alertCommentCount = assetTypeAlerts.find(a => a.alert.conversationId == alertConversationId);

                assetTypeAlerts.sort((a, b) => {
                    const startA = new Date(a.alert.startTime);
                    const startB = new Date(b.alert.startTime);
                    return startB - startA;
                });

                var distinctAlert = [...assetTypeAlerts][0];
                let distinctUnitId = distinctAlert.alert.pathNames !== null && distinctAlert.alert.pathNames.length > 2 ? distinctAlert.alert.pathNames[2].split('-')[0] : null;

                if (isDevAlerts && distinctAlert.typeId == AlertTypeIdsEnum.UnitOffline && distinctUnitId !== null && distinctAlerts.some(d => d.typeId == distinctAlert.typeId && d.alert.pathNames.length > 2 && d.alert.pathNames[2].split('-')[0] == distinctUnitId)) {
                    let prevDistinctAlert = distinctAlerts.find(d => d.typeId == distinctAlert.typeId && d.alert.pathNames.length > 2 && d.alert.pathNames[2].split('-')[0] == distinctUnitId);
                    let prevUnitAlerts = consolidateAlertsDict[distinctAlert.typeId][prevDistinctAlert.alert.assetId];

                    let unitAlerts = prevUnitAlerts.concat(assetTypeAlerts);

                    collapseAlerts = collapseAlerts.concat(distinctAlert);
                    collapseUnitAlertsDict[distinctAlert.alert.assetId] = prevDistinctAlert.alert.assetId;
                    consolidateAlertsDict[prevDistinctAlert.typeId][prevDistinctAlert.alert.assetId] = unitAlerts;
                }
                else {
                    if (alertConversationId != 0) {
                        distinctAlert.alert.conversationId = alertConversationId;
                        distinctAlert.commentsCount = alertCommentCount.alert.conversationItemCount;
                        distinctAlert.alert.conversationItemCount = alertCommentCount.alert.conversationItemCount;
                    }
                    distinctAlerts = distinctAlerts.concat(distinctAlert);

                    if (consolidateAlertsDict[currAlert.typeId] === undefined) {
                        consolidateAlertsDict[currAlert.typeId] = {};
                        consolidateAlertsDict[currAlert.typeId][currAlert.alert.assetId] = assetTypeAlerts;
                    }
                    else {
                        consolidateAlertsDict[currAlert.typeId][currAlert.alert.assetId] = assetTypeAlerts;
                    }
                }
            }
        }

        distinctAlerts.sort((a, b) => {
            let aAlerts = consolidateAlertsDict[a.typeId][a.alert.assetId];
            let bAlerts = consolidateAlertsDict[b.typeId][b.alert.assetId];
            if (!alerts.some(c => c.alert.tagIds.includes(TagIdsEnum.NewAlert))) {
                if (!bAlerts.some(d => d.alert.tagIds.includes(TagIdsEnum.NewAlert)))
                    return 0;
                else
                    return 1;
            }
            else {
                if (!bAlerts.some(d => d.alert.tagIds.includes(TagIdsEnum.NewAlert)))
                    return -1;
                else
                    return 0;
            }
        });

        this.setState({ alertList: distinctAlerts, consolidateAlertsDict: consolidateAlertsDict, alertTableLoading: false });
    }

    async filterAlerts(tabIndex, radio, isActive, isScheduled, selectedTypes) {
        if (this.state.tableObj.equipmentAlertsList !== null && this.state.tableObj.equipmentAlertsList !== undefined)
            this.setState({ alertTableLoading: true }, () => {
                let alerts = tabIndex == 0 ? this.state.tableObj.equipmentAlertsList : this.state.tableObj.deviceAlertsList;
                if (this.props.hasServiceTier && this.props.serviceTier < 3)
                    alert = alerts.filter(a => a.typeId > 101 && a.typeId < 107);  

                if (radio === "Critical")
                    alerts = alerts.filter(a => a.severity === "Critical");
                else if (radio === "Warning")
                    alerts = alerts.filter(a => a.severity === "Warning");

                if (isActive !== null) {
                    if (isActive == true)
                        alerts = alerts.filter(a => a.alert.isActive == true);
                    else
                        alerts = alerts.filter(a => a.alert.isActive == false);
                }

                if (isScheduled !== null) {
                    if (isScheduled == true)
                        alerts = alerts.filter(a => a.alert.isMuted == true);
                    else
                        alerts = alerts.filter(a => a.alert.isMuted == false);
                }

                this.consolidateAlerts(alerts); //this.setState({ alertList: alerts, alertTableLoading: false });  
            });
    }

    async reloadTable() {
        this.setState({
            alertTableLoading: true,
            alertList: []
        });

        this.setState(prevState => ({
            alertArgs: {
                ...prevState.alertArgs,
                isActive: this.state.alertType === "Inactive" ? false : true
            }
        }));

        await this.getAlerts();
    }

    async changeRadio(val) {
        let x = val;
        Event("Triage Alerts Radio Clicked", "User clicked one of the radio buttons for the alert table in Triage Alerts", "Triage Alerts Radio Clicked");

        this.setState({
            alertType: x,
            alertList: []
        });

        this.setState(prevState => ({
            alertArgs: {
                ...prevState.alertArgs,
                isActive: x === "Inactive" ? false : true
            }
        }));

        this.setState({ alertTableLoading: true }, async () => {
            await this.getAlerts();
        });
    }

    addConversationToAlert(conversationId, alertId) {
        var index = 0;
        var alert = null;

        index = this.state.tableObj.alerts.findIndex(a => {
            return a.alertId === alertId;
        });

        var tempTableObj = [...this.state.tableObj.alerts];
        alert = { ...tempTableObj[index] };
        alert.conversationId = conversationId.toString();
        tempTableObj[index] = alert;

        this.setState(prevState => ({
            tableObj: {
                ...prevState.tableObj,
                alerts: tempTableObj
            }
        }));
    }

    updateAlertList(alertsTable) {
        this.setState({ alertList: alertsTable });
    }

    onShowAllClick() {
        var filter = !this.state.showAllAlerts ? "" : this.props.currentPartnerId;
        this.setState({ showAllAlerts: !this.state.showAllAlerts, partnerIdFilter: filter }, () => {
            if (this.state.showAllAlerts)
                Event("Show All Alerts", "User clicked Show All button", "Show All Clicked");
        });
    }

    openUpgradeSubscriptionModal() {
        this.showUpgradeSubscriptionModal();
    }

    showUpgradeSubscriptionModal() {
        this.setState({ showUpgradeSubscription: !this.state.showUpgradeSubscription }, () => {
            if (this.state.showUpgradeSubscription)
                ModalView("upgradeSubscription");
        });
    }

    onSubscriptionSelect(e) {
        this.setState({ newSubscriptionId: e.target.value });
    }

    onUpgradeSubscription() {
        Event("Subscription Upgrade", "User upgraded a subscription", "Subscription Upgrade");
        this.setState({ loading: true }, async () => {
            if (this.props.serviceTier !== this.state.newSubscriptionId) {
                var partnerOrg = this.props.partners.find(p => p.name == this.props.userOrg);
                await PartnerService.updateservicetier(partnerOrg.organizationId, this.state.newSubscriptionId)
                    .then(response => {
                        if (this._isMounted) {
                            this.setState({ showUpgradeSubscription: false, loading: false }, () => {
                                this.props.setServiceTier(this.state.newSubscriptionId);
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            }
            else {
                this.setState({ showUpgradeSubscription: false, loading: false });
            }
        });
    }

    onResetClick(type) {
        if (type == "location") {
            const setaddress = {
                name: "",
                street: "",
                city: "",
                state: "",
                zip: ""
            }
            this.setState({
                locationID: 0,
                selectedLocation: setaddress,
                locationList: [],
                locationRawList: []
            });
        }


        if (type == "customer") {
            const setaddress = {
                name: "",
                street: "",
                city: "",
                state: "",
                zip: ""
            }
            this.setState({
                customerID: 0,
                selectedCustomer: setaddress,
                isInValidCustomerId: false,
                customerRawList: [],
                customerList: []
            });
        }
    }
    onlocationChange(e, type) {
        if (type == "location") {
            const type = e.target.value;

            const lcationObj = this.state.locationRawList.find(x => x.id == parseInt(type, 10));

            if (lcationObj != null) {
                const setaddress = {
                    name: lcationObj.name,
                    street: lcationObj.address.street,
                    city: lcationObj.address.city,
                    state: lcationObj.address.state,
                    zip: lcationObj.address.zip
                }
                this.setState({
                    locationID: parseInt(type, 10),
                    selectedLocation: setaddress
                }, () => { this.checkValidCustomer() });

            }
        }
        if (type == "customer") {
            const type = e.target.value;

            const customerObj = this.state.customerRawList.find(x => x.id == parseInt(type, 10));

            if (customerObj != null) {
                const setaddress = {
                    name: customerObj.name,
                    street: customerObj.address.street,
                    city: customerObj.address.city,
                    state: customerObj.address.state,
                    zip: customerObj.address.zip
                }
                this.setState({
                    customerID: parseInt(type, 10),
                    selectedCustomer: setaddress
                }, () => { this.checkValidCustomer() });

            }
        }

    }
    checkValidCustomer() 
    {
        if (this.state.customerID != "" && this.state.locationID != "") {
	    const lcationObj = this.state.locationRawList?.find(x => x.customerId == this.state.customerID && x.id == this.state.locationID);
            if (lcationObj != null && lcationObj?.id == this.state.locationID) {
                this.setState({
                    isInValidCustomerId: false,
                    isInvalidCustomerandLocation: false
                });

            } else {
                this.setState({
                    isInValidCustomerId: true,
                    isInvalidCustomerandLocation: true
                });
            }
        }
        else 
	     {
            this.setState({
                isInvalidCustomerandLocation: true
            });
	  }

      
    }
    onLocationObjChange(e, field ,type) 
    { 
        const value = e.target.value;
        switch (field)
	         {
            case "name":
                (type == "location")&& 
                this.setState({
                    selectedLocation: { ...this.state.selectedLocation, name: value }
                }, () => this.updateSelectedLocation());

                (type == "customer") &&
                    this.setState({
                        selectedCustomer: { ...this.state.selectedCustomer, name: value }
                    }, () => this.updateSelectedCustomer());
                break;
            case "street":
                (type == "location") &&
                this.setState({
                    selectedLocation: { ...this.state.selectedLocation, street: value }
                }, () => this.updateSelectedLocation());

                (type == "customer") &&
                    this.setState({
                        selectedCustomer: { ...this.state.selectedCustomer, street: value }
                    }, () => this.updateSelectedCustomer());
                break;
            case "city":

                (type == "location") &&
                this.setState({
                    selectedLocation: { ...this.state.selectedLocation, city: value }
                }, () => this.updateSelectedLocation());

                (type == "customer") &&
                    this.setState({
                        selectedCustomer: { ...this.state.selectedCustomer, city: value }
                    }, () => this.updateSelectedCustomer());
                break; 
            case "zip":

                (type == "location") &&
                this.setState({
                    selectedLocation: { ...this.state.selectedLocation, zip: value }
                }, () => this.updateSelectedLocation());

                (type == "customer") &&
                    this.setState({
                        selectedCustomer: { ...this.state.selectedCustomer, zip: value }
                    }, () => this.updateSelectedCustomer());
                break;

            case "state":

                (type == "location") &&
                    this.setState({
                        selectedLocation: { ...this.state.selectedLocation, state: value }
                    }, () => this.updateSelectedLocation());

                (type == "customer") &&
                this.setState({
                    selectedCustomer: { ...this.state.selectedCustomer, state: value }
                }, () => this.updateSelectedCustomer());
                break;
            case "locationId":
                this.setState({
                    locationID: value
                });

                break;
            case "customerId":
                this.setState({
                    customerID: value
                });

                break;
                
		   }
      
	 }

    setBackrefreshAlertList()
      {
        this.setState({
            requiredToRefreshAlertList : false
        });
	  }
    updateSelectedLocation() 
    { 
        const lcationObj = this.state.locationRawList?.find(x => x.name == this.state.selectedLocation.name);
        if (lcationObj != null) {
            if ((lcationObj.address.street == this.state.selectedLocation.street) && (lcationObj.address.zip == this.state.selectedLocation.zip) && (lcationObj.address.state == this.state.selectedLocation.state)) {
                this.setState({
                    locationID: lcationObj.id

                });
            }
            else {
                this.setState({
                    locationID: 0

                });
            }
        } else {
            this.setState({
                locationID: 0

            });
	 }

    }

    updateSelectedCustomer() {
        const custpmerObj = this.state.customerRawList?.find(x => x.name == this.state.selectedCustomer.name);
        if (custpmerObj != null) {
            if ((custpmerObj.address.street == this.state.selectedCustomer.street) && (custpmerObj.address.zip == this.state.selectedCustomer.zip) && (custpmerObj.address.state == this.state.selectedCustomer.state)) {
                this.setState({
                    customerID: custpmerObj.id

                });
            }
            else {
                this.setState({
                    customerID: 0

                });
            }
        } else {
            this.setState({
                customerID: 0

            });
        }

    }
    
    getAddress(address) {

        //const setaddress = {
        //    name: address.name,
        //    street: address.street,
        //    city: address.city,
        //    state: address.state,
        //    zip: address.zip
        //}
        //this.setState({
        //    selectedLocation: setaddress,
        //    selectedCustomer: setaddress
        //}, () => this.onLocationSearch("both") );
        

    }
    changeLocationorCustomerId(type)
     {
        this.setState({
            loadingCreateJob: true
        }, () => {
            let locationList = [];
            let customerList = [];
            let locationRawList = [];
            let customerRawList = [];
            const value = (type == "customer") ? this.state.customerID : (type == "location") ? this.state.locationID : 0;

            ServiceTitanService.getSearchLocationIDorCustomerID(this.props.currentPartnerId, value, type)
                .then(response6 => {
                    if (this._isMounted) {
                        var stOptions = response6.data;

                        if (stOptions !== undefined && stOptions !== null) {

                            if (stOptions.locationList !== undefined && stOptions.locationList !== null && stOptions.locationList.length && type == "location") {
                                locationRawList = stOptions.locationList;
                                locationList = stOptions.locationList.map(a => { return { id: a.id, name: a.id + "-" + a.name + "|" + a.address.street + "|" + a.address.city }; });

                                const setLocation = stOptions.locationList.find(x => x.id == this.state.locationID);
                                if (setLocation != null) {
                                    const setaddress = {
                                        name: setLocation.name,
                                        street: setLocation.address.street,
                                        city: setLocation.address.city,
                                        state: setLocation.address.state,
                                        zip: setLocation.address.zip
                                    }
                                    this.setState({
                                        selectedLocation: setaddress
                                    });
                                }
                                this.setState({
                                    locationList: locationList,
                                    locationRawList: locationRawList,
                                }, () => { this.checkValidCustomer() });
                            }
                            if (stOptions.locationList.length == 0 && type == "location") {
                                const selectedLocation = {
                                    name: "",
                                    street: "",
                                    city: "",
                                    state: "",
                                    zip: ""
                                };
                                this.setState({
                                    selectedLocation: selectedLocation,
                                    locationList: [],
                                    locationRawList: []
                                }, () => { this.checkValidCustomer() });
                            }
                            if (stOptions.customerList !== undefined && stOptions.customerList !== null && stOptions.customerList.length && type == "customer") {
                                customerRawList = stOptions.customerList;
                                customerList = stOptions.customerList.map(a => { return { id: a.id, name: a.id + "-" + a.name + "|" + a.address.street + "|" + a.address.city }; });

                                const setCustomer = stOptions.customerList.find(x => x.id == this.state.customerID);
                                if (setCustomer != null) {
                                    const setCustomerObj = {
                                        name: setCustomer.name,
                                        street: setCustomer.address.street,
                                        city: setCustomer.address.city,
                                        state: setCustomer.address.state,
                                        zip: setCustomer.address.zip
                                    }
                                    this.setState({
                                        selectedCustomer: setCustomerObj
                                    });
                                }
                                this.setState({
                                    customerList: customerList,
                                    customerRawList: customerRawList,
                                }, () => { this.checkValidCustomer() });
                            }
                            if (stOptions.customerList.length == 0 && type == "customer") {
                                const customer = {
                                    name: "",
                                    street: "",
                                    city: "",
                                    state: "",
                                    zip: ""
                                };
                                this.setState({
                                    selectedCustomer: customer,
                                    customerList: [],
                                    customerRawList: [],
                                    loadingCreateJob: false
                                }, () => { this.checkValidCustomer() });
                            }

                        }
                        this.setState({
                            loadingCreateJob: false
                        });
                    }

                   
                })
                .catch(e => {
                    console.log(e);
                })
        });

      }

    onLocationSearch(type)
    {
        this.setState({
            loadingCreateJob: true
        }, () => {
            let locationList = [];
            let customerList = [];
            let locationRawList = [];
            let customerRawList = [];
            if (type == "location" || type == "both") {
                ServiceTitanService.getservicetitanLocation(this.props.currentPartnerId, this.state.selectedLocation)
                    .then(response6 => {
                        if (this._isMounted) {
                            var stOptions = response6.data;

                            if (stOptions !== undefined && stOptions !== null) {

                                if (stOptions.locationList !== undefined && stOptions.locationList !== null && stOptions.locationList.length) {
                                    locationRawList = stOptions.locationList;
                                    locationList = stOptions.locationList.map(a => { return { id: a.id, name: a.id + "-" + a.name + "|" + a.address.street + "|" + a.address.city }; });
                                }

                            }
                            this.setState({
                                locationList: locationList,
                                locationRawList: locationRawList,
                                loadingCreateJob: false
                            });
                        }
                    })
                    .catch(e => {
                        this.setState({
                            loadingCreateJob: false
                        });
                        console.log(e);
                    });
            }
            if (type == "customer" || type == "both") {

                ServiceTitanService.getservicetitanCustomer(this.props.currentPartnerId, this.state.selectedCustomer)
                    .then(response1 => {
                        if (this._isMounted) {
                            var stOptions = response1.data;

                            if (stOptions !== undefined && stOptions !== null) {
                                if (stOptions.customerList !== undefined && stOptions.customerList !== null && stOptions.customerList.length) {

                                    customerRawList = stOptions.customerList;
                                    customerList = stOptions.customerList.map(a => { return { id: a.id, name: a.id + "-" + a.name + "|" + a.address.street + "|" + a.address.city }; });
                                }

                            }
                            this.setState({
                                customerRawList: customerRawList,
                                customerList: customerList,
                                loadingCreateJob: false
                            });
                        }
                    })
                    .catch(e => {
                        this.setState({
                            loadingCreateJob: false
                        });
                        console.log(e);
                    });
            }
            if (type == "customerId") {
                this.changeLocationorCustomerId("customer")

            }
            if (type == "locationId") {
                this.changeLocationorCustomerId("location");
            }
        });
       
     }
    render() {
        const { classes } = this.props;
        let subscriptionButton = this.props.hasServiceTier ?
            <div className="btn-right">
                <button type="button" className="secondary-btn btn-large" onClick={this.openUpgradeSubscriptionModal} style={{ width: 'unset', marginTop: '20px' }}>Upgrade Subscription Level</button>
            </div>
            : null;

        return (
            <div className={classes.root}>

            <div className="infoPage">
                    
                    <div className="row no-gutters">
                        <div className="col">
                            <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                                Triage Alerts
                            </h2>
                        </div>
                        <div className="col">
                            {subscriptionButton}
                        </div>
                    </div>

                    <TriageAlertsTable alertList={this.state.alertList} startTabIndex={this.state.startTabIndex} startTableCounts={this.state.startTableCounts} _isMounted={this._isMounted} loading={this.state.loading} alertTableLoading={this.state.alertTableLoading} alertMessaging={this.state.alertMessaging} assetComments={this.state.assetComments} tableObj={this.state.tableObj}
                        filterAlerts={this.filterAlerts} reloadTable={this.reloadTable} customers={this.props.customers} consolidateAlertsDict={this.state.consolidateAlertsDict} addConversationToAlert={this.addConversationToAlert} userOrg={this.props.userOrg} updateAlertList={this.updateAlertList}
                        setActivePath={this.props.setActivePath} source="TriageAlerts" userName={this.props.userName} serviceTier={this.props.serviceTier} hasServiceTier={this.props.hasServiceTier} hasServiceTitan={this.props.hasServiceTitan} PartnerId={this.props.currentPartnerId}
                        jobTypesList={this.state.jobTypesList} campaignIdsList={this.state.campaignIdsList} tagsList={this.state.tagsList} callReasonsList={this.state.callReasonsList} showAllBtn={this.state.showShowAllBtn} showAllAlerts={this.state.showAllAlerts} showAllClick={this.onShowAllClick}
                        locationList={this.state.locationList} locationRawList={this.state.locationRawList} locationId={this.state.locationID} onlocationChange={this.onlocationChange} selectedLocation={this.state.selectedLocation} onLocationObjChange={this.onLocationObjChange} onLocationSearch={this.onLocationSearch}
                        changeLocationId={this.changeLocationId} selectedCustomer={this.state.selectedCustomer} customerRawList={this.state.customerRawList} customerList={this.state.customerList} customerID={this.state.customerID} getAddress={this.getAddress} loadingCreateJob={this.state.loadingCreateJob}
                        isInValidCustomerId={this.state.isInValidCustomerId} isInvalidCustomerandLocation={this.state.isInvalidCustomerandLocation} onResetClick={this.onResetClick} requiredToRefreshAlertList={this.state.requiredToRefreshAlertList} setBackrefreshAlertList={this.setBackrefreshAlertList } fromDashboard={this.state.fromDashboard}
                    />

                    <UpgradeSubscriptionModal show={this.state.showUpgradeSubscription} onClick={this.showUpgradeSubscriptionModal} onSubscriptionSelect={this.onSubscriptionSelect} onSave={this.onUpgradeSubscription} serviceTier={this.state.newSubscriptionId !== '0' ? this.state.newSubscriptionId: this.props.serviceTier} />

                    {/*<BaseAlertPage alertList={this.state.alertList} getAlerts={this.getAlerts} changeRadio={this.changeRadio} reloadTable={this.reloadTable} _isMounted={this._isMounted} alertTableLoading={this.state.alertTableLoading} loading={this.state.loading} showButtons={true}
                        setCurrentPartnerAndCustomer={this.props.setCurrentPartnerAndCustomer} setCurrentPartnerId={this.props.setCurrentPartnerId} setCurrentCustomerId={this.props.setCurrentCustomerId} setActivePath={this.props.setActivePath} alertMessaging={this.state.alertMessaging} partnerInfo={this.state.partnerInfo} />*/}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(TriageAlerts);
