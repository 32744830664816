/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/
import {useEffect, useState} from "react";
import InstallService from "../../services/install.service";


export default function useTechnicianInfo(macId){
    const [data, setData] = useState({});
    useEffect(() => {
        let isMounted = true;
        InstallService.getinstallbymacid(macId).then((response)=>{
            if (isMounted) {
                setData(response);
            }
        })

        return () => {
            isMounted = false;
        };
    }, [macId]);

    return data;
}