/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import "../../../CSS/DataModal.css";

import * as Roles from '../Users/Roles';

const animatedComponents = makeAnimated();

class UserEditModal extends Component {
    constructor(props) {
        super(props);

        this.getUserRoles = this.getUserRoles.bind(this);

        let displayedOptions = Roles.partnerAdminRoles; //this.props.isPartnerAdmin ? Roles.partnerAdminRoles : Roles.roles;

        if (!this.props.isPortalAdmin) {
            displayedOptions = displayedOptions.filter(option => option.name !== "LW-FC Beta Installer");            
        }
        if (this.props.isGlobalAdmin === 1) 
	    { 
            displayedOptions = displayedOptions.filter(option => option.name !== "Operations");    
	    }
        this.state = {
            role: null,
            roleId: null,
            roleOptions: displayedOptions,
            roleList: []
        }
    };

  
    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
        //do nothing.
    };

    getUserRoles(roleList) {
        let options = this.state.roleOptions;
        let roleArray = roleList.split(", ");
        let userRoles = [];

        roleArray.forEach(role => {
            let i = options.findIndex(option => option["name"] === role);
            userRoles = userRoles.concat(options[i]);
        });

        return userRoles;
    }

    getOptionLabel = (option) => {
        return (option["name"]);
    }

    getOptionValue = (option) => {
        return (option["roleId"]);
    }
    handleRoleCheck(e, roleId) {
        if (e.target.checked) {
            this.props.roleList.concat(roleId);
        }
        else {
            var index = this.props.roleList.indexOf(roleId);
            this.props.roleList.splice(index, 1);
        }
    };

    render() {
        if (!this.props.show) {
            return null;
        }
        else {
            if (this.props.isGlobalAdmin != null && this.props.isGlobalAdmin !== "") {
                switch (this.props.isGlobalAdmin) { 
                    case 3:
                    case 2:
                    case 1:
                        var types = (<div style={{ paddingRight: 2 + "px" }}>
                            <p className="semibold">Type</p>
                            <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                isMulti
                                options={this.state.roleOptions}
                                defaultValue={this.getUserRoles(this.props.editUser.roleList)}
                                getOptionLabel={this.getOptionLabel}
                                getOptionValue={this.getOptionValue}
                                onChange={this.props.onRoleChange}
                            /></div>)
                        break;

                    default :
                }
            };
            return (

                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Edit User</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body-2 h-500" style={{ textAlign: "left" }}>
                            <br/>
                            <form>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="semibold">First Name</p>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="firstName"
                                                value={this.props.editUser.firstName}
                                                onChange={this.props.onFirstNameChange}
                                            />
                                        </div>
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="semibold">Last Name</p>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="lastName"
                                                value={this.props.editUser.lastName}
                                                onChange={this.props.onLastNameChange}
                                            />
                                        </div>
                                        <br/>
                                    </div>
                                        <div className="col-sm-12 col-md-6">
                                            {types}
                                        </div>
                                    </div>
                            </form>
                            <br/>
                        </div>


                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={this.props.onSave}>Save</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default UserEditModal;