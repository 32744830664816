/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import "../../../CSS/DataModal.css";
import styled from "styled-components";
import { Row, Container } from 'react-bootstrap';

const StyledContainer = styled.div`
    padding: 5px;
    text-align: left;
    margin-left: 10px;
    margin-top: 5px;
`;

export class MoreColors extends React.Component {
    
    render() {
        return (
            <Container>
                <h4>Gateway</h4>
                <h5>Problem Solving</h5>
                <StyledContainer>
                    <Row>
                        <div className="col-md-6 col-lg-3 px-1" id="color-name">
                            <p> 1. <span style={{ color: '#FF00FF' }}> Purple/Pink </span></p>
                        </div>
                        <div className="col-md-12 col-lg-9" id="color-description">
                            <small> Gateway is not configured, AP not active. Use mobile app to configure the Gateway. </small>
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <div className="col-md-6 col-lg-3 px-1" id="color-name" text-align="left">
                            <p> 2. <span style={{ color: 'green' }}> Green </span></p>
                        </div>
                        <div className="col-md-12 col-lg-9" id="color-description" text-align="left">
                            <small> Wi-Fi is in AP mode and can be configured with mobile app. AP mode is when the Gateway broadcasts the LifePulse network which allows you to connect to it with the mobile app. </small>
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <div className="col-md-6 col-lg-3 px-1" id="color-name" text-align="left">
                            <p> 3. <span style={{ color: '#FF00FF' }}> Blinking Purple </span>/ <span style={{ color: '#FF00FF' }}> Pink </span></p>
                        </div>
                        <div className="col-md-12 col-lg-9" id="color-description" text-align="left">
                            <small> Gateway is exchanging data with the Sensor Hub. This can happen when the Sensor Hub is sending the data it gathered from the sensors or because the Gateway is sending new firmware to the Sensor Hub. </small>
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <div className="col-md-6 col-lg-3 px-1" id="color-name" text-align="left">
                            <p> 4. <span style={{ color: 'red' }}> Red </span></p>
                        </div>
                        <div className="col-md-12 col-lg-9" id="color-description" text-align="left">
                            <small> Error, something is wrong with the Gateway. Please escalate to the LifeWhere team. </small>
                        </div>
                    </Row>
                    <br/><br/><br/><br/><br/><br/><br/>
                </StyledContainer>
            </Container>
        );
    }
}