/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';


class SelectCustomer extends React.Component {
    /* obj.organizationId here is for customerId not the same as in Partners where it is the partners organization Id.,  */
    render() {
        if (this.props.customers != null) {
            //this.props.customers.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

            return (
                <div className="select-container">
                    <span className="select-message">Customers</span><br/>
                    <select className="selectpicker" style={{ width: 200 + 'px', height: 30 + 'px', paddingLeft: '13px', paddingRight: 18 + 'px' }} value={this.props.selectedCustomer} onChange={this.props.onCustomerSelect}>
                        {this.props.customers.map((obj, index) =>
                            <option key={`${index}-${obj.organizationId}`} id={`${index}-${obj.organizationId}`} value={obj.organizationId}>{obj.firstName === "" ? obj.lastName : obj.lastName + ", " + obj.firstName}</option>
                            )}
                        </select>
                </div>                
            );
        }
        else {
            return (null);
        }
    }
} 
export default SelectCustomer;