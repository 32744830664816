/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class DevicesModal extends Component {
    constructor(props) {
        super(props);

        this.onViewDevicesClick = this.onViewDevicesClick.bind(this);
        this.setPartnerCallback = this.setPartnerCallback.bind(this);
        this.setCustomerCallback = this.setCustomerCallback.bind(this);

        this.state = {
        }
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
    };

    onViewDevicesClick = () => {
        if (this.props.isCurrentJob) {
            this.props.setActivePath("/devices");
        }
        else {
            this.props.setCurrentPartnerAndCustomer(this.props.customer.parentOrganizationId, this.props.customer.organizationId, this.setPartnerCallback);
        }
    }

    setPartnerCallback() {
        /*this.props.setCurrentCustomerId(this.props.customer.organizationId, this.setCustomerCallback);*/
        this.props.setActivePath("/devices");
    }

    setCustomerCallback() {
        // Was used for setPartnerCallback, No longer used but will keep stub for future use
        //this.props.setActivePath("/devices");
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        else {
            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>View Devices</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body-2" style={{ textAlign: "center" }}>
                            <br />
                            <p>{this.props.message}</p>
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <Link to="/devices" className="primary-btn btn-small" onClick={this.onViewDevicesClick}>
                                View Devices
                            </Link>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default DevicesModal;