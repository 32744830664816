/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import "../../CSS/DataModal.css";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

class RetrainAssetModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            macIdConfirmed: false,
            macId: "",
            selectedAlertTypeIds: []
        }

        this.onChangeMacId = this.onChangeMacId.bind(this);
        this.onConfirmClick = this.onConfirmClick.bind(this);
        this.onChangeAlerts = this.onChangeAlerts.bind(this);
        this.onRetrainAsset = this.onRetrainAsset.bind(this);
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {

    };

    onChangeMacId(e) {
        const macId = e.target.value;

        this.setState({
            macId: macId
        });
    }

    onConfirmClick() {
        if (this.state.macId.trim() === this.props.currentMacId) {
            this.setState({ macIdConfirmed: true });
        }
        else {
            this.setState({ message: "Mismatched MAC IDs. To retrain must enter the MAC ID for the selected asset." });

            setTimeout(() => {
                this.setState({
                    message: ""
                });
            }, 3000);
        }
    }

    onChangeAlerts = (selectedOptions) => {
        var options = [];

        selectedOptions.forEach(option => {
            options = options.concat(option.id.toString());
        });

        this.setState({ selectedAlertTypeIds: options });
    }

    onRetrainAsset() {
        this.setState({ macId: "" }, () => {
            this.props.onRetrainAsset(this.state.selectedAlertTypeIds);
        });
    }

    getOptionLabel = (option) => {
        return (option.type);
    }

    getOptionValue = (option) => {
        return (option.id);
    }

    render() {
        let retrainLoading = this.props.modalLoading ?
            <div className="loading" style={{ display: "block", }}>
                <div className="loading-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className="loading-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : <div>{null}</div>

        if (!this.props.show) {
            return null;
        }
        else {
            let fpAlertsContent = this.state.macIdConfirmed ?
                <div>
                    <br/>
                    <div style={{ marginBottom: '20px' }}>
                        Select Alert Types causing Asset to need Retraining
                    </div>
                    <div className="row no-gutters">
                        <div className="col-10 offset-1">
                            <div>
                                <form>
                                    <Select
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999999999 }) }}
                                        menuPortalTarget={document.body}
                                        isMulti
                                        options={this.props.falsePositiveAlerts}
                                        getOptionLabel={this.getOptionLabel}
                                        getOptionValue={this.getOptionValue}
                                        onChange={this.onChangeAlerts}
                                        placeholder="Select Alert Types..."
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                : <div>{null}</div>;

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Retrain Asset</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ justifyContent: 'center' }}>
                            <br />

                            <div style={{ marginBottom: '20px' }}>
                                Are you sure you want to Retrain this asset with MAC ID {this.props.currentMacId}? Please enter the MAC ID of this asset below to confirm.
                            </div>
                            <div className="row no-gutters">
                                <div className='col-8 offset-1'>
                                    <input 
                                        type="text"
                                        className="form-control"
                                        id="mac-id"
                                        value={this.state.macId}
                                        onChange={this.onChangeMacId}
                                    />
                                </div>
                                <div className="col-2" style={{ display: 'flex' }}>
                                    <button className="primary-btn btn-small" disabled={this.state.macIdConfirmed} style={{ padding: '0.375rem 0.75rem', marginLeft: '5px', lineHeight: '1.5' }} onClick={this.onConfirmClick}>Confirm</button>
                                </div>
                            </div>
                            {fpAlertsContent}
                            <p>{this.state.message}</p>
                            <br />
                        </div>


                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <button className="primary-btn btn-small" disabled={!this.state.macIdConfirmed || (this.state.selectedAlertTypeIds.length < 1)} onClick={this.onRetrainAsset}>Retrain Asset</button>
                        </div>

                    </div>
                    {retrainLoading}

                </div>
            );
        }
    }
}

export default RetrainAssetModal;