/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import "../../CSS/DataModal.css";

class SummarizedAlertsModal extends React.Component {


    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
        this.props.onClick();
    };

    getDateStr(startTime) {
        var d = new Date(startTime);
        var hr = d.getHours() < 10 ? "0" : "";
        var min = d.getMinutes() < 10 ? "0" : "";
        var sec = d.getSeconds() < 10 ? "0" : "";
        var month = d.getMonth() + 1;

        var date = month + "/" + d.getDate() + "/" + d.getFullYear() + " " + d.toLocaleTimeString('en-US');

        if (startTime == "" || startTime == null)
            date = "";

        return (date);
    }

    render() {

        if (!this.props.show) {
            return null;
        }
        else {

            let tableContent = this.props.alertList != null && this.props.alertList !== undefined && this.props.alertList.length ?
                <tbody>
                    {this.props.alertList.map((alert, index) =>
                        <tr className="alerts-table-row" key={`${alert.alertId}-${index}`} data={index} style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>
                            <td>{alert.alertId}</td>
                            <td>{this.getDateStr(alert.alert.startTime)}</td>
                            <td>{this.getDateStr(alert.alert.endTime)}</td>
                        </tr>
                    )}
                </tbody>
                : <tbody>
                    <tr className="alerts-table-row" style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>
                        <td>{this.props.loading ? "Fetching alerts..." : "No summarized alerts"}</td>
                        <td></td>
                    </tr>
                </tbody>

            let table = <table className="table-hover" style={{ width: '100%' }}>
                <thead>
                    <tr className="alerts-table-row">
                        <th>ALERT ID</th>
                        <th>START TIME</th>
                        <th>END TIME</th>
                    </tr>
                </thead>
                {tableContent}
            </table>;


            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Summarized Alerts</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ padding: '20px 60px' }}>
                            {table}
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Done</button>
                            
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default SummarizedAlertsModal;
