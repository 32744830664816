/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import "../../CSS/DataModal.css";
import "react-datepicker/dist/react-datepicker.css";
import { Image, Spinner } from 'react-bootstrap';

const animatedComponents = makeAnimated();

const customStyles = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: () => ({
        width: '220px',
        color: 'rgba(0, 0, 0, 0.65)'
    })
}

class CreateJobModal extends Component {

    constructor(props) {
        super(props);
       
        this.onChangeTags = this.onChangeTags.bind(this);
        this.locationSearch = this.locationSearch.bind(this);
        this.onEnableEditforLocation = this.onEnableEditforLocation.bind(this);
        this.onEnableEditforCustomer = this.onEnableEditforCustomer.bind(this);
        this.onResetClick = this.onResetClick.bind(this);
        this.state = {
            showComments: false,
            selectedTags: [],
            selectedTagIds: [],
            isEditEnbaleForLocation : true,
            isEditEnbaleFoCustomer :true
        }
    }

    onCancelClick() { }
    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onResetClick(e,type) {
        e.preventDefault();
        this.props.onResetClick(type);
    };

    onEnableEditforLocation(e) {
        e.preventDefault();
        this.setState({
            isEditEnbaleForLocation: !this.state.isEditEnbaleForLocation
        });
     };

    onEnableEditforCustomer(e) {
        e.preventDefault();
        this.setState({
            isEditEnbaleFoCustomer: !this.state.isEditEnbaleFoCustomer
        });
    };

    onChangeTags = (selectedOptions) => {
        let tags = selectedOptions.map(s => s.name);
        let tagIds = selectedOptions.map(s => s.id);

        this.setState({ selectedTags: selectedOptions, selectedTagIds: tagIds }, () => {
            this.props.onChangeTags(this.state.selectedTags);
        });

    }

    getOptionLabel = (option) => {
        return (option["name"]);
    }

    getOptionValue = (option) => {
        return (option["id"]);
    }
    locationSearch = (e, type ) => {
        e.preventDefault();
        if (type == "location") {
            if (this.props.selectedLocation?.state?.length > 0 || this.props.selectedLocation?.name?.length > 0 || this.props.selectedLocation?.street?.length > 0 ||
                this.props.selectedLocation?.city?.length > 0 || this.props.selectedLocation?.zip?.length > 0) {
                this.props.onLocationSearch(type);
            }
        }
        if (type == "customer") {
            if (this.props.selectedCustomer?.state?.length > 0 || this.props.selectedCustomer?.name?.length > 0 || this.props.selectedCustomer?.street?.length > 0 ||
                this.props.selectedCustomer?.city?.length > 0 || this.props.selectedCustomer?.zip?.length > 0) {
                this.props.onLocationSearch(type);
            }
        }
        if (type == "customerId" || type == "locationId") { this.props.onLocationSearch(type); }
	
    }

    render() {
        const { classes } = this.props;
        const Loading = this.props.loadingCreateJob;
        if (!this.props.show) {
            return null;
        }
        else {
            let location =
                this.props.hasServiceTitan ?
                    <div > 
                        <div className="container">
                            <div className="stHeader-container">
                        <h5>Location - ({this.props.locationId == 0 ? "Home Addres" : this.props.locationList?.find(x => x.id == this.props.locationId)?.name}) </h5>
                        </div>
                            <div className="stClear-container">
                                <button className="stClear-btn btn-small" onClick={e => { this.onResetClick(e,"location"); }}>Reset Location</button>
		            </div>
                           
                        </div>
                        <div className="row">
                            <div className="col">
                                <div style={{ paddingRight: 2 + "px" }}>
                                    <p className="formLbl">Name</p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="L-Name"
                                        value={this.props.selectedLocation.name}
                                        disabled={Loading ? "disabled" : ""}
                                        style={{ backgroundColor: (Loading) ? "#D3D3D3" : "" }}
                                        onChange={e => this.props.onLocationObjChange(e, "name", "location")}
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div style={{ paddingRight: 2 + "px" }}>
                                    <p className="formLbl">Street</p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="L-Street"
                                        value={this.props.selectedLocation.street}
                                        disabled={Loading ? "disabled" : ""}
                                        style={{ backgroundColor: (Loading) ? "#D3D3D3" : "" }}
                                        onChange={e => this.props.onLocationObjChange(e, "street", "location")}
                                    />
                                </div>
                            </div>

                            <div className="col">
                                <div style={{ paddingRight: 2 + "px" }}>
                                    <p className="formLbl">City</p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="location-City"
                                        value={this.props.selectedLocation.city}
                                        disabled={Loading ? "disabled" : ""}
                                        style={{ backgroundColor: (Loading) ? "#D3D3D3" : "" }}
                                        onChange={e => this.props.onLocationObjChange(e, "city", "location")}
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div style={{ paddingRight: 2 + "px" }}>
                                    <p className="formLbl">State</p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="location-State"
                                        value={this.props.selectedLocation.state}
                                        disabled={Loading ? "disabled" : ""}
                                        style={{ backgroundColor: (Loading) ? "#D3D3D3" : "" }}
                                        onChange={e => this.props.onLocationObjChange(e, "state", "location")}
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div style={{ paddingRight: 2 + "px" }}>
                                    <p className="formLbl">Zip</p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="location-zip"
                                        value={this.props.selectedLocation.zip}
                                        disabled={Loading ? "disabled" : ""}
                                        style={{ backgroundColor: (Loading) ? "#D3D3D3" : "" }}
                                        onChange={e => this.props.onLocationObjChange(e, "zip", "location")}
                                    />
                                </div>
                            </div>
                            <div>
                                <div style={{ paddingRight: 2 + "px", paddingTop: 32 + "px" }}>
                                    <button style={{ backgroundColor: "white", height: 36 + 'px', border: 0 + 'px', verticalAlign: "top", float: "right" }} onClick={e => this.locationSearch(e, "location")}  >
                                        <Image src={require('../../img/search-icon.png')} style={{ width: 30 + 'px', height: 30 + 'px', display: "inline-block" }} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="formLbl">Location Results <b>({(Loading) ? "Loading..." : this.props.locationList?.length > 0 ? this.props.locationList?.length : 0})</b> </p>
                                <select className="selectpicker-modal" style={{ width: '100%', height: 40 + 'px' }} value={this.props.locationId} onChange={e => this.props.onlocationChange(e, "location")}>
                                    <option key="0" id="0" defaultValue hidden value={0}>--Select Locations--</option>
                                    {this.props.locationList?.map((obj, index) =>
                                        <option key={`${index}-${obj.id}`} id={`${index}-${obj.id}`} value={obj.id}>{obj.name}</option>
                                    )}
                                </select>
                            </div>
                            <div className="col">
                                <div style={{ paddingRight: 2 + "px", width: 60 + "%", display: "inline-block" }}>
                                    <p className="formLbl">Location Record</p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{ backgroundColor: (this.state.isEditEnbaleForLocation) ? "#D3D3D3" : "" }}
                                        id="message"
                                        value={this.props.locationId}
                                        disabled={(this.state.isEditEnbaleForLocation) ? "disabled" : ""}

                                        onChange={e => this.props.onLocationObjChange(e, "locationId")}
                                    />
                                </div>
                                <div style={{ paddingRight: 2 + "px", paddingTop: 32 + "px", display: "inline-block" }}>
                                    {(!this.state.isEditEnbaleForLocation) ?
                                        (
                                            <div> <button style={{ backgroundColor: "white", height: 36 + 'px', border: 0 + 'px', verticalAlign: "top", float: "right", paddingTop: 10 + "px" }} onClick={e => { this.onEnableEditforLocation(e); }} >
                                                <Image src={require('../../img/cancel.png')} style={{ width: 30 + 'px', height: 30 + 'px', display: "inline-block" }} />
                                            </button>
                                                <button style={{ backgroundColor: "white", height: 36 + 'px', border: 0 + 'px', verticalAlign: "top", float: "right", paddingTop: 10 + "px" }} onClick={e => { this.locationSearch(e, "locationId")}} >
                                                    <Image src={require('../../img/search-icon.png')} style={{ width: 30 + 'px', height: 30 + 'px', display: "inline-block" }} />
                                                </button>
                                            </div>
                                        ) :
                                        (
                                            <button style={{ backgroundColor: "white", height: 36 + 'px', border: 0 + 'px', verticalAlign: "top", float: "right", paddingTop: 10 + "px" }} onClick={e => { this.onEnableEditforLocation(e); }} >
                                                <Image src={require('../../img/edit.png')} style={{ width: 30 + 'px', height: 30 + 'px', display: "inline-block" }} />
                                            </button>
                                        )
                                    }
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div>{null}</div>;
            let customer = this.props.hasServiceTitan ?

                <div >
                    <div className="container">
                        <div className="stHeader-container">
                             <h5>Customer - ({this.props.customerID == 0 ? "Customer Name" : this.props.customerList?.find(x => x.id == this.props.customerID)?.name})</h5>
                          </div>
                        <div className="stClear-container">
                            <button className="stClear-btn btn-small" onClick={e => { this.onResetClick(e,"customer"); }}>Reset Customer</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div style={{ paddingRight: 2 + "px" }}>
                                <p className="formLbl">Name</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="message"
                                    value={this.props.selectedCustomer.name}
                                    disabled={Loading ? "disabled" : ""}
                                    style={{ backgroundColor: (Loading) ? "#D3D3D3" : "" }}
                                    onChange={e => this.props.onLocationObjChange(e, "name", "customer")}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div style={{ paddingRight: 2 + "px" }}>
                                <p className="formLbl">Street</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="locatio"
                                    value={this.props.selectedCustomer.street}
                                    disabled={Loading ? "disabled" : ""}
                                    style={{ backgroundColor: (Loading) ? "#D3D3D3" : "" }}
                                    onChange={e => this.props.onLocationObjChange(e, "street", "customer")}
                                />
                            </div>
                        </div>

                        <div className="col">
                            <div style={{ paddingRight: 2 + "px" }}>
                                <p className="formLbl">City</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="location-City"
                                    value={this.props.selectedCustomer.city}
                                    disabled={Loading ? "disabled" : ""}
                                    style={{ backgroundColor: (Loading) ? "#D3D3D3" : "" }}
                                    onChange={e => this.props.onLocationObjChange(e, "city", "customer")}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div style={{ paddingRight: 2 + "px" }}>
                                <p className="formLbl">State</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="location-State"
                                    value={this.props.selectedCustomer.state}
                                    disabled={Loading ? "disabled" : ""}
                                    style={{ backgroundColor: (Loading) ? "#D3D3D3" : "" }}
                                    onChange={e => this.props.onLocationObjChange(e, "state", "customer")}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div style={{ paddingRight: 2 + "px" }}>
                                <p className="formLbl">Zip</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="location-zip"
                                    value={this.props.selectedCustomer.zip}
                                    disabled={Loading ? "disabled" : ""}
                                    style={{ backgroundColor: (Loading) ? "#D3D3D3" : "" }}
                                    onChange={e => this.props.onLocationObjChange(e, "zip", "customer")}
                                />
                            </div>
                        </div>
                        <div>
                            <div style={{ paddingRight: 2 + "px", paddingTop: 32 + "px" }}>
                                <button style={{ backgroundColor: "white", height: 36 + 'px', border: 0 + 'px', verticalAlign: "top", float: "right" }} onClick={e => this.locationSearch(e, "customer")}  >
                                    <Image src={require('../../img/search-icon.png')} style={{ width: 30 + 'px', height: 30 + 'px', display: "inline-block" }} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p className="formLbl">Customer Results<b>({(Loading) ? "Loading..." : this.props.customerList?.length > 0 ? this.props.customerList?.length : 0})</b> </p>
                            <select className="selectpicker-modal" style={{ width: '100%', height: 40 + 'px' }} value={this.props.customerID} onChange={e => this.props.onlocationChange(e, "customer")}>
                                <option key="0" id="0" defaultValue hidden value={0}>--Select Customer--</option>
                                {this.props.customerList?.map((obj, index) =>
                                    <option key={`${index}-${obj.id}`} id={`${index}-${obj.id}`} value={obj.id}>{obj.name}</option>
                                )}
                            </select>
                            {(this.props.isInValidCustomerId) && (<label style={{ color: 'red' }} >The location and customer IDs entered are not associated</label>)}
                        </div>
                        <div className="col">
                            <div style={{ paddingRight: 2 + "px", width: 60 + "%", display: "inline-block" }}>
                                <p className="formLbl">Customer Record</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    style={{ backgroundColor: (this.state.isEditEnbaleFoCustomer) ? "#D3D3D3" : "" }}
                                    id="message"
                                    value={this.props.customerID}
                                    disabled={(this.state.isEditEnbaleFoCustomer) ? "disabled" : ""}
                                    onChange={e => this.props.onLocationObjChange(e, "customerId")}
                                />
                            </div>
                            <div style={{ paddingRight: 2 + "px", paddingTop: 32 + "px", display: "inline-block" }}>
                                {(!this.state.isEditEnbaleFoCustomer) ?
                                    (
                                        <div> <button style={{ backgroundColor: "white", height: 36 + 'px', border: 0 + 'px', verticalAlign: "top", float: "right", paddingTop: 10 + "px" }} onClick={e => { this.onEnableEditforCustomer(e); }} >
                                            <Image src={require('../../img/cancel.png')} style={{ width: 30 + 'px', height: 30 + 'px', display: "inline-block" }} />
                                        </button>
                                            <button style={{ backgroundColor: "white", height: 36 + 'px', border: 0 + 'px', verticalAlign: "top", float: "right", paddingTop: 10 + "px" }} onClick={e => { this.locationSearch(e, "customerId") }} >
                                                <Image src={require('../../img/search-icon.png')} style={{ width: 30 + 'px', height: 30 + 'px', display: "inline-block" }} />
                                            </button>
                                        </div>) :
                                    (<button style={{ backgroundColor: "white", height: 36 + 'px', border: 0 + 'px', verticalAlign: "top", float: "right", paddingTop: 10 + "px" }} onClick={e => { this.onEnableEditforCustomer(e); }} >
                                        <Image src={require('../../img/edit.png')} style={{ width: 30 + 'px', height: 30 + 'px', display: "inline-block" }} />
                                    </button>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                : null;


            let comments = this.state.showComments ?
                <div style={{ paddingRight: 2 + "px" }}>
                    <p className="formLbl">Comments</p>
                    <input
                        type="text"
                        className="form-control"
                        id="message"
                        value={this.props.editAlert.comments}
                        onChange={this.props.onCommentsChange}
                    />
                </div>
                : <div>{null}</div>;
            let followUpDate = this.props.hasServiceTitan ?
                <div style={{ paddingRight: '2px' }}>
                    <p className="formLbl">Follow Up Date</p>
                    <DatePicker
                        customInput={<input className="form-control" style={{ paddingRight: '2px' }} />}
                        id="followUpDate"
                        name="followUpDate"
                        selected={this.props.followUpDate}
                        onChange={date => { this.props.onFollowUpDateChange(date); }}
                        dateFormat="yyyy-MM-dd"
                        popperPlacement="top-end"
                        popperProps={{
                            positionFixed: true,
                            zIndex: '999999999999 !important'
                        }}
                    />
                </div>
                : null;

            let jobTypeSelect = this.props.hasServiceTitan && this.props.jobTypesList.length ?
                <div style={{ paddingRight: 2 + "px" }}>
                    <p className="formLbl">Job Type (optional)</p>
                    <select className="selectpicker-modal" style={{ width: '220px' }} value={this.props.jobTypeId} onChange={this.props.onJobTypeChange}>
                        <option key="0" id="0" defaultValue hidden value={0}>--Select Job Type--</option>
                        {this.props.jobTypesList.map((obj, index) =>
                            <option key={`${index}-${obj.id}`} id={`${index}-${obj.id}`} value={obj.id}>{obj.name}</option>
                        )}
                    </select>
                </div>
                : null;

            let tagSelect = this.props.hasServiceTitan && this.props.tagsList.length ?
                <div style={{ paddingRight: 2 + "px" }}>
                    <p className="formLbl">Tags (optional)</p>
                    <Select
                        styles={this.customStyles}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        isMulti
                        options={this.props.tagsList}
                        getOptionLabel={this.getOptionLabel}
                        getOptionValue={this.getOptionValue}
                        onChange={this.onChangeTags}
                        value={this.props.selectedTags}
                        placeholder="No Tags"
                    />
                </div>
                : null;

            let callReasonSelect = this.props.hasServiceTitan && this.props.callReasonsList.length ?
                <div style={{ paddingRight: 2 + "px" }}>
                    <p className="formLbl">Call Reason (optional)</p>
                    <select className="selectpicker-modal" style={{ width: '220px' }} value={this.props.callReasonId} onChange={this.props.onCallReasonChange}>
                        <option key="0" id="0" defaultValue hidden value={0}>--Select Call Reason--</option>
                        {this.props.callReasonsList.map((obj, index) =>
                            <option key={`${index}-${obj.id}-callReason`} id={`${index}-${obj.id}`} value={obj.id}>{obj.name}</option>
                        )}
                    </select>
                </div>
                : null;

            let campaignIdSelect = this.props.hasServiceTitan && this.props.campaignIdsList.length ?
                <div style={{ paddingRight: 2 + "px" }}>
                    <p className="formLbl">Campaign</p>
                    <select className="selectpicker-modal" style={{ width: '220px' }} value={this.props.campaignId} onChange={this.props.onCampaignChange}>
                        <option key="0" id="0" defaultValue hidden value={0}>--Select Campaign--</option>
                        {this.props.campaignIdsList.map((obj, index) =>
                            <option key={`${index}-${obj.id}-campaign`} id={`${index}-${obj.id}`} value={obj.id}>{obj.name}</option>
                        )}
                    </select>
                </div>
                : null;



            let renderContent =

                (< div className="modal" style={{ display: this.props.show ? "block" : "none" }
                }>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Create Job</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body-2" style={{ textAlign: "left" }}>
                            <br />
                            <form>
                                <div className="row">
                                    <div className="col">
                                        {location}
                                        {customer}
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Subject</p>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="subject"
                                                value={this.props.editAlert.message}
                                                onChange={this.props.onSubjectChange}
                                            />
                                        </div>
                                        {comments}
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Priority</p>
                                            <select className="selectpicker-modal" style={{ width: '220px' }} value={this.props.createJob.priority} onChange={this.props.onPriorityOpportunityChange}>
                                                <option key="0" id="0" defaultValue hidden value={0}>--Select Priority--</option>
                                                <option key="1" id="1" value={3}>Low</option>
                                                <option key="2" id="2" value={2}>Medium</option>
                                                <option key="3" id="3" value={1}>High</option>
                                            </select>
                                        </div>

                                        {followUpDate}
                                        {campaignIdSelect}
                                        {jobTypeSelect}
                                        {callReasonSelect}
                                        {tagSelect}
                                    </div>
                                </div>
                            </form>
                            <br />
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={this.props.onSave}>Create Job</button>
                        </div>
                    </div>
                </div >);

            //let contents =
            //<div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
            //<div className="loading-wrapper">
            //    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
            //</div>
            //</div>
            //renderContent;
            let onLoadingSubmitServiceTitan = this.props.onSTLoading ?
                <div className="loading" style={{ display: "block", marginLeft: '0', width: '100%', backgroundColor: 'rgba(0,0,0,0.65)' }}>
                    <div className="loading-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', top: '15%' }}>
                        <div className="loading-body" style={{ display: 'block' }}>
                            <Spinner animation="border" variant="light" />
                            <p style={{ color: 'white', marginTop: '10px' }}> Please wait... </p>
                        </div>
                    </div></div>
                : <div>{null}</div>

            return (
                <div>
              <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                        <div className="modal-wrapper-servicetitan"
                    style={{
                        transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                        opacity: this.props.show ? '1' : '0'
                    }}>

                    <div className="modal-header">
                        <h3>Create Job</h3>
                        <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                    </div>

                    <div className="modal-body-2" style={{ textAlign: "left" }}>
                        <br />
                        <form>
                            <div className="row">
                                <div className="col">
                                    {location}
                                    {customer}
                                    <div style={{ paddingRight: 2 + "px" }}>
                                        <p className="formLbl">Subject</p>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="subject"
                                            value={this.props.editAlert.message}
                                            onChange={this.props.onSubjectChange}
                                        />
                                    </div>
                                    {comments}
                                    <div style={{ paddingRight: 2 + "px" }}>
                                        <p className="formLbl">Priority</p>
                                        <select className="selectpicker-modal" style={{ width: '220px' }} value={this.props.createJob.priority} onChange={this.props.onPriorityOpportunityChange}>
                                            <option key="0" id="0" defaultValue hidden value={0}>--Select Priority--</option>
                                            <option key="1" id="1" value={3}>Low</option>
                                            <option key="2" id="2" value={2}>Medium</option>
                                            <option key="3" id="3" value={1}>High</option>
                                        </select>
                                    </div>

                                    {followUpDate}
                                    {campaignIdSelect}
                                    {jobTypeSelect}
                                    {callReasonSelect}
                                    {tagSelect}
                                </div>
                            </div>
                        </form>
                        <br />
                    </div>

                    <div className="modal-footer">
                        <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                        <button className="primary-btn btn-small" onClick={this.props.onSave}>Create Job</button>
                    </div>
                    {onLoadingSubmitServiceTitan}
                </div>
            </div>
                </div>
            );
        }
    }
}


export default CreateJobModal;