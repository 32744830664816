/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';

class SelectPartner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partners: []
        }
    }

    render() {
        if (this.props.partners != null) {

            this.props.partners.sort((a, b) => a.company.localeCompare(b.company, undefined, { numeric: true }));

            let select = <select className="selectpicker" style={{ width: 200 + 'px', height: 30 + 'px', paddingLeft: 13 + 'px', paddingRight: 18 + 'px' }} onChange={this.props.onPartnerSelect} value={this.props.selectedPartner}>
                    {
                        this.props.partners.map((obj, index) =>
                            <option key={`${index}-${obj.organizationId}`} id={`${index}-${obj.organizationId}`} value={obj.organizationId}>{obj.company}</option>
                        )
                    }
                </select>;

            let partnerPicker = this.props.inFaveModal ?
                <div style={{ marginBottom: '10px' }}>
                    <span className="select-message" style={{ paddingLeft: '0px' }}>Select from partner:
                        {select}
                    </span>
                    <br/>
                </div>
                : <div>
                    <span className="select-message">Partners</span>
                    <br />
                    {select}
                </div>;

            return (
                <div className="select-container">
                    {partnerPicker}
                </div>
            );
        }
        else {
            return (null);
        }
    }
}
export default SelectPartner;

