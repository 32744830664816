/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { PageView, Event } from "../GoogleAnalytics";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import alerttypemessagingService from '../../services/alerttypemessaging.service'; 

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

function CustomizeAlerts(props) {
    
    const { classes, isLoading, currentPartnerId, _isMounted, setActivePath, showUnsavedModal, onContinueFromComponent } = props;
    const [isMounted, setIsMounted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [typeValidated, setTypeValidated] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [alertMessaging, setAlertMessaging] = useState([]);
    const [customMessaging, setCustomMessaging] = useState({});
    const [isCustom, setIsCustom] = useState(false);
    const [showUnsaved, setShowUnsaved] = useState(null);
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [showRestoreConfirm, setShowRestoreConfirm] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");

    function getStorageValue(key, defaultValue, isJson) {
        const saved = localStorage.getItem(key);
        var value = saved !== null ? saved : defaultValue;
        if (isJson && value !== defaultValue && value !== "") {
            try {
                value = JSON.parse(value);
            }
            catch(e) {
                console.log(e);
                console.log(`error parsing value: ${value}`);
            }
        }
        return value;
    }

    const populateInfo = (unsaved) => {
        setLoading(true);
        if (_isMounted && isMounted) {
            PageView();
            if (!unsaved) {
                alerttypemessagingService.getpartnermessaging(currentPartnerId)
                .then(response => {
                    if (_isMounted && isMounted) {
                        setAlertMessaging(response);
                        setSelectedIndex(0);
                        const customPartners = response[selectedIndex] !== null && response[selectedIndex] !== undefined && response[selectedIndex].customPartners !== null ? response[selectedIndex].customPartners : "";
                        const isCustom = customPartners.length ? customPartners.includes(parseInt(currentPartnerId)) : false;
                        setCustomMessaging(response[selectedIndex]);
                        setIsCustom(isCustom);
                        localStorage.setItem('alertMessaging', JSON.stringify(response));
                        //set local storage?
                        updateLocalStorage(unsaved,customMessaging);
                        setLoading(false);
                    }
                })
                .catch(e => {
                    console.log(e);
                })
            }
            else {
                const alertMessaging = getStorageValue('alertMessaging', '[]', true);
                const customIndex = parseInt(getStorageValue('customIndex', 0));
                const customMessaging = getStorageValue('customMessaging', '{}', true);
                const isCustom = customMessaging !== '{}' && customMessaging.customPartners !== null && customMessaging.customPartners.length ? customMessaging.customPartners.includes(parseInt(currentPartnerId)) : false;
                setAlertMessaging(alertMessaging);
                setSelectedIndex(customIndex);
                setCustomMessaging(customMessaging);
                setIsCustom(isCustom);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        setIsMounted(true);
        PageView();

        if (!isLoading) {
            let unsaved = getStorageValue('unsavedChanges', false);
            unsaved = unsaved === 'true' || unsaved === true ? true : false;
            populateInfo(unsaved);
        }

        return () => {
            setIsMounted(false);        
        };
    }, [isMounted]);

    const updateLocalStorage = (unsavedChanges, data, index = null) => {
        localStorage.setItem('unsavedChanges', unsavedChanges);
        localStorage.setItem('customMessaging', JSON.stringify(data));
        localStorage.setItem('customIndex', index == null ? selectedIndex : index);
        if (isMounted) {
            setShowSnackbar(false);
        }
    }

    // inputs
    const onSelectAlertType = (e) => {
        const typeIndex = e.target.value;
        const newType = alertMessaging[typeIndex];
        setSelectedIndex(typeIndex);
        const customData = { ...customMessaging, 
            id: newType.id,
            typeName: newType.typeName,
            alertMessage: newType.alertMessage,
            severity: newType.severity,
            possibleCauses: newType.possibleCauses,
            risk: newType.risk,
            techAction: newType.techAction,
            homeownerAction: newType.homeownerAction,
            customPartners: newType.customPartners
        };
        const typePartners = newType.customPartners !== null ? newType.customPartners : [];
        const typeCustom = typePartners.length ? typePartners.includes(parseInt(currentPartnerId)) : false; 
        updateLocalStorage(false, customData, typeIndex);
        setCustomMessaging(customData);
        setIsCustom(typeCustom);
    }

    const onChangeMessage = (e) => {
        const customData = { ...customMessaging, alertMessage: e.target.value };
        updateLocalStorage(true, customData);
        setCustomMessaging(customData);
    }

    const onChangeSeverity = (e) => {
        const customData = { ...customMessaging, severity: e.target.value };
        updateLocalStorage(true, customData);
        setCustomMessaging(customData);
    }

    const onChangePossibleCauses = (e) => {
        const customData = { ...customMessaging, possibleCauses: e.target.value };
        updateLocalStorage(true, customData);
        setCustomMessaging(customData);
    }

    const onChangeRisk = (e) => {
        const customData = { ...customMessaging, risk: e.target.value };
        updateLocalStorage(true, customData);
        setCustomMessaging(customData);
    }

    const onChangeTechAction = (e) => {
        const customData = { ...customMessaging, techAction: e.target.value };
        updateLocalStorage(true, customData);
        setCustomMessaging(customData);
    }

    const onChangeHomeownerAction = (e) => {
        const customData = { ...customMessaging, homeownerAction: e.target.value };
        updateLocalStorage(true, customData);
        setCustomMessaging(customData);
    }
    
    //restore and save

    const restoreMessaging = async () => {
        setLoading(true);
        const response = await alerttypemessagingService.restore(parseInt(currentPartnerId), customMessaging.id);
        if (response != null && response != undefined) {
            const customData = response;
            let newMessaging = [...alertMessaging];
            newMessaging[selectedIndex] = customData;
            alertMessaging[selectedIndex] = customData;
            setAlertMessaging(newMessaging);
            setIsCustom(false);
            setShowRestoreConfirm(false);
            updateLocalStorage(false, customData);
            setLoading(false);
        }
    }

    const handleRestore = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setShowRestoreConfirm(true);
    }

    const updateMessaging = async (goNextPage) => {
        const response = await alerttypemessagingService.setpartnermessaging(parseInt(currentPartnerId), customMessaging);
        if (response != null && response != undefined && response != false) {
            setStatusMessage("Successfully updated partner alert messaging");
            let newMessaging = [...alertMessaging];
            newMessaging[selectedIndex] = customMessaging;
            alertMessaging[selectedIndex] = customMessaging;
            setAlertMessaging(newMessaging);
            setIsCustom(true);
            updateLocalStorage(false, customMessaging);
        }
        else {
            setStatusMessage("Failed to update partner alert messaging.");
        }
        setTypeValidated(false);
        setLoading(false);
        setShowSnackbar(true);
        if (goNextPage)
            onContinueWithoutSaving();
    }

    const updateType = () => {
        setLoading(true);
        updateMessaging(false);
        setShowSaveConfirm(false);
    }

    const onSaveType = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
            event.stopPropagation();
        if (form.checkValidity() === false) {
            setTypeValidated(false);
        }
        else {
            setTypeValidated(true);
            setLoading(true);
            setShowSaveConfirm(true);
        }
    }

    const onSaveThenContinue = e => {
        localStorage.setItem("showUnsavedModal", false);
        setLoading(true);
        setShowUnsaved(false);
        const customData = getStorageValue("customMessaging", '{}', true);
        const customIndex = parseInt(getStorageValue('customIndex', 0));
        setCustomMessaging(customData);
        setSelectedIndex(customIndex);
        //validate first
        updateMessaging(true);
    }

    const onContinueWithoutSaving = e => {
        localStorage.setItem("showUnsavedModal", false);
        updateLocalStorage(false, {}, 0);
        onContinueFromComponent(continueCallback);
    }

    const continueCallback = () => {
        const nextPath = getStorageValue('nextPath', "/customizeAlerts");
        props.history.push(nextPath);
        setActivePath(nextPath);
    }

    const setShowModal = (e) => {
        setShowSaveConfirm(!showSaveConfirm);
    }

    const setShowRestoreModal = (e) => {
        setShowRestoreConfirm(!setShowRestoreConfirm);
    }

    let contents = loading 
    ? <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
        <div className="loading-wrapper">
            <div className="modal-body"><Spinner animation="border" variant="light" /></div>
        </div>
    </div>
    : <div>
        <div style={{ margin: '15px 30px', padding: '15px 25px 20px', border: '2px solid #ebeef0', borderRadius: '.3rem' }}>
            <Form noValidate validated={typeValidated} onSubmit={onSaveType}>
                <Row style={{ borderBottom: '2px solid #ebeef0' }}>
                    <Form.Group as={Col} md="4" offset="4" controlId="alertType2">
                        <Form.Label>Alert Type</Form.Label>
                        <Form.Control as="select" value={selectedIndex} onChange={onSelectAlertType}>
                            <option value={-1} disabled defaultValue hidden>Select Alert Type...</option>
                            {alertMessaging.map((type, index) => 
                                <option key={`alertType=${index}-2`} value={index}>{type.typeName}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group as={Col} md="6" controlId="alertMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control required type="text" placeholder="" value={customMessaging.alertMessage} onChange={onChangeMessage} />
                        <Form.Control.Feedback type="invalid">Alert Message is required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="severity">
                        <Form.Label>Severity</Form.Label>
                        <Form.Control required as="select" value={customMessaging.severity} onChange={onChangeSeverity} isInvalid={(typeValidated && customMessaging.alertTypeId == 0) ? true : false}>
                            <option value="" disabled defaultValue hidden>Select Severity...</option>
                            <option value={"Critical"}>Critical</option>
                            <option value={"Warning"}>Warning</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Severity is required</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="6" controlId="possibleCauses">
                        <Form.Label>Possible Causes</Form.Label>
                        <Form.Control required type="text" placeholder="" value={customMessaging.possibleCauses} onChange={onChangePossibleCauses} />
                        <Form.Control.Feedback type="invalid">Possible Causes required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="risk">
                        <Form.Label>Risk</Form.Label>
                        <Form.Control required type="text" placeholder="" value={customMessaging.risk} onChange={onChangeRisk} />
                        <Form.Control.Feedback type="invalid">Risk is required</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="6" controlId="techAction">
                        <Form.Label>Technician Action</Form.Label>
                        <Form.Control required type="text" placeholder="" value={customMessaging.techAction} onChange={onChangeTechAction} />
                        <Form.Control.Feedback type="invalid">Technician Action is required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="homeownerAction">
                        <Form.Label>Homeowner Action</Form.Label>
                        <Form.Control required type="text" placeholder="" value={customMessaging.homeownerAction} onChange={onChangeHomeownerAction} />
                        <Form.Control.Feedback type="invalid">Homeowner Action is required</Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Button type="submit" style={{ backgroundColor: '#0033a0', borderColor: '#0033a0' }}>Save</Button>
                <Button type="button" onClick={handleRestore} style={{ backgroundColor: 'white', borderColor: '#0033a0', color: '#0033a0', marginLeft: '5px', display: isCustom ? 'unset' : 'none' }}>Restore Default</Button>
            </Form>
        </div>
    </div>;

    return (
        <div className={classes.root}>
            <div className='infoPage'>
                <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>Customize Alert Messaging</h2>
                {contents}

                <ConfirmationModal 
                    show={showSaveConfirm} 
                    onYes={updateType} 
                    onClick={setShowModal} 
                    message={"Would you like to save your changes to this alert type's messaging?"} 
                    title={"Save Changes"}></ConfirmationModal>

                <ConfirmationModal 
                    show={showRestoreConfirm} 
                    onYes={restoreMessaging} 
                    onClick={setShowRestoreModal} 
                    message={"Would you like to restore this alert type's messaging to deafult?"} 
                    title={"Restore Default"}></ConfirmationModal>

                <ConfirmationModal 
                    show={showUnsaved == null ? localStorage.getItem('showUnsavedModal') === 'true' : showUnsavedModal} 
                    onYes={onSaveThenContinue} 
                    onClick={onContinueWithoutSaving} 
                    message={"You have unsaved changes, would like to save before leaving?"} 
                    title={"Unsaved Changes"}></ConfirmationModal>

                <Snackbar open={showSnackbar} message={statusMessage} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} />
            </div>
        </div>
    );
}

export default withStyles(styles) (CustomizeAlerts);