/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import styled from "styled-components";

import { Figure } from 'react-bootstrap';

const SideIcon = styled.div`
    padding-top:10px;
    margin-top: 20px;
`;

const StyledSideItem = styled.div`
  height: auto;
  width: 95%; /* width must be same size as Sidebar to center */
  text-align: left; /* Aligns <a> inside of SideIcon div */
  margin-bottom: 0;   /* Puts space between SideItems */
  margin-left: 10px;
  margin-right: 10px;
  a {
    font-size: 1.2em;
    color: black;
    }  
  }
`;

/* This defines the actual bar going down the screen */
const StyledSidebar = styled.div`
  position: fixed;     /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
  max-height: 95vH;
  width: 175px;     /* Set the width of the sidebar */
  z-index: 1;      /* Stay on top of everything */

  background-color: clear; /* Black */
  overflow-x: hidden;     /* Disable horizontal scroll */
  overflow-y: hidden;
  padding-top: 25px;
`;

export class GatewaySidebar extends React.Component {
    
    render() {
        return (
            <StyledSidebar>
                <StyledSideItem>
                    <h5> Gateway</h5>
                    <h6>Debug Workflow</h6>
                    <SideIcon>
                    <Figure>  
                        <Figure.Image
                            width="auto"
                            src={require('../../../img/Gateway-1.png')}
                            alt='sideImg'
                            padding-top={25}
                            padding-bottom={25}
                            /> 
                    </Figure>
                    </SideIcon>
                    <br/>
                    <b>Pair</b>
                    <p>The Pair button is used to get the gateway to broadcast its network, to reset/erase the current Wi-Fi network configuration or to reset the gateway to factory settings.</p>
                    <b>Reset</b>
                    <p>The Reset button is used to reset the Gateway if it is having problems.</p>
                </StyledSideItem>
            </StyledSidebar>
        );
    }
}
