/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import "../../../CSS/DataModal.css";

class UpdateImageModal extends React.Component {

    constructor(props) {
        super(props);

        this.handleImageChange = this.handleImageChange.bind(this);
        this.onYes = this.onYes.bind(this);

        this.state = {
            file: null,
            updateImgFile: null,
            byteArray: []
        }
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
        this.setState({ file: null, updateImg: null });
    };

    onYes() {
        this.setState({ file: null, updateImg: null });
        this.props.onYes();
    }

    handleImageChange(e) {
        this.setState({ file: URL.createObjectURL(e.target.files[0]), updateImgFile: e.target.files[0] });
        this.props.handleImageChange(e.target.files[0]);
    }

    render() {

        if (!this.props.show) {
            return null;
        }
        else {

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header" >
                            <h3>{this.props.title}</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ textAlign: "center" }}>
                            <p>{this.props.message}</p>
                            <input type="file" id="image" name="image" accept="image/png, image/jpeg, image/jpg" onChange={this.handleImageChange} style={{ paddingLeft: '75px' }}/>
                            <div style={{ justifyContent: "center" }}>
                                <br />
                                <img src={this.state.file} alt="" style={{ maxWidth: '65%', maxHeight: '275px' }}/>
                            </div> 
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={this.onYes}>Upload</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default UpdateImageModal;