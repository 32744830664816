/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import { withStyles } from "@material-ui/core/styles";
import React, {Component} from "react";
import {Spinner} from "react-bootstrap";
import { PageView, ModalView } from "../../GoogleAnalytics";
import CreateUserModal from "./CreateUserModal";


const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

class UserManagement extends Component {
    static displayName = UserManagement.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.showCreateUserModal = this.showCreateUserModal.bind(this);
        
        this.state = {
            loading: false,
            showConfirm: false
        };
    }
    
    componentDidMount() {
        this._isMounted = true;
        PageView();

        if (this.state.loading === false) {
            this.setState({
                loading: true
            }, () => {
                this.setState({
                    loading: false
                })
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    render(){
        const { classes } = this.props;

        let contents = this.state.loading ?
            <div className={["loading", classes.loader].join(' ')} style = {{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : "Create user for mobile";

        return (
            <div className={classes.root}>
                <div className="infoPage">
                    <div className="row no-gutters">
                        <div className="col">
                            <h3 className="pageTitle" id="tableLabel">Mobile Users</h3>
                        </div>
                        <div className="col pageTitle">
                            <div className="btn-right">
                                <button type="Add" className="secondary-btn btn-large" style={{ width: 110 + 'px' }} onClick={this.showCreateUserModal} >Create User</button>
                            </div>
                        </div>
                    </div>
                    {contents}

                    <CreateUserModal onClick={this.showCreateUserModal} show={this.state.showConfirm} onAddUser={this.onAddUser}  ></CreateUserModal>
                </div>
            </div>
        );             
    }

    showCreateUserModal() {
        this.setState({
            showConfirm: !this.state.showConfirm
        }, () => {
            if (this.state.showConfirm)
                ModalView("createUser");
        });
        window.scrollTo(0, 0);
    }
}

export default withStyles(styles)(UserManagement);