/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/servicetitan";
class ServiceTitanService {

    async getservicetitanoptions(partnersId) {
        const response = await http.get(`${controller}/getservicetitanoptions?partnersId=${partnersId}`);
        const data = response;
        return data;
    }

    async getjobtypes(partnersId) {
        const response = await http.get(`${controller}/getjobtypes?partnersId=${partnersId}`);
        const data = response;
        return data;
    }

    async createleadfromportal(partnersId,alertId,portalLead) { //alertId, oppName, priority, followUpDate, typeId, partnersId, customer) {
        const response = await http.post(`${controller}/createleadfromportal?partnersId=${partnersId}&alertId=${alertId}`, portalLead); //alertId=${alertId}&oppName=${oppName}&priority=${priority}&followUpDate=${followUpDate}&jobTypeId=${typeId}&partnersId=${partnersId}`, customer);
        const data = response;
        return data;
    }

    async getScheduleDetails(caseId) {
        const response = await http.get(`${controller}/getScheduleDetails?caseId=${caseId}`);
        const data = response;
        return data;
    }
    async getservicetitanLocation(partnersId, location) {
        const response = await http.post(`${controller}/getservicetitanLocation?partnersId=${partnersId}`, location);
        const data = response;
        return data;
    }

    async getSearchLocationIDorCustomerID(partnersId, id,type) {
        const response = await http.get(`${controller}/getSearchLocationIDorCustomerID?partnersId=${partnersId}&id=${id}&type=${type}`);
        const data = response;
        return data;
    }
    async getservicetitanCustomer(partnersId, customer) {
        const response = await http.post(`${controller}/getservicetitanCustomer?partnersId=${partnersId}`, customer);
        const data = response;
        return data;
    }
    async serviceValidation(serviceData) {
        const response = await http.post(`${controller}/ServiceValidation`, serviceData);
        const data = response;
        return data;
    }
}



export default new ServiceTitanService();
