/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/accountmanagement";
class ForgotPasswordService {
    
    async post(id, isMobile) {
        const payload = {
            email: id
          };
        const response = await http.post(`${controller}/forgotpassword?ismobile=` + isMobile, JSON.stringify(payload));
        const data = response;
        return data.data;
    }

    async forcepasswordreset(resetObj) {
        resetObj.email = encodeURIComponent(resetObj.email);
        resetObj.password = encodeURIComponent(resetObj.password);
        const response = await http.post(`${controller}/forcepasswordreset`, resetObj);
        const data = response;
        return data.data;
    }

    async resetpassword(resetObj) {
        resetObj.email = encodeURIComponent(resetObj.email);
        resetObj.password = encodeURIComponent(resetObj.password);
        resetObj.url = encodeURIComponent(resetObj.url);
        const response = await http.post(`${controller}/resetpassword`, resetObj);
        const data = response;
        return data.data;
    }
}

export default new ForgotPasswordService();