/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../../CSS/DataModal.css";
import InviteUserForm from './InviteUserForm';
import { Spinner } from 'react-bootstrap';

const jsUser = {
    firstName: "",
    lastName: "",
    email: "",
    roleList: "",
    roleListId: [],
    roleListIdStr: ""
};

class InviteUserModal extends Component {
    constructor(props) {
        super(props);

        this.onAddUser = this.onAddUser.bind(this);
        this.onInvite = this.onInvite.bind(this);
        this.handleChangeUser = this.handleChangeUser.bind(this);
        this.isGlobalAdmin = this.isGlobalAdmin.bind(this);

        this.state = {
            numUsers: 0,
            newUsers: [
                {
                    firstName: "",
                    lastName: "",
                    email: "",
                    roleList: "",
                    roleListId: [],
                    roleListIdStr: ""
                }
            ]
        }
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
        this.setState({ numUsers: 0, newUsers: [{ jsUser }] });
    };

    onInvite() {
        this.props.onInvite(this.state.newUsers);
        this.setState({ numUsers: 0, newUsers: [{ jsUser }] });
    }

    handleChangeUser(user, index) {
        var userArray = this.state.newUsers;
        userArray[index] = user;
        this.setState({ newUsers: userArray });

        console.log(userArray);
    }

    onAddUser() {
        this.setState({ numUsers: this.state.numUsers + 1 }, () => {
            var userArray = this.state.newUsers;
            userArray[this.state.numUsers] = jsUser;
            this.setState({ newUsers: userArray });
        });
    }

    isGlobalAdmin() {
        let userRolesList = this.props?.roles;
        if (!Array.isArray(userRolesList)) {
            userRolesList = JSON.parse(userRolesList);
        }
        if (userRolesList?.includes("ADMIN") && userRolesList?.includes("PARTNER ADMIN")) {
            return 3; // 3 for  both Admin && partner admin role 
        }
        else if (userRolesList?.includes("ADMIN")) {
            return 2;  // 2 for admin role 
        }
        else if (userRolesList?.includes("PARTNER ADMIN")) {
            return 1; // 1 for partner admin role 
        }
        else {
            return 0;   // 0 for no admin & partner admin role 
        }
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        else {
            let inviteLoading = this.props.inviteLoading ?
                <div className="loading" style={{ display: "block", marginLeft: '0', width: '100%', backgroundColor: 'rgba(0,0,0,0.65)' }}>
                    <div className="loading-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', top: '35%' }}>
                        <div className="loading-body" style={{ display: 'block' }}>
                            <Spinner animation="border" variant="light" />
                            <p style={{ color: 'white', marginTop: '10px' }}> Please wait, sending invitation emails to users... </p>
                        </div>
                    </div></div>
                : <div>{null}</div>

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Invite User</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body-2" style={{ textAlign: "left" }}>
                            {this.state.newUsers.map((form, index) =>
                                <InviteUserForm index={index} key={index} newUser={this.state.newUsers[index]} handleChangeUser={this.handleChangeUser} isGlobalAdmin={this.isGlobalAdmin} isCustomerAccount={this.props.isCustomerAccount}></InviteUserForm>
                            )}
                            <button className="btn-small secondary-btn" onClick={this.onAddUser} style={{ marginTop: 10 + 'px' }} >+ Add Another User</button>
                        </div>


                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={this.onInvite}>Send Invite</button>
                        </div>
                        {inviteLoading}
                    </div>
                </div>
            );
        }
    }

}

export default InviteUserModal;