/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from "react";
import makeAnimated from "react-select/animated";
import RegisterUserService from "../../../services/registeruser.service";
import {Event} from "../../GoogleAnalytics";
import PhoneNumberInput from "../../Phone/PhoneNumberInput";

const jsUser = {
    firstName: "",
    lastName: "",
    email: "", 
    password: "", 
    confirmPassword: "",
    phoneNumber: ""
};

class CreateUserModal extends Component {
    constructor(props) {
        super(props);

        this.onAddUser = this.onAddUser.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
        this.handleError = this.handleError.bind(this);
        
        this.state = {
            newUser: jsUser,
            error: "",
            isUpdating: false
        }
    }
    handleError(error){
        this.setState({error});
    }
    onChangeFirstName(e) {
        const firstName = e.target.value;

        this.setState(function (prevState) {
            return {
                newUser: {
                    ...prevState.newUser,
                    firstName: firstName
                }
            };
        });
    }

    onChangeLastName(e) {
        const lastName = e.target.value;

        this.setState(prevState => ({
            newUser: {
                ...prevState.newUser,
                lastName: lastName
            }
        }));
    }

    onChangeEmail(e) {
        const email = e.target.value;

        this.setState(prevState => ({
            newUser: {
                ...prevState.newUser,
                email: email
            }
        }));
    }

    onChangePassword(e) {
        const password = e.target.value;

        this.setState(prevState => ({
            newUser: {
                ...prevState.newUser,
                password: password
            }
        }));
    }

    onChangeConfirmPassword(e) {
        const confirmPassword = e.target.value;

        this.setState(prevState => ({
            newUser: {
                ...prevState.newUser,
                confirmPassword: confirmPassword
            }
        }));
    }
    
    onChangePhone(e) {
        const phoneNumber = e;

        this.setState(prevState => ({
            newUser: {
                ...prevState.newUser,
                phoneNumber: phoneNumber
            }
        }));
    }
    
    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
        Event("Cancel Create User", "User cancelled creating new user", "Cancel Create New User");
        this.setState({ newUser:  jsUser  });
    };

    onAddUser() {
        if (!this.state.newUser.phoneNumber || this.state.error) return;
        this.setState({isUpdating: true});
        RegisterUserService.create(this.state.newUser)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        message: "The user was created successfully!"
                    }, () => {
                        this.setState({ newUsers: jsUser });
                    });
                    Event("Create User", "User clicked create button for new user", "Create User Clicked");
                }
                else {
                    this.setState({
                        message: "Failed to delete the user!"
                    });
                }
                this.setState({isUpdating: false});
                setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 3000);
            })
            .catch(e => {
                console.log(e);
                this.setState({isUpdating: false});
            });
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        else {
                return (
                    <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                        <div className="modal-wrapper"
                             style={{
                                 transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                                 opacity: this.props.show ? '1' : '0'
                             }}>
                            <div className="modal-header">
                                <h3>Create User</h3>
                                <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                            </div>
                            <div className="modal-body" style={{ textAlign: "left" }}>
                                <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <form>
                                                    <div style={{ paddingRight: 2 + "px" }}>
                                                        <label htmlFor="description">FirstName</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="firstName"
                                                            onChange={this.onChangeFirstName}
                                                        />
                                                    </div>
                                                    <div style={{ paddingRight: 2 + "px" }}>
                                                        <label htmlFor="description">LastName</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="lastName"
                                                            onChange={this.onChangeLastName}
                                                        />
                                                    </div>
                                                    <div style={{ paddingRight: 2 + "px" }}>
                                                        <label htmlFor="description">email</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            id="email"
                                                            onChange={this.onChangeEmail}
                                                        />
                                                    </div>
                                                    <div style={{ paddingRight: 2 + "px" }}>
                                                        <PhoneNumberInput
                                                            phoneNumber={this.state.newUser.phoneNumber}
                                                            setPhoneNumber={this.onChangePhone}
                                                            onError={this.handleError}
                                                        />
                                                    </div>
                                                    <div style={{ paddingRight: 2 + "px" }}>
                                                        <label htmlFor="description">password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            id="password"
                                                            onChange={this.onChangePassword}
                                                        />
                                                    </div>
                                                    <div style={{ paddingRight: 2 + "px" }}>
                                                        <label htmlFor="description">confirm password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            id="confirmPassword"
                                                            onChange={this.onChangeConfirmPassword}
                                                        />
                                                    </div>
                                                <br/>
                                                </form>
                                            </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                                <button 
                                    className="primary-btn btn-small" 
                                    onClick={this.onAddUser}
                                    disabled={!!this.state.error || !this.state.newUser.phoneNumber || this.state.isUpdating}>
                                {this.state.isUpdating ? 'Creating...' : 'Create'}</button>
                            </div>
                        </div>
                    </div>
                );
        }
    }

}

export default CreateUserModal;