/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/ReleaseNotes";
class ReleaseNotesService {

    async getreleasenotes() {
        const response = await http.get(`${controller}`);
        const data = response.data;
        return data;
    }

    async addreleasenotes(notes) {
        const response = await http.post(`${controller}`, notes);
        const data = response.data;
        return data;
    }
};

export default new ReleaseNotesService();