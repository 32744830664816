/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import Highcharts from 'highcharts';

require('highcharts/modules/boost')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

class Graph extends Component {

    constructor(props) {
        super(props);

        this.chartContainer = React.createRef();
        this.state = {
            options: this.props.options
        }
    }

    componentDidMount() {
        this.chart = new Highcharts[this.props.type || 'Chart'](
            this.chartContainer.current,
            this.props.options
        );
    }

    componentWillUnmount() {
        this.chart.destroy();
    }

    render() {
        return <div ref={this.chartContainer} />;
    }
}

class BasicHighchartGraph extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.getOptions = this.getOptions.bind(this);

        this.state = {
            graphdata: this.props.graphData
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getOptions(chartData) {
        let seriesArray = [];
        seriesArray = this.props.chartData;
        

        let newOptions = {
            time: { 
                useUTC: false 
            },
            title: {
                text: undefined
            },
            yAxis: [{
                offset: 10,
                crosshair: true,
                title: {
                    text: this.props.yAxisTitle,
                }
            },
            {
                offset: 10,
                crosshair: true,
                title: {
                    text: this.props.yAxisTitle2,
                },
                opposite: true
            }],
            xAxis: {
                title: {
                    text: 'Date'
                },
                type: 'datetime',
                //tickInterval: 24 * 3600 * 1000,
                labels: {
                    format: '{value:%m/%d}',
                    align: 'center'
                },
                offset: 10,
                crosshair: true,
                minPadding: 0.03,
                maxPadding: 0.02
            },
            chart: {
                type: 'line',
                zoomBySingleTouch: true,
                zoomType: 'x',
                accessibility: false 
            },
            series: seriesArray,
            plotOptions: {
                series: {
                    lineWidth: 1,
                    findNearestPointBy: 'xy',
                    marker: {
                        enabled: false
                    }
                }
            },
            lang: {
                noData: "No History Available"
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#303030'
                }
            }
        };

        return newOptions;
    }

    render() {
        return (
            <div>
                <Graph key={`${this.props.chartData.length}-basic`} options={this.getOptions(this.props.chartData)} />
            </div>
        );
    }
}

BasicHighchartGraph.defaultProps = {
    yAxisTitle2: ''
};

export default BasicHighchartGraph;