/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/devicecertificates";
const devicecertificateService = {
    getcurrentKey: async () => {
        const response = await http.get(`${controller}/GetActiveDeviceCertificate`);
        return response.data;
    },
    saveaccesskey: async (accessKey) => {
        const response = await http.post(`${controller}/saveaccesskey`, {"Certificate":`${accessKey}`, "IsActive":true});
        return response.data;
    }
};
export default devicecertificateService;
