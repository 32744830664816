/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/system";
class AccessKeyToolService {
    async validateaccessKey(accessKey) {
        accessKey = encodeURIComponent(accessKey);
        const response = await http.get(`${controller}/validateaccesskey?accessKey=${accessKey}`);
        const data = await response.data;
        return data;
    }

    async accesskeytimetolive(accessKey) {
        accessKey = encodeURIComponent(accessKey);
        const response = await http.get(`${controller}/accesskeytimetolive?accessKey=${accessKey}`);
        const data = await response.data;
        return data;
    }

    async getaccesskeymodeldetails(accessKey) {
        accessKey = encodeURIComponent(accessKey);
        const response = await http.post(`${controller}/getaccesskeymodeldetails`, JSON.stringify(accessKey));
        const data = await response.data;
        return data;
    }

    async generateaccesskey() {
        const response = await http.get(`${controller}/generateaccesskey`);
        const data = await response.data;
        return data;
    }
}

export default new AccessKeyToolService();