/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { useState,useEffect }from 'react';
import { Spinner } from 'react-bootstrap';
import { Event, Timing, ModalView } from "../../components/GoogleAnalytics";
import IntegrationModle from '../Integrations/IntegrationModel';
import DeleteIntegrationModel from '../Integrations/DeleteIntegrationModel';
import serviceIntegration from '../../services/serviceIntegration.service';
import * as CryptoJS from 'crypto-js';

const TitanService = {
    ClientSecret: "",
    APIKey: "",
    ClientId: "",
    TenantId: ""
}
const addService = {
    id: 0,
    createUserId: "",
    updateUserId: "",
    isActive: true,
    initialTimeStamp: "",
    updatedTimeStamp: "",
    serviceType: "",
    serviceIntegrationObject: JSON.stringify(TitanService),
    uniqueServiceId :0

}
function AddIntegration(props) {
   
    const [Integration, setIntegration] = useState([]);
    const [ShowAddIntegration, setShowAddIntegration] = useState(false);
    const [serviceInfo, setServiceInfo] = useState(addService);
    const [isSaveEnable, setIsSaveEnable] = useState(false);
    const [isDeletEnable, setIsDeletEnable] = useState(false);
    const [deleteObj, setDeleteObj] = useState({});
    const [isSTEdit, setIsSTEdit] = useState(false);

	
    const showSaveModal = () => {
        setServiceInfo(addService);
        setIsSaveEnable(true);
        setShowAddIntegration(!ShowAddIntegration);
        
    };

    const showDeleteModal = () => {
        setIsDeletEnable(!setIsDeletEnable);

    };

    const updateService = (service) => {
        let serviceObj = JSON.parse(service?.serviceIntegrationObject);
     //   if (serviceObj != null && serviceObj != undefined) 
     //   {
     //       serviceObj.PassWord = decryptData(serviceObj.PassWord);
            
	    //}
        
        //setServiceInfo({ ...service, serviceIntegrationObject: JSON.stringify(serviceObj) });
        setServiceInfo(service);
        setShowAddIntegration(!ShowAddIntegration);
        setIsSaveEnable(true);
        setIsSTEdit(true);
    };

    const deleteService = (service) => {
        setDeleteObj(service);
        setIsDeletEnable(true);
    };

   

    useEffect(() => {
        if (props && props.partnersId) {
            serviceIntegration.getServiceList(props.partnersId)
                .then(response => {
                    if (response && response?.data) {
                        setIntegration(response?.data);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }, [isDeletEnable, ShowAddIntegration]);


    let contents =
        <div  style={{ display: "block" }}>
            <div className="loading-wrapper">
                <div className="modal-body"><Spinner animation="border" variant="light" /></div>
            </div></div>;

    let tableContent =
        Integration.length ?
            <tbody>
            {Integration.map((item, index) =>
                <tr key={item?.id} >
                    <td>{item?.serviceType}</td>
                    <td>{item?.updatedTimeStamp}</td>
                    <td>
                        <button type="button" className="secondary-btn btn-small" onClick={() => { deleteService(item); }}>Delete</button>
                        <button type="button" className="primary-btn btn-small" onClick={() => { updateService(item); }}>Edit</button>
                    </td>   
                    </tr>
                )}
            </tbody>
            :
            <tbody>
                <tr>
                    <td>No Active Integrations!</td>
                </tr>
            </tbody>;
       

    return (<div>
        <div className="row no-gutters">
            <div className="col">
                <h3 className="pageTitle" id="tableLabel">Active Integrations</h3>
            </div>
            <div className="col pageTitle">
                <div className="btn-right">
                    <button type="Add" className="secondary-btn btn-large" style={{ width: 150 + 'px' }} onClick={showSaveModal} >+ Add Integration</button>
                </div>
            </div>
        </div>
        <div className="table-container" style={{ width: '80%' }}>
            <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                <thead>
                    <tr >
                        <th>Integration</th>
                        <th>Added On</th>
                    </tr>
                </thead>
                {tableContent}
                
            </table>
            {ShowAddIntegration &&
                <IntegrationModle show={ShowAddIntegration} onChage={showSaveModal} data={serviceInfo} isSaveEnable={isSaveEnable} currentAccount={props.currentAccount} partnersId={props.partnersId} isSTEdit={isSTEdit}/>}
            {isDeletEnable && <DeleteIntegrationModel onChage={showDeleteModal} delete={deleteObj} />}
        </div>  

       
    </div>);
}

export default AddIntegration;