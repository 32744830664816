/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import { Navbar, Nav, Form } from 'react-bootstrap';
import styled from 'styled-components';

import LogOffModal from "../components/LogOff";

const Styles = styled.div`
  .navbar { background-color: transparent; }
  a, .navbar-nav, .navbar-light .nav-link {
    color: #9FFFCB;
    &:hover { color: white; }
  }
  .navbar-brand {
    font-size: 1.4em;
    color: #9FFFCB;
    &:hover { color: white; }
  }
  .form-center {
    position: fixed !important;
    left: 25%;
    right: 25%;
  }
`;


//var modalOverlay = document.querySelector('.modal-overlay')

export class NavigationBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = { loading: true, showLogon: false };        

        this.showLogonModal = this.showLogonModal.bind(this);
        this.openLogonModal = this.openLogonModal.bind(this);
    }

    showLogonModal = e => {
        this.setState({
            showLogon: !this.state.showLogon
        });
        window.scrollTo(0, 0);
    }

    openLogonModal() {
        this.showLogonModal(null);
    }
   
    render() {

        return (
            <Styles className='onTop'>
                <Navbar expand="lg" style={{ height: '40px' }}>
                    <Nav className="mr-auto" >
                    </Nav>
                </Navbar>

            </Styles>);
    }
}


    