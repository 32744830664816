/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";

class TurnOffSystemModal extends Component {

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    render() {

        if (!this.props.show) {
            return null;
        }
        else {

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Turn Off Customer HVAC System</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); }}>×</span>
                        </div>

                        <div className="modal-body" >
                            <br />
                            Would you like to turn off the HVAC System for this customer?<br />
                            <b>This should only be done to prevent catastrophic failure of home equipment that is already in failure.</b>
                            <br />
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); }}>Cancel</button>
                            <button type='button' className="primary-btn btn-small" onClick={this.props.onTurnOff}>Yes</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}


export default TurnOffSystemModal;