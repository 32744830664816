/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/
import React from "react";

const UserAssetList = ({data}) => {
    const userAssets = data;

    let list = [];
    list.push(<strong key={`organization-strong`}>The following assets will be removed from the system:</strong>)
    for (let levelOne of userAssets){
        list.push(<br key={`${levelOne.id}-br`} /> )
        list.push(<span key={levelOne.id}>{levelOne.name} </span> )
        for (let levelTwo of levelOne.children){
            list.push(<br key={`${levelTwo.id}-br`} /> )
            list.push(<span style={{ display: "inline-block", marginLeft: "4em"}} key={levelTwo.id}>{levelTwo.name} </span> )
            for (let levelThree of levelTwo.children){
                list.push(<br key={`${levelThree.id}-br`} /> )
                list.push(<span style={{display: "inline-block", marginLeft: "8em"}} key={levelThree.id}>{levelThree.name} </span> )
            }
        }
    }
    return list;
}
export default UserAssetList;