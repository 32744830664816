import React, { useState, useEffect, Component } from 'react';
import { Spinner, Image } from 'react-bootstrap';
import { PageView, Event } from "../GoogleAnalytics";
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LongArrowUpIcon from '@material-ui/icons/ArrowUpward';
import LongArrowDownIcon from '@material-ui/icons/ArrowDownward';
import DeviceEnergyMetricsTable from './DeviceEnergyMetricsTable';


const defaultSortCol = "CUSTOMER";
const currentDate = new Date();
const month = currentDate.getMonth() + 1;

function EnergyMetricsTable(props) {
    const { classes, isLoading, history, setActivePath, _isMounted, monthlyDataObj, summarizedData, dateSelected } = props;
    const [isMounted, setIsMounted] = useState(false);
    const [columnData, setColumnData] = useState({});
    const [columnAltered, setColumnAltered] = useState(false);
    const [columnSort, setColumnSort] = useState({
        enabled: true,
        type: defaultSortCol,
        reverse: false
    });
    const [selected, setSelected] = useState(-1);
    const [selectedDeviceName, setSelectedDeviceName] = useState(currentDate);

    const getPrettyName = (name) => {
        if (name !== null && name !== undefined) {

            var split = name.split("__");
            var name = split[0].replace("?", "'");

            const indexOfSlash = name.indexOf(' / ');
            if (indexOfSlash !== -1) {
                name = name.substring(indexOfSlash + 3);
            }
            return name;
        }
        else {
            return "";
        }
    }

    const formatSeconds = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${hours} hours, ${minutes} minutes, ${remainingSeconds} seconds`;
    }

    const convertWattToKwh = (watts) => {
        var kwh = (watts / 1000) * 24;
        return kwh;
    }

    const iconFunction = (columnName, columnSort) => {
        let arrowIcon = columnSort.enabled ?
            (columnSort.reverse ?
                <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
                : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />)
            : null;

        let icon = columnSort.enabled && columnSort.type === columnName ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;

        return icon;
    }

    const onColumnClick = (selectedCol) => {
        const colSort = columnSort;
        let newSort = colSort;
        let data = summarizedData;
        let colData = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colData = sortColumn(data, "CUSTOMER", false); /* default sort by is start time */
                    colAltered = false;
                }
                else {
                    newSort = {
                        enabled: true,
                        type: selectedCol,
                        reverse: true
                    };
                    colData = sortColumn(data, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colData = sortColumn(data, selectedCol, false);
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colData = sortColumn(data, selectedCol, false);
            colAltered = true;
        }

        setColumnSort(newSort, () => {
            setColumnData(colData, () => {
                setColumnAltered(colAltered);
            });
        });
    }

    const sortColumn = (dataList, col, isReverse) => {
        let colData = [];
        let data = dataList;

        if (col === "CUSTOMER") {
            colData = data.sort((a, b) => {
                const nameA = a.customer;
                const nameB = b.customer;

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "HOME") {
            colData = data.sort((a, b) => {
                const nameA = a.homeName;
                const nameB = b.homeName;

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "HVAC_SYSTEM") {
            colData = data.sort((a, b) => {
                const nameA = a.hvacName;
                const nameB = b.hvacName;

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "DEVICE") {
            colData = data.sort((a, b) => {
                const nameA = a.deviceName;
                const nameB = b.deviceName;

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "ASSET_TYPE") {
            colData = data.sort((a, b) => {
                const nameA = a.assetType;
                const nameB = b.assetType;

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "DAY") {
            colData = data.sort((a, b) => {
                const dateA = new Date(a.runDate);
                const dateB = new Date(b.runDate);
                return dateB - dateA;
            });
        }
        else if (col === "AMPS") {
            colData = data.sort((a, b) => {
                const numA = a.amps;
                const numB = b.amps;

                return numB - numA;
            });
        }
        else if (col === "KWH") {
            colData = data.sort((a, b) => {
                const numA = a.kilowattHours;
                const numB = b.kilowattHours;

                return numB - numA;
            });
        }
        else if (col === "TIME_RUNNING") {
            colData = data.sort((a, b) => {
                const numA = a.timeRunning;
                const numB = b.timeRunning;

                return numB - numA;
            });
        }
        else if (col === "ELECTRIC_COST") {
            colData = data.sort((a, b) => {
                const numA = a.cost;
                const numB = b.cost;

                return numB - numA;
            });
        }
        else if (col === "GAS_COST") {
            colData = data.sort((a, b) => {
                const numA = a.gasCost;
                const numB = b.gasCost;

                return numB - numA;
            });
        }
        else if (col === "ALERTS") {
            colData = data.sort((a, b) => {
                const numA = a.alerts;
                const numB = b.alerts;

                return numB - numA;
            });
        }
        else if (col === "RUNS") {
            colData = data.sort((a, b) => {
                const numA = a.runs;
                const numB = b.runs;

                return numB - numA;
            });
        }

        if (isReverse) {
            colData = colData.reverse();
        }

        return colData;
    }

    const onDeviceClick = (index, deviceName) => {
        Event("Energy Metrics Device Clicked", "User clicked a device in Partner Energy Metrics", "Energy Metrics Device Clicked");
        let x = index;
        if (selected == x) {
            setSelected(-1);
        }
        else {
            var device = deviceName;
            setSelected(x);
            setSelectedDeviceName(device);
        }
    }

    const getExpandContent = (index, deviceName) => {
        var deviceContent = monthlyDataObj.filter(row => row.deviceName === deviceName);
        console.log("here");

        return (
            <tr className="expanded" key={`expandedContent-${index}`} style={{ backgroundColor: 'white !important' }}>
                <td colSpan={10} style={{ color: 'unset' }}>
                    <DeviceEnergyMetricsTable deviceData={deviceContent} iconFunction={iconFunction} formatSeconds={formatSeconds} convertWattToKwh={convertWattToKwh} columnSort={sortColumn} dateSelected={dateSelected}/>
                </td>
            </tr>
        );
    }

    let summaryData = columnAltered ? columnData : summarizedData;

    let summaryContent = isLoading ?
    <div>
            <Spinner animation="border" variant="dark"/>
    </div>
    :summaryData.length ?
        <tbody>
            {summaryData.map((item, index) =>
                [<tr className={selected == index ? "alerts-table-selected alerts-table-row" : "alerts-table-row"} key={item.deviceName} data={index} value={index} onClick={() => onDeviceClick(index, item.deviceName)}>
                    <td style={{ padding: '16px 0px 16px 10px', borderLeft: '1px solid #ebeef0' }}>
                        {item.customer}
                    </td>
                    <td>{getPrettyName(item.homeName)}</td>
                    <td>{getPrettyName(item.hvacName)}</td>
                    <td>{item.deviceName}</td>
                    <td>{item.assetType}</td>
                    <td>{item.amps}</td>
                    <td>{item.kilowattHours}</td>
                    <td>{formatSeconds(item.timeRunning.toFixed(0))}</td>
                    <td>{item.runs}</td>
                    <td>${item.cost}</td>
                    <td>${item.gasCost}</td>
                    <td className="alerts-td-right">
                        {selected == index ? <ArrowUpIcon className="db-blue-reverse" style={{ borderRadius: '12px' }} /> : <ArrowDownIcon className="db-blue db-blue-bg" style={{ borderRadius: '12px' }} />}
                    </td>
                </tr>,
                    selected == index && (getExpandContent(index, item.deviceName))
                ])}
        </tbody>
        : <tbody>
            <tr>
                <td>{""}</td>
            </tr>
        </tbody>;


    return (
        <div style={{ margin: '20px 30px' }}>

            <div>
                <table className="table-hover" style={{ width: '100%' }}>

                    <thead>
                        <tr className="alerts-table-row">
                            <th style={{ padding: '0px 0px 0px 10px' }}>
                                <div onClick={() => onColumnClick("CUSTOMER")}>
                                    <span style={{ cursor: 'pointer' }}>CUSTOMER</span>
                                    {iconFunction("CUSTOMER", columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick("HOME")}>
                                    <span style={{ cursor: 'pointer' }}>HOME</span>
                                    {iconFunction("HOME", columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick("HVAC_SYSTEM")}>
                                    <span style={{ cursor: 'pointer' }}>HVAC SYSTEM</span>
                                    {iconFunction("HVAC_SYSTEM", columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick("DEVICE")}>
                                    <span style={{ cursor: 'pointer' }}>DEVICE</span>
                                    {iconFunction("DEVICE", columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick("ASSET_TYPE")}>
                                    <span style={{ cursor: 'pointer' }}>ASSET TYPE</span>
                                    {iconFunction("ASSET_TYPE", columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick("AMPS")}>
                                    <span style={{ cursor: 'pointer' }}>AMPS</span>
                                    {iconFunction("AMPS", columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick("KWH")}>
                                    <span style={{ cursor: 'pointer' }}>KWH</span>
                                    {iconFunction("KWH", columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick("TIME_RUNNING")}>
                                    <span style={{ cursor: 'pointer' }}>TIME RUNNING</span>
                                    {iconFunction("TIME_RUNNING", columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick("RUNS")}>
                                    <span style={{ cursor: 'pointer' }}>RUNS</span>
                                    {iconFunction("RUNS", columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick("ELECTRIC_COST")}>
                                    <span style={{ cursor: 'pointer' }}>ELECTRIC COST</span>
                                    {iconFunction("ELECTRIC_COST", columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick("GAS_COST")}>
                                    <span style={{ cursor: 'pointer' }}>GAS COST</span>
                                    {iconFunction("GAS_COST", columnSort)}
                                </div>
                            </th>
                            <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 10px 10px 5px' }}></th>
                        </tr>
                    </thead>
                    {summaryContent}
                </table>
            </div>
        </div>
        );
    }
    
export default EnergyMetricsTable;