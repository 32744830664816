/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceArea, ReferenceLine, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';

//const series = [
//    {
//        name: 'Average Compressor',
//        unit: 'A',
//        data: [
//            { timestamp: "/Date(1625513296000)/", value: 6.28779 }
//        ],
//    },
//    {
//        name: 'Predicted Average Compressor',
//        unit: 'A',
//        data: [
//            { timestamp: "/Date(1627421656000)/", value: 6.74574 },
//            { timestamp: "/Date(1627455136000)/", value: 5.88638 }
//        ],
//    }
//];

const TiltedAxisTick = (props) => {
    const { isSameDay, x, y, payload } = props;
    let time = moment(payload.value);
    let format = isSameDay ? 'lll' : 'L';
    let timeStr = time.format(format);
    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor="middle"
                fill="#666">
                {timeStr}
            </text>
        </g>
    );
};

const CustomToolTip = (props) => {
    const { active, payload } = props;

    if (active) {
        if (payload != null) {
            let time = moment(props.label);
            let timeStr = time.format('llll');
            const newPayload = payload.map((v) => ({
                    name: v.name,
                    value: v.value,
                    unit: v.unit
                })
            );

            return (
                <div><DefaultTooltipContent {...props} payload={newPayload} label={timeStr} /></div>
            );
        }
        else {
            return (
                <div><DefaultTooltipContent {...props} /></div>
            );
        }
    }
    return null;
}

let colors = ["#0033a0", "purple"];

export class MultiSeriesGraph extends Component {

    constructor(props) {
        super(props);
        this.getAxisYDomain = this.getAxisYDomain.bind(this);
        this.state = {
            data: this.props.data.series,
            showZoom: false,
            left: 'dataMin',
            right: 'dataMax',
            refAreaLeft: '',
            refAreaRight: '',
            top: this.props.data.yDataMax, //'dataMax+1',
            bottom: this.props.data.yDataMin,
            animation: true
        }
    }

    getAxisYDomain(from, to, ref, offset){
        const refData = this.props.data.series.reduce((acc, e) => {
            const value = e.data.filter((f) => f["timestamp"] >= from && f["timestamp"] <= to);
            if (value) {
                acc.push({ name: e.name, data: value, unit: e.unit, referenceLines: e.referenceLines });
            }
            return acc;
        }, []);
        let [bottom, top] = [refData[0]['data'][0][ref], refData[0]['data'][0][ref]];
        refData.forEach((s) => {
            s.data.forEach((d) => {
                if (d[ref] > top) top = d[ref];
                if (d[ref] < bottom) bottom = d[ref];
            })
        });

        this.setState(() => ({
            data: refData.slice(),
        }));

        return [(bottom | 0) - offset, (top | 0) + offset];
    };

    zoom() {
        let { refAreaLeft, refAreaRight } = this.state;
        //const { data } = this.state;

        if (refAreaLeft === refAreaRight || refAreaRight === '') {
            this.setState(() => ({
                refAreaLeft: '',
                refAreaRight: '',
            }));
            return;
        }

        if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

        const [bottom, top] = this.getAxisYDomain(refAreaLeft, refAreaRight, 'value', 1);

        this.setState(() => ({
            showZoom: true,
            refAreaLeft: '',
            refAreaRight: '',
            left: refAreaLeft,
            right: refAreaRight,
            bottom,
            top,
        }), () => { this.props.showZoomButton(true)});
    }

    zoomOut() {
        //const { data } = this.state;
        this.setState(() => ({
            data: this.props.data.series,
            showZoom: false, // todo check
            refAreaLeft: '',
            refAreaRight: '',
            left: 'dataMin',
            right: 'dataMax',
            top: 'dataMax+1',
            bottom: 'dataMin-1'
        }), () => { this.props.showZoomButton(false) });
    }

    timeRange(data) {
        if (data[0] === null || data[0] === undefined)
            return false;
        let start = moment(data[0]['data'][0]["timestamp"]).valueOf();
        let end = start;
        data.forEach((s) => {
            s.data.forEach((d) => {
                let time = moment(d["timestamp"]).valueOf();
                if (time > end) end = time;
                if (time < start) start = time;
            })
        });
        return  moment(start).isSame(end, 'day');
    }

    render() {
        let yAxisLabel = this.props.data.yAxisLabel;
        const { data, refAreaLeft, refAreaRight } = this.state;
        const yMax = Math.ceil(this.props.data.yDataMax + 0.5);
        const yMin = this.props.data.yDataMin === 0 ? 0 : Math.floor(this.props.data.yDataMin - 0.5); 
        let isDay = this.timeRange(this.props.data.series); //data);

        let zoomButton = this.props.isFullScreen ?
            <div>
                <button className="secondary-btn btn-small graph-btn" onClick={this.zoomOut.bind(this)} disabled={!this.state.showZoom} style={{ width: '100px', marginBottom: '15px' }}> Zoom Out</button>
            </div>
            :<div></div>;
         
        return (
            <div className="highlight-bar-charts" style={{ userSelect: 'none', width: '100%' }}>
                <ResponsiveContainer width="100%" height={this.props.isFullScreen ? 490 : 450}>
                    <LineChart margin={{ top: 5, right: 30, left: 20, bottom: 50 }}
                        onMouseDown={(e) => this.setState({ refAreaLeft: e.activeLabel })}
                        onMouseMove={(e) => this.state.refAreaLeft && this.setState({ refAreaRight: e.activeLabel })}
                        onMouseUp={this.zoom.bind(this)} >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="timestamp" label={{ value: 'Time', position: 'bottom' }} allowDuplicatedCategory={false} tick={<TiltedAxisTick isSameDay={isDay} />} padding={{ left: 20, right: 20 }}/>
                        <YAxis dataKey="value" label={{ value: yAxisLabel, angle: -90, position: 'insideLeft' }} yAxisId="1" domain={[yMin, yMax]} allowDecimals={false} type="number" padding={{ top: 20, bottom: 20 }}/>
                        <Tooltip content={<CustomToolTip />} />
                        <Legend verticalAlign="top" />
                        {data.map((v, i) => (
                            <Line yAxisId="1" dataKey="value" dot={false} data={v.data} name={v.name} key={v.name} unit={v.unit} stroke={colors[i]}/>
                        ))}
                        {refAreaLeft && refAreaRight ? (
                            <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
                        ) : null}
                        {data.map((v) => (
                            v.referenceLines.map((r) => (
                                <ReferenceLine y={r.value} label={r.name} stroke={r.color} yAxisId="1" />
                            ))
                        ))}
                    </LineChart>
                </ResponsiveContainer>
                {zoomButton}
            </div>
        );
    }
}

export default MultiSeriesGraph;