/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import "../../CSS/DataModal.css";

class ReplaceSystemModal extends React.Component {

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {

    };

    render() {

        if (!this.props.show) {
            return null;
        }
        else {

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0',
                            width: '50%',
                            left: '25%'
                        }}>

                        <div className="modal-header">
                            <h3>Reminder: Install LifeWhere on New System</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body">
                            <h5 style={{ padding: '10px' }}>To install LifeWhere on the new system:</h5>
                            <div style={{ textAlign: "left", paddingLeft: "10px" }}>
                                <p>&bull; Log in to the LifeWhere mobile app</p>
                                <p>&bull; Select Settings and tap Devices</p>
                                <p>&bull; Tap Replace this HVAC System and follow the app instructions to complete the replacement</p>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button className="primary-btn btn-small" onClick={this.props.onReplaceSystem}>Okay</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default ReplaceSystemModal;