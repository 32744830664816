/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/assetComments";
class CommentsService {

    async getassetcomments() {
        const response = await http.get(`${controller}`);
        const data = await response;
        return data;
    }

    async getallassetcomments(assetId) {
        const response = await http.get(`${controller}/getallassetcomments?assetId=${assetId}`);
        const data = await response;
        return data;
    }

    async updatecomment(comment) {
        const response = await http.post(`${controller}`, comment);
        const data = await response;
        return data;
    }

    async deletecomment(commentId) {
        const response = await http.delete(`${controller}?id=${commentId}`);
        const data = await response;
        return data;
    }

}

export default new CommentsService();