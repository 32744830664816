/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/house";
class HomeService {
    
    async get(id, getProperties=true) {
        const response = await http.get(`${controller}?organizationId=${id}&getProperties=${getProperties}`); 
        const data = await response;
        return data;
    }

    add(data) {
        return http.post(`${controller}`, data);
    }

    update(data) {
        return http.put(`${controller}`, data);        
    }

    delete(id, customerId, partnerId) {
        return http.delete(`${controller}?houseAssetId=${id}&customerId=${customerId}&partnerId=${partnerId}`);
    }

}

export default new HomeService();