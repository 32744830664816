/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/billing";
const billingService = {
    getpartnersubscriptions: async (id) => {
        const response = await http.post(`${controller}/getpartnersubscriptions?partnerOrgId=${id}`, null);
        return response.data;
    },
    getbillingdevicecontracts: async (orgId, deviceIdsStr) => {
        const response = await http.post(`${controller}/getbillingdevicecontracts?customerOrgId=${orgId}&deviceIdsStr=${deviceIdsStr}`);
        return response.data;
    },
    quitContracts: async (org, contracts) => {
        const response = await http.post(`${controller}/quitcontracts?customerOrgId=${org}`, contracts);
        return response;
    }
};
export default billingService;