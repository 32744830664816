/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import ReactGA from 'react-ga';

const trackingID = "UA-200607099-1";

export const initGA = () => {
    ReactGA.initialize(trackingID, { debug: false });
};

export const PageView = () => {
    ReactGA.pageview(window.location.pathname);
};

export const SetPageViewTitle = (pageName) => {
    ReactGA.pageview(pageName);
}

export const ModalView = (modalName) => {
    ReactGA.modalview(modalName);
};

export const SetPageView = (pageName) => {
    ReactGA.pageview(window.location.pathname + pageName);
}

/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export const Event = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label
    });
};

export const EventVal = (category, action, label, value) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
        value: value
    });
};

export const EventNonInt = (category, action, label, nonInteractive) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
        nonInteractive: nonInteractive
    });
};

export const Timing = (category, variable, value, label) => {
    ReactGA.timing({
        category: category,
        variable: variable,
        value: Math.round(value),
        label: label
    });
};

export const Exception = (desc, isFatal) => {
    ReactGA.Exception({
        description: desc,
        fatal: isFatal
    });
};