import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Event, PageView, ModalView, Timing } from "../GoogleAnalytics";
import { withStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import LongArrowUpIcon from '@material-ui/icons/ArrowUpward';
import LongArrowDownIcon from '@material-ui/icons/ArrowDownward';
import AccountService from "../../services/account.service";
import AssetService from "../../services/asset.service";

import ConfirmPasswordModal from "../Account/ConfirmUser/ConfirmPasswordModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import ConfirmDeleteAccountModal from "../Account/ConfirmDeleteAccountModal";

import UserAssetList from "../Assets/Hierarchy/UserAssetList";

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

function DeleteAccounts(props) {

    const { classes, isLoading, currentPartnerId, _isMounted } = props;
    const [isMounted, setIsMounted] = useState(false);
    const [deletedAccounts, setDeletedAccounts] = useState([]);
    const [loading, setLoading] = useState(true);

    // Delete Flow
    const [showCancelModal, setShowCancelModal] = useState(false); 
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
    const [showDeleteAccount, setShowDeleteAccount] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
    const [showRecoverConfirmation, setShowRecoverConfirmation] = useState(false);
    const [message, setMessage] = useState("");
    const [isDeleted, setIsDeleted] = useState(false);
    const [waitDelete, setWaitDelete] = useState(false);
    const [userassethierarchy, setuserassethierarchy] = useState([]);
    const [isActiveBillingPlan, setIsActiveBillingPlan] = useState(false);
    const [currentAccountId, setCurrentAccountId] = useState("");
    const [modalTitle, setModalTitle] = useState("");

    const [columnAltered, setColumnAltered] = useState(false);
    const [columnList, setColumnList] = useState([]);
    const [columnSort, setColumnSort] = useState({
        enabled: true,
        type: "DATE",
        reverse: false
    });
    const actionMessage = "Please Retry or Contact Support";

    const fetchDeletedAccounts = async () => {
        try {
            if (_isMounted && isMounted) {
                PageView();
                await AccountService.partnerdeletedaccounts(currentPartnerId)
                    .then(response => {
                        if (_isMounted && isMounted) {
                            const data = response.data;
                            setDeletedAccounts(data);
                            console.log(isDeleted);
                            setLoading(false);
                        }
                    });
            }
        } catch (error) {
            console.error('Error fetching deleted accounts:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        setIsMounted(true);
        fetchDeletedAccounts();

        return () => {
            setIsMounted(false);
        };
    }, [isMounted]);

    // DELETE FLOWS:
    const showConfirmationModal = e => {
        if (!showConfirmation) {
            setCurrentAccountId(e.organizationId);
            checkAccountAssets(e.organizationId);
            ModalView("../ConfirmationModal/ConfirmationModal");
        }
        setShowConfirmation(!showConfirmation);
        setModalTitle(!showConfirmation ? "Delete Assets" : "");
        window.scrollTo(0, 0);
    }

    const showRecoverConfirmationModal = e => {
        if (!showRecoverConfirmation) {
            setCurrentAccountId(e.organizationId);
            //ModalView("../ConfirmationModal/ConfirmationModal");
            ModalView("/confirmDeleteAccount");
        }
        setShowRecoverConfirmation(!showRecoverConfirmation);
        setModalTitle(!showRecoverConfirmation ? "Recover Account" : "");
        setMessage(!showRecoverConfirmation ? "Are you sure you want to recover this account: " + e.name : "")
        window.scrollTo(0, 0);
    }

    const showPasswordConfirmationModal = () => {
        setShowPasswordConfirmation(!showPasswordConfirmation);
        setShowCancelModal(false);
        setShowConfirmCancelModal(false);
        setShowConfirmation(false, () => {
            if (showPasswordConfirmation) {
                ModalView("ConfirmPasswordModal");
            }
        });

        window.scrollTo(0, 0);
    }

    const cancelAccountDelete = () => {
        setShowRecoverConfirmation(false);
        setShowCancelModal(false);
        setShowConfirmCancelModal(false);
        setShowConfirmation(false);
    }

    const showDeleteAccountModal = e => {
        setShowDeleteAccount(!showDeleteAccount);
        setMessage(!showDeleteAccount ? "Would you like to delete the account?" : "");
        setShowPasswordConfirmation(false);
        setShowCancelModal(false);
        setShowConfirmCancelModal(false);
        setShowConfirmation(false, () => {
            if (showDeleteAccount) {
                ModalView("/confirmDeleteAccount");
            } else if (isDeleted) {
                // todo: Don't need to do this, just refresh list of deleted accounts
                //this.props.setCurrentPartnerAndCustomer(this.state.currentAccount.parentAccountId, 0, this.setPartnerCallback);
            }
        });

        window.scrollTo(0, 0);
    }

    const checkAccountAssets = async (id) => {
        await setWaitDelete(true);
        await AssetService.getassethierarchy(id).then(response => {
            if (response.status === 200 && response.data !== undefined && response.data !== null && response.data.length > 0) {
                setuserassethierarchy(response.data);
                setIsDeleted(false);
                setIsActiveBillingPlan(false);
            } else {
                setMessage("Failed to delete the account! Reason: " + response.data);
                setIsDeleted(false);
                setIsActiveBillingPlan(false);
            }
            setTimeout(() => {
                setWaitDelete(false);
            }, 3000);

        })
        .catch(e => {
            console.log(e);
        });
    }

    const deleteAccount = async (id) => {
        setLoading(true);
        await setWaitDelete(true);
        await AccountService.delete(id)
            .then(response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data === "Success") {
                    fetchDeletedAccounts();
                    setIsDeleted(true);
                    console.log(isDeleted);
                    setMessage("The account was deleted successfully!");
                    setIsActiveBillingPlan(false);
                    Event("Customer Delete", "User deleted customer account", "Successful Customer Account Deletion");
                } else if (response.status === 200 && response.data !== undefined && response.data !== null && response.data === "Active") {
                    setMessage("Need to cancel the subscription first before delete the account!");
                    setIsDeleted(false);
                    setIsActiveBillingPlan(false);
                    setLoading(false);
                } else {
                    setMessage("Failed to delete the account! Reason: " + response.data + ". " + actionMessage);
                    setIsDeleted(false);
                    setIsActiveBillingPlan(false);
                    setLoading(false);
                }
                setTimeout(() => {
                    setWaitDelete(false);
                    setLoading(false);
                }, 3000);
            })
            .catch(e => {
                console.log(e);
                setLoading(false);
            });
    }

    const restoreAccount = async () => {
        setLoading(true);
        await setWaitDelete(true);
        await AccountService.recoverAccount(currentPartnerId, currentAccountId)
            .then(response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data === "Success") {
                    fetchDeletedAccounts();
                    setMessage("The account was recovered successfully!");
                    setIsDeleted(true);
                    setIsActiveBillingPlan(false);
                    Event("Customer Recover", "User recovered customer account", "Successful Customer Account Recovery");
                } else {
                    setMessage("Failed to recover the account! Reason: " + response.data + ". " + actionMessage);
                    setIsDeleted(false);
                    setIsActiveBillingPlan(false);
                    setLoading(false);
                }
                setTimeout(() => {
                    setWaitDelete(false);
                    setLoading(false);
                }, 3000);
            })
            .catch(e => {
                console.log(e);
                setLoading(false);
            });
    }

    // END DELETE FLOW

    const onColumnClick = (selectedCol) => {
        const colSort = columnSort;
        let newSort = colSort;
        const tableList = deletedAccounts;
        let metrics = tableList;
        let colAssets = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colAssets = sortColumn(metrics, "ID", false); /* default sort by is DEVICEID */
                    colAltered = false;
                }
                else {
                    newSort = {
                        enabled: true,
                        type: selectedCol,
                        reverse: true
                    };
                    colAssets = sortColumn(metrics, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colAssets = sortColumn(metrics, selectedCol, false);
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colAssets = sortColumn(metrics, selectedCol, false);
            colAltered = true;
        }

        setColumnSort(newSort, () => {
            setColumnList(colAssets, () => {
                setColumnAltered(colAltered);
            });
        });
    }

    function sortColumn(dataList, col, isReverse) {
        let colData = [];
        let data = dataList;


        if (col === "ID") {
            colData = data.sort((a, b) => {
                const numA = a.organizationId;
                const numB = b.organizationId;

                return numB - numA;
            });
        }
        else if (col === "NAME") {
            colData = data.sort((a, b) => {
                const stringA = a.name;
                const stringB = b.name;

                return stringA.localeCompare(stringB);
            });
        }
        else if (col === "DESCRIPTION") {
            colData = data.sort((a, b) => {
                const stringA = a.description;
                const stringB = b.description;

                return stringA.localeCompare(stringB);
            });
        }
        else {
            colData = data;
        }

        if (isReverse) {
            colData = colData.reverse();
        }

        return colData;
    }

    let arrowIcon = columnSort.enabled ?
        (columnSort.reverse ?
            <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
            : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />)
        : null;

    let idIcon = columnSort.enabled && columnSort.type === "ID" ?
        <span style={{ height: '15px', paddingLeft: '3px' }}>
            {arrowIcon}
        </span>
        : null;

    let nameIcon = columnSort.enabled && columnSort.type === "NAME" ?
        <span style={{ height: '15px', paddingLeft: '3px' }}>
            {arrowIcon}
        </span>
        : null;

    let descriptionIcon = columnSort.enabled && columnSort.type === "DESCRIPTION" ?
        <span style={{ height: '15px', paddingLeft: '3px' }}>
            {arrowIcon}
        </span>
        : null;

    let contents = loading
        ? <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
            <div className="loading-wrapper">
                <div className="modal-body"><Spinner animation="border" variant="light" /></div>
            </div>
        </div>
        :
        <div className="table-container">
            <table className="table-striped table-bordered table-sm table-hover" style={{ width: '100%' }}>
                <thead>
                    <tr className="alerts-table-row">
                        <th style={{ paddingLeft: '30px' }}>
                            <div onClick={() => onColumnClick("ID")}>
                                <span style={{ cursor: 'pointer' }}>CUSTOMER ID</span>
                                {idIcon}
                            </div>
                        </th>
                        <th>
                            <div onClick={() => onColumnClick("NAME")}>
                                <span style={{ cursor: 'pointer' }}>NAME</span>
                                {nameIcon}
                            </div>
                        </th>
                        <th>
                            <div onClick={() => onColumnClick("DESCRIPTION")}>
                                <span style={{ cursor: 'pointer' }}>DESCRIPTION</span>
                                {descriptionIcon}
                            </div>
                        </th>
                        <th>
                            <div>
                                <span style={{ cursor: 'pointer' }}>ACTIONS</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {deletedAccounts.map(account => (
                        <tr className="alerts-table-row" key={account.organizationId}>
                            <td style={{ borderLeft: '1px solid #ebeef0', paddingLeft: '30px' }}>{account.organizationId}</td>
                            <td>{account.name}</td>
                            <td>{account.description}</td>
                            <td style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button className="primary-btn btn-small" style={{ borderRadius: '0.75rem', padding: '6px 10px', width: '15%' }}
                                    title="Restore Account"
                                    onClick={() => showRecoverConfirmationModal(account)}
                                >
                                    <RestoreFromTrashIcon color="white" fontSize="medium"/>
                                </button>
                                <button className="red-btn btn-small" style={{ borderRadius: '0.75rem', padding: '6px 10px', width: '15%' }}
                                    title="Permanently Delete Account"
                                    onClick={() => showConfirmationModal(account)}
                                >
                                    <DeleteForeverIcon color="white" fontSize="medium"/>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <ConfirmPasswordModal
                show={showPasswordConfirmation}
                onCancelClick={showPasswordConfirmationModal}
                onYes={showDeleteAccountModal}
                onLogOff={null} />


            <ConfirmDeleteAccountModal
                onClick={showRecoverConfirmationModal}
                show={showRecoverConfirmation}
                onConfirm={restoreAccount}
                accountId={currentAccountId}
                messageBody={message}
                loading={waitDelete}
                isDeleted={isDeleted}
                isActiveBillingPlan={isActiveBillingPlan}
                onCancelClick={cancelAccountDelete}
            />

            <ConfirmationModal
                show={showConfirmation}
                onYes={showPasswordConfirmationModal}
                onClick={cancelAccountDelete}
                message={<UserAssetList data={userassethierarchy} />}
                title={modalTitle} />

            <ConfirmDeleteAccountModal
                onClick={showDeleteAccountModal}
                show={showDeleteAccount}
                onConfirm={deleteAccount}
                accountId={currentAccountId}
                messageBody={message}
                loading={waitDelete}
                isDeleted={isDeleted}
                isActiveBillingPlan={isActiveBillingPlan}
                onCancelClick={cancelAccountDelete}
            />
        </div>;

    return (
        <div className={classes.root}>
            <div className='infoPage'>
                <h3 className="pageTitle" id="tabelLabel" >Deleted Accounts</h3>
                {contents}
            </div>
        </div>
    );
};

export default withStyles(styles) (DeleteAccounts);