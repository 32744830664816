/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import ForgotPasswordForm from '../ForgotPassword/ForgotPasswordForm';
import ChangePassword from '../ChangePassword/ChangePassword';

class ForgotPasswordModal extends Component {

    onClick = e => {
        this.props.onClick();
    };

    render() {
        if (!this.props.show) {
            return null;
        } else {
            let passwordContent = this.props.isAdmin ?
            <div>
                <div className="modal-header">
                    <h3>Reset Password</h3>
                    <span className="close-modal-btn" onClick={() => { this.onClick(); }}>×</span>
                </div>
                <ChangePassword registerUserId={this.props.emailToReset} changePasswordUri={""} currentAccount={this.props.currentAccount} endForgotPassword={this.props.endForgotPassword} isMobile={this.props.isMobile} isNewUser={false} isAdminReset={this.props.isAdmin} />
            </div>
            :
            <div>
                <div className="modal-header">
                    <h3>Reset Password</h3>
                    <span className="close-modal-btn" onClick={() => { this.onClick(); }}>×</span>
                </div>

                <div className="forgot-password-modal-body">
                    <ForgotPasswordForm passwordEmailSent={this.props.passwordEmailSent} emailToReset={ this.props.emailToReset } endForgotPassword={this.props.endForgotPassword} isMobile={this.props.isMobile} isModal={true} />
                </div>
            </div>;
            return (
                <div className="modal d-flex justify-content-center" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="forgot-password-modal-wrapper col-lg-6 col-sm"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>
                        {passwordContent}                   
                    </div>
                </div>                         
            );
        }
    }
}

export default ForgotPasswordModal;