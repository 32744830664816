import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";

const PhoneNumberInput = ({phoneNumber, setPhoneNumber, onError}) => {
    const [error, setError] = useState('');
    
    const phoneNumberRegex = useCallback(
        ()=> /^(\+?\d{1,2})?[\s.-]?(\(?\d{3}\)?)[\s.-]?\d{3}[\s.-]?\d{4}$/, []
    );
    
    const validatePhone = useCallback((number) => {
        if(phoneNumberRegex().test(number)) {
            setError('');
            onError('');
        }else {
            setError('Invalid phone number format');
            onError('Invalid phone number format');
        }
    }, [onError, phoneNumberRegex]);
    
    const handleChange = useCallback(
        (e) => {
        let {value} = e.target;
        value  = value.replace(/[^\d\s()-]/g, '');
        const digitCount = value.replace(/\D/g, '').length;
        if(digitCount <= 10 && value.length <= 14) {
            setPhoneNumber(value);
            validatePhone(value);
        }
    }, [setPhoneNumber]);
    
    useEffect(() => {
        if(phoneNumber){
            validatePhone(phoneNumber);
        }
    },[phoneNumber, validatePhone]);
    
    return (
            <div>
                <label htmlFor="phoneNumber">Phone Number:</label>
                <input 
                    id="phoneNumber" 
                    type="text" 
                    className="form-control" 
                    placeholder="Enter phone number" 
                    onChange={handleChange} 
                    value={phoneNumber} />
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
    );
};

PhoneNumberInput.propTypes = {
    phoneNumber: PropTypes.string.isRequired,
    setPhoneNumber: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
}
export default PhoneNumberInput;