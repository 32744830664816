/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from "react";
import * as PropTypes from "prop-types";

export class RunRightPanel extends Component {
    render() {
        let multiCheckboxes = this.props.isMultiCT ?
            <div>
                <div>
                    <input type="checkbox" value="blowerCurrent" checked={this.props.showBlower} id="gdo" onChange={this.props.handleCheckbox} />
                    &nbsp; Add Blower Current &nbsp;&nbsp;
                </div>
                <div>
                    <input type="checkbox" value="inducerCurrent" checked={this.props.showInducer} id="gdo" onChange={this.props.handleCheckbox} />
                    &nbsp; Add Inducer Current &nbsp;&nbsp;
                </div>

            </div> : null;

                /*let analyticsButton = !this.props.isThermostat ?
            <div>
                <button className={this.props.showAnnotations && this.props.hasAnalytics ? "primary-btn btn-small" : "secondary-btn btn-small"}
                    onClick={this.props.onClick1} disabled={!this.props.hasAnalytics} style={{ width: "100px", marginBottom: "2px" }}>Run Analytic
                    </button>
            </div>
            : null;*/

        return <div>
            <div>
                <button className="secondary-btn btn-small graph=btn" disabled={this.props.isFullScreen} onClick={this.props.onClick}
                        style={{width: "100px", marginBottom: "2px"}}>Full Screen
                </button>
            </div>
            <div>
                <button className={this.props.showAnnotations && this.props.hasAnalytics ? "primary-btn btn-small" : "secondary-btn btn-small"}
                    onClick={this.props.onClick1} disabled={!this.props.hasAnalytics} style={{ width: "100px", marginBottom: "2px" }}>Run Analytic
                    </button>
            </div>
            {multiCheckboxes}

            
            {/*analyticsButton*/}
            <div>
                <button className="secondary-btn btn-small graph-btn" onClick={this.props.onClick2}
                        disabled={!this.props.zoomOutEnabled} style={{width: "100px"}}> Zoom Out
                </button>
            </div>
        </div>
    }
}

RunRightPanel.propTypes = {
    fullScreenBtn: PropTypes.any,
    showAnnotations: PropTypes.any,
    onClick: PropTypes.any,
    onClick1: PropTypes.any,
    zoomOutEnabled: PropTypes.any,
    hasAnalytics: PropTypes.any
};