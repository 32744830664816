import React, { Component } from 'react';

class DevTemp extends React.Component {

    getTemp(temp) {
        let x = temp.indexOf(" ");
        let val = temp.substring(0, x);
        return val;
    }

    render() {

        if (this.props.devTemp == null) {
            return (null);
        }
        else {
            return (
                <div>{this.props.devTemp} &deg;F</div>
            );
        }
    }
}

export default DevTemp;