/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/
import {useEffect, useState} from "react";
import AssetService from "../../../services/asset.service";

const UseUserAssets = (accountId, fromAssetId) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        let isMounted = true;
        if (accountId !== "-1"){
            AssetService.assethierarchybyorgid(accountId, fromAssetId)
                .then((response) => {
                    if (isMounted) {
                        const assets = response.data;
                        setData(assets);
                    }
                });
        }

        return () => {
            isMounted = false;
        };
    },[accountId, fromAssetId]);

    return data;
}

export default UseUserAssets;