/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/
import React from "react";
import {useState} from "react";
import ConfirmUserPasswordForm from "./ConfirmUserPasswordForm";

export default function ConfirmPasswordModal(props){
    const [validation, setValidation] = useState({isValid: false, isRedirect: false});
    
    function onCancelClick(e){
        setValidation({isValid: false, isRedirect: false});
        props.onCancelClick && props.onCancelClick(e);
        if (validation.isRedirect){
            props.onLogOff(e);
        }
    }
    function submitUserPassword(e){
        setValidation(e);
    }
    
    function onYesClick(e){
        setValidation({isValid: false, isRedirect: false});
        props.onYes(e);
    }
    
    return (
    <div className="modal" style={{ display: props.show ? "block" : "none" }}>
            <div className="modal-wrapper"
                 style={{
                     transform: props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                     opacity: props.show ? '1' : '0'
                 }}>

                <div className="modal-header">
                    <h3>Confirm user password</h3>
                    <span className="close-modal-btn" onClick={e => onCancelClick(e)}>×</span>
                </div>

                <div className="modal-body">
                    <br/>
                    {validation.isRedirect ? 
                        <div>Too many wrong password attempts.</div> : <ConfirmUserPasswordForm onSubmit={submitUserPassword} isValid={validation.isValid}/>}
                </div>

                <div className="modal-footer">
                    <button className="secondary-btn btn-small" onClick={e => onCancelClick(e)}>Cancel</button>
                    {validation.isValid && <button className="primary-btn btn-small" onClick={onYesClick}>Yes</button>}
                </div>
            </div>
        </div>
    );
}