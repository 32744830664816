/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

export class TimeRightPanel extends Component {
    render() {
        return <div className="grey-container">
            <h5 className="sectionTitle">Graph Options</h5>
            <div className="row no-gutters">
                <div className="col-sm-2 col-md-6 col-lg-3 col-xl-2">
                    <div style={{marginLeft: "10px"}}>Start:</div>
                </div>
                <div className="col-sm-9 col-md-12 col-lg-9 centered" style={{textAlign: "center"}}>
                    <DatePicker autoComplete="off"
                                customInput={<input className="txt-detail" style={{
                                    maxWidth: "274px",
                                    textAlign: "center",
                                    paddingRight: "18px"
                                }}/>}
                                id="startDate"
                                name="startDate"
                                selected={this.props.selected}
                                onChange={this.props.onChange}
                                dateFormat="MM/dd/yyyy h:mm:ss aa"
                                timeIntervals={15}
                                showTimeSelect
                                popperPlacement="top-end"
                                popperProps={{
                                    positionFixed: true
                                }}
                    />
                </div>
            </div>

            <div className="row no-gutters">
                <div className="col-sm-2 col-md-6 col-lg-3 col-xl-2">
                    <div style={{marginLeft: "10px"}}>End:</div>
                </div>
                <div className="col-sm-9 col-md-12 col-lg-9 centered" style={{textAlign: "center"}}>
                    <DatePicker autoComplete="off"
                                customInput={<input className="txt-detail" style={{
                                    maxWidth: "274px",
                                    textAlign: "center",
                                    paddingRight: "18px",
                                    marginBottom: "0px"
                                }}/>}
                                id="endDate"
                                name="endDate"
                                selected={this.props.selected1}
                                onChange={this.props.onChange1}
                                dateFormat="MM/dd/yyyy h:mm:ss aa"
                                timeIntervals={15}
                                showTimeSelect
                                popperPlacement="top-end"
                                popperProps={{
                                    positionFixed: true
                                }}
                    />
                </div>
            </div>
            <div className="row no-gutters centered">
                <button style={{marginTop: ".7em"}} className="secondary-btn btn-small"
                        onClick={this.props.onClick}> Update Graph
                </button>
                <button style={{marginTop: ".7em"}} className="secondary-btn btn-small"
                        onClick={this.props.onClick1}> Fullscreen
                </button>
                {this.props.showAnalyticsButton ? (<button className={this.props.showAnnotations && this.props.hasAnalytics ? "primary-btn btn-small" : "secondary-btn btn-small"}
                    onClick={this.props.onClick3} disabled={!this.props.hasAnalytics} style={{ marginTop: ".7em" }}>Run Analytic
                        </button>) : null}
                {this.props.showZoomButton ? (<button style={{marginTop: ".7em"}} className="secondary-btn btn-small"
                                                      onClick={this.props.onClick2}>Zoom Out</button>) : null}
            </div>
        </div>;
    }
}

TimeRightPanel.propTypes = {
    selected: PropTypes.any,
    onChange: PropTypes.func,
    selected1: PropTypes.any,
    onChange1: PropTypes.func,
    onClick: PropTypes.func,
    onClick1: PropTypes.func,
    showZoomButton: PropTypes.any,
    onClick2: PropTypes.func,
    hasAnalyics: PropTypes.any
};