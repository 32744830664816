/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import HighchartGraph from '../Graph/HighchartGraph';
import { jsSource } from "../../componentObjects";
import 'react-tabs/style/react-tabs.css';
import "../../CSS/DataModal.css";
import moment from 'moment';

class InstallCheckGraphModal extends Component {

    constructor(props) {
        super(props);

        this.renderGraph = this.renderGraph.bind(this);

        this.state = {
            currentRunName: "",
            currentSource: jsSource,
        }
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
        this.props.onClick();
    };

    renderGraph() {
        let dataObject = this.props.dataObject;
        var maxValue = Math.max(...dataObject["current"].map(a => a.Value));
        let runDataList = [];
        var globalPointStart = null;

        let multiseriesGraph = {
            graphAnnotations: [],
            highchartsGraphAnnotations: null,
            highchartsSeries: null,
            series: [],
            xAxisLabel: null,
            yAxisLabel: "Amps",
            yDataMax: maxValue,
            yDataMin: 0
        };

        var i = 0;
        for (let seriesName in dataObject) {
            if (dataObject[seriesName]) {
                multiseriesGraph.series[i] = {
                    color: seriesName == "current" ? "blue" : seriesName == "blowercurrent" ? "red" : "green",
                    data: dataObject[seriesName],
                    name: "Predicted Average Compressor Amps",
                    referenceLines: [],
                    unit: "A"
                };
                let dataArr = dataObject[seriesName].map(a => Number(a.Value));
                let pointStart = moment(dataObject[seriesName][0].Timestamp);
                globalPointStart = pointStart;
                let data = {
                    name: seriesName == "current" ? "Main Current" : seriesName == "blowercurrent" ? "Blower Current" : seriesName == "inducercurrent" ? "Inducer Current" : seriesName,
                    data: dataArr,
                    color: seriesName == "current" ? "blue" : seriesName == "blowercurrent" ? "red" : "green",
                    zIndex: 2,
                    type: 'line',
                    pointStart: pointStart,
                    pointInterval: 1000
                };
                runDataList = runDataList.concat(data);
                i = i + 1;
            }
        }



        return (
            <div>
                <HighchartGraph graphType={"TRIAGEALERTS"} chartData={runDataList} key={`Multiseries-${multiseriesGraph.series.length}-fullscreen`} graph={multiseriesGraph} isAirConditioner={false} dataMax={maxValue}
                    showRLA={false} rlaValue={null} showLRA={false} lraValue={null} showFLA={false} flaValue={null} graphAnnotations={null}
                    showAnalytics={false} hasAnalytics={false} showFurnace={true} showTemp={false} timeString={null} pointStart={globalPointStart} />
            </div>
        );
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        else {

            return (
                <div className="fullscreen-modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="big-modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header" >
                            <h3>Install Graphs</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ textAlign: "center", justifyContent: "center" }}>

                            {this.renderGraph()}

                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Close</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default InstallCheckGraphModal;