/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from "react";
//import DatePicker from "react-datepicker";
import * as PropTypes from "prop-types";
import moment from 'moment';

export class ACRightPanel extends Component {

    getRunName(run) {
        if (run === "All Runs")
            return run;

        let status = run.charAt(0) === 'g' ? "" : run.charAt(0) === 'y' ? "" : run.charAt(0) === 'r' ? "BAD RUN: " : "UNTRAINED: ";
        let date = run.substr(1);
        let name = moment(date).add(moment().utcOffset(), "minutes");
        let dateTimeName = `${status} ${name.format("l h:mm:ss A")}`;
        return dateTimeName;
    }

    render() {
        /*let timelineOptions = this.props.isDaily ?
            <div>
                <div className="row no-gutters">
                    <div className="col-sm-2 col-md-6 col-lg-3 col-xl-2">
                        <div style={{marginLeft: "10px"}}>Start:</div>
                    </div>
                    <div className="col-sm-9 col-md-12 col-lg-9 centered" style={{textAlign: "center"}}>
                        <DatePicker autoComplete="off"
                                    customInput={<input className="txt-detail" style={{
                                        maxWidth: "274px",
                                        textAlign: "center",
                                        paddingRight: "18px"
                                    }}/>}
                                    id="startDate"
                                    name="startDate"
                                    selected={this.props.selected}
                                    onChange={this.props.onChange}
                                    dateFormat="MM/dd/yyyy h:mm:ss aa"
                                    timeIntervals={15}
                                    showTimeSelect
                                    popperPlacement="top-end"
                                    popperProps={{
                                        positionFixed: true
                                    }}
                        />
                    </div>
                </div>

                <div className="row no-gutters">
                    <div className="col-sm-2 col-md-6 col-lg-3 col-xl-2">
                        <div style={{marginLeft: "10px"}}>End:</div>
                    </div>
                    <div className="col-sm-9 col-md-12 col-lg-9 centered" style={{textAlign: "center"}}>
                        <DatePicker autoComplete="off"
                                    customInput={<input className="txt-detail" style={{
                                        maxWidth: "274px",
                                        textAlign: "center",
                                        paddingRight: "18px",
                                        marginBottom: "0px"
                                    }}/>}
                                    id="endDate"
                                    name="endDate"
                                    selected={this.props.selected1}
                                    onChange={this.props.onChange1}
                                    dateFormat="MM/dd/yyyy h:mm:ss aa"
                                    timeIntervals={15}
                                    showTimeSelect
                                    popperPlacement="top-end"
                                    popperProps={{
                                        positionFixed: true
                                    }}
                        />
                    </div>
                </div>
                <div className="row no-gutters centered">
                    <button style={{marginTop: ".7em"}} className="secondary-btn btn-small"
                            onClick={this.props.onClick3}> Update Graph
                    </button>
                </div>
            </div >
            : null;*/

        let runOptions = !this.props.isDaily && this.props.runNamesList !== null && this.props.runNamesList.length > 1 ?
            <div className="row">
                <div className="col-sm-2 col-md-6 col-lg-3 col-xl-2">
                    <div style={{ marginLeft: '10px' }}>Runs:</div>
                </div>
                <div className="col-sm-9 col-md-12 col-lg-9 centered">
                    <button className="btn-detail" onClick={this.props.onLeftRunClick} disabled={!this.props.canLeftClick} style={{ width: '23px' }} > {"<"}</button>
                    <select className="txt-detail" onChange={this.props.onChangeRun} value={this.props.selectedRun} style={{ maxWidth: '228px', paddingLeft: '10px', paddingRight: '18px' }}>
                        {this.props.runNamesList !== null ?
                            this.props.runNamesList.map((run, index) =>
                                <option key={`${index}-${run}`} id={`${index}-${run}`} value={index}> {this.getRunName(run)} &nbsp; &nbsp; &nbsp;</option>
                            )
                            : <option value="">No Runs</option>
                        }
                    </select>
                    <button className="btn-detail" onClick={this.props.onRightRunClick} disabled={!this.props.canRightClick} style={{ width: '23px' }} >{">"}</button>
                </div>
            </div>
            : null;

        return (
            <div>
                <div className="grey-container">
                    <h5 className="sectionTitle">
                        Graph Options
                    </h5>

                    {runOptions}
                    {/*timelineOptions*/}

                    <div className="radio-container" style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div>
                            <input type="checkbox" value="analytics" checked={this.props.visibility.analytics} id="gdo" onChange={this.props.handleCheckbox} disabled={!this.props.hasAnalytics} />
                                &nbsp; Analytics &nbsp;&nbsp;
                            </div>
                        <div>
                            <input type="checkbox" value="furnace" checked={this.props.visibility.furnace} id="gdo" onChange={this.props.handleCheckbox} />
                                &nbsp; Furnace Current &nbsp;&nbsp;
                            </div>
                        <div>
                            <input type="checkbox" value="temp" checked={this.props.visibility.temp} id="gdo" onChange={this.props.handleCheckbox} />
                                &nbsp; Temperature &nbsp;&nbsp;
                            </div>
                        <div>
                            <input type="checkbox" value="rla" checked={this.props.visibility.rla} id="gdo" onChange={this.props.handleCheckbox} />
                                &nbsp; RLA &nbsp;&nbsp;
                            </div>
                        <div>
                            <input type="checkbox" value="lra" checked={this.props.visibility.lra} id="gdo" onChange={this.props.handleCheckbox} />
                                &nbsp; LRA &nbsp;&nbsp;
                            </div>
                    </div>
                </div>
            </div>
        );
    }
}

ACRightPanel.propTypes = {
    fullScreenBtn: PropTypes.any,
    showAnnotations: PropTypes.any,
    onClick: PropTypes.func,
    onClick1: PropTypes.func,
    onLeftRunClick: PropTypes.func,
    onRightRunClick: PropTypes.func,
    selectedRun: PropTypes.any,
    zoomOutEnabled: PropTypes.any,
    hasAnalytics: PropTypes.any,
    visibility: PropTypes.any,
    handleCheckbox: PropTypes.func,
    isDaily: PropTypes.any,
    canLeftClick: PropTypes.any,
    canRightClick: PropTypes.any,
    runNamesList: PropTypes.any
};