/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/schedule";
class ScheduleService {

    async getopportunities(start, end) {
        const response = await http.post(`${controller}/getopportunities?startTime=${start}&endTime=${end}`);
        const data = response;
        return data;
    }

    async getuseropportunities(start, end, alerts) {
        const response = await http.post(`${controller}/getuseropportunities?startTime=${start}&endTime=${end}`, alerts);
        const data = response;
        return data;
    }

    async getopportunitydetails(caseId) {
        const response = await http.post(`${controller}/getopportunitydetails?caseId=${caseId}`);
        const data = response;
        return data;
    }

    async updateopportunity(date, id, opp) {
        const response = await http.post(`${controller}/updateopportunity?dateStr=${date}&assignId=${id}`, opp);
        const data = response;
        return data;
    }

    async closeOpportunity( id, opp) {
        const response = await http.post(`${controller}/closeOpportunity?assignId=${id}`, opp);
        const data = response;
        return data;
    }

    async updateopportunitystatus(caseId, status) {
        const response = await http.post(`${controller}/updateopportunitystatus?caseId=${caseId}&newStatus=${status}`);
        const data = response;
        return data;
    }
}

export default new ScheduleService();