/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/triagecheckout";
const triagecheckoutService = {
    getuntriagedqueueasset: async (userId, checkoutTime, assets) => {
        const response = await http.post(`${controller}/getuntriagedqueueasset?userId=${userId}&checkoutTime=${checkoutTime}`, assets);
        return response.data;
    },
    gettriagequeueitem: async (userId, checkoutTime, triageInfo) => {
        const response = await http.post(`${controller}/gettriagequeueitem?userId=${userId}&checkoutTime=${checkoutTime}`, triageInfo);
        return response.data;
    },
    skipasset: async (triageSkip) => {
        const response = await http.post(`${controller}/skipasset`, triageSkip);
        return response.data;
    },
    finishasset: async (triageCheckout) => {
        const response = await http.post(`${controller}/finishasset`, triageCheckout);
        return response.data;
    },
    clearuserskips: async (userId) => {
        const response = await http.post(`${controller}/clearuserskips?userId=${userId}`);
        return response.data;
    },
    getskippedassets: async (userId, userEmail = null) => {
        const response = await http.post(`${controller}/getskippedassets?userId=${userId}&userEmail=${userEmail}`);
        return response.data;
    },
    getcurrentcheckouts: async () => {
        const response = await http.post(`${controller}/getcurrentcheckouts`);
        return response.data;
    },
    clearcheckouts: async (clearAll, checkoutIdStr = null) => {
        const response = await http.post(`${controller}/clearcheckouts?clearAll=${clearAll}&checkoutIdStr=${checkoutIdStr}`);
        return response.data;
    }
};
export default triagecheckoutService;
