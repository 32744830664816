/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import "../../CSS/DataModal.css";
import DatePicker from 'react-datepicker';

class MuteAlertConfirmationModal extends React.Component {

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {

    };

    render() {

        if (!this.props.show) {
            return null;
        }
        else {

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0',
                            width : "500px",
                            left : "35%"
                        }}>

                        <div className="modal-header">
                            {/*<h3>{this.props.title}</h3>*/}
                            <h3>Silence AC Device Alerts</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ height : '300px'} } >

                            {this.props.message}

                            <label htmlFor="description">AC Alerts mute until</label>
                            <DatePicker
                                customInput={<input className="txt-detail" /*disabled={this.state.disableGraphOptions}*/ style={{ maxWidth:'200px', textAlign: "center" }} />}
                                id="Mute Until"
                                name="Mute Until"
                                selected={this.props.selectedMutedDate }
                                onChange={date => { this.props.onChangeMuteUntillDate(date); }}
                                className="text-center date-picker-reports"
                                popperPlacement="top-end"
                                popperProps={{
                                    positionFixed: true
                                }}
                                containerStyle={{ //datepicker container style
                                    width: "180px",
                                    margin: "auto"
                                }}
                                style={{ //input style
                                    width: "100%",
                                    height: "26px",
                                    boxSizing: "border-box"
                                }}

                            />
                        </div>
                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={this.props.onYes}>Yes</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default MuteAlertConfirmationModal;
