/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import AccountService from '../../services/account.service';
import AlertService from '../../services/alerts.service';
import PartnerService from '../../services/partner.service';
import ServiceTitanService from '../../services/servicetitan.service';
import Select from 'react-select';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import memoize from 'memoize-one';
import moment from 'moment';
import { PageView, ModalView, Event, Timing } from "../GoogleAnalytics";
import UpgradeSubscriptionModal from "../Dashboard/UpgradeSubscriptionModal";

import Snackbar from '@material-ui/core/Snackbar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Pagination from '@material-ui/lab/Pagination';
import LongArrowUpIcon from '@material-ui/icons/ArrowUpward';
import LongArrowDownIcon from '@material-ui/icons/ArrowDownward';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { AlertTypeIdsEnum, TagIdsEnum, alertSearchStartDate } from '../../componentObjects';
import makeAnimated from 'react-select/animated';
import billingService from "../../services/billing.service";

const animatedComponents = makeAnimated();

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important'
        },
    }
});

const subscriptionOptions = [
    {
        value: 'All',
        label: 'All'
    },
    {
        value: '1',
        label: '1 Year'
    },
    {
        value: '2',
        label: '2 Year'
    },
    {
        value: '3',
        label: '3 Year'
    }
];

const showingOptions = [
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'Early Terminations',
        label: 'Early Terminations'
    },
    {
        value: 'Both',
        label: 'Both'
    }
];

/*const billingOptions = [
    {
        value: '08/01/23 - 08/31/23',
        label: '08/01/23 - 08/31/23'
    }, 
    {
        value: '07/01/23 - 07/31/23',
        label: '07/01/23 - 07/31/23'
    },
    {
        value: '06/01/23 - 06/30/23',
        label: '06/01/23 - 06/30/23'
    },
    {
        value: '05/01/23 - 05/31/23',
        label: '05/01/23 - 05/31/23'
    },
    {
        value: '04/01/23 - 04/30/23',
        label: '04/01/23 - 04/30/23'
    },
    {
        value: '03/01/23 - 03/31/23',
        label: '03/01/23 - 03/31/23'
    },
    {
        value: '02/01/23 - 02/29/23',
        label: '02/01/23 - 02/29/23'
    }
];*/

const QUITCLASSCOST_UnderOneYear = "$200";
const QUITCLASSCOST_OneToTwoYears = "$150";
const QUITCLASSCOST_TwoToThreeYears = "$100";

class Subscriptions extends Component {
    static displayName = Subscriptions.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.loadInfo = this.loadInfo.bind(this);
        this.getActiveByPeriod = this.getActiveByPeriod.bind(this);
        this.earlyTerminationSubscriptionsByPeriod = this.earlyTerminationSubscriptionsByPeriod.bind(this);
        this.getBillingOptions = this.getBillingOptions.bind(this);
        this.changeBillingFilter = this.changeBillingFilter.bind(this);
        this.changeSubFilter = this.changeSubFilter.bind(this);
        this.changeShowingFilter = this.changeShowingFilter.bind(this);

        this.onColumnClick = this.onColumnClick.bind(this);
        this.columnSort = this.columnSort.bind(this);

        this.onChangePage = this.onChangePage.bind(this);
        this.getPageList = this.getPageList.bind(this);
        this.getSummary = this.getSummary.bind(this);

        this.state = {
            message: "",
            showMessage: false,

            loading: false,
            subscriptionInfo: {},
            subLengthDict: {},
            subLengthObj: {},
            subPeriodDict: {},
            subList: [],

            selectedSubLength: subscriptionOptions[0],
            selectedShowing: showingOptions[0],

            selectedBillingPeriod: null, //billingOptions[0],
            selectedBillingIndex: 0,
            billingPeriods: [], //billingOptions,

            columnSubList: [],
            columnAltered: false,
            columnSort: {
                enabled: false,
                type: null,
                reverse: false
            },

            pageCount: 1,
            currentPage: 1,
            summary: {
                oneYr: 0,
                twoYr: 0,
                threeYr: 0,
                zeroToOneYr: 0,
                oneToTwoYr: 0,
                twoToThreeYr: 0
            },
            isMonitoringContractType: false 
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        PageView();

        if (!this.props.isLoading) {
            let isContractType = this.props.attributeList?.find(x => (x.name == "ContractType" && x.organizationId == this.props.currentPartnerId))?.value === "Monitoring"

            this.setState({ loading: true, isMonitoringContractType: isContractType }, async () => {
                this.loadInfo();
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadInfo() {
        if (this._isMounted)
            this.setState({ loading: true }, async () => {
                await billingService.getpartnersubscriptions(this.props.currentPartnerId)
                    .then(response => {
                        if (this._isMounted)
                            this.setState({ subscriptionInfo: response }, () => {
                                let periods = this.state.subscriptionInfo !== null && this.state.subscriptionInfo !== undefined && this.state.subscriptionInfo.billingPeriods !== null && this.state.subscriptionInfo.billingPeriods !== undefined ? this.getBillingOptions(this.state.subscriptionInfo.billingPeriods) : [];
                                if (periods !== undefined && periods.length) {
                                    this.setState({
                                        loading: false,
                                        billingPeriods: periods,
                                        selectedBillingPeriod: periods[0],
                                        subLengthDict: this.state.subscriptionInfo.subscriptionsDict
                                    }, () => {
                                        this.setState({ subLengthObj: this.state.subLengthDict }, () => {
                                            if (this.state.subLengthObj !== undefined){
                                                let activeSubscriptionsByPeriodDict = this.state.subLengthObj.filter(o=>o.end === null);
                                                let dateRange = this.state.selectedBillingPeriod.label.split('-');
                                                let starDate = dateRange[0].trim();
                                                let endDate = dateRange[1].trim();
                                                this.setState({
                                                    subPeriodDict: activeSubscriptionsByPeriodDict,
                                                    subList: activeSubscriptionsByPeriodDict !== undefined && activeSubscriptionsByPeriodDict !== null ? activeSubscriptionsByPeriodDict.filter(o=> new Date(new Date(o.start).setFullYear(new Date(o.start).getFullYear() + o.duration)) >= new Date(starDate) && new Date(o.start) <= new Date(endDate)) : []
                                                }, () => {
                                                    this.getSummary();
                                                    this.setUpPagination();
                                                });
                                            }
                                        });
                                    });
                                }
                                else {
                                    this.setState({
                                        loading: false,
                                        message: "No Partner Subscriptions Found",
                                        showMessage: true,
                                        pageCount: 1
                                    });

                                    setTimeout(() => {
                                        this.setState({
                                            message: "",
                                            showMessage: false
                                        });
                                    }, 3000);
                                }
                            })
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({
                            loading: false,
                            message: "Error occured while getting Partner Subscriptions",
                            showMessage: true
                        });

                        setTimeout(() => {
                            this.setState({
                                message: "",
                                showMessage: false
                            });
                        }, 3000);
                    })
            });
    }
    
    getActiveByPeriod(filter){
        let activeSubscriptionsByPeriodDict = this.state.subLengthObj.filter(o=>o.end === null);
        if (filter !== undefined && filter !== null && !isNaN(filter) && !isNaN(parseFloat(filter))){
            activeSubscriptionsByPeriodDict = activeSubscriptionsByPeriodDict.filter(o=>o.duration === filter);
        }
        let dateRange = this.state.selectedBillingPeriod.label.split('-');
        let starDate = dateRange[0].trim();
        let endDate = dateRange[1].trim();
        return activeSubscriptionsByPeriodDict.filter(o=> new Date(new Date(o.start).setFullYear(new Date(o.start).getFullYear() + o.duration)) >= new Date(starDate) && new Date(o.start) <= new Date(endDate));
    }

    earlyTerminationSubscriptionsByPeriod(filter){
        let earlyTerminationSubscriptionsByPeriodDict = this.state.subLengthObj;
        if (filter !== undefined && filter !== null && !isNaN(filter) && !isNaN(parseFloat(filter))){
            //earlyTerminationSubscriptionsByPeriodDict = earlyTerminationSubscriptionsByPeriodDict.filter(o=> o.duration === filter);
            switch(filter) {
                case 1:
                    earlyTerminationSubscriptionsByPeriodDict = earlyTerminationSubscriptionsByPeriodDict.filter(o=> o.remaining.includes("2 Year"));
                    break;
                case 2:
                    earlyTerminationSubscriptionsByPeriodDict = earlyTerminationSubscriptionsByPeriodDict.filter(o=> o.remaining.includes("1 Year"));
                    break;
                default:
                    earlyTerminationSubscriptionsByPeriodDict = earlyTerminationSubscriptionsByPeriodDict.filter(o=> !o.remaining.includes("Years"));
            }
        }
        earlyTerminationSubscriptionsByPeriodDict = earlyTerminationSubscriptionsByPeriodDict.filter(o=>o.end !== null);
        
        let dateRange = this.state.selectedBillingPeriod.label.split('-');
        let starDate = dateRange[0].trim();
        let endDate = dateRange[1].trim();
        return earlyTerminationSubscriptionsByPeriodDict.filter(o=> new Date(o.end) <= new Date(endDate));
    }
    getBillingOptions(periods) {
        let options = [];

        if (periods !== undefined) {
            for (var i = 0; i < periods.length; i++) {
                var option = {
                    value: i,
                    label: periods[i]
                };

                options = options.concat(option);
            }
        }

        return options;
    }

    setUpPagination() {
        let tableList = /*this.state.columnSort.enabled ? this.state.columnSubList :*/ this.state.subList;
        let pageCount = 1;
        if (tableList.length > 100) {
            pageCount = Math.ceil(tableList.length / 100);
        }
        this.setState({ pageCount: pageCount });
    }

    getSummary() {
        let list = this.state.subLengthDict !== {} && this.state.subLengthDict !== undefined && this.getActiveByPeriod();//this.state.subList;
        let earlyTerms = this.state.subLengthDict !== {} && this.state.subLengthDict !== undefined && this.earlyTerminationSubscriptionsByPeriod();

        if (list.length) {
            let oneYr = list.filter(s => s.duration.includes("1")).length;
            let twoYr = list.filter(s => s.duration.includes("2")).length;
            let threeYr = list.filter(s => s.duration.includes("3")).length;

            //let earlyTerms = list.filter(s => s.end !== null && s.end !== "");

            //todo FIX
            /*let twoToThree = earlyTerms.filter(s => s.amountToBeCharged == QUITCLASSCOST_TwoToThreeYears).length; //!s.remaining.includes("Years")).length;
            let oneToTwo = earlyTerms.filter(s => s.amountToBeCharged == QUITCLASSCOST_OneToTwoYears).length; //s.remaining.includes("1 Year")).length;
            let zeroToOne = earlyTerms.filter(s => s.amountToBeCharged == QUITCLASSCOST_UnderOneYear).length; //s.remaining.includes("2 Year")).length;
            */
            let twoToThree = earlyTerms.filter(s => !s.remaining.includes("Years")).length;
            let oneToTwo = earlyTerms.filter(s => s.remaining.includes("1 Year")).length;
            let zeroToOne = earlyTerms.filter(s => s.remaining.includes("2 Year")).length;

            let summary = {
                oneYr: oneYr,
                twoYr: twoYr,
                threeYr: threeYr,
                zeroToOneYr: zeroToOne,
                oneToTwoYr: oneToTwo,
                twoToThreeYr: twoToThree
            };

            if (this._isMounted)
                this.setState({ summary: summary });
        }
    }

/* FILTERS */

    changeBillingFilter = (selectedOption) => {
        Event("Changed Billing Date Filter", "User changed billing date filter for Subscriptions table", "Changed Billing Date Filter");
        let x = selectedOption.value;
        let index = x;//this.state.billingPeriods[x]; 
        //let list = this.state.subPeriodDict !== undefined && this.state.subPeriodDict.length >= index ? this.state.subPeriodDict[index] : [];
        this.setState({
            selectedBillingPeriod: selectedOption,
            selectedBillingIndex: index
        }, ()=>{
            let list = this.state.subPeriodDict !== undefined && this.getActiveByPeriod();

            if (this._isMounted)
                this.setState({
                    subList: list,
                    selectedSubLength: subscriptionOptions[0],
                    selectedShowing: showingOptions[0],
                    currentPage: 1,
                    colSort: {
                        enabled: false,
                        type: null,
                        reverse: false
                    }
                }, () => {
                    this.getSummary();
                    this.setUpPagination();
                });
        });
    }

    changeSubFilter = (selectedOption) => {
        Event("Changed Subscription Length Filter", "User changed subscription length filter for Subscriptions table", "Changed Subscription Length Filter");
        let x = selectedOption;

        if (x !== this.state.selectedSubLength) {
            let subPeriodDict = this.getActiveByPeriod(x.value);
            if (this.state.selectedShowing.value !== "Active")
                subPeriodDict = this.state.selectedShowing.value == "Both" ? this.getActiveByPeriod(x.value) : this.earlyTerminationSubscriptionsByPeriod(x.value);
            let subList = subPeriodDict;

            if (this._isMounted)
                this.setState({
                    selectedSubLength: x,
                    subPeriodDict: subPeriodDict,
                    subList: subList,
                    currentPage: 1,
                    colSort: {
                        enabled: false,
                        type: null,
                        reverse: false
                    }
                }, () => {
                    this.getSummary();
                    this.setUpPagination();
                });
        }
            
    }

    changeShowingFilter = (selectedOption) => {
        Event("Changed Showing Filter", "User changed showing filter for Subscriptions table", "Changed Showing Filter");
        let x = selectedOption;

        if (x !== this.state.selectedShowing) {
            let subPeriodDict = this.getActiveByPeriod(this.state.selectedSubLength.value);
            if (x.value !== "Active")
                subPeriodDict = x.value == "Both" ? this.getActiveByPeriod(this.state.selectedSubLength.value) : this.earlyTerminationSubscriptionsByPeriod(this.state.selectedSubLength.value);
            let subList = subPeriodDict;

            if (this._isMounted)
                this.setState({
                    selectedShowing: x,
                    subPeriodDict: subPeriodDict,
                    subList: subList,
                    currentPage: 1,
                    colSort: {
                        enabled: false,
                        type: null,
                        reverse: false
                    }
                }, () => {
                    this.getSummary();
                    this.setUpPagination();
                });
        }
            
    }

/* TABLE HELPERS */

    onColumnClick(selectedCol) {
        const colSort = this.state.columnSort;
        let newSort = colSort;
        const subList = this.state.subList; 
        let subs = subList;
        let colSubs = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colSubs = this.columnSort(subs, "START", false); /* default sort by is start time */
                    colAltered = false;
                }
                else {
                    newSort.reverse = true;
                    colSubs = this.columnSort(subs, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colSubs = this.columnSort(subs, selectedCol, false);
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colSubs = this.columnSort(subs, selectedCol, false);
            colAltered = true;
        }

        if (this._isMounted)
            this.setState({ columnSort: newSort, columnSubList: colSubs }, () => {
                this.setState({ columnAltered: colAltered });
            });
    }

    columnSort(list, col, isReverse) {
        let colList = [];
        let subs = list; //this.getPage() ?

        if (col === "DEVICEID") {
            colList = subs.sort((a, b) => {
                const valA = a.deviceId.toLocaleUpperCase();
                const valB = b.deviceId.toLocaleUpperCase();

                return valA.localeCompare(valB, undefined, { numeric: true });
            });
        }
        else if (col === "NAME") {
            colList = subs.sort((a, b) => {
                const valA = a.name.toLocaleUpperCase();
                const valB = b.name.toLocaleUpperCase();

                return valA.localeCompare(valB, undefined, { numeric: true });
            });
        }
        else if (col === "ADDRESS") {
            colList = subs.sort((a, b) => {
                const valA = a.address.toLocaleUpperCase();
                const valB = b.address.toLocaleUpperCase();

                return valA.localeCompare(valB, undefined, { numeric: true });
            });
        }
        else if (col === "START") {
            colList = subs.sort((a, b) => {
                const dateA = new Date(a.start);
                const dateB = new Date(b.start);
                return dateB - dateA;
            });
        }
        else if (col === "LENGTH") {
            colList = subs.sort((a, b) => {
                const valA = a.duration.split(' ');
                const valB = b.duration.split(' ');

                return valB - valA;
            });
        }
        else if (col === "REMAINING") {
            colList = subs.sort((a, b) => {
                let valA = 0;
                let valB = 0;

                let aSplit = a.remaining.split(' '); //[#,Years,#,Months] or [#, Months]
                let bSplit = b.remaining.split(' ');
                if (aSplit.length > bSplit.length) {
                    valA = 1;
                }
                else if (aSplit.length == bSplit.length) {
                    if (a.remaining.includes('Years')) {
                        valA = (parseInt(aSplit[0], 10) * 12) + parseInt(aSplit[2], 10);
                        valB = (parseInt(bSplit[0], 10) * 12) + parseInt(bSplit[2], 10);
                    }
                    else {
                        valA = parseInt(aSplit[0], 10);
                        valB = parseInt(bSplit[0], 10);
                    }
                }
                else if (aSplit.length < bSplit.length) {
                    valB = 1;
                }
                 
                return valB - valA;
            });
        }
        else if (col === "TERMINATION") {
            colList = subs.sort((a, b) => {
                const dateA = new Date(a.end);
                const dateB = new Date(b.end);
                return dateB - dateA;
            });
        }

        if (isReverse)
            colList = colList.reverse();

        return colList;
    }

    onChangePage(e, page) {
        if (this._isMounted)
            this.setState({ currentPage: page }, () => {
                this.getPageList();
            });
    }

    getPageList() {
        let page = null;
        let endIndex = (this.state.currentPage * 100) - 1;
        let startIndex = (this.state.currentPage- 1) * 100;

        if (this.state.subList !== null && this.state.subList !== undefined) {
            /*if (endIndex > this.state.subList.length - 1) {
                endIndex = this.state.subList.length - 1;
            }*/

            page = this.state.subList.slice(startIndex, endIndex);
        }
        
        return page;
    }

    renderTable() {
        let tableList = /*this.state.columnSort.enabled ? this.state.columnSubList :*/ this.getPageList();

        let arrowIcon = this.state.columnSort.enabled ?
            (this.state.columnSort.reverse ?
                <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
                : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />)
            : null;

        let blankIcon = <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px', visibility: 'hidden' }} />;

        let devIdIcon = this.state.columnSort.enabled && this.state.columnSort.type === "DEVICEID" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : <span style={{ height: '15px', paddingLeft: '3px' }}>
                {blankIcon}
            </span>;

        let nameIcon = this.state.columnSort.enabled && this.state.columnSort.type === "NAME" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : <span style={{ height: '15px', paddingLeft: '3px' }}>
                {blankIcon}
            </span>;
        
        let addressIcon = this.state.columnSort.enabled && this.state.columnSort.type === "ADDRESS" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : <span style={{ height: '15px', paddingLeft: '3px' }}>
                {blankIcon}
            </span>;
        
        let startIcon = this.state.columnSort.enabled && this.state.columnSort.type === "START" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : <span style={{ height: '15px', paddingLeft: '3px' }}>
                {blankIcon}
            </span>;

        let lengthIcon = this.state.columnSort.enabled && this.state.columnSort.type === "LENGTH" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : <span style={{ height: '15px', paddingLeft: '3px' }}>
                {blankIcon}
            </span>;

        let remainingIcon = this.state.columnSort.enabled && this.state.columnSort.type === "REMAINING" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : <span style={{ height: '15px', paddingLeft: '3px' }}>
                {blankIcon}
            </span>;

        let termIcon = this.state.columnSort.enabled && this.state.columnSort.type === "TERMINATION" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : <span style={{ height: '15px', paddingLeft: '3px' }}>
                {blankIcon}
            </span>;

        let termColumn = this.state.selectedShowing.value == "Active" ?
            null
            : <th>
                <div onClick={() => this.onColumnClick("TERMINATION")}>
                    <span style={{ cursor: 'pointer' }}>TERMINATION DATE</span>
                    {termIcon}
                </div>
            </th>;

        let tableContent = this.state.subList !== undefined && this.state.subList !== null && this.state.subList.length ?
            <tbody>
                {tableList.map((sub, index) =>
                    <tr className="alerts-table-row" key={`${sub.deviceId}-tableRow-${index}`} data={index}
                        value={index}>
                        <td style={{borderLeft: '1px solid #ebeef0'}}>{sub.deviceId}</td>
                        <td>{sub.name}</td>
                        <td>{sub.address}</td>
                        <td>{sub.start}</td>
                        {this.state.isMonitoringContractType ? null : <td><span>{sub.duration} Year</span></td>}
                        {this.state.isMonitoringContractType ? null : <td style={{borderRight: this.state.selectedShowing.value == "Active" ? '1px solid #ebeef0' : 'none'}}>{sub.remaining}</td>}
                        {this.state.selectedShowing.value == "Active" ?
                            null
                            : <td style={{borderRight: '1px solid #ebeef0'}}>{sub.end}</td>
                        }
                    </tr>
                )}
            </tbody>
            : <tbody>
            <tr className="alerts-table-row" key={`NoSubs-tableRow`}>
                    <td colSpan={this.state.selectedShowing.value == "Active" ? 4 : 5}>No subscriptions to show</td>
                </tr>
            </tbody>;

                    let pagination =
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination count={this.state.pageCount} page={this.state.currentPage} onChange={this.onChangePage} showFirstButton={this.state.pageCount > 7 ? true : false} showLastButton={this.state.pageCount > 7 ? true : false} size="small" />
            </div>;

        return (
            <div style={{ margin: '20px 30px' }}>
                <div style={{ justifyContent: 'center' }}>
                    <table style={{ width: '100%', textAlign: 'center', tableLayout: 'fixed', marginBottom: '30px' }}>
                        <thead>
                        <tr className="alerts-table-row">
                            <th className="table-shrink" style={{textAlign: 'center', padding: '10px 0px'}}>
                                <div onClick={() => this.onColumnClick("DEVICEID")}>
                                    <span style={{cursor: 'pointer'}}>DEVICE ID</span>
                                    {devIdIcon}
                                </div>
                            </th>
                            <th className="table-shrink" style={{textAlign: 'center', padding: '10px 0px'}}>
                                <div onClick={() => this.onColumnClick("NAME")}>
                                    <span style={{cursor: 'pointer'}}>NAME</span>
                                    {nameIcon}
                                </div>
                            </th>
                            <th className="table-shrink" style={{textAlign: 'center', padding: '10px 0px'}}>
                                <div onClick={() => this.onColumnClick("ADDRESS")}>
                                    <span style={{cursor: 'pointer'}}>ADDRESS</span>
                                    {addressIcon}
                                </div>
                            </th>
                            <th className="table-shrink" style={{textAlign: 'center', padding: '10px 0px'}}>
                                <div onClick={() => this.onColumnClick("START")}>
                                    <span style={{cursor: 'pointer'}}>CONTRACT START DATE</span>
                                    {startIcon}
                                </div>
                            </th>
                            {this.state.isMonitoringContractType ? null : <th>
                                <div onClick={() => this.onColumnClick("LENGTH")}>
                                    <span style={{cursor: 'pointer'}}>CONTRACT LENGTH</span>
                                    {lengthIcon}
                                </div>
                            </th>}
                            {this.state.isMonitoringContractType ? null : <th>
                                <div onClick={() => this.onColumnClick("REMAINING")}>
                                    <span style={{cursor: 'pointer'}}>REMAINING CONTRACT LENGTH</span>
                                    {remainingIcon}
                                </div>
                            </th>}
                            {termColumn}
                        </tr>
                        </thead>

                        {tableContent}

                    </table>
                    {pagination}
                </div>
            </div>
        );
    }

    render() {
        const {classes} = this.props;

        let loader = this.state.loading ?
            <div className={["loading", classes.loader].join(' ')} style={{display: "block"}}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div>
            </div>
            : null;


        let tableOptions =
            <div className="alerts-tab-wrapper" style={{ margin: '15px 30px', backgroundColor: "#f7f9fa", padding: '15px 0' }}>
                <div className="row no-gutters" style={{ paddingLeft: '40px', margin: '5px' }}>
                    <div style={{ height: '24px', display: 'flex', alignItems: 'center' }}>
                        &nbsp; Billing Date: &nbsp;&nbsp;
                        <span style={{ minWidth: '200px', marginRight: '20px' }}>
                            <Select 
                                styles={this.customStyles}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                options={this.state.billingPeriods}
                                onChange={this.changeBillingFilter}
                                defaultValue={this.state.selectedBillingPeriod}
                                value={this.state.selectedBillingPeriod}
                                />
                        </span>

                        {this.state.isMonitoringContractType ? null : <>&nbsp; Subscription Length: &nbsp;&nbsp;
                        <span style={{ minWidth: '200px', marginRight: '20px' }}>
                            <Select 
                                styles={this.customStyles}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                options={subscriptionOptions}
                                onChange={this.changeSubFilter}
                                defaultValue={this.state.selectedSubLength}
                                value={this.state.selectedSubLength}
                            />
                        </span></>}

                        &nbsp; Showing: &nbsp;&nbsp;
                        <span style={{ minWidth: '200px', marginRight: '20px' }}>
                            <Select 
                                styles={this.customStyles}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                options={showingOptions}
                                onChange={this.changeShowingFilter}
                                defaultValue={this.state.selectedShowing}
                                value={this.state.selectedShowing}
                                />
                        </span>
                        
                    </div>
                </div>
            </div>;

        let summary =
            <div className="alerts-tab-wrapper" style={{ margin: '20px 30px', border: '1px solid #ebeef0', borderRadius: '.1rem', boxShadow: 'inset 0 -1px 0 rgba(100,121,143,0.122)' }}>
                
                <div className="row no-gutters" style={{ marginTop: '15px' }}>
                    {this.state.isMonitoringContractType ? null : <div className="col-2">
                        <h6 style={{ paddingLeft: '20px' }}>Summary</h6>
                    </div>}
                    {this.state.isMonitoringContractType ? <div className="col-2">
                        <Card elevation={0}>
                            <CardContent style={{ padding: 'unset' }}>
                                <h6 style={{ textAlign: 'center' }}>Active</h6>
                                <div className="row no-gutters card-row" style={{ textAlign: 'center', fontSize: '80%', marginTop: '12px', marginBottom: '15px' }}>

                                    <div className="col-4 offset-4 dashboard-val">
                                        <div className="dashboard-number" style={{ marginBottom: '4px' }}>
                                            { Object.keys(this.state.subLengthObj).length === 0 && this.state.subLengthObj.constructor === Object ? 0 : this.getActiveByPeriod().length}
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div> : <div className="col-3" style={{ justifyContent: 'right' }}>
                        <Card elevation={0}>
                            <CardContent style={{ padding: 'unset' }}>
                                <h6 style={{ textAlign: 'center' }}>Contract Length</h6>
                                <div className="row no-gutters card-row" style={{ textAlign: 'center', fontSize: '80%', marginTop: '12px', marginBottom: '15px' }}>

                                    <div className="col-4 dashboard-val">
                                        <div className="dashboard-number" style={{ marginBottom: '4px' }}>
                                            {this.state.summary.oneYr}
                                        </div>
                                        <span style={{ color: "dimGray" }}>1 Year</span>
                                    </div>

                                    <div className="col-4" style={{ borderLeft: '1px solid lightGray', borderRight: '1px solid lightGray' }}>
                                        <div className="dashboard-val">
                                            <div className="dashboard-number" style={{ marginBottom: '4px' }}>
                                                {this.state.summary.twoYr}
                                                </div>
                                            <span style={{ color: "dimGray" }}>2 Year</span>
                                        </div>
                                    </div>

                                    <div className="col-4 dashboard-val">
                                        <div className="dashboard-number" style={{ marginBottom: '4px' }}>
                                            {this.state.summary.threeYr}
                                            </div>
                                        <span style={{ color: "dimGray" }}>3 Year</span>
                                    </div>

                                </div>
                            </CardContent>
                        </Card>
                    </div>}

                    <div className="col-3 offset-1" style={{ justifyContent: 'left' }}>
                        <Card elevation={0}>
                            <CardContent style={{ padding: 'unset' }}>
                                <h6 style={{ textAlign: 'center' }}>{this.state.isMonitoringContractType ? null : 'Early'} Terminations</h6>
                                <div className="row no-gutters card-row" style={{ textAlign: 'center', fontSize: '80%', marginTop: '12px', marginBottom: '15px' }}>

                                    {this.state.isMonitoringContractType ? <div className="col-4 offset-4 dashboard-val">
                                        <div className="dashboard-number" style={{marginBottom: '4px'}}>
                                            { Object.keys(this.state.subLengthObj).length === 0 && this.state.subLengthObj.constructor === Object ? 0 : this.earlyTerminationSubscriptionsByPeriod().length}
                                        </div>
                                    </div> : <div className="col-4 dashboard-val">
                                        <div className="dashboard-number" style={{marginBottom: '4px'}}>
                                            {this.state.summary.zeroToOneYr}
                                        </div>
                                        <span style={{ color: "dimGray" }}>0-1 Year</span>
                                    </div>}

                                    {this.state.isMonitoringContractType ? null : <div className="col-4" style={{ borderLeft: '1px solid lightGray', borderRight: '1px solid lightGray' }}>
                                        <div className="dashboard-val">
                                            <div className="dashboard-number" style={{ marginBottom: '4px' }}>
                                                {this.state.summary.oneToTwoYr}
                                                </div>
                                            <span style={{ color: "dimGray" }}>1-2 Year</span>
                                        </div>
                                    </div>}

                                    {this.state.isMonitoringContractType ? null : <div className="col-4 dashboard-val">
                                        <div className="dashboard-number" style={{ marginBottom: '4px' }}>
                                            {this.state.summary.twoToThreeYr}
                                            </div>
                                        <span style={{ color: "dimGray" }}>2-3 Year</span>
                                    </div>}

                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>;

        let table = this.renderTable();

        return (
            <div className={classes.root}>
                <div className="infoPage">

                    <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                        Subscriptions
                    </h2>

                    <div>
                        {tableOptions}
                        {summary}
                        {table}
                    </div>

                    <Snackbar open={this.state.showMessage} message={this.state.message} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} />

                    {loader}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Subscriptions);