/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ForgotPasswordForm from './ForgotPasswordForm';

class ForgotPassword extends Component {
    static displayName = ForgotPassword.name;

    render() {
       return (
            <ForgotPasswordForm passwordEmailSent={this.props.passwordEmailSent} endForgotPassword={this.props.endForgotPassword} isMobile={this.props.isMobile} />
       );
    }
}

export default withRouter(ForgotPassword);
