/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import makeAnimated from 'react-select/animated';

class JobDetailForm extends Component {

    getPriority(p) {
        if (p === 1)
            return "High";
        else if (p === 2)
            return "Medium";
        else if (p === 3)
            return "Low";
        else
            return "";
    }

    getDate(str) {
        let dateStr = str;
        let time = "";

        if (dateStr !== null && dateStr !== "") {
            dateStr = dateStr.replace("/", "");
            dateStr = dateStr.replace("Date(", "");
            dateStr = dateStr.replace(")", "");
            var unixDate = parseInt(dateStr);
            var date = new Date(1970, 1, 1, 0, 0, 0);
            date.setMilliseconds(unixDate);
            time = date.getMonth() + "/" + date.getDate() + "/" + date.getFullYear() + " " + date.toLocaleTimeString('en-US'); //date.ToString("yyyy-MM-dd'T'HH:mm:ssZ");
        }

        if (dateStr == null || dateStr == "")
            time = "";

        return time;
    }

    render() {
        return (
            <div>
                <div className="row no-gutters">
                    <div className="col">
                        <div className="row">
                            <div className="col-5">
                                <p className="semibold">Job Id:</p>
                            </div>
                            <div className="col">
                                <p>{this.props.case.caseId}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p className="semibold">Created On:</p>
                            </div>
                            <div className="col">
                                <p>{this.getDate(this.props.case.createdOn)}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p className="semibold">Scheduled For:</p>
                            </div>
                            <div className="col">
                                <p>{this.getDate(this.props.case.scheduledFor)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col-5">
                                <p className="semibold">Priority:</p>
                            </div>
                            <div className="col">
                                <p>{this.getPriority(this.props.case.priority)}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p className="semibold">Created By:</p>
                            </div>
                            <div className="col">
                                <p>{this.props.case.initiatedByName}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p className="semibold">Technician:</p>
                            </div>
                            <div className="col">
                                <p>{this.props.case.assignedToName}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        );
    }
}
export default JobDetailForm;