/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

export const partnerAdminRoles = [
    {
        roleId: 13,
        name: "Technician",
        description: "Technician"
    },
    {
        roleId: 14,
        name: "Scheduler",
        description: "Scheduler"
    },
    {
        roleId: 15,
        name: "Partner Admin",
        description: "Partner Admin"
    },
    {
        roleId: 26,
        name: "Operations",
        description: "Operations"
    }
];

export const roles = [
    {
        roleId: 1,
        name: "Owner",
        description: "Owner"
    },
    {
        roleId: 2,
        name: "Service",
        description: "Service"
    },
    {
        roleId: 3,
        name: "API",
        description: "API Service"
    },
    {
        roleId: 4,
        name: "Admin",
        description: "System Administrators"
    },
    {
        roleId: 5,
        name: "User",
        description: "Regular Users"
    },
    {
        roleId: 6,
        name: "Power User",
        description: "Power Users"
    },
    {
        roleId: 7,
        name: "Customer",
        description: "Customer"
    },
    {
        roleId: 8,
        name: "Test",
        description: "Test"
    },
    {
        roleId: 9,
        name: "GetAlerts",
        description: "GetAlerts"
    },
    {
        roleId: 10,
        name: "Customer Support",
        description: "Customer Support"
    },
    {
        roleId: 11,
        name: "OTA",
        description: "People who have access to OTA server and modify the configurations"
    },
    {
        roleId: 12,
        name: "Support",
        description: "Support"
    },
    {
        roleId: 13,
        name: "Technician",
        description: "Technician"
    },
    {
        roleId: 14,
        name: "Scheduler",
        description: "Scheduler"
    },
    {
        roleId: 15,
        name: "Partner Admin",
        description: "Partner Admin"
    },
    {
        roleId: 17,
        name: "Data Science",
        description: "Data Science"
    },
    {
        roleId: 18,
        name: "Read Only",
        description: "Read Only"
    },
    {
        roleId: 19,
        name: "Portal Admin",
        description: "LifeWhere Portal Admin"
    },
    {
        roleId: 23,
        name: "LW-FC Beta Installer",
        description: "LW-FC Beta Installer"
    },
    {
        roleId: 26,
        name: "Operations",
        description: "Operations"
    }
];
