/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/appointment";
const appointmentService = {
    getappointmentbyid: async (id) => {
        const response = await http.get(`${controller}/getappointmentbyid?caseId=${id}`);
        return response.data;
    },
    addappointmentdb: async (appt) => {
        const response = await http.post(`${controller}/addappointmentdb`, appt);
        return response.data;
    },
    updateappointmentdb: async (appt) => {
        const response = await http.put(`${controller}/updateappointmentdb`, appt);
        return response.data;
    }
};
export default appointmentService;