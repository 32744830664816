/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/iotest";
class IoTestService {

    async getIoTest(obj) {
        const response = await http.post(`${controller}/getIoTest`, obj);
        const data = await response.data;
        return data;
    }
}

export default new IoTestService();