/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, {useEffect, useState} from 'react';

const SelectHome = ({homes, onChange}) => {
    const [selected, setSelected] = useState(0);
    useEffect(() => {
        let isMounted = true;
        handleChange();
        if (isMounted && homes !== undefined && homes.length) {
            let firstHomeInList = homes[0];
            onChange(firstHomeInList);
        }
        return () => {
            isMounted = false;
        }
    }, [homes]);

    function onChangeItem(e){
        let value = homes[e.target.value];
        setSelected(e.target.value);
        onChange(value);
    }
    function handleChange(){
        setSelected(0);
    }

    function getPrettyName(name) {
        if (name !== null && name !== undefined) {

            var split = name.split("__");
            name = split[0].replace("?", "'");

            const indexOfSlash = name.indexOf(' / ');
            if (indexOfSlash !== -1) {
                name = name.substring(indexOfSlash + 3);
            }
            return name;
        }
        else {
            return "";
        }
    }

    return homes != null && homes.length ?
        <div className="select-container-2">
                <span className="select-message">Select which house<br />
                    <select className="selectpicker" style={{width: 300 + 'px'}} onChange={onChangeItem}
                            value={selected}>
                        {homes.map((obj, index) =>
                            <option key={`${index}-${obj.orgId}`} id={`${index}-${obj.orgId}`}
                                    value={index}>{getPrettyName(obj.nickname)}</option>
                        )}
                    </select>
                </span>
        </div>
        :
        <div className="select-container-2">
                <span className="select-message">Select which house<br />
                    <select className="selectpicker" style={{ width: 300 + 'px' }} >
                        
                    </select>
                </span>
        </div>
}
export default SelectHome;