/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import "../../../CSS/DataModal.css";
import styled from "styled-components";
import { Row, Container, Figure } from 'react-bootstrap';

const StyledContainer = styled.div`
    padding: 5px;
    text-align: left;
    margin-left: 10px;
    margin-top: 5px;
`;

const SideIcon = styled.div`
    padding-top:10px;
`;

export class WiFiProblem extends React.Component {
    
    render() {
        return (
            <Container>
                <h4>Gateway</h4>
                <h5>Wi-Fi Problems</h5>
                <StyledContainer>
                    <Row>
                        <div className="col" id="color-description">
                            <small>
                                The Wi-Fi configuration can only be restored through the LifeWhere mobile app. One sign that the gateway is not connected to Wi-Fi is that the gateway LED light will turn solid green.
                            </small> 
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <div className="col-sm-11 col-md-7 col-lg-5 px-1" id="color-name">
                            <SideIcon>
                                <Figure>
                                    <Figure.Image 
                                        width="auto"
                                        src={require('../../../img/NetworkConfig.png')}
                                        alt='mobileImg'
                                        
                                    />
                                </Figure>
                            </SideIcon> 
                        </div>
                        <div className="col-sm-11 col-md" id="color-description">
                            <small>To restore the Wi-Fi configuration, in the mobile app select Settings, Devices, and a Device. Then tap on the Advanced button, and then tap on Change Wi-Fi Network. Follow the mobile app instructions to select the Wi-Fi network and enter the password.</small>
                            <br /><br />
                            <small>Note: If the user sets the wrong network password during the provisioning process, the gateway LED light will blink yellow and then turn solid green, and the user will be asked to check the gateway LED light. If the gateway LED light is solid green, the user will be prompted to reconnect to Wi-Fi to enter the correct password.</small>
                        </div>
                    </Row>
                    <br/><br/><br/>
            
                </StyledContainer>
            </Container>
        );
    }
}