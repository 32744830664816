/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";
import { Spinner } from 'react-bootstrap';

//import * as ServiceTiers from '../Dashboard/ServiceTiers';

class UpgradeSubscriptionModal extends Component {

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    render() {

        if (!this.props.show) {
            return null;
        }
        else {
            var content = (this.props.serviceTier !== "0") ?
                <select className="selectpicker" style={{ width: 220 + 'px' }} onChange={this.props.onSubscriptionSelect} value={this.props.serviceTier}>
                    <option key="1" id="1" value="1">Pro-IQ Essentials</option>
                    <option key="2" id="2" value="2">Pro-IQ Essentials Plus</option>
                    <option key="3" id="3" value="3">Pro-IQ Advanced</option>
                    <option key="4" id="4" value="4">Pro-IQ Command</option>
                    <option key="5" id="5" value="5">Pro-IQ Protect</option>
                </select> :
                <Spinner animation="border" />

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Upgrade Subscription Level</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); }}>×</span>
                        </div>

                        <div className="modal-body">
                            <br />
                            What subscription would you like to upgrade to?
                            <div className="select-container" style={{ alignText: 'left' }}>
                                {content}
                            </div>
                            <br />
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); }}>Cancel</button>
                            <button type='button' className="primary-btn btn-small" onClick={this.props.onSave}>Upgrade</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}


export default UpgradeSubscriptionModal;