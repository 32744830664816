/*

Resideo/LifeWhere
Copyright (C) 2018-2023 Resideo/LifeWhere

mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";

class EmailLinkModal extends Component {

    onClick = e => {
        this.props.onClick();
    };

    onChange() {

    }

    onCopyBoth() {
        let linkObj = this.props.linkObj;
        if (linkObj !== null && linkObj !== undefined && linkObj.linkUrl !== null && linkObj.linkUrl !== "") {
            let emailBody = "Hello";
            if (linkObj.firstName !== null && linkObj.firstName !== "")
                emailBody += " " + linkObj.firstName;
            emailBody += ",\n\n";
            if (linkObj.companyName !== null && linkObj.companyName !== "")
                emailBody += linkObj.companyName + " is installing LifeWhere for you! Tap the link below to begin your account activation.\n\n";
            else 
                emailBody += "Tap the link below to begin your LifeWhere account activation.\n\n";
            emailBody += linkObj.linkUrl;
            
            navigator.clipboard.writeText(emailBody);
        }       
    }

    getLink(linkObj) {
        if (linkObj !== null && linkObj !== undefined) {
            console.log("linkObj:");
            console.log(linkObj);
            let linkStr = "No URL Found";
            if (linkObj.linkUrl !== null && linkObj.linkUrl !== "") {
                let emailBody = "Hello";
                if (linkObj.firstName !== null && linkObj.firstName !== "")
                    emailBody += " " + linkObj.firstName;
                emailBody += ",\n\n";
                if (linkObj.companyName !== null && linkObj.companyName !== "")
                    emailBody += linkObj.companyName + " is installing LifeWhere for you! Tap the link below to begin your account activation.";
                else 
                    emailBody += "Tap the link below to begin your LifeWhere account activation.";

                return(
                    <div>
                        <p>
                            Please copy the email body and/or the activation link for the user below
                        </p>
                        <br />
                        <div className="alerts-tab-wrapper" style={{ marginRight: '20px' }}>
                                <div className="row no-gutters" style={{ marginBottom: '10px' }}>
                                    <div className="col-2" style={{ textAlign: 'right', paddingRight: '15px' }}>
                                        <p className="semibold">Email Body:</p>
                                    </div>
                                    <div className="col-10">
                                        <textarea 
                                            className="form-control" 
                                            rows="3" onChange={this.onChange}
                                            style={{ margin: '0 0 0.75em' }}
                                            value={emailBody}/>
                                    </div>
                                </div>

                                <div className="row no-gutters">
                                    <div className="col-2" style={{ textAlign: 'right', paddingRight: '15px' }}>
                                        <p className="semibold">Link:</p>
                                    </div>
                                    <div className="col-10">
                                        <a href={linkObj.linkUrl} 
                                            className="form-control-link" 
                                            onChange={this.onChange}
                                            style={{ margin: '0 0 0.75em' }}>
                                            {linkObj.linkUrl}
                                        </a>
                                    </div>
                                </div>
                        </div>
                    </div>
                );
            }
            else {
                return(<div>
                    <p>No Link Found</p>
                </div>);
            }
        }
        else {
            return(<div>
                <p>No Link Found</p>
            </div>);
        }
    }

    render() {
        if (!this.props.show) {
            return null;
        } else {
            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Activation Link</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); }}>×</span>
                        </div>

                        <div className="modal-body">
                            <br />
                            <h6 style={{ padding: '0px 70px' }}>
                                {this.getLink(this.props.linkObj)}
                            </h6>
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); }}>Close</button>
                            <button className="primary-btn btn-small" onClick={() => { this.onCopyBoth(this.props.linkObj); }}>Copy Both</button>
                        </div>

                    </div>
                </div> 
            );
        }
    }
}

export default EmailLinkModal;