/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import "../../CSS/DataModal.css";

class UpdateAssetClassModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            removeList: [],
            applyList: [],
            loading: false
        };

        this.handleRemove = this.handleRemove.bind(this);
        this.handleApply = this.handleApply.bind(this);
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {

    };

    handleRemove(e, classId) {
        if (e.target.checked) {
            this.props.removeList = this.props.removeList.concat(classId);
        }
    };

    handleApply(e, classId) {
        if (e.target.checked) {
            this.props.applyList.concat(classId);
        }
    };

    render() {

        let assetClassesLoading = this.props.updateLoading ?
            <div className="loading" style={{ display: "block", }}>
                <div className="loading-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className="loading-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : <div>{null}</div> 

        if (!this.props.show) {
            return null;
        }
        else {
            return (
                <div>
                    <div className="modal" style={{ display: this.props.show ? (this.props.updateLoading ? "none": "block") : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Asset Classes</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ textAlign: "left" }}>
                            <p>
                                A Class is a TwinThread object that allows us to add behaviors to an Asset. Some classes represent State information like Training or New, while other classes help us define what analytics to apply to this asset.
                                Every asset should have at least three applied classes: <strong>LifePulseX</strong>, either <strong>Furnace, AirConditioner, HeatPump,</strong> or <strong>AirHandler</strong>, and either <strong>New, Reprovision, Retrain, Training,</strong> or <strong>Operational.</strong>
                            </p>
                                <div className="white-container" style={{ width: '100%', borderTop: '1px solid #ebeef0', borderLeft: '1px solid #ebeef0' }}>
                                <h6 className="sectionTitle" id="tabelLabel1">Currently Applied Classes</h6>
                                <div className="table-container-2">
                                <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel" >
                                    <thead>
                                        <tr >
                                            <th style={{ width: 340 + "px" }}>Name</th>
                                            <th>Description</th>
                                            <th style={{ width: 75 + "px" }}>Remove</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.props.appliedClasses.map((ttclass, index) =>
                                            <tr key={ttclass.classId} data={index}>
                                                <td>{ttclass.name}</td>
                                                <td>{ttclass.description}</td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <input type="checkbox" id="rc" value={ttclass.classId} className="px-1" checked={this.props.checked} onChange={this.props.handleRemove} style={{ width: 50 + "px" }} />  
                                                </td>
                                            </tr>
                                         )}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            <br />
                                <div className="white-container" style={{ width: '100%', borderTop: '1px solid #ebeef0', borderLeft: '1px solid #ebeef0' }}>
                                <h6 className="sectionTitle" id="tabelLabel1">Available Classes to Apply</h6>
                                <div className="table-container-2">
                                <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel" >
                                    <thead>
                                        <tr >
                                            <th style={{ width: 340 + "px" }}>Name</th>
                                            <th>Description</th>
                                            <th style={{ width: 75 + "px", textAlign: 'center' }}>Apply</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.props.unappliedClasses.map((ttclass, index) =>
                                            <tr key={ttclass.classId} data={index}>
                                                <td>{ttclass.name}</td>
                                                <td>{ttclass.description}</td>
                                                <td style={{ textAlign: 'center' }}><input type="checkbox" id="ac" value={ttclass.classId} className="px-1" checked={this.props.checked} onChange={this.props.handleApply} style={{ width: 50 + "px" }} /></td> 
                                            </tr>
                                         )}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>


                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={this.props.onUpdateClasses}>Update Classes</button>
                            </div>
                            
                    </div>
                    
                    </div>
                    {assetClassesLoading}
                </div>
            );
        }
    }
}

export default UpdateAssetClassModal;